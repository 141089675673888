import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Modal as ChakraModal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { ModalType } from '../../../enum/ModalType';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearCart } from '../../../store/modules/cart';
import { hideModal } from '../../../store/modules/ui';
import { getModalState } from '../../../store/modules/ui/selectors';
import ConfirmBannerADP from '../../pages/Admin/Banners/ConfirmBannerADP';
import ConfirmSubmitBanner from '../../pages/Admin/Banners/ConfirmSubmitBanner';
import ConfirmEditBranchEmail from '../../pages/Admin/ConfirmEditEmail/ConfirmEditBranchEmail';
import ConfirmEditGeneralSalesEmail from '../../pages/Admin/ConfirmEditEmail/ConfirmEditGeneralSalesEmail';
import ConfirmEditProduct from '../../pages/Admin/Products/ConfirmEditProduct';
import DeleteVoucher from '../../pages/Admin/Reservations/DeleteVoucherModal';
import ConfirmManualDeleteModal from '../../pages/Admin/Reservations/ManualDeleteModal';
import ConfirmReservation from '../../pages/ConfirmReservation/ConfirmReservation';
import DeleteProduct from '../../pages/DeleteProduct/DeleteProduct';
import FailReservation from '../../pages/FailReservation/FailReservation';
import Login from '../../pages/Login/Login';
import Logout from '../../pages/Logout/Logout';
import SuccessfulReservation from '../../pages/SuccessfulReservation/SuccessfulReservation';
import SystemSettings from '../../pages/SystemSettings/SystemSettings';
import VoucherDifferenceModal from '../../pages/VoucherDifferenceModal';

const modalsByType = {
  [ModalType.LOGIN]: Login,
  [ModalType.LOGOUT]: Logout,
  [ModalType.DELETE_PRODUCT]: DeleteProduct,
  [ModalType.CONFIRM_RESERVATION]: ConfirmReservation,
  [ModalType.SUCCESSFUL_RESERVATION]: SuccessfulReservation,
  [ModalType.FAIL_RESERVATION]: FailReservation,
  [ModalType.CONFIRM_EMAIL_BRANCH_EDIT]: ConfirmEditBranchEmail,
  [ModalType.CONFIRM_EMAIL_GENERAL_SALES_EDIT]: ConfirmEditGeneralSalesEmail,
  [ModalType.SYSTEM_SETTINGS]: SystemSettings,
  [ModalType.CONFIRM_PRODUCT_EDIT]: ConfirmEditProduct,
  [ModalType.CONFIRM_BANNER_SUBMIT]: ConfirmSubmitBanner,
  [ModalType.CONFIRM_BANNER_ADP]: ConfirmBannerADP,
  [ModalType.CONFIRM_VOUCHER_DELETE]: DeleteVoucher,
  [ModalType.CONFIRM_MANUAL_DELETE_VOUCHER]: ConfirmManualDeleteModal,
  [ModalType.VOUCHER_DIFFERENCE]: VoucherDifferenceModal,
};

const Modal = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { type, show } = useAppSelector(getModalState);
  const ModalComponent = modalsByType[type];
  const navigate = useNavigate();

  const { isMobile } = useBreakpoint();

  if (isMobile && show) {
    return (
      <Box
        data-testid="mobile-modal-container"
        position="absolute"
        top="0"
        height="100%"
        zIndex="100"
        width="100%"
      >
        <ModalComponent isMobile />
      </Box>
    );
  }

  const allowedModalClose = [
    ModalType.CONFIRM_RESERVATION,
    ModalType.CONFIRM_MANUAL_DELETE_VOUCHER,
    ModalType.CONFIRM_VOUCHER_DELETE,
    ModalType.CONFIRM_PRODUCT_EDIT,
    ModalType.CONFIRM_BANNER_SUBMIT,
    ModalType.CONFIRM_BANNER_ADP,
    ModalType.CONFIRM_EMAIL_BRANCH_EDIT,
    ModalType.CONFIRM_EMAIL_GENERAL_SALES_EDIT,
    ModalType.LOGIN,
  ];

  return (
    <ChakraModal
      closeOnOverlayClick={!allowedModalClose.includes(type)}
      onClose={() => {
        if (type === ModalType.SUCCESSFUL_RESERVATION) {
          dispatch(clearCart());
          navigate('/');
        }
        return dispatch(hideModal());
      }}
      isOpen={show}
      size={isMobile ? 'full' : 'sm'}
      isCentered
      data-testid="modal-container"
      blockScrollOnMount={true}
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent
        data-testid="modal-content"
        alignItems="center"
        backgroundColor="background"
        borderRadius="6px"
        margin={0}
      >
        <Flex flexDirection="column" justifyContent="center" width="100%">
          <ModalComponent isMobile={false} />
        </Flex>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
