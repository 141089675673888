export const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const pageSizes = [5, 10, 20];

export const branches = ['Sinsacate', 'Pocitos'];

export const SkeletonCells = [
  {
    width: '20px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
];
