import { DateTime } from 'luxon';
import { Dispatch, SetStateAction } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { FormattedStatus, Voucher } from '../../../../interfaces/voucher';
import { SelectCell, StatusText } from '../../../shared/Table/CellComponents';

interface columnProps {
  selectedVouchers: string[];
  setSelectedVouchers: Dispatch<SetStateAction<string[]>>;
  showManualDelete: boolean;
}

export const columns = ({
  selectedVouchers,
  setSelectedVouchers,
  showManualDelete,
}: columnProps): ColumnDef<Voucher>[] => {
  const columnArray: ColumnDef<Voucher>[] = [
    {
      id: 'select',
      cell: ({ row: { original, getIsSelected, getCanSelect, getToggleSelectedHandler } }) => {
        return SelectCell({
          row: { original, getIsSelected, getCanSelect, getToggleSelectedHandler },
          selectedVouchers,
          setSelectedVouchers,
        });
      },
      size: 20,
      maxSize: 20,
    },
    {
      accessorKey: 'IDTablaWS',
      header: 'Nro de reserva',
    },
    {
      accessorKey: 'clientId',
      header: 'Nro de cliente',
    },
    {
      accessorKey: 'date',
      header: 'Fecha',
      cell: ({ row: { original } }) =>
        DateTime.fromISO(original.date).toUTC().toFormat('dd/MM/yyyy'),
    },
    {
      id: 'finalPrice',
      header: 'Total a pagar ($)',
      cell: ({ row: { original } }) =>
        new Intl.NumberFormat('es-CL').format(
          original.products.reduce((acc, item) => acc + item.finalPrice, 0),
        ),
    },
    {
      accessorKey: 'status',
      header: 'Estado',
      cell: ({ row: { original } }) => StatusText(original.status as FormattedStatus),
    },
  ];

  if (showManualDelete)
    columnArray.push({
      accessorKey: 'manualDelete',
      header: 'Baja Manual',
      cell: ({ row: { original } }) => (original.manualDelete ? 'Activado' : 'Desactivado'),
    });

  return columnArray;
};
