import React, { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Image, Text } from '@chakra-ui/react';

import CleaningIcon from '../../../assets/Icons/CleaningIcon';
import DealsIcon from '../../../assets/Icons/DealsIcon';
import DrinksIcon from '../../../assets/Icons/DrinksIcon';
import ElectroHomeIcon from '../../../assets/Icons/ElectroHomeIcon';
import LubricantsIcon from '../../../assets/Icons/LubricantsIcon';
import PendingToReceive from '../../../assets/Icons/PendingToReceive';
import PerfumeryIcon from '../../../assets/Icons/PerfumeryIcon';
import PetsIcon from '../../../assets/Icons/PetsIcon';
import SeasonIcon from '../../../assets/Icons/SeasonIcon';
import StoreIcon from '../../../assets/Icons/StoreIcon';
import { CategoriesBrands } from '../../../constants/brands';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';
import GoBack from '../../shared/GoBack/GoBack';
import BrandItems from '../../shared/SubCategoryView/BrandItems';

interface CategoryIcon {
  icon: ReactElement;
  title: string;
  container: string;
  dataTestId: string;
  code: string;
}

const MobileCategories = () => {
  const { breakPoint } = useBreakpoint();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const data = [
    {
      icon: <SeasonIcon />,
      title: 'Temporada',
      container: 'season-container',
      dataTestId: 'season-icon',
      code: '01.18',
    },
    {
      icon: <StoreIcon />,
      title: 'Almacén',
      container: 'store-container',
      dataTestId: 'store-icon',
      code: '01.01',
    },
    {
      icon: <DrinksIcon />,
      title: 'Bebidas',
      container: 'drinks-container',
      dataTestId: 'drinks-icon',
      code: '01.03',
    },
    {
      icon: <ElectroHomeIcon />,
      title: 'Electro-Hogar',
      container: 'electroHome-container',
      dataTestId: 'electroHome-icon',
      code: '01.05',
    },
    {
      icon: <CleaningIcon />,
      title: 'Limpieza',
      container: 'cleaning-container',
      dataTestId: 'cleaning-icon',
      code: '01.09',
    },
    {
      icon: <PerfumeryIcon />,
      title: 'Perfumería',
      container: 'perfumery-container',
      dataTestId: 'perfumery-icon',
      code: '01.11',
    },
    {
      icon: <PetsIcon />,
      title: 'Mascotas',
      container: 'pets-container',
      dataTestId: 'pets-icon',
      code: '01.10',
    },
    {
      icon: <LubricantsIcon />,
      title: 'Lubricantes',
      container: 'lubricants-container',
      dataTestId: 'lubricants-icon',
      code: '01.12',
    },
  ];

  const URLs = {
    '/deals': 'deals',
    '/pendingToReceive': 'pendingToReceive',
  };

  const getImageUrl = () => {
    if (breakPoint === 'base') {
      return 'https://norpan-images.s3.us-west-2.amazonaws.com/banners-images/oilBannerMobile.png';
    }
    return 'https://norpan-images.s3.us-west-2.amazonaws.com/banners-images/oilBannerDesktop.svg';
  };

  const url = URLs[pathname as keyof typeof URLs] || 'category';

  return (
    <Flex width="100%" height="100%" flexDirection="column" data-testid="categories-container">
      <GoBack title={textContent.categories} />
      <Flex
        flexDirection="column"
        minHeight={{ base: '150px', md: '220px' }}
        width={{ md: '100%' }}
        backgroundColor="white"
        borderRadius="8px"
        justifyContent="flex-start"
      >
        <Text
          margin={{ base: '20px 30px 0' }}
          color="darkBlue"
          fontWeight="500"
          fontSize={{ base: '16px', md: '18px' }}
        >
          {textContent.category.SelectFavoriteBrand}
        </Text>
        <Flex
          data-testid="brands-carousel"
          paddingRight={{ base: '20px', xl: '10px' }}
          paddingLeft={{ base: '20px', xl: '10px' }}
          alignItems="start"
          justifyContent={{ base: 'flex-start', md: 'space-evenly' }}
          overflowX="scroll"
          gap="20px"
        >
          {CategoriesBrands.map(({ brand, image }, index: number) => (
            <BrandItems
              key={`${brand}-${index}`}
              navigationPath={`brand/${brand}`}
              brandIcon={image}
              title={brand}
              loading={false}
            />
          ))}
        </Flex>
      </Flex>
      <Flex justifyContent="center" marginBottom="16px" marginTop="24px">
        <Image
          src={getImageUrl()}
          alt="Banner Aceite"
          data-testid="oil-banner-mobile"
          width="100%"
          onClick={() => navigate('/category/01.02')}
          cursor="pointer"
          marginRight="25px"
          marginLeft="17px"
        />
      </Flex>
      <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" padding="0 22px">
        <Button
          justifyContent="space-between"
          marginBottom="16px"
          backgroundColor="greyOnHover"
          color="darkBlue"
          width="155px"
          padding="16px"
          align-items="center"
          flex-shrink="0"
          fontFamily="Roboto"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="normal"
          height="56px"
          data-testid="deals-button"
          onClick={() => navigate('/deals')}
        >
          <DealsIcon />
          {textContent.deals}
        </Button>
        <Button
          justifyContent="space-between"
          marginBottom="16px"
          backgroundColor="greyOnHover"
          color="darkBlue"
          width="155px"
          padding="16px"
          align-items="center"
          flex-shrink="0"
          fontFamily="Roboto"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="normal"
          height="56px"
          data-testid="pending-to-receive-button"
          onClick={() => navigate('/pendingToReceive')}
        >
          <PendingToReceive />
          {textContent.onTrip}
        </Button>
        {data.map((category: CategoryIcon, index: number) => (
          <Button
            justifyContent="space-between"
            marginBottom="16px"
            key={`${index}-${category.title}`}
            backgroundColor="greyOnHover"
            color="darkBlue"
            width="155px"
            padding="16px"
            align-items="center"
            flex-shrink="0"
            fontFamily="Roboto"
            fontSize="12px"
            fontStyle="normal"
            fontWeight="600"
            lineHeight="normal"
            height="56px"
            data-testid={category.dataTestId}
            onClick={() => navigate(`/${url}/${category.code}`)}
          >
            {category.icon}
            {category.title}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default MobileCategories;
