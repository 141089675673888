import * as yup from 'yup';

import textContent from '../../../utils/textContent.json';

export const AddProductToCartValidation = (availableStock: number) => {
  return yup.object({
    quantitySelection: yup
      .number()
      .max(availableStock, textContent.product.messageMaximum)
      .required(textContent.product.messageRequired)
      .positive(textContent.product.messagePositive)
      .integer(textContent.product.messageInteger)
      .typeError(textContent.product.messageRequired),
  });
};
