import { get, URL } from '../api';
import { ProductDeals, ProductStatus } from '../enum/Product';
import { GetBrandsResponse } from '../interfaces/brands';

interface GetBrandsByCategoryProps {
  categoryCode: string;
  status?: ProductStatus;
  deals?: ProductDeals;
  onlyStock?: boolean;
}

export const getBrandsByCategory = async ({
  categoryCode,
  status,
  deals,
  onlyStock = false,
}: GetBrandsByCategoryProps) => {
  const urlSearchParams = new URLSearchParams();
  if (status) {
    urlSearchParams.set('status', status);
  }
  if (deals) {
    urlSearchParams.set('deals', deals);
  }
  const response = await get<GetBrandsResponse>(
    `${URL.BRAND}/${categoryCode}?${urlSearchParams.toString()}&onlyStock=${onlyStock}`,
  );

  return response.data.brands;
};
