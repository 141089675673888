import * as React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Props } from '../Types';

export const CbseBrand = ({ dataTestId }: Props) => {
  const { breakPoint } = useBreakpoint();

  return (
    <svg
      cursor="pointer"
      data-testid={dataTestId}
      width={breakPoint === 'xl' ? '105' : '51'}
      height={breakPoint === 'xl' ? '42' : '20'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105 41"
      fill="none"
    >
      <g clipPath="url(#clip0_2399_60163)">
        <path
          d="M48.5679 21.5727C49.4009 22.2539 50.219 22.7865 50.8646 23.4796C52.8698 25.6365 53.3755 28.2723 53.1137 31.1135C52.965 32.7348 52.5217 34.2491 51.5727 35.6087C50.5046 37.1408 48.91 37.9262 47.3006 38.6908C45.1169 39.729 42.7756 40.1158 40.3897 40.1485C36.305 40.2021 32.2204 40.1664 28.1357 40.1634C27.1599 40.1634 27.1212 40.1277 27.1212 39.1757C27.1212 27.7636 27.1212 16.3516 27.1123 4.93951C27.1123 4.33261 27.2045 3.97561 27.9542 3.98454C32.5327 4.02916 37.1142 3.94586 41.6898 4.08569C44.3107 4.16601 46.771 4.94844 48.9041 6.62335C50.7992 8.11085 51.8285 10.0624 52.0219 12.371C52.1587 13.9984 52.0219 15.6792 51.7601 17.2976C51.531 18.7018 50.695 19.8859 49.5586 20.8022C49.2343 21.061 48.9071 21.3139 48.5679 21.5786V21.5727ZM40.9639 28.4865C40.9639 27.0288 40.5831 26.5439 39.3752 26.3892C38.9736 26.3386 38.7505 26.4338 38.7505 26.8652C38.7505 27.8469 38.7505 28.8287 38.7505 29.8104C38.7505 30.215 38.9557 30.3638 39.3484 30.3757C40.4432 30.4084 40.9668 29.8104 40.9668 28.4895L40.9639 28.4865ZM38.7415 14.2869C38.7415 14.2869 38.7445 14.2869 38.7475 14.2869C38.7475 14.7808 38.7118 15.2746 38.7653 15.7625C38.7862 15.9499 38.9736 16.2326 39.1402 16.2772C39.8542 16.4676 40.6634 15.9916 40.7735 15.2597C40.8627 14.6618 40.8359 14.0281 40.7378 13.4301C40.6842 13.0999 40.4432 12.6953 40.1636 12.5317C39.8393 12.3413 39.3752 12.3056 38.9885 12.3532C38.8754 12.3681 38.7862 12.8857 38.7534 13.1802C38.7118 13.5462 38.7445 13.918 38.7445 14.2869H38.7415Z"
          fill="#E2001A"
        />
        <path
          d="M73.7875 12.5099C72.7255 12.3195 71.7645 12.1291 70.7977 11.9833C70.4615 11.9327 70.1075 11.9535 69.7683 11.9952C67.9833 12.2064 67.1593 13.6106 68.0458 15.1993C68.6735 16.3238 69.5422 17.3323 70.405 18.3022C73.6031 21.9019 75.162 26.0609 74.0047 30.7823C72.9902 34.9205 70.4258 37.9669 66.1805 39.3443C63.8392 40.1059 61.4265 40.3915 59.0108 40.1297C57.6244 39.9809 56.2857 39.3383 54.944 38.8683C54.7506 38.7998 54.5304 38.4637 54.5304 38.2495C54.5066 35.1644 54.5185 32.0764 54.5304 28.9913C54.5304 28.9259 54.581 28.8574 54.6405 28.7117C55.1611 28.9199 55.6669 29.1609 56.1935 29.3186C56.7587 29.4881 57.3388 29.6488 57.9249 29.6904C59.9092 29.8422 61.3521 27.837 60.5964 25.8914C60.2751 25.0643 59.7396 24.2849 59.1684 23.5977C58.4782 22.7647 57.6482 22.0477 56.8747 21.2861C54.5007 18.9477 53.5963 16.0442 53.7748 12.8074C53.9414 9.77883 54.9142 6.99126 56.9729 4.70647C59.2934 2.13311 62.194 0.719988 65.7313 0.761637C67.8941 0.788412 70.0093 0.969887 72.0085 1.93973C73.3115 2.57043 74.0107 3.34393 73.8917 4.93852C73.7251 7.17274 73.853 9.43076 73.847 11.6769C73.847 11.9595 73.8054 12.2391 73.7846 12.5099H73.7875Z"
          fill="#E2001A"
        />
        <path
          d="M90.4322 29.6227C94.5913 30.5836 97.8221 29.0337 100.497 25.5113C100.541 25.9784 100.589 26.2312 100.589 26.4811C100.595 29.471 100.583 32.4579 100.601 35.4478C100.604 36.007 100.431 36.3968 100.015 36.7954C97.6317 39.0653 94.7727 40.0411 91.5241 40.0739C89.2125 40.0947 86.895 40.0649 84.7441 39.1189C80.5434 37.2774 77.6368 34.1655 76.1642 29.831C73.9984 23.4585 76.9674 15.9616 82.8847 12.7992C86.3565 10.9428 90.0871 10.2526 93.9338 11.2105C98.7057 12.3975 102.088 15.3636 104.159 19.8231C104.438 20.4241 104.314 20.7394 103.76 21.0756C99.8302 23.4526 95.9151 25.8564 91.9971 28.2513C91.4348 28.5964 90.8785 28.9504 90.3222 29.3014C90.3608 29.4085 90.3995 29.5186 90.4382 29.6257L90.4322 29.6227ZM85.8656 22.7029C85.8656 23.0331 86.0828 23.1997 86.5558 22.9141C87.484 22.3489 88.4152 21.7896 89.3285 21.1976C89.8015 20.8911 89.742 20.5817 89.2155 20.3556C87.8975 19.7963 85.8894 21.0875 85.8686 22.7029H85.8656Z"
          fill="#E2001A"
        />
        <path
          d="M25.1016 14.866C24.3222 14.5834 23.6439 14.2859 22.9358 14.0895C17.9527 12.7151 14.0406 16.419 13.1481 20.096C12.7048 21.9257 12.6305 23.7999 13.3891 25.5462C14.4006 27.8756 16.3254 29.2025 18.7441 29.8362C20.6838 30.3449 22.6056 30.33 24.4917 29.5714C24.6673 29.5 24.8636 29.4821 25.1403 29.4167C25.179 29.7529 25.2355 30.0325 25.2355 30.3151C25.2414 33.0819 25.2206 35.8486 25.2504 38.6154C25.2593 39.3085 25.0064 39.6715 24.3727 39.963C22.68 40.7395 20.9009 40.8674 19.0832 40.8496C15.6174 40.8198 12.2645 40.2694 9.2717 38.4369C5.44289 36.0926 2.76242 32.8201 1.59028 28.4171C0.447881 24.1361 0.433006 19.8521 1.9205 15.6752C3.95242 9.96621 7.91213 6.14335 13.7372 4.32265C16.0487 3.59973 18.4287 3.31711 20.8414 3.50453C22.0909 3.59973 23.3374 3.84963 24.5631 4.1144C24.8279 4.17093 25.1998 4.59635 25.2028 4.85517C25.2444 8.06519 25.2295 11.2782 25.2236 14.4882C25.2236 14.6012 25.1552 14.7113 25.1016 14.869V14.866Z"
          fill="#E2001A"
        />
        <path
          d="M91.2111 3.99857C91.8537 3.99857 92.4963 3.99857 93.136 3.99857C93.6387 3.99857 93.8262 4.18599 93.5763 4.68282C92.8206 6.17626 92.0947 7.68458 91.3034 9.16017C91.1754 9.40115 90.7857 9.62725 90.5061 9.63915C89.3964 9.6927 88.2838 9.65105 87.1741 9.66592C86.5821 9.67485 86.4958 9.40412 86.6892 8.92218C87.2723 7.46146 87.8286 5.98884 88.4533 4.54597C88.5604 4.29904 88.9383 4.05212 89.2149 4.01939C89.8724 3.94204 90.5447 3.99559 91.2111 3.99559V3.99857Z"
          fill="#E2001A"
        />
        <path
          d="M78.4073 38.3227C78.4073 39.7953 77.253 40.9466 75.7506 40.9466C74.2483 40.9466 73.0791 39.7923 73.0791 38.3227C73.0791 36.853 74.2631 35.7314 75.7506 35.7314C77.2381 35.7314 78.4073 36.8857 78.4073 38.3227ZM73.7455 38.3227C73.7455 39.477 74.5993 40.3933 75.7685 40.3933C76.9377 40.3933 77.7439 39.477 77.7439 38.3405C77.7439 37.2041 76.9079 36.255 75.7536 36.255C74.5993 36.255 73.7455 37.1862 73.7455 38.3256V38.3227ZM75.3431 39.6822H74.7421V37.091C74.9801 37.0434 75.3103 37.0107 75.7387 37.0107C76.2296 37.0107 76.4498 37.091 76.6402 37.2011C76.783 37.3112 76.893 37.5164 76.893 37.7693C76.893 38.0549 76.6729 38.2751 76.3575 38.3703V38.403C76.6104 38.4982 76.7532 38.6886 76.8306 39.0337C76.9109 39.4294 76.9555 39.587 77.021 39.6822H76.3724C76.2951 39.587 76.2475 39.349 76.1671 39.0486C76.1195 38.763 75.9619 38.638 75.6316 38.638H75.346V39.6822H75.3431ZM75.3579 38.2126H75.6435C75.9767 38.2126 76.2445 38.1025 76.2445 37.8318C76.2445 37.5938 76.0719 37.4361 75.6911 37.4361C75.5335 37.4361 75.4234 37.451 75.3579 37.4659V38.2096V38.2126Z"
          fill="#E2001A"
        />
      </g>
      <defs>
        <clipPath id="clip0_2399_60163">
          <rect
            width="103.533"
            height="40.1862"
            fill="white"
            transform="translate(0.766602 0.760742)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
