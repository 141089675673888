import React from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';

import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/types';
import textContent from '../../../utils/textContent.json';
import Button from '../Button/Button';

const CustomerButton = () => {
  const { isOpen } = useAppSelector((state: RootState) => state.sideMenuIsOpen);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      fontStyle="normal"
      fontWeight="500"
      lineHeight="normal"
      color="darkBlue"
      minHeight="110px"
      width="80%"
      boxShadow="0px 1.62145px 3.2429px 0px rgba(0, 17, 51, 0.15);"
      backgroundColor="var(--tonos-y-matices-white-white-50, #FFF)"
      padding="10px 0"
      border="0.811px solid #E2E8F0"
      borderRadius="6.486px"
      position={!isOpen ? 'fixed' : 'initial'}
      bottom={!isOpen ? '20px' : ''}
      data-testid={'customer-button-container'}
    >
      <Text fontSize="16px" data-testid="title-customer-button" textAlign="center" padding="10px">
        {textContent.customerButton.title}
      </Text>
      <Text
        fontSize="12px"
        margin="0 0 16px 0"
        data-testid="sub-title-customer-button"
        textAlign="center"
        fontWeight="300"
        padding="0 10px"
      >
        {!isOpen ? textContent.customerButton.subTitle : ''}
      </Text>
      <Link
        href={`https://wa.me/5493525521001?text=${textContent.newClientCodeInfoWhatsappMessage}`}
        isExternal
      >
        <Button
          color="white"
          backgroundColor="purple"
          fontSize="16px"
          _hover={{ backgroundColor: 'darkPurple' }}
          data-testid="customer-button"
        >
          {textContent.customerButton.customerButtonText}
        </Button>
      </Link>
    </Flex>
  );
};

export default CustomerButton;
