import './ProductsCarousel.css';
import React from 'react';
import { Flex } from '@chakra-ui/react';

import { ProductType } from '../../../../interfaces';
import ProductCard from '../../../shared/ProductCard/ProductCard';

interface Props {
  backgroundColor: string;
  dataTestId: string;
  productsList: ProductType[];
  isLoading: boolean;
  carouselTitle: string;
  urlKey: string;
}

const ProductsCarousel = ({
  backgroundColor,
  dataTestId,
  productsList,
  carouselTitle,
  urlKey,
}: Props) => {
  return (
    <Flex
      padding="50px 0"
      height="auto"
      minHeight={{ base: '380px', xl: '450px' }}
      backgroundColor={backgroundColor}
      alignItems="center"
      overflowX="scroll"
      data-testid={dataTestId}
      gap="10px"
      paddingLeft="5px"
      className="products-carousel-container"
    >
      {productsList.slice(0, 6).map((product, index) => (
        <ProductCard
          key={`${product.iDArticlePrice}-${index}`}
          product={product}
          carouselTitle={carouselTitle}
          urlKey={urlKey}
        />
      ))}
    </Flex>
  );
};

export default ProductsCarousel;
