import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

const SellsInformationSkeleton = () => {
  return (
    <Flex direction="column" data-testid="sells-information-view-skeleton">
      <Skeleton
        height={{ base: '55px', md: '130px' }}
        width="388px"
        borderRadius="12px"
        marginBottom={'80px'}
      ></Skeleton>
    </Flex>
  );
};

export default SellsInformationSkeleton;
