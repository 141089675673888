import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import { getQuotations } from './actions';
import { InitialState } from './types';

export const quotationsSlice = createSlice({
  name: 'quotations',
  initialState: {
    quotation: [],
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {
    clearQuotationsData: () => {
      return {
        quotation: [],
        loading: false,
        error: undefined,
      } as InitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuotations.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getQuotations.fulfilled, (state, action) => {
        state.quotation = action.payload;
        state.loading = false;
      })
      .addCase(getQuotations.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      });
  },
});

export const quotationsReducer = quotationsSlice.reducer;
export const { clearQuotationsData } = quotationsSlice.actions;
