import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { ProductType } from '../../../interfaces';
import { getBrandsByCategory } from '../../../services/brands';
import { getProductsByCategory } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { categoryData } from '../../../store/modules/categories';
import { RootState } from '../../../store/types';
import { categoryOrder } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';
import { getProductByCategoryKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import BrandsCarousel from '../BrandsCasousel/BrandsCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';
import SubCategoryViewSkeleton from './SubCategoryViewSkeleton';

const SubCategoryViewMobile = (): JSX.Element => {
  const { id, subCategoryId: categoryCode } = useParams();

  const { data: brands, isLoading: isLoadingBrands } = useSWR(
    categoryCode && { categoryCode: categoryCode, key: SWRKey.products },
    getBrandsByCategory,
  );
  const { data, isLoading, error, size, setSize } = useSWRInfinite(
    getProductByCategoryKey(categoryCode as string),
    getProductsByCategory,
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }

    const totalPages = data[0].totalPages;
    return {
      brands: data,
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  const categories = useAppSelector((state: RootState) => categoryData(state));

  const categoryItem = useMemo(
    () => categoryOrder(categories.data).find((category) => category.code === id),
    [categories.data, id],
  );

  const subCategoryName = useMemo(() => {
    const name: string[] = [];
    categoryItem?.item.forEach((item) => {
      if (item.code === categoryCode) name.push(item.subCategory);
      item.subItems.forEach((subItem) => {
        if (subItem.code === categoryCode) name.push(subItem.description);
      });
    });
    products.forEach((product: ProductType) => {
      if (product.operatingCenterCode === categoryCode)
        name.push(product?.operatingCenterDesc as string);
    });
    return name[0];
  }, [categoryItem?.item, products, categoryCode]);

  if (isLoading || isLoadingBrands) {
    return <SubCategoryViewSkeleton />;
  }

  if (categories.error || !categoryItem || error || products.length === 0) {
    return (
      <ErrorScreen
        title={textContent.product.associatedProductsNotFound}
        arrowTitle={transformString(categoryItem?.category)}
      />
    );
  }

  return (
    <Flex justifyContent="center" direction="column">
      <GoBack
        title={transformString(categoryItem?.category)}
        subTitle={transformString(subCategoryName)}
      />
      <Flex
        flexDirection="column"
        alignItems={{ md: 'center' }}
        backgroundColor={{ base: 'white', md: 'grey50' }}
        padding={{ md: '30px 0' }}
      >
        <Flex
          flexDirection="column"
          minHeight={{ base: '150px', md: '220px' }}
          width={{ md: '85%' }}
          backgroundColor="white"
          borderRadius="8px"
          justifyContent="flex-start"
        >
          <Text
            margin={{ base: '20px 30px 0' }}
            color="darkBlue"
            fontWeight="500"
            fontSize={{ base: '16px', md: '18px' }}
          >
            {textContent.category.SelectFavoriteBrand}
          </Text>
          <BrandsCarousel brands={brands} isLoading={categories.loading} />
        </Flex>
      </Flex>
      <Flex justifyContent="flex-start" direction="column" height="100%" backgroundColor="grey50">
        <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
          <InfiniteScroll
            dataLength={products.length}
            next={() => setSize(size + 1)}
            hasMore={hasMore}
            loader={<ProductCardSkeleton array={2} />}
          >
            <Grid
              gridTemplateColumns={{
                base: 'repeat(auto-fill, minmax(150px, 1fr))',
                xl: 'repeat(auto-fill, minmax(200px, 1fr))',
              }}
              data-testid="product-list"
              row={'initial'}
            >
              {products.map((product: ProductType, index: number) => (
                <Flex justifyContent={'center'} key={index} margin="20px 0">
                  <ProductCard
                    product={product}
                    data-testid={`product-card-${index}`}
                    urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_CATEGORY}?categoryCode=${categoryCode}`}
                  />
                </Flex>
              ))}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubCategoryViewMobile;
