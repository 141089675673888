import { VoucherProductWithStock } from '../../../interfaces/voucher';
import { CartItem } from '../cart/types';
import { InitialState } from './slice';

export interface ConfirmReservationData {
  products: CartItem[] | VoucherProductWithStock[];
}

export interface BranchData {
  branchId: string;
  branch: string;
  branchEmail: string;
}

export const isBranchData = (data: InitialState['modal']['data']): data is BranchData => {
  return typeof data === 'object' && 'branchId' in data;
};
