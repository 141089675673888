import React from 'react';

interface Props {
  dataTestId: string;
  isMobile: boolean;
}

const EmptyCartIcon = ({ dataTestId, isMobile }: Props) => (
  <svg
    data-testid={dataTestId}
    width={isMobile ? '200px' : '257px'}
    height={isMobile ? '160px' : '205px'}
    viewBox="0 0 257 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.195312" width="256" height="204.8" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.7075 137.217H157.892C158.419 137.217 158.933 137.16 159.428 137.052C159.922 137.16 160.436 137.217 160.964 137.217H214.212C218.17 137.217 221.38 134.008 221.38 130.049C221.38 126.09 218.17 122.881 214.212 122.881H208.068C204.109 122.881 200.9 119.672 200.9 115.713C200.9 111.754 204.109 108.545 208.068 108.545H227.524C231.482 108.545 234.692 105.336 234.692 101.377C234.692 97.4183 231.482 94.2091 227.524 94.2091H204.996C208.954 94.2091 212.164 90.9999 212.164 87.0411C212.164 83.0823 208.954 79.8731 204.996 79.8731H139.46C143.418 79.8731 146.628 76.6639 146.628 72.7051C146.628 68.7463 143.418 65.5371 139.46 65.5371H81.0915C77.1327 65.5371 73.9235 68.7463 73.9235 72.7051C73.9235 76.6639 77.1327 79.8731 81.0915 79.8731H40.1315C36.1727 79.8731 32.9635 83.0823 32.9635 87.0411C32.9635 90.9999 36.1727 94.2091 40.1315 94.2091H65.7315C69.6903 94.2091 72.8995 97.4183 72.8995 101.377C72.8995 105.336 69.6903 108.545 65.7315 108.545H24.7715C20.8127 108.545 17.6035 111.754 17.6035 115.713C17.6035 119.672 20.8127 122.881 24.7715 122.881H64.7075C60.7487 122.881 57.5395 126.09 57.5395 130.049C57.5395 134.008 60.7487 137.217 64.7075 137.217ZM231.62 137.217C235.578 137.217 238.788 134.008 238.788 130.049C238.788 126.09 235.578 122.881 231.62 122.881C227.661 122.881 224.452 126.09 224.452 130.049C224.452 134.008 227.661 137.217 231.62 137.217Z"
      fill="#EBF1FF"
    />
    <path
      d="M171.204 151.552H194.17M65.7318 151.552H87.7008H65.7318ZM52.5508 151.552H59.5878H52.5508ZM198.983 151.552H201.924H198.983Z"
      stroke="#76A4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.547 44.3415L111.913 57.0935M153.897 44.3415L142.531 57.0935L153.897 44.3415ZM127.171 39.9355V57.0935V39.9355Z"
      stroke="#FF923F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.2194 72.7021H159.328L153.587 81.3138L161.242 87.0549H93.3057L101.917 81.3138L95.2194 72.7021Z"
      fill="#EBF1FF"
    />
    <rect x="91.3311" y="84.9902" width="72.704" height="76.8" rx="1.6" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.0264 126.98V91.7947C96.0264 90.3451 97.2144 89.1699 98.68 89.1699L161.12 154.79C161.12 156.723 159.573 158.29 157.664 158.29H99.4823C97.5736 158.29 96.0264 156.723 96.0264 154.79V141.714V138.166V126.98ZM96.0264 134.577V130.636V134.577Z"
      fill="#EBF1FF"
    />
    <path
      d="M92.3555 127.448V88.9682C92.3555 87.3828 93.6565 86.0977 95.2613 86.0977H161.743C162.788 86.0977 163.635 86.9544 163.635 88.0114V157.862C163.635 159.975 161.941 161.689 159.851 161.689H96.1398C94.0498 161.689 92.3555 159.975 92.3555 157.862V143.561V139.681M92.3555 135.756V131.446"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M94.2625 86.0977V74.6154C94.2625 73.5585 95.0314 72.7017 95.9799 72.7017H159.525C160.473 72.7017 161.242 73.5585 161.242 74.6154V86.0977"
      stroke="#5C92FF"
      strokeWidth="2"
    />
    <path
      d="M111.964 106.192C113.814 106.192 115.313 104.693 115.313 102.843C115.313 100.994 113.814 99.4941 111.964 99.4941C110.115 99.4941 108.615 100.994 108.615 102.843C108.615 104.693 110.115 106.192 111.964 106.192Z"
      fill="#E8F0FE"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M143.54 106.192C145.39 106.192 146.889 104.693 146.889 102.843C146.889 100.994 145.39 99.4941 143.54 99.4941C141.691 99.4941 140.191 100.994 140.191 102.843C140.191 104.693 141.691 106.192 143.54 106.192Z"
      fill="#E8F0FE"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M143.063 106.192C143.063 114.648 136.208 121.502 127.753 121.502C119.298 121.502 112.443 114.648 112.443 106.192"
      stroke="#FF923F"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M95.2779 73.6543L102.014 80.3038C102.417 80.7011 102.421 81.3495 102.024 81.7519C101.955 81.8216 101.876 81.881 101.791 81.9284L94.2627 86.0964"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M160.389 73.7266L153.943 80.3011C153.547 80.7049 153.553 81.3533 153.957 81.7492C154.025 81.8153 154.101 81.8717 154.184 81.917L161.851 86.0993"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default EmptyCartIcon;
