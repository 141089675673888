import React from 'react';

import { CategoriesIconPros } from './DrinksIcon';

const SuccessfulReservationIcon = ({ dataTestId }: CategoriesIconPros) => {
  return (
    <svg
      width="201"
      height="160"
      viewBox="0 0 201 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
    >
      <rect x="0.5" width="200" height="160" fill="#FAFAFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8996 107.2H123.7C124.112 107.2 124.513 107.155 124.9 107.071C125.286 107.155 125.688 107.2 126.1 107.2H167.7C170.792 107.2 173.3 104.693 173.3 101.6C173.3 98.5072 170.792 95.9999 167.7 95.9999H162.9C159.807 95.9999 157.3 93.4927 157.3 90.3999C157.3 87.3072 159.807 84.7999 162.9 84.7999H178.1C181.192 84.7999 183.7 82.2927 183.7 79.1999C183.7 76.1072 181.192 73.5999 178.1 73.5999H160.5C163.592 73.5999 166.1 71.0927 166.1 68C166.1 64.9072 163.592 62.4 160.5 62.4H109.3C112.392 62.4 114.9 59.8927 114.9 56.8C114.9 53.7072 112.392 51.2 109.3 51.2H63.6996C60.6068 51.2 58.0996 53.7072 58.0996 56.8C58.0996 59.8927 60.6068 62.4 63.6996 62.4H31.6996C28.6068 62.4 26.0996 64.9072 26.0996 68C26.0996 71.0927 28.6068 73.5999 31.6996 73.5999H51.6996C54.7924 73.5999 57.2996 76.1072 57.2996 79.1999C57.2996 82.2927 54.7924 84.7999 51.6996 84.7999H19.6996C16.6068 84.7999 14.0996 87.3072 14.0996 90.3999C14.0996 93.4927 16.6068 95.9999 19.6996 95.9999H50.8996C47.8068 95.9999 45.2996 98.5072 45.2996 101.6C45.2996 104.693 47.8068 107.2 50.8996 107.2ZM181.3 107.2C184.392 107.2 186.9 104.693 186.9 101.6C186.9 98.5072 184.392 96 181.3 96C178.207 96 175.7 98.5072 175.7 101.6C175.7 104.693 178.207 107.2 181.3 107.2Z"
        fill="#F3F7FF"
      />
      <path
        d="M138.5 36V40.8"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.5 47.2V52"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.5 43.9999H135.3"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.7 43.9999H146.5"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1002 118.398V121.598"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1002 127.999V131.199"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.7002 124.798H68.9002"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.2998 124.798H78.4998"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.069 104.901C130.182 103.539 131.182 102.114 132.069 100.639C134.021 97.3955 135.428 93.9108 136.288 90.318C137.157 86.6845 137.467 82.9405 137.216 79.2237C136.996 75.9805 136.349 72.758 135.272 69.6479C134.205 66.5623 132.714 63.5872 130.8 60.8121C129.66 59.1584 128.369 57.5757 126.927 56.0829C124.81 53.8912 122.489 52.0176 120.022 50.463C117.371 48.7927 114.553 47.4908 111.641 46.5587C107.995 45.3915 104.202 44.8043 100.409 44.8001C97.4574 44.7968 94.5056 45.1463 91.6222 45.85C88.376 46.6423 85.2164 47.8835 82.2415 49.5754C79.6263 51.0628 77.1538 52.8985 74.8906 55.0838C72.0464 57.83 69.7426 60.9258 67.9812 64.2389C66.2567 67.4826 65.0521 70.9346 64.3694 74.471C63.6661 78.1144 63.5167 81.8474 63.9233 85.5343C64.4504 90.3129 65.9117 95.0142 68.3117 99.3429C69.8474 102.113 71.7675 104.73 74.0732 107.117C77.7335 110.907 82.0069 113.746 86.5838 115.628C92.1497 117.917 98.1647 118.791 104.072 118.24C109.03 117.777 113.912 116.31 118.389 113.832"
        fill="white"
      />
      <path
        d="M129.069 104.901C130.182 103.539 131.182 102.114 132.069 100.639C134.021 97.3955 135.428 93.9108 136.288 90.318C137.157 86.6845 137.467 82.9405 137.216 79.2237C136.996 75.9805 136.349 72.758 135.272 69.6479C134.205 66.5623 132.714 63.5872 130.8 60.8121C129.66 59.1584 128.369 57.5757 126.927 56.0829C124.81 53.8912 122.489 52.0176 120.022 50.463C117.371 48.7927 114.553 47.4908 111.641 46.5587C107.995 45.3915 104.202 44.8043 100.409 44.8001C97.4574 44.7968 94.5056 45.1463 91.6222 45.85C88.376 46.6423 85.2164 47.8835 82.2415 49.5754C79.6263 51.0628 77.1538 52.8985 74.8906 55.0838C72.0464 57.83 69.7426 60.9258 67.9812 64.2389C66.2567 67.4826 65.0521 70.9346 64.3694 74.471C63.6661 78.1144 63.5167 81.8474 63.9233 85.5343C64.4504 90.3129 65.9117 95.0142 68.3117 99.3429C69.8474 102.113 71.7675 104.73 74.0732 107.117C77.7335 110.907 82.0069 113.746 86.5838 115.628C92.1497 117.917 98.1647 118.791 104.072 118.24C109.03 117.777 113.912 116.31 118.389 113.832"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.061 112.303C123.386 110.807 125.453 108.94 126.798 107.433L121.061 112.303Z"
        fill="white"
      />
      <path
        d="M121.061 112.303C123.386 110.807 125.453 108.94 126.798 107.433"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M134.1 83.6936C133.872 83.6977 133.642 83.6997 133.412 83.6997C113.21 83.6997 96.6673 67.949 95.2811 48C79.2005 50.5365 66.9004 64.5221 66.9004 81.3948C66.9004 100.065 81.9608 115.2 100.539 115.2C118.348 115.2 132.924 101.292 134.1 83.6936Z"
        fill="#EBF1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.7189 80.2982C86.7402 79.3945 85.2222 79.4639 84.3282 80.4532C83.4343 81.4425 83.5029 82.977 84.4815 83.8807L95.7767 94.3111C97.1468 95.5763 99.2721 95.4791 100.524 94.0941C100.576 94.0353 100.576 94.0353 100.626 93.9748L116.746 74.3772C117.593 73.3469 117.454 71.8172 116.435 70.9606C115.415 70.1039 113.902 70.2447 113.055 71.275L97.9002 89.6999L87.7189 80.2982Z"
        fill="white"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.1591 51.5305C87.599 52.1158 86.075 52.827 84.6027 53.6645C82.3149 54.9658 80.1519 56.5719 78.1719 58.4839C77.3603 59.2677 76.599 60.084 75.888 60.9288M73.6881 63.8703C73.1228 64.7245 72.6025 65.6003 72.1274 66.4941C71.7764 67.1543 71.4501 67.8243 71.1485 68.5028"
        fill="white"
      />
      <path
        d="M89.5982 52.7008C90.2445 52.4583 90.5719 51.7378 90.3295 51.0914C90.087 50.445 89.3664 50.1176 88.72 50.3601L89.5982 52.7008ZM84.6027 53.6645L85.2207 54.751L84.6027 53.6645ZM78.1719 58.4839L77.3036 57.5847L78.1719 58.4839ZM74.9316 60.124C74.4871 60.6522 74.555 61.4407 75.0832 61.8852C75.6114 62.3297 76.3999 62.2619 76.8444 61.7337L74.9316 60.124ZM74.7305 64.5601C75.1115 63.9844 74.9536 63.2088 74.3779 62.8278C73.8022 62.4469 73.0267 62.6047 72.6457 63.1804L74.7305 64.5601ZM72.1274 66.4941L73.2311 67.0808L72.1274 66.4941ZM70.0063 67.9949C69.7258 68.6258 70.0098 69.3645 70.6406 69.645C71.2714 69.9254 72.0102 69.6414 72.2906 69.0106L70.0063 67.9949ZM88.72 50.3601C87.0987 50.9684 85.5149 51.7076 83.9847 52.5779L85.2207 54.751C86.6351 53.9465 88.0992 53.2632 89.5982 52.7008L88.72 50.3601ZM83.9847 52.5779C81.6067 53.9305 79.3595 55.5994 77.3036 57.5847L79.0402 59.3831C80.9443 57.5444 83.0231 56.001 85.2207 54.751L83.9847 52.5779ZM77.3036 57.5847C76.461 58.3985 75.6703 59.2463 74.9316 60.124L76.8444 61.7337C77.5277 60.9217 78.2597 60.1369 79.0402 59.3831L77.3036 57.5847ZM72.6457 63.1804C72.0582 64.0683 71.5174 64.9784 71.0236 65.9074L73.2311 67.0808C73.6876 66.2221 74.1874 65.3808 74.7305 64.5601L72.6457 63.1804ZM71.0236 65.9074C70.6589 66.5935 70.3198 67.2898 70.0063 67.9949L72.2906 69.0106C72.5805 68.3587 72.894 67.715 73.2311 67.0808L71.0236 65.9074Z"
        fill="#75A4FE"
      />
    </svg>
  );
};

export default SuccessfulReservationIcon;
