import * as React from 'react';

interface Props {
  dataTestId: string;
}

const SwapBannerIcon = ({ dataTestId }: Props) => (
  <svg
    data-testid={dataTestId}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16732 14.9999C9.16732 15.9166 8.41732 16.6666 7.50065 16.6666C6.58398 16.6666 5.83398 15.9166 5.83398 14.9999C5.83398 14.0833 6.58398 13.3333 7.50065 13.3333C8.41732 13.3333 9.16732 14.0833 9.16732 14.9999ZM7.50065 8.33325C6.58398 8.33325 5.83398 9.08325 5.83398 9.99992C5.83398 10.9166 6.58398 11.6666 7.50065 11.6666C8.41732 11.6666 9.16732 10.9166 9.16732 9.99992C9.16732 9.08325 8.41732 8.33325 7.50065 8.33325ZM7.50065 3.33325C6.58398 3.33325 5.83398 4.08325 5.83398 4.99992C5.83398 5.91659 6.58398 6.66659 7.50065 6.66659C8.41732 6.66659 9.16732 5.91659 9.16732 4.99992C9.16732 4.08325 8.41732 3.33325 7.50065 3.33325ZM12.5007 6.66659C13.4173 6.66659 14.1673 5.91659 14.1673 4.99992C14.1673 4.08325 13.4173 3.33325 12.5007 3.33325C11.584 3.33325 10.834 4.08325 10.834 4.99992C10.834 5.91659 11.584 6.66659 12.5007 6.66659ZM12.5007 8.33325C11.584 8.33325 10.834 9.08325 10.834 9.99992C10.834 10.9166 11.584 11.6666 12.5007 11.6666C13.4173 11.6666 14.1673 10.9166 14.1673 9.99992C14.1673 9.08325 13.4173 8.33325 12.5007 8.33325ZM12.5007 13.3333C11.584 13.3333 10.834 14.0833 10.834 14.9999C10.834 15.9166 11.584 16.6666 12.5007 16.6666C13.4173 16.6666 14.1673 15.9166 14.1673 14.9999C14.1673 14.0833 13.4173 13.3333 12.5007 13.3333Z"
      fill="#3377FF"
    />
  </svg>
);
export default SwapBannerIcon;
