import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, Text } from '@chakra-ui/react';

import { useAppDispatch } from '../../../store/hooks';
import { isSystemActive, updateSettings } from '../../../store/modules/settings';
import { hideModal } from '../../../store/modules/ui';
import textContent from '../../../utils/textContent.json';

const SystemSettings = () => {
  const dispatch = useAppDispatch();

  const isSystemOn = useSelector(isSystemActive);

  const onConfirm = async () => {
    await dispatch(updateSettings({ isSystemActive: !isSystemOn }));
    dispatch(hideModal());
  };

  return (
    <Flex flexDirection="column" p="8px 12px" data-testid="system-settings-modal-container">
      <Text
        fontWeight="600"
        color="darkBlue"
        fontSize="20px"
        textAlign="start"
        lineHeight="31px"
        data-testid="system-settings-message"
      >
        {textContent.system.systemText}
      </Text>
      <Text>
        {isSystemOn
          ? textContent.system.systemAboutToShutdown
          : textContent.system.systemAboutToPowerOn}
      </Text>
      <Flex justify="space-evenly" marginTop="40px" height="40px">
        <Button
          variant="outline"
          borderColor="purple"
          data-testid="cancel-button"
          width="155px"
          height="40px"
          onClick={() => dispatch(hideModal())}
          color="purple"
          _hover={{ bg: 'greyOnHover' }}
        >
          {textContent.cancel}
        </Button>
        <Button
          data-testid="save-button"
          width="155px"
          height="40px"
          color="white"
          onClick={onConfirm}
          _disabled={{
            bg: 'disabledPurple',
            cursor: 'default',
            _hover: { bg: 'disabledPurple' },
          }}
          _hover={{ bg: 'darkPurple' }}
          backgroundColor="purple"
        >
          {textContent.confirm}
        </Button>
      </Flex>
    </Flex>
  );
};

export default SystemSettings;
