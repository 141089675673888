import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import { getProductById, getProducts } from './actions';
import { InitialState } from './types';

export const productsSlice = createSlice({
  name: 'product',
  initialState: {
    data: [],
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {
    clearProductData: () => {
      return {
        data: [],
        loading: false,
        error: undefined,
      } as InitialState;
    },
  },
  extraReducers: (builder) => {
    // GET ALL
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string'
            ? action.payload
            : textContent.product.foundProblemProducts;
        state.loading = false;
      })
      // GET BY ID
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.data = [action.payload];
        state.loading = false;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string'
            ? action.payload
            : textContent.product.foundProblemProductById;
        state.loading = false;
      });
  },
});

export const productReducer = productsSlice.reducer;
export const { clearProductData } = productsSlice.actions;
