import { mutate } from 'swr';

import { SWRKey } from '../enum/SWRKey';
import { MonthAndYear, ProductType } from '../interfaces';
import { GetByFiltersProps } from '../interfaces/products';
import { Voucher } from '../interfaces/voucher';

export const getProductsByBrandKey =
  (brand: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      currentPage: page,
      brand,
      key: SWRKey.products,
    };
  };

export const getProductsBySearchBarKey =
  (searchValue: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    if (!searchValue.toLowerCase().trim()) {
      return null;
    }
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      currentPage: page,
      searchValue,
      pageSize: 18,
      full: true,
    };
  };

export const getProductByCategoryKey =
  (categoryCode: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      currentPage: page,
      categoryCode,
      key: SWRKey.products,
    };
  };

export const getProductDealsKey = (
  pageIndex: number,
  previousPageData: {
    products: ProductType[];
    totalPages: number;
  },
) => {
  const page = pageIndex + 1;

  if (previousPageData && previousPageData.totalPages < page) return null;
  return {
    key: SWRKey.productsOnDeals,
    currentPage: page,
  };
};

export const getProductOnDealsByCategoryKey =
  (categoryCode: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      currentPage: page,
      categoryCode,
      key: SWRKey.productsOnDeals,
    };
  };

export const getProductOnDealsByCategoryAndBrandKey =
  (categoryCode: string, brand: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      currentPage: page,
      categoryCode,
      brand,
      key: SWRKey.productsOnDeals,
    };
  };

export const getProductsByFiltersKey =
  ({ deals, status, categoryCode, brand, key }: Omit<GetByFiltersProps, 'currentPage'>) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;

    return { deals, status, categoryCode, brand, currentPage: page, key };
  };

export const getVoucherKey =
  (monthAndYear: MonthAndYear) =>
  (
    pageIndex: number,
    previousPageData: {
      vouchers: Voucher[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;

    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      key: SWRKey.reservationHistory,
      currentPage: page,
      monthAndYear,
    };
  };

export const getProductsPendingToReceiveKey = (
  pageIndex: number,
  previousPageData: {
    products: ProductType[];
    totalPages: number;
  },
) => {
  const page = pageIndex + 1;

  if (previousPageData && previousPageData.totalPages < page) return null;
  return {
    currentPage: page,
    key: SWRKey.productsPendingToReceive,
  };
};

export const getProductPendingToReceiveByCategoryKey =
  (categoryCode: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      key: SWRKey.productsPendingToReceive,
      currentPage: page,
      categoryCode,
    };
  };

export const getProductPendingToReceiveByCategoryAndBrandKey =
  (categoryCode: string, brand: string) =>
  (
    pageIndex: number,
    previousPageData: {
      products: ProductType[];
      totalPages: number;
    },
  ) => {
    const page = pageIndex + 1;
    if (previousPageData && previousPageData.totalPages < page) return null;
    return {
      key: SWRKey.productsPendingToReceive,
      currentPage: page,
      categoryCode,
      brand,
    };
  };

interface ClearCacheProps {
  revalidate?: boolean;
}

export const clearCache = ({ revalidate = true }: ClearCacheProps) =>
  mutate(() => true, undefined, { revalidate });
