import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { getProductsByDeals } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { getProductDealsKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import CategoriesCarousel from '../Categories/CategoriesCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';
import DealsViewSkeleton from './DealsViewSkeleton';

const DealsViewMobile = () => {
  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const { data, isLoading, error, size, setSize } = useSWRInfinite(
    getProductDealsKey,
    getProductsByDeals,
    {
      revalidateFirstPage: false,
    },
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  if (isLoading || loadingFavorites) {
    return <DealsViewSkeleton />;
  }

  if (error || products.length === 0) {
    return (
      <ErrorScreen
        url={'/'}
        title={textContent.product.productError}
        arrowTitle={textContent.deals}
      />
    );
  }

  return (
    <Flex justifyContent="flex-start" direction="column">
      <GoBack title={textContent.deals} />
      <Flex backgroundColor="grey50" height={{ base: '130px' }} alignItems={{ base: 'flex-start' }}>
        <CategoriesCarousel />
      </Flex>
      <Box padding={{ base: '0' }} backgroundColor="grey50">
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '16px' }}
          fontWeight={{ base: '500' }}
          margin={{ base: '24px' }}
        >
          {textContent.highlightedDeals}
        </Text>
        <InfiniteScroll
          dataLength={products.length}
          next={() => {
            setSize((currentSize) => currentSize + 1);
          }}
          hasMore={hasMore}
          loader={<ProductCardSkeleton array={2} />}
        >
          <Grid gridTemplateColumns={{ base: 'repeat(auto-fill, minmax(150px, 1fr))' }}>
            {products.map((product, index) => (
              <Flex justifyContent="center" key={index} marginBottom="20px">
                <ProductCard
                  isDeals
                  product={product}
                  urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_DEALS}`}
                />
              </Flex>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Flex>
  );
};

export default DealsViewMobile;
