import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import EmptyVouchersListIcon from '../../../../assets/Icons/EmptyVouchersListIcon';
import textContent from '../../../../utils/textContent.json';

const EmptyReservations = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop="120px"
      paddingBottom="120px"
      gap="40px"
    >
      <EmptyVouchersListIcon dataTestId="empty-voucher-list" />
      <Text
        width="256px"
        textAlign="center"
        fontSize={{ base: '20px', md: '25px' }}
        fontWeight="500"
        data-testid="empty-reservation-history-message"
      >
        {textContent.reservations.emptyReservations}
      </Text>
    </Flex>
  );
};

export default EmptyReservations;
