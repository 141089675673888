import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getModalState } from '../../../store/modules/ui/selectors';
import { hideModal } from '../../../store/modules/ui/slice';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';

const ConfirmModal = ({
  children,
  title,
  subtitle,
}: {
  children: JSX.Element;
  title: string;
  subtitle: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { callback } = useAppSelector(getModalState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSave = async () => {
    setLoading(true);
    if (callback) await callback();
    navigate('/admin/banners');
    dispatch(hideModal());
  };

  return (
    <div data-testid="confirm-banner-modal-container">
      <Flex justifyContent="flex-end" p="8px 12px">
        <CloseIcon
          color={loading ? 'grey' : ''}
          onClick={() => {
            if (!loading) dispatch(hideModal());
          }}
          cursor={loading ? 'default' : 'pointer'}
          width="12px"
          height="12px"
          data-testid="close-banner-submit-modal-icon"
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        height="485px"
        padding="0 20.5px 24px"
      >
        {children}
        <Flex flexDirection="column">
          <Text
            fontSize="20px"
            fontWeight="500"
            paddingBottom="24px"
            lineHeight="21.1px"
            align="center"
            data-testid="title"
          >
            {title}
          </Text>
          <Text
            fontSize="18px"
            fontWeight="500"
            lineHeight="21.1px"
            align="center"
            data-testid="confirmation-message-title"
          >
            {subtitle}
          </Text>
        </Flex>
        <Text
          fontSize="18px"
          fontWeight="500"
          lineHeight="21.1px"
          align="center"
          data-testid="confirmation-message-subtitle"
        >
          {textContent.continueMark}
        </Text>
        <Flex
          justifySelf="flex-end"
          justify="space-evenly"
          marginTop="40px"
          height="40px"
          width="388px"
        >
          <Button
            variant="outline"
            borderColor="purple"
            data-testid="cancel-button"
            width="155px"
            height="40px"
            isDisabled={loading}
            onClick={() => dispatch(hideModal())}
            color="purple"
            _hover={{ bg: 'greyOnHover' }}
          >
            {textContent.cancel}
          </Button>
          <Button
            data-testid="confirm-button"
            width="155px"
            height="40px"
            color="white"
            content={textContent.confirm}
            isLoading={loading}
            onClick={onSave}
            _disabled={{
              bg: 'disabledPurple',
              cursor: 'default',
              _hover: { bg: 'disabledPurple' },
            }}
            _hover={{ bg: 'darkPurple' }}
            backgroundColor="purple"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ConfirmModal;
