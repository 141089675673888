import * as React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Props } from '../Types';

export const CostaDelSolBrand = ({ dataTestId }: Props) => {
  const { breakPoint } = useBreakpoint();

  return (
    <svg
      cursor="pointer"
      data-testid={dataTestId}
      width={breakPoint === 'xl' ? '105' : '51'}
      height={breakPoint === 'xl' ? '35' : '17'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105 35"
      fill="none"
    >
      <g clipPath="url(#clip0_2418_70345)">
        <path
          d="M104.233 0.96582C104.084 1.5047 103.99 1.91445 103.858 2.3093C102.574 6.16343 101.33 10.0325 99.9793 13.8642C98.7724 17.2888 97.2178 20.5742 95.107 23.579C93.8901 25.3099 92.4299 26.8123 90.6444 28.054C88.0344 29.8718 85.1563 31.029 82.0297 31.635C80.8154 31.8709 79.5713 32.0497 78.337 32.0944C74.9721 32.2136 71.6072 32.303 68.2398 32.2955C65.7366 32.2906 63.2334 32.1217 60.7327 32.0124C58.669 31.923 56.6054 31.8163 54.5417 31.7095C51.4078 31.5481 48.2763 31.3792 45.1423 31.2178C44.2657 31.1731 43.3916 31.1259 42.515 31.1135C37.0765 31.039 31.6355 30.88 26.197 30.9322C21.6748 30.9769 17.1527 31.2997 12.6802 32.0025C9.20355 32.5464 5.74179 33.1871 2.27257 33.7855C2.00437 33.8327 1.7312 33.865 1.39844 33.9122C1.44562 33.711 1.463 33.5819 1.50522 33.4602C2.83132 29.6731 4.08044 25.8587 5.50587 22.1089C6.92633 18.3765 8.19283 14.5794 10.0504 11.0084C11.9079 7.44235 14.7985 5.02358 18.6849 3.69004C21.65 2.67187 24.7194 2.12305 27.841 1.88465C30.5999 1.67357 33.3738 1.48484 36.1378 1.50719C39.4828 1.5345 42.8304 1.76545 46.1729 1.96164C49.1479 2.13547 52.118 2.40119 55.0906 2.58744C59.2725 2.84819 63.4569 3.08162 67.6388 3.3076C69.2307 3.39452 70.8274 3.4566 72.4217 3.48889C75.0019 3.54104 77.5846 3.64782 80.1598 3.57084C84.6646 3.43674 89.1693 3.25297 93.6617 2.94752C96.8627 2.72899 100.044 2.2646 103.126 1.33087C103.441 1.2365 103.749 1.12475 104.223 0.96582L104.233 0.96582Z"
          fill="url(#paint0_linear_2418_70345)"
        />
        <path
          d="M0.790569 34.5823C1.68457 31.94 2.53138 29.3648 3.43035 26.8094C4.47335 23.8394 5.46172 20.847 6.64875 17.9365C8.58575 13.1933 12.0003 9.84829 16.7932 7.90384C19.4528 6.82359 22.2366 6.26235 25.0527 5.77562C29.8331 4.95364 34.6533 4.96109 39.4685 5.08277C42.9948 5.17217 46.5112 5.52729 50.035 5.75824C53.4695 5.98422 56.9039 6.21517 60.3384 6.42625C66.2313 6.78882 72.1342 6.84345 78.0321 6.69942C84.5558 6.54049 91.0572 6.04134 97.4468 4.6457C99.1951 4.26327 100.869 3.55056 102.577 2.98684C102.803 2.91234 103.027 2.82791 103.407 2.69629C103.223 3.27739 103.091 3.71694 102.945 4.15152C101.703 7.89887 100.549 11.676 99.1976 15.3836C97.8243 19.1508 96.0462 22.7293 93.4114 25.8211C92.4379 26.9634 91.2956 27.912 90.0713 28.7837C87.0317 30.9442 83.6147 32.0965 79.9518 32.5435C78.1911 32.7595 76.4055 32.7819 74.63 32.8837C73.7856 32.9309 72.9388 32.9905 72.092 32.9855C69.9017 32.9706 67.7139 32.9383 65.5236 32.8812C63.8871 32.839 62.2506 32.7521 60.614 32.6751C58.5727 32.5757 56.5339 32.4665 54.4926 32.3622C52.4339 32.2554 50.3753 32.1486 48.3166 32.0468C46.1685 31.94 44.0229 31.8208 41.8748 31.7364C39.6175 31.647 37.3601 31.5774 35.1028 31.5253C32.8429 31.4731 30.5831 31.4185 28.3233 31.4384C26.0833 31.4582 23.8359 31.4707 21.6059 31.6644C17.6648 32.0046 13.7213 32.392 9.80258 32.9284C6.93682 33.3232 4.1083 33.9937 1.2624 34.5376C1.11092 34.5674 0.956953 34.5699 0.788086 34.5848L0.790569 34.5823Z"
          fill="url(#paint1_radial_2418_70345)"
        />
        <g opacity="0.5">
          <path
            d="M25.2294 20.0449C23.6053 20.0449 22.7858 18.9324 23.0987 17.415C23.3992 15.9548 24.4 15.2248 25.89 15.2248C26.3395 15.2248 26.8038 15.2819 27.2061 15.4209L27.596 14.3159C27.0696 14.2314 26.5207 14.2041 25.967 14.2041C23.8462 14.2041 21.9688 15.2148 21.5069 17.4498C21.045 19.6947 22.4481 21.0432 24.6433 21.0432C25.3685 21.0432 26.3792 20.8669 26.5654 19.958C26.5779 19.9009 26.5903 19.8363 26.5928 19.7792L26.5456 19.732C26.1358 19.9654 25.6516 20.0399 25.2319 20.0399L25.2294 20.0449Z"
            fill="#006A30"
          />
          <path
            d="M31.38 14.1152C29.3933 14.1152 27.65 15.3966 27.2378 17.3982C26.8181 19.437 27.6649 21.076 29.8502 21.076C32.038 21.076 33.7217 19.3923 34.1514 17.3063C34.576 15.2476 33.4064 14.1152 31.3824 14.1152H31.38ZM32.5372 17.4454C32.3112 18.5405 31.5414 20.1497 30.1383 20.1497C28.688 20.1497 28.5763 18.6051 28.8122 17.4553C29.0506 16.2956 29.7683 15.0415 31.1714 15.0415C32.5372 15.0415 32.7681 16.3229 32.5372 17.4454Z"
            fill="#006A30"
          />
          <path
            d="M34.7997 16.2378C34.601 17.2013 35.1052 17.6781 35.9346 18.1475C36.5753 18.5125 36.9304 18.736 36.8311 19.2153C36.7168 19.7666 36.1258 20.0944 35.3907 20.0944C34.9139 20.0944 34.3924 19.9826 34.087 19.7492L34.01 19.7964C33.9901 19.8436 33.9777 19.9081 33.9628 19.9752C33.7741 20.8915 34.678 21.0505 35.4205 21.0505C37.3774 21.0505 38.2466 19.8535 38.4279 18.9645C38.6315 17.9736 38.0827 17.4869 37.2781 17.0374C36.7317 16.7295 36.2425 16.4563 36.3493 15.9323C36.4263 15.5574 36.8013 15.1749 37.6307 15.1749C38.0131 15.1749 38.4303 15.2767 38.7159 15.381L39.0984 14.3157C38.7308 14.2511 38.3087 14.2139 37.8393 14.2139C36.1308 14.2139 35.0158 15.1774 34.7947 16.2427L34.7997 16.2378Z"
            fill="#006A30"
          />
          <path
            d="M44.0602 15.3219C44.6339 15.3219 45.0436 14.9941 45.2125 14.2739L45.1777 14.2168C44.989 14.254 44.1893 14.2913 43.5387 14.2913H39.548L39.3369 15.3194H41.2739L40.1241 20.8945H41.6812L42.831 15.3194H44.0627L44.0602 15.3219Z"
            fill="#006A30"
          />
          <path
            d="M46.4548 14.2852L42.7422 20.9008H43.9839L45.0517 18.9638H47.3239L47.6021 20.9008H49.2833L48.2105 14.2852H46.4548ZM45.5409 18.0176L46.3058 16.6245C46.5541 16.1576 46.79 15.6709 46.8819 15.4449H46.9092C46.9117 15.6609 46.939 16.1377 47.0036 16.6046L47.2097 18.0176H45.5384H45.5409Z"
            fill="#006A30"
          />
          <path
            d="M55.0525 14.2666C54.2231 14.2666 53.3117 14.284 52.4798 14.3312L51.1338 20.8623C51.7919 20.9095 52.5245 20.9269 53.1925 20.9269C55.4647 20.9269 57.665 19.7026 58.1616 17.2888C58.631 15.0141 57.1037 14.2666 55.0525 14.2666ZM56.5475 17.485C56.2594 18.8781 55.2735 20.0006 53.709 20.0006C53.4408 20.0006 53.1229 19.9733 52.8622 19.936L53.8307 15.2401C54.1411 15.2128 54.5186 15.1929 54.824 15.1929C56.2942 15.1929 56.8256 16.139 56.5475 17.485Z"
            fill="#006A30"
          />
          <path
            d="M61.6292 18.0293C62.2029 18.0293 62.6027 17.7487 62.7716 17.0285L62.7343 16.9714C62.5456 17.0087 61.746 17.0459 61.0953 17.0459H60.2659L60.621 15.325H63.1714L63.3825 14.2969H59.2974L57.9365 20.9025H61.4033C61.9844 20.9025 62.4016 20.5474 62.5704 19.8273L62.5357 19.7701C62.3469 19.8074 61.5473 19.8446 60.8967 19.8446H59.6947L60.0697 18.0293H61.6367H61.6292Z"
            fill="#006A30"
          />
          <path
            d="M65.6859 19.8446H64.6727L65.815 14.2969H64.2779L62.917 20.9025H66.2024C66.7761 20.9025 67.1933 20.5474 67.3597 19.8273L67.3249 19.7701C67.1362 19.8074 66.3365 19.8446 65.6859 19.8446Z"
            fill="#006A30"
          />
          <path
            d="M72.7041 15.1749C73.0865 15.1749 73.5037 15.2767 73.7893 15.381L74.1717 14.3157C73.8042 14.2511 73.382 14.2139 72.9152 14.2139C71.2066 14.2139 70.0916 15.1774 69.8706 16.2427C69.6719 17.2063 70.1761 17.6831 71.0055 18.1524C71.6462 18.5175 72.0013 18.741 71.902 19.2203C71.7877 19.7716 71.1967 20.0994 70.4592 20.0994C69.9824 20.0994 69.4609 19.9876 69.1554 19.7542L69.0784 19.8014C69.0586 19.8485 69.0461 19.9131 69.0312 19.9802C68.8425 20.8965 69.744 21.0554 70.489 21.0554C72.4458 21.0554 73.315 19.8585 73.4963 18.9694C73.6999 17.9786 73.1511 17.4919 72.3465 17.0424C71.8002 16.7344 71.3109 16.4613 71.4177 15.9373C71.4947 15.5623 71.8697 15.1799 72.7016 15.1799L72.7041 15.1749Z"
            fill="#006A30"
          />
          <path
            d="M78.3741 14.1172C76.3874 14.1172 74.6441 15.3986 74.2319 17.4002C73.8122 19.439 74.659 21.078 76.8444 21.078C79.0322 21.078 80.7159 19.3943 81.1455 17.3083C81.5702 15.2496 80.4005 14.1172 78.3766 14.1172H78.3741ZM79.5313 17.4473C79.3054 18.5425 78.5355 20.1517 77.1324 20.1517C75.6822 20.1517 75.5704 18.6071 75.8063 17.4573C76.0447 16.2976 76.7624 15.0435 78.1655 15.0435C79.5313 15.0435 79.7623 16.3249 79.5313 17.4473Z"
            fill="#006A30"
          />
          <path
            d="M85.4675 19.7701C85.2788 19.8074 84.4791 19.8446 83.8285 19.8446H82.8153L83.9576 14.2969H82.4204L81.0596 20.9025H84.345C84.9187 20.9025 85.3359 20.5474 85.5023 19.8273L85.465 19.7701H85.4675Z"
            fill="#006A30"
          />
          <path
            d="M24.9814 19.7051C23.3573 19.7051 22.5378 18.5925 22.8507 17.0752C23.1512 15.615 24.1519 14.8849 25.6419 14.8849C26.0914 14.8849 26.5558 14.942 26.9581 15.0811L27.348 13.976C26.8215 13.8916 26.2727 13.8643 25.7189 13.8643C23.6006 13.8643 21.7208 14.875 21.2589 17.11C20.797 19.3549 22.2 20.7034 24.3953 20.7034C25.1204 20.7034 26.1312 20.527 26.3174 19.6181C26.3298 19.561 26.3422 19.4965 26.3447 19.4393L26.2975 19.3922C25.8878 19.6256 25.4035 19.7001 24.9839 19.7001L24.9814 19.7051Z"
            fill="white"
          />
          <path
            d="M31.1319 13.7754C29.1453 13.7754 27.402 15.0568 26.9897 17.0584C26.57 19.0972 27.4169 20.7362 29.6022 20.7362C31.79 20.7362 33.4737 19.0525 33.9033 16.9665C34.328 14.9078 33.1583 13.7754 31.1344 13.7754H31.1319ZM32.2892 17.1055C32.0632 18.2007 31.2933 19.8099 29.8903 19.8099C28.44 19.8099 28.3282 18.2653 28.5642 17.1155C28.8026 15.9558 29.5202 14.7017 30.9233 14.7017C32.2892 14.7017 32.5201 15.9831 32.2892 17.1055Z"
            fill="white"
          />
          <path
            d="M34.5517 15.8989C34.353 16.8624 34.8571 17.3392 35.6865 17.8086C36.3272 18.1736 36.6824 18.3971 36.583 18.8764C36.4688 19.4277 35.8778 19.7555 35.1427 19.7555C34.6659 19.7555 34.1444 19.6438 33.8389 19.4103L33.762 19.4575C33.7421 19.5047 33.7297 19.5693 33.7148 19.6363C33.526 20.5527 34.43 20.7116 35.1725 20.7116C37.1294 20.7116 37.9985 19.5146 38.1798 18.6256C38.3834 17.6348 37.8346 17.148 37.03 16.6985C36.4837 16.3906 35.9945 16.1174 36.1013 15.5935C36.1782 15.2185 36.5532 14.836 37.3827 14.836C37.7651 14.836 38.1823 14.9379 38.4679 15.0422L38.8503 13.9768C38.4828 13.9122 38.0606 13.875 37.5913 13.875C35.8827 13.875 34.7677 14.8385 34.5467 15.9039L34.5517 15.8989Z"
            fill="white"
          />
          <path
            d="M43.8127 14.982C44.3863 14.982 44.7961 14.6542 44.965 13.9341L44.9277 13.877C44.739 13.9142 43.9393 13.9515 43.2887 13.9515H39.298L39.0869 14.9796H41.0239L39.8741 20.5546H41.4312L42.581 14.9796H43.8127V14.982Z"
            fill="white"
          />
          <path
            d="M46.2048 13.9453L42.4922 20.5609H43.7339L44.8017 18.6239H47.0739L47.3521 20.5609H49.0333L47.9605 13.9453H46.2048ZM45.2909 17.6778L46.0558 16.2846C46.3041 15.8177 46.54 15.331 46.6319 15.105H46.6592C46.6617 15.3211 46.689 15.7979 46.7536 16.2647L46.9597 17.6778H45.2884H45.2909Z"
            fill="white"
          />
          <path
            d="M54.8044 13.9248C53.975 13.9248 53.0636 13.9422 52.2317 13.9894L50.8857 20.5205C51.5438 20.5677 52.2764 20.5851 52.9444 20.5851C55.2167 20.5851 57.4169 19.3608 57.9136 16.947C58.3829 14.6723 56.8557 13.9248 54.8044 13.9248ZM56.2994 17.1432C56.0113 18.5364 55.0255 19.6588 53.461 19.6588C53.1928 19.6588 52.8749 19.6315 52.6141 19.5943L53.5826 14.8983C53.8931 14.871 54.2705 14.8511 54.576 14.8511C56.0461 14.8511 56.5775 15.7972 56.2994 17.1432Z"
            fill="white"
          />
          <path
            d="M61.3787 17.6856C61.9524 17.6856 62.3522 17.405 62.521 16.6848L62.4863 16.6277C62.2975 16.6649 61.4979 16.7022 60.8473 16.7022H60.0178L60.373 14.9812H62.9233L63.1344 13.9531H59.0493L57.6885 20.5588H61.1552C61.7363 20.5588 62.1535 20.2037 62.3224 19.4835L62.2876 19.4264C62.0989 19.4636 61.2992 19.5009 60.6486 19.5009H59.4467L59.8217 17.6856H61.3886H61.3787Z"
            fill="white"
          />
          <path
            d="M65.4379 19.5009H64.4247L65.567 13.9531H64.0298L62.6689 20.5588H65.9544C66.528 20.5588 66.9452 20.2037 67.1116 19.4835L67.0769 19.4264C66.8881 19.4636 66.0885 19.5009 65.4379 19.5009Z"
            fill="white"
          />
          <path
            d="M72.4531 14.8341C72.8355 14.8341 73.2527 14.9359 73.5383 15.0402L73.9208 13.9749C73.5532 13.9103 73.1311 13.873 72.6642 13.873C70.9557 13.873 69.8406 14.8366 69.6196 15.9019C69.421 16.8655 69.9251 17.3423 70.7545 17.8116C71.3952 18.1767 71.7503 18.4002 71.651 18.8794C71.5368 19.4307 70.9457 19.7585 70.2082 19.7585C69.7314 19.7585 69.2099 19.6468 68.9044 19.4134L68.8274 19.4605C68.8076 19.5077 68.7952 19.5723 68.7803 19.6393C68.5915 20.5557 69.493 20.7146 70.238 20.7146C72.1948 20.7146 73.064 19.5177 73.2453 18.6286C73.4489 17.6378 72.9001 17.151 72.0955 16.7016C71.5492 16.3936 71.06 16.1205 71.1667 15.5965C71.2437 15.2215 71.6187 14.8391 72.4506 14.8391L72.4531 14.8341Z"
            fill="white"
          />
          <path
            d="M78.1231 13.7773C76.1365 13.7773 74.3932 15.0587 73.9809 17.0603C73.5612 19.0991 74.4081 20.7381 76.5934 20.7381C78.7812 20.7381 80.4649 19.0544 80.8945 16.9684C81.3192 14.9097 80.1495 13.7773 78.1256 13.7773H78.1231ZM79.2804 17.1075C79.0544 18.2026 78.2845 19.8118 76.8815 19.8118C75.4312 19.8118 75.3194 18.2672 75.5554 17.1174C75.7938 15.9577 76.5114 14.7036 77.9145 14.7036C79.2804 14.7036 79.5113 15.985 79.2804 17.1075Z"
            fill="white"
          />
          <path
            d="M85.2194 19.4313C85.0307 19.4685 84.2311 19.5058 83.5804 19.5058H82.5672L83.7096 13.958H82.1724L80.8115 20.5637H84.097C84.6706 20.5637 85.0878 20.2086 85.2542 19.4884L85.2194 19.4313Z"
            fill="white"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M20.3263 27.6039C25.6058 25.4086 31.4715 24.7704 37.1334 24.5295C44.7796 24.2067 52.4382 24.7282 60.072 25.1255C67.3829 25.5054 74.7882 25.9152 82.0842 25.0957C82.9062 25.0038 83.5966 24.7903 84.4111 24.6562C81.369 25.9624 77.8154 26.6428 74.3934 26.8837C70.8695 27.1295 67.3904 27.1147 63.8615 26.983C60.1167 26.8415 44.8144 25.9301 40.9677 25.8779C34.0616 25.7811 27.2101 26.0145 20.4057 27.5865C20.3784 27.5939 20.3486 27.5989 20.3213 27.6039C24.908 26.6354 25.288 25.5377 20.3213 27.6039H20.3263Z"
            fill="#006A30"
          />
          <path
            d="M19.3604 27.6554C22.6632 25.4254 31.3598 24.3153 37.0243 24.0744C44.6705 23.7516 52.3291 24.2731 59.9628 24.6704C67.2738 25.0504 74.6791 25.4601 81.9751 24.6406C82.7971 24.5487 85.4567 24.3451 86.2737 24.2135C83.0454 25.4129 78.2551 25.9841 74.833 26.2225C71.3092 26.4684 66.9087 26.3367 63.3824 26.2026C59.6375 26.0611 44.7102 25.2565 40.8635 25.2043C33.9574 25.1075 25.4073 25.4403 19.6211 27.7299C19.5938 27.7398 20.2395 27.1339 20.2146 27.1488C24.0116 24.8716 23.9471 26.8111 19.3604 27.6554Z"
            fill="white"
          />
        </g>
        <path
          d="M19.7734 26.6068C25.053 24.4115 30.9186 23.7733 36.5806 23.5324C44.2268 23.2096 51.8854 23.7311 59.5192 24.1284C66.8301 24.5084 74.2354 24.9181 81.5314 24.0986C82.3534 24.0067 83.1729 23.8975 83.9899 23.7634C80.7616 24.9628 77.3023 25.4794 73.8828 25.7202C70.3589 25.9661 66.8202 25.9065 63.2938 25.7749C59.549 25.6333 44.2765 24.8809 40.4298 24.8287C33.5236 24.7319 26.6423 25.1689 19.8604 26.5894C19.833 26.5944 19.8032 26.6018 19.7759 26.6068C24.3626 25.6383 24.7426 24.5407 19.7759 26.6068H19.7734Z"
          fill="white"
        />
        <path
          d="M24.3523 20.2722C22.1571 20.2722 20.754 18.9262 21.2159 16.6813C21.6778 14.4463 23.5552 13.4355 25.6759 13.4355C26.2297 13.4355 26.7785 13.4629 27.305 13.5498L26.9151 14.6524C26.5153 14.5108 26.0484 14.4562 25.599 14.4562C24.109 14.4562 23.1082 15.1863 22.8077 16.644C22.4948 18.1588 23.3168 19.2714 24.9409 19.2714C25.3606 19.2714 25.8448 19.1969 26.2546 18.9634L26.3017 19.0106C26.3017 19.0653 26.2868 19.1323 26.2744 19.1894C26.0882 20.0958 25.0775 20.2746 24.3498 20.2746L24.3523 20.2722Z"
          fill="white"
        />
        <path
          d="M29.5601 20.2991C27.3747 20.2991 26.5254 18.6626 26.9476 16.6238C27.3598 14.6222 29.1031 13.3408 31.0898 13.3408C33.0765 13.3408 34.2859 14.4732 33.8612 16.5319C33.4316 18.6179 31.7479 20.3016 29.5601 20.3016V20.2991ZM30.8787 14.2671C29.4756 14.2671 28.758 15.5212 28.5196 16.6809C28.2836 17.8307 28.3954 19.3753 29.8457 19.3753C31.2959 19.3753 32.0211 17.7661 32.247 16.671C32.478 15.5485 32.247 14.2671 30.8787 14.2671Z"
          fill="white"
        />
        <path
          d="M35.132 20.2716C34.387 20.2716 33.4831 20.1127 33.6718 19.1963C33.6842 19.1318 33.6991 19.0647 33.7165 19.02L33.7935 18.9728C34.0989 19.2063 34.6204 19.318 35.0972 19.318C35.8323 19.318 36.4258 18.9902 36.5401 18.4389C36.6394 17.9621 36.2843 17.7386 35.6436 17.3736C34.8141 16.9067 34.31 16.4299 34.5087 15.4664C34.7272 14.401 35.8447 13.4375 37.5533 13.4375C38.0201 13.4375 38.4423 13.4747 38.8123 13.5393L38.4299 14.6047C38.1443 14.5028 37.7271 14.3985 37.3447 14.3985C36.5127 14.3985 36.1378 14.781 36.0608 15.156C35.954 15.6799 36.4407 15.9506 36.9895 16.2586C37.7941 16.708 38.343 17.1948 38.1393 18.1856C37.9556 19.0747 37.0889 20.2716 35.132 20.2716Z"
          fill="white"
        />
        <path
          d="M43.7703 14.5489H42.5385L41.3887 20.124H39.8317L40.9815 14.5489H39.042L39.2531 13.5208H43.2438C43.8919 13.5208 44.6941 13.4835 44.8828 13.4463L44.92 13.5034C44.7537 14.2236 44.3414 14.5514 43.7678 14.5514L43.7703 14.5489Z"
          fill="white"
        />
        <path
          d="M47.3111 20.1239L47.0329 18.1869H44.7607L43.6928 20.1239H42.4512L46.1638 13.5107H47.922L48.9948 20.1239H47.3135H47.3111ZM46.7151 15.8302C46.648 15.3633 46.6232 14.884 46.6207 14.6705H46.5934C46.499 14.8964 46.2656 15.3807 46.0172 15.85L45.2524 17.2432H46.9237L46.7175 15.8302H46.7151Z"
          fill="white"
        />
        <path
          d="M52.9015 20.152C52.2334 20.152 51.5009 20.1346 50.8428 20.0874L52.1887 13.5587C53.0207 13.5115 53.932 13.4941 54.7615 13.4941C56.8152 13.4941 58.3424 14.2416 57.8731 16.5164C57.3764 18.9302 55.1762 20.1544 52.9015 20.1544V20.152ZM54.533 14.4179C54.2276 14.4179 53.8501 14.4378 53.5397 14.4651L52.5712 19.1611C52.8319 19.1984 53.1498 19.2257 53.418 19.2257C54.985 19.2257 55.9709 18.1032 56.2564 16.7101C56.5346 15.3641 56.0031 14.4179 54.533 14.4179Z"
          fill="white"
        />
        <path
          d="M60.328 14.5506L59.9729 16.2715H60.8048C61.453 16.2715 62.2551 16.2343 62.4438 16.197L62.4811 16.2541C62.3147 16.9743 61.9124 17.2549 61.3388 17.2549H59.7718L59.3968 19.0702H60.6012C61.2494 19.0702 62.0515 19.033 62.2402 18.9957L62.2775 19.0504C62.1111 19.7705 61.6939 20.1256 61.1103 20.1256H57.6436L59.0044 13.5225H63.092L62.8809 14.553H60.3305L60.328 14.5506Z"
          fill="white"
        />
        <path
          d="M65.9134 20.1256H62.6279L63.9888 13.5225H65.526L64.3836 19.0677H65.3968C66.0475 19.0677 66.8471 19.0305 67.0358 18.9932L67.0731 19.0479C66.9067 19.768 66.487 20.1232 65.9159 20.1232L65.9134 20.1256Z"
          fill="white"
        />
        <path
          d="M70.1979 20.2736C69.4529 20.2736 68.5515 20.1146 68.7402 19.1983C68.7526 19.1337 68.7675 19.0667 68.7874 19.022L68.8644 18.9748C69.1698 19.2082 69.6913 19.32 70.1681 19.32C70.9032 19.32 71.4967 18.9922 71.611 18.4409C71.7103 17.9641 71.3552 17.7406 70.7145 17.3755C69.885 16.9087 69.3809 16.4319 69.5796 15.4683C69.7981 14.403 70.9156 13.4395 72.6242 13.4395C73.0935 13.4395 73.5132 13.4767 73.8832 13.5413L73.5008 14.6066C73.2152 14.5048 72.798 14.4005 72.4156 14.4005C71.5836 14.4005 71.2087 14.7829 71.1317 15.1579C71.0224 15.6819 71.5116 15.9526 72.0604 16.2605C72.865 16.71 73.4139 17.1967 73.2102 18.1876C73.0265 19.0766 72.1598 20.2736 70.2029 20.2736H70.1979Z"
          fill="white"
        />
        <path
          d="M76.5509 20.303C74.3631 20.303 73.5163 18.6665 73.936 16.6277C74.3482 14.6261 76.094 13.3447 78.0782 13.3447C80.1021 13.3447 81.2742 14.4771 80.8496 16.5333C80.42 18.6193 78.7363 20.303 76.5485 20.303H76.5509ZM77.8696 14.271C76.4665 14.271 75.7488 15.5226 75.5104 16.6823C75.2745 17.8321 75.3863 19.3767 76.8365 19.3767C78.2868 19.3767 79.0119 17.7675 79.2379 16.6724C79.4689 15.5499 79.2379 14.2685 77.8696 14.2685V14.271Z"
          fill="white"
        />
        <path
          d="M84.056 20.1256H80.7705L82.1314 13.5225H83.6686L82.5262 19.0702H83.5394C84.1901 19.0702 84.9897 19.033 85.1784 18.9957L85.2157 19.0528C85.0493 19.773 84.6296 20.1281 84.0584 20.1281L84.056 20.1256Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2418_70345"
          x1="1.40092"
          y1="17.4402"
          x2="104.233"
          y2="17.4402"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.4" stopColor="#BDCE05" />
          <stop offset="1" stopColor="#008F38" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_2418_70345"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(51.7709 18.6393) scale(37.7715 37.7715)"
        >
          <stop offset="0.39" stopColor="#008C38" />
          <stop offset="1" stopColor="#007833" />
        </radialGradient>
        <clipPath id="clip0_2418_70345">
          <rect
            width="103.533"
            height="33.6864"
            fill="white"
            transform="translate(0.702148 0.967773)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
