import useSWR from 'swr';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { ProductType } from '../../../interfaces';
import { getProductsByFilter } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { categoryData } from '../../../store/modules/categories';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { categoryOrder } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const SubCategoryBrands = () => {
  const navigate = useNavigate();
  const { id, subCategoryId: categoryCode, name: brand } = useParams();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const { data, error, isLoading } = useSWR(
    {
      currentPage,
      brand,
      categoryCode: categoryCode === 'all' ? id : categoryCode,
      key: SWRKey.products,
      onlyStock: categoryCode === 'all',
    },
    getProductsByFilter,
  );

  const { data: categories, loading: categoriesLoading } = useAppSelector(categoryData);

  const categoryItem = useMemo(
    () => categoryOrder(categories).find((category) => category.code === id),
    [categories, id],
  );

  const subCategoryName = useMemo(() => {
    if (data?.products) {
      return (
        categoryItem?.item
          .reduce((name: string[], item) => {
            if (item.code === categoryCode) {
              name.push(item.subCategory);
            }
            name.push(
              ...item.subItems
                .filter((subItem) => subItem.code === categoryCode)
                .map((subItem) => subItem.description),
            );
            return name;
          }, [])
          .concat(
            data?.products
              ?.filter((product: ProductType) => product.operatingCenterCode === categoryCode)
              .map((product: ProductType) => product?.operatingCenterDesc as string),
          )[0] || ''
      );
    }
  }, [categoryItem, categoryCode, data]);

  if (isLoading || categoriesLoading || loadingFavorites) {
    return (
      <ProductCardSkeleton
        array={12}
        onClick={() => navigate(`/category/${categoryItem?.code}/${categoryCode}`)}
      />
    );
  }

  if (error) {
    return (
      <ErrorScreen
        url={`/category/${categoryItem?.code}/${categoryCode}`}
        title={textContent.product.associatedProductsNotFound}
      />
    );
  }

  return (
    <Flex justifyContent="flex-start" direction="column" height="100%" backgroundColor="grey50">
      <GoBack title={transformString(subCategoryName)} subTitle={transformString(brand)} />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        backgroundColor="grey50"
        height="100%"
      >
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
        >
          {data?.products.map((product, index) => (
            <Flex justifyContent={'center'} key={index} margin="20px 0">
              <ProductCard
                product={product}
                urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_BRAND}?productsBrand=${name}`}
              />
            </Flex>
          ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    </Flex>
  );
};

export default SubCategoryBrands;
