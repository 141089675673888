import React from 'react';

interface Props {
  dataTestId: string;
}
const CrossIcon = ({ dataTestId }: Props) => (
  <svg
    data-testid={dataTestId}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99935 0.666992C4.39102 0.666992 0.666016 4.39199 0.666016 9.00033C0.666016 13.6087 4.39102 17.3337 8.99935 17.3337C13.6077 17.3337 17.3327 13.6087 17.3327 9.00033C17.3327 4.39199 13.6077 0.666992 8.99935 0.666992ZM8.99935 15.667C5.32435 15.667 2.33268 12.6753 2.33268 9.00033C2.33268 5.32533 5.32435 2.33366 8.99935 2.33366C12.6743 2.33366 15.666 5.32533 15.666 9.00033C15.666 12.6753 12.6743 15.667 8.99935 15.667ZM11.991 4.83366L8.99935 7.82533L6.00768 4.83366L4.83268 6.00866L7.82435 9.00033L4.83268 11.992L6.00768 13.167L8.99935 10.1753L11.991 13.167L13.166 11.992L10.1743 9.00033L13.166 6.00866L11.991 4.83366Z"
      fill="#3377FF"
    />
  </svg>
);

export default CrossIcon;
