import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, post, URL } from '../../../api';
import { ProductType } from '../../../interfaces';
import textContent from '../../../utils/textContent.json';
import { FavoriteResponse, GetFavorite, GetFavoriteListResponse } from './types';

export const getFavoriteList = createAsyncThunk('GET_FAVORITE', async (_, { rejectWithValue }) => {
  try {
    const response = await get<GetFavoriteListResponse>(URL.FAVORITE);

    return response;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return rejectWithValue(`${textContent.favorite.favoriteNotFound}`);
    }

    if (isAxiosError(error) && error.response?.status === 400) {
      return rejectWithValue(`${textContent.favorite.foundProblemFavoriteList}`);
    }

    return rejectWithValue(`${textContent.favorite.foundProblemFavoriteList}`);
  }
});

export const addProductFavorite = createAsyncThunk(
  'ADD_FAVORITE',
  async (product: GetFavorite | ProductType, { rejectWithValue }) => {
    try {
      await post<FavoriteResponse>(URL.FAVORITE, { productId: product.articleCode });
      return product;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.favorite.existingFavorite}`);
      }
      return rejectWithValue(`${textContent.favorite.foundProblemFavorite}`);
    }
  },
);

export const deleteFavorite = createAsyncThunk(
  'DELETE_FAVORITE',
  async (data: { productId: string }, { rejectWithValue }) => {
    const { productId } = data;

    try {
      const response = await del<FavoriteResponse>(`${URL.FAVORITE}/${productId}`);

      return { data: response };
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return rejectWithValue(`${textContent.favorite.favoriteProductNotFound}`);
      }
      return rejectWithValue(`${textContent.favorite.foundProblemDeleteFavorite}`);
    }
  },
);
