import React from 'react';
import { To } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';

import ContactIcon from '../../../assets/Icons/Contact';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';

interface TermsScreenProps {
  url?: To;
}

const TermsScreen = ({ url = -1 as To }: TermsScreenProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      <GoBack url={url} title={textContent.termsAndConditions.name} data-testid="back-button" />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight={isMobile ? '75vh' : '70vh'}
        data-testid="terms-container"
        marginBottom="50px"
      >
        <Flex flexDirection="column" alignItems="center" data-testid="terms-content-containter">
          <Flex margin="20px">
            <ContactIcon dataTestId="contact-icon" isMobile={isMobile} />
          </Flex>
          <Text
            fontSize={{ base: '20px', xl: '25px' }}
            fontStyle="normal"
            fontWeight="500"
            lineHeight="29px"
            data-testid="terms-title"
            paddingBottom="10px"
            textAlign="center"
          >
            {textContent.termsAndConditions.title}
          </Text>
          <Text
            marginBottom="28px"
            data-testid="terms-text-content"
            fontSize={{ base: '16px', xl: '20px' }}
            textAlign="center"
            fontWeight="400"
            lineHeight="29px"
            letterSpacing="0.16px"
            color="grey900"
            padding={isMobile ? '20px' : '20px 50px'}
          >
            {textContent.termsAndConditions.content}
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default TermsScreen;
