import React from 'react';
import { Text } from '@chakra-ui/react';

const CardLabel = ({
  bgColor,
  label = '',
  textColor = 'white',
  testId,
}: {
  bgColor: string;
  label?: string;
  textColor?: string;
  testId: string;
}) => {
  return (
    <Text
      position="absolute"
      data-testid={testId}
      top="0"
      left="0"
      backgroundColor={bgColor}
      color={textColor}
      borderRadius="15.343px 0px"
      padding="2px 8px"
      fontSize="12px"
      fontStyle="normal"
      fontWeight="700"
      lineHeight="14px"
      letterSpacing="0em"
    >
      {label}
    </Text>
  );
};

export default CardLabel;
