import useSWR from 'swr';
import React, { Dispatch, SetStateAction } from 'react';
import { Button, Flex, Select } from '@chakra-ui/react';

import { monthNames } from '../../../constants/months';
import { SWRKey } from '../../../enum/SWRKey';
import { MonthAndYear } from '../../../interfaces';
import { getVoucherFilters } from '../../../services/voucher';
import textContent from '../../../utils/textContent.json';

const Filters = ({
  monthAndYear,
  setMonthAndYear,
}: {
  monthAndYear: MonthAndYear;
  setMonthAndYear: Dispatch<SetStateAction<MonthAndYear>>;
}) => {
  const { data: years } = useSWR({ key: SWRKey.reservationHistoryFilters }, getVoucherFilters);

  return (
    <Flex
      alignSelf={{ base: 'center', md: 'flex-start' }}
      width="100%"
      data-testid="filters-containers"
      marginLeft={{ md: '112px' }}
      maxWidth={{ base: '721px' }}
      justifyContent={{ base: 'center', md: 'flex-end' }}
      gap="16px"
      paddingTop="16px"
    >
      <Flex flexDirection="column" gap="10px">
        <Flex>
          <Select
            onChange={(e) => setMonthAndYear({ ...monthAndYear, month: e.target.value })}
            backgroundColor="white"
            data-testid="month-dropdown"
            fontWeight="400"
            fontSize="14px"
            value={monthAndYear.month}
            width="127px"
            placeholder="Mes"
            height="32px"
          >
            {monthNames.map((month, idx) => (
              <option data-testid={`month-${idx}`} key={idx} value={idx + 1}>
                {month}
              </option>
            ))}
          </Select>
          <Select
            onChange={(e) => {
              setMonthAndYear({ ...monthAndYear, year: e.target.value });
            }}
            backgroundColor="white"
            fontWeight="400"
            fontSize="14px"
            value={monthAndYear.year}
            width="127px"
            data-testid="year-dropdown"
            placeholder="Año"
            height="32px"
          >
            {years?.length &&
              years.map((year, idx) => (
                <option data-testid={`year-${idx}`} key={idx} value={year}>
                  {year}
                </option>
              ))}
          </Select>
        </Flex>
        {Object.values(monthAndYear).some((value) => value !== '') && (
          <Button
            data-testid="clear-filters-button"
            variant="link"
            color="#B23E8F"
            fontSize="14px"
            fontWeight="500"
            onClick={() => setMonthAndYear({ month: '', year: '' })}
          >
            {textContent.reservations.emptyFilters}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default Filters;
