import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import {
  clientCodeVerification,
  getUserRole,
  phoneCodeAuthentication,
  userLogout,
} from './actions';
import { InitialState } from './types';

export const userAuthSlice = createSlice({
  name: 'auth',
  initialState: {
    data: {},
    loading: false,
    error: undefined,
    isLogged: false,
  } as InitialState,
  reducers: {
    clearAuthData: () => {
      return {
        data: {},
        loading: false,
        error: undefined,
        isLogged: false,
      } as InitialState;
    },
    cleanError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientCodeVerification.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(clientCodeVerification.fulfilled, (state, action) => {
        state.data = action.payload?.data;
        state.data.isAdmin = false;
        state.loading = false;
      })
      .addCase(clientCodeVerification.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      })
      .addCase(phoneCodeAuthentication.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(phoneCodeAuthentication.fulfilled, (state) => {
        state.loading = false;
        state.isLogged = true;
      })
      .addCase(phoneCodeAuthentication.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      })
      .addCase(getUserRole.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getUserRole.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      })
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.data.isAdmin = action.payload.isAdmin;
        state.loading = false;
      })
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.error = typeof action.payload === 'string' ? action.payload : textContent.logoutError;
        state.loading = false;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

const persistConfig = {
  key: 'auth',
  storage,
  stateReconciler: autoMergeLevel2<InitialState>,
};

export const userAuthReducer = persistReducer(persistConfig, userAuthSlice.reducer);
export const { clearAuthData, cleanError } = userAuthSlice.actions;
