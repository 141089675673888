import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';

interface Props {
  email: string;
  branch: string;
  branchId: string;
}

const EditIconCell = ({ email, branch, branchId }: Props) => {
  const navigate = useNavigate();

  return (
    <EditIcon
      _hover={{ cursor: 'pointer' }}
      data-testid="edit-email-button"
      color="lightBlue"
      onClick={() => navigate('/admin/branch', { state: { email, branch, branchId } })}
    />
  );
};

export default EditIconCell;
