import { AxiosError } from 'axios';

import { get, patch, URL } from '../api';
import {
  GeneralSalesEmailData,
  GetGeneralSalesEmailsResponse,
} from '../interfaces/generalSalesEmail';
import textContent from '../utils/textContent.json';

export const getGeneralSalesEmail = async () => {
  const generalSalesEmailResponse = await get<GetGeneralSalesEmailsResponse>(
    URL.GENERAL_SALES_EMAIL,
  );

  const formattedGeneralSalesEmail: GeneralSalesEmailData = {
    id: generalSalesEmailResponse.data._id,
    email: generalSalesEmailResponse.data.email,
  };

  return {
    email: formattedGeneralSalesEmail,
  };
};

export const updateGeneralSalesEmail = async (id: string, newEmail: string) => {
  try {
    await patch(`${URL.GENERAL_SALES_EMAIL}/${id}`, { email: newEmail });
    return {
      message: textContent.generalSalesEmail.updateEmail.successMessage,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400)
        throw new Error(textContent.generalSalesEmail.updateEmail.errorMessage);
    }
    throw new Error(textContent.generalSalesEmail.updateEmail.genericErrorMessage);
  }
};
