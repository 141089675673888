import * as React from 'react';
interface Props {
  dataTestId: string;
  onClick?: (event: React.MouseEvent) => void;
  cursor: string;
  bgIcon: boolean;
}
const HeartIcon = ({ dataTestId, onClick, cursor, bgIcon }: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}
    onClick={onClick}
    cursor={cursor}
    fill={bgIcon ? '#FF923F' : '#FFFFFF'}
  >
    <g id="Group 7223">
      <rect id="Rectangle 2791" width="20" height="20" rx="2" fill="#EFE9ED" fillOpacity="0" />
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2092 13.2306C15.0575 11.5812 16 9.96097 16 8.38665C16 6.44794 14.7255 5.00697 13.0327 5C12.258 5 11.8306 5.07838 11.3213 5.32573C11.008 5.47787 10.7301 5.68327 10.4876 5.94428L10.0016 6.46761L9.51102 5.9484C9.27392 5.69744 9 5.49661 8.69009 5.34452C8.16542 5.08704 7.70952 5 6.9697 5C5.25576 5 4 6.42065 4 8.38665C4 9.96097 4.94255 11.5812 6.79085 13.2306C7.79855 14.1299 9.09403 15.0285 10 15.5C10.906 15.0285 12.2015 14.1299 13.2092 13.2306Z"
        stroke="#FF923F"
        strokeWidth="0.8"
      />
    </g>
  </svg>
);
export default HeartIcon;
