import { useEffect, useMemo, useState } from 'react';

export const useBreakpoint = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const breakPoint = useMemo(() => {
    if (width < 375) {
      return 'xs';
    }
    if (width < 768) {
      return 'base';
    }
    if (width >= 768 && width < 1280) {
      return 'md';
    }
    return 'xl';
  }, [width]);

  const isMobile = useMemo(() => {
    return width < 768;
  }, [width]);

  return {
    breakPoint,
    isMobile,
  };
};
