import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Skeleton } from '@chakra-ui/react';

import Carousel from '../Carousel/Carousel';

const DealsViewSkeleton = () => {
  const navigate = useNavigate();

  return (
    <>
      <Flex
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
        backgroundColor="background"
      >
        <Flex
          alignItems="center"
          textAlign="start"
          margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
        >
          <ArrowBackIcon
            boxSize={8}
            marginRight="20px"
            onClick={() => navigate(-1)}
            cursor="pointer"
            data-testid="arrow-icon"
          />
          <Box>
            <Skeleton width="80px" height="20px" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        backgroundColor="grey50"
        height={{ base: '130px', md: '198px' }}
        alignItems="center"
        data-testid="deals-category-skeleton"
      >
        <Carousel
          datatestid="categories"
          height={90}
          width={'full'}
          columns={10}
          md={6}
          base={4.5}
          sm={3.5}
          dots={false}
          infinite={false}
          autoplay={false}
        >
          {Array(9)
            .fill(undefined)
            .map((_, index) => (
              <Box
                key={index}
                width="60px"
                height="60px"
                data-testid={`skeleton-categories-${index}`}
              >
                <Skeleton width="60px" height="60px" margin="10px" borderRadius="8px" />
              </Box>
            ))}
        </Carousel>
      </Flex>
      <Box
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        backgroundColor="grey50"
        height="100%"
      >
        <Flex alignItems="flex-start">
          <Skeleton margin="24px 0" width="150px" height="18px" borderRadius="2px" />
        </Flex>
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
        >
          {Array(8)
            .fill(undefined)
            .map((_, index) => (
              <Flex justifyContent={'center'} key={index} marginBottom="20px">
                <Skeleton
                  key={index}
                  width={{ base: '135px ', xl: '185px' }}
                  height={{ base: '250px', xl: '315px' }}
                  borderRadius="8px"
                  data-testid={`skeleton-products-${index}`}
                />
              </Flex>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default DealsViewSkeleton;
