import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, Image, Skeleton, SkeletonText, Text } from '@chakra-ui/react';

interface BrandItemsProps {
  title: string;
  loading: boolean;
  pathname?: string;
  brandIcon?: JSX.Element | string;
  navigationPath?: string;
}

const BrandItems = ({ navigationPath, title, loading, pathname, brandIcon }: BrandItemsProps) => {
  const navigate = useNavigate();
  const { id, subCategoryId: categoryCode } = useParams();

  const navigatePath = () => {
    if (pathname?.startsWith('/deals/pendingToReceive'))
      return navigate(`/deals/pendingToReceive/${id}/brand/${title}`);
    if (pathname?.startsWith('/deals')) return navigate(`/deals/${id}/brand/${title}`);
    if (pathname?.startsWith('/pendingToReceive'))
      return navigate(`/pendingToReceive/${id}/brand/${title}`);
    return navigate(`/category/${id}/${categoryCode}/brand/${title}`);
  };

  return (
    <Flex margin="8px 0" flexDirection="column" width="100px">
      <Skeleton isLoaded={!loading} height={{ base: '55px', md: '80px' }} borderRadius="12px">
        <Flex
          justifyContent="center"
          alignItems="center"
          width={{ base: '55px', md: '80px' }}
          height={{ base: '55px', md: '80px' }}
          borderRadius="12px"
          backgroundColor="white"
          margin="5px auto"
          cursor="pointer"
          boxShadow="0px 4px 8px 0px rgba(0, 17, 51, 0.15)"
          onClick={() => (navigationPath ? navigate(`../${navigationPath}`) : navigatePath())}
        >
          {brandIcon ? (
            typeof brandIcon === 'string' ? (
              <Image src={brandIcon} alt="Brand Icon" borderRadius={12} />
            ) : (
              brandIcon
            )
          ) : (
            <></>
          )}
        </Flex>
      </Skeleton>

      <SkeletonText isLoaded={!loading} mt="4" noOfLines={2} width={loading ? '60px' : 'auto'}>
        <Flex justifyContent="center">
          <Text
            fontWeight="600"
            fontSize={{ base: '12px', md: '18px' }}
            textAlign="center"
            color="darkBlue"
            cursor="pointer"
            onClick={() => (navigationPath ? navigate(`../${navigationPath}`) : navigatePath())}
          >
            {title}
          </Text>
        </Flex>
      </SkeletonText>
    </Flex>
  );
};

export default BrandItems;
