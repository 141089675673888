import React from 'react';
import { Box, Skeleton } from '@chakra-ui/react';

const CardsSkeleton = () => {
  return (
    <Box data-testid="container-skeleton" paddingRight="10px">
      <Skeleton
        width={{ base: '120px', xl: '161.287px' }}
        height={{ base: '130px', xl: '174.727px' }}
        marginBottom="10px"
        borderRadius="9.59px"
        data-testid="product-image-skeleton"
      />
      <Skeleton
        height="16px"
        width={{ base: '120px', xl: '161.287px' }}
        marginBottom="5px"
        data-testid="product-title-skeleton"
      />
      <Skeleton height="15px" width="50%" marginBottom="5px" data-testid="product-price-skeleton" />
      <Skeleton
        height="23px"
        width={{ base: '120px', xl: '161.287px' }}
        marginBottom="15px"
        data-testid="product-button-skeleton"
      />
    </Box>
  );
};

export default CardsSkeleton;
