import React from 'react';

const TrashIcon = () => (
  <svg
    width="257"
    height="205"
    viewBox="0 0 257 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="256" height="204.8" transform="translate(0.5)" fill="#FAFAFC" />
    <rect x="0.5" width="256" height="204.8" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0122 137.217H158.196C158.723 137.217 159.237 137.16 159.732 137.052C160.227 137.16 160.741 137.217 161.268 137.217H214.516C218.475 137.217 221.684 134.008 221.684 130.049C221.684 126.09 218.475 122.881 214.516 122.881H208.372C204.413 122.881 201.204 119.672 201.204 115.713C201.204 111.754 204.413 108.545 208.372 108.545H227.828C231.787 108.545 234.996 105.336 234.996 101.377C234.996 97.4183 231.787 94.2091 227.828 94.2091H205.3C209.259 94.2091 212.468 90.9999 212.468 87.0411C212.468 83.0823 209.259 79.8731 205.3 79.8731H139.764C143.723 79.8731 146.932 76.6639 146.932 72.7051C146.932 68.7463 143.723 65.5371 139.764 65.5371H81.3962C77.4374 65.5371 74.2282 68.7463 74.2282 72.7051C74.2282 76.6639 77.4374 79.8731 81.3962 79.8731H40.4362C36.4774 79.8731 33.2682 83.0823 33.2682 87.0411C33.2682 90.9999 36.4774 94.2091 40.4362 94.2091H66.0362C69.995 94.2091 73.2042 97.4183 73.2042 101.377C73.2042 105.336 69.995 108.545 66.0362 108.545H25.0762C21.1174 108.545 17.9082 111.754 17.9082 115.713C17.9082 119.672 21.1174 122.881 25.0762 122.881H65.0122C61.0534 122.881 57.8442 126.09 57.8442 130.049C57.8442 134.008 61.0534 137.217 65.0122 137.217ZM231.924 137.217C235.883 137.217 239.092 134.008 239.092 130.049C239.092 126.09 235.883 122.881 231.924 122.881C227.965 122.881 224.756 126.09 224.756 130.049C224.756 134.008 227.965 137.217 231.924 137.217Z"
      fill="#EBF1FF"
    />
    <path
      d="M171.508 151.552H194.474M66.0365 151.552H88.0055H66.0365ZM52.8555 151.552H59.8925H52.8555ZM199.287 151.552H202.228H199.287Z"
      stroke="#76A4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.219 79.2178C113.992 79.2178 112.983 78.2191 113.136 77.0014C113.334 75.4283 113.741 73.8854 114.35 72.4146C115.244 70.2577 116.553 68.2979 118.204 66.647C119.855 64.9962 121.815 63.6867 123.972 62.7933C126.129 61.8999 128.44 61.44 130.775 61.44C133.11 61.44 135.421 61.8999 137.578 62.7933C139.735 63.6867 141.695 64.9962 143.346 66.647C144.997 68.2979 146.306 70.2577 147.199 72.4146C147.809 73.8854 148.216 75.4283 148.414 77.0014C148.567 78.2191 147.558 79.2178 146.331 79.2178C145.103 79.2178 144.127 78.2162 143.924 77.0059C143.757 76.0165 143.479 75.0467 143.093 74.1154C142.423 72.4977 141.441 71.0279 140.203 69.7897C138.965 68.5516 137.495 67.5695 135.877 66.8994C134.26 66.2294 132.526 65.8845 130.775 65.8845C129.024 65.8845 127.29 66.2294 125.673 66.8994C124.055 67.5695 122.585 68.5516 121.347 69.7897C120.109 71.0279 119.127 72.4977 118.457 74.1154C118.071 75.0467 117.793 76.0165 117.626 77.0059C117.423 78.2162 116.447 79.2178 115.219 79.2178Z"
      fill="#5C92FF"
    />
    <path
      d="M157.799 146.808C159.049 142.82 163.884 99.8388 165.256 87.4934C165.454 85.7179 164.06 84.1953 162.274 84.1953H99.3024C97.5079 84.1953 96.1147 85.7604 96.3226 87.5429L100.199 120.778L104.142 146.784C104.335 148.059 105.322 149.068 106.592 149.289L116.298 150.984C116.469 151.014 116.641 151.029 116.814 151.029H130.777H149.873C149.942 151.029 150.007 151.031 150.077 151.034C150.891 151.066 156.445 151.124 157.799 146.808Z"
      fill="#FAFAFC"
    />
    <path
      d="M105.974 142.798C104.742 138.778 101.408 101.033 100.455 90.0342C100.372 89.0739 101.562 88.5885 102.2 89.3114L151.355 145.063C151.924 145.709 151.466 146.724 150.605 146.724H134.856H112.55C112.499 146.724 112.45 146.728 112.399 146.734C111.77 146.808 107.314 147.174 105.974 142.798Z"
      fill="#EBF1FF"
    />
    <path
      d="M117.996 101.484L122.935 129.497"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M138.961 129.423L143.9 101.411"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M100.91 134.684C100.91 134.684 101.621 143.218 103.754 146.773C105.888 150.329 108.732 150.329 108.732 150.329C108.732 150.329 147.843 151.04 150.688 150.329C153.532 149.618 155.666 150.329 157.799 146.773C159.932 143.218 164.199 87.04 164.199 87.04"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M100.026 129.536L99.3086 121.812"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.7761 116.907L95.9316 87.04"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.8203 85.6177L172.731 85.6177"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default TrashIcon;
