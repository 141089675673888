import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { ProductStatus } from '../../../enum/Product';
import { SWRKey } from '../../../enum/SWRKey';
import { getBrandsByCategory } from '../../../services/brands';
import { getProductsPendingToReceiveByCategory } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingCategory, structuredAndOrderedCategory } from '../../../store/modules/categories';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { RootState } from '../../../store/types';
import { transformString } from '../../../utils/search-items';
import { getProductPendingToReceiveByCategoryKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import BrandsCarousel from '../BrandsCasousel/BrandsCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';
import SubCategoryViewSkeleton from '../SubCategoryView/SubCategoryViewSkeleton';

const CategoriesPendingToReceiveMobile = () => {
  const { id: categoryCode } = useParams();
  const { pathname } = useLocation();

  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const loadingCategory = useAppSelector(isLoadingCategory);
  const categoryItem = useAppSelector((state: RootState) =>
    structuredAndOrderedCategory(state, categoryCode as string),
  );

  const { data, isLoading, error, size, setSize } = useSWRInfinite(
    getProductPendingToReceiveByCategoryKey(categoryCode as string),
    getProductsPendingToReceiveByCategory,
  );

  const { data: brands, isLoading: isLoadingBrands } = useSWR(
    categoryCode && {
      categoryCode,
      key: SWRKey.brands,
      status: ProductStatus.EN_VIAJE,
    },
    getBrandsByCategory,
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  if (isLoading || loadingFavorites || isLoadingBrands) {
    return <SubCategoryViewSkeleton />;
  }

  if (error || products.length === 0) {
    return <ErrorScreen title={textContent.product.productError} />;
  }

  return (
    <Flex justifyContent="center" direction="column">
      <GoBack title={textContent.onTrip} subTitle={transformString(categoryItem?.category)} />
      <Flex flexDirection="column" backgroundColor={{ base: 'white' }}>
        {brands && brands.length > 0 && (
          <Flex
            flexDirection="column"
            minHeight={{ base: '150px', md: '220px' }}
            backgroundColor="white"
            borderRadius="8px"
            justifyContent="flex-start"
          >
            <Text
              margin={{ base: '20px 30px 0' }}
              color="darkBlue"
              fontWeight="500"
              fontSize={{ base: '16px' }}
            >
              {textContent.category.SelectFavoriteBrand}
            </Text>
            <BrandsCarousel pathname={pathname} brands={brands} isLoading={loadingCategory} />
          </Flex>
        )}
      </Flex>
      <Box padding={{ base: '0' }} backgroundColor="grey50">
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '16px' }}
          fontWeight={{ base: '500' }}
          margin={{ base: '24px' }}
        >
          {textContent.product.pendingToReceive}
        </Text>
        <InfiniteScroll
          dataLength={products.length}
          next={() => setSize(size + 1)}
          hasMore={hasMore}
          loader={<ProductCardSkeleton array={2} />}
        >
          <Grid
            gridTemplateColumns={{ base: 'repeat(auto-fill, minmax(150px, 1fr))' }}
            data-testid="product-list"
          >
            {products.map((product, index) => (
              <Flex justifyContent={'center'} key={index} marginBottom="20px">
                <ProductCard
                  urlKey={`${URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE}/${categoryCode}`}
                  product={product}
                />
              </Flex>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Flex>
  );
};

export default CategoriesPendingToReceiveMobile;
