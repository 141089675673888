import React from 'react';

const EmptyVouchersListIcon = ({ dataTestId }: { dataTestId: string }) => {
  return (
    <svg
      data-testid={dataTestId}
      width="222"
      height="125"
      viewBox="0 0 222 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.5122 99.2171H140.696C141.223 99.2171 141.737 99.1602 142.232 99.0522C142.727 99.1602 143.241 99.2171 143.768 99.2171H197.016C200.975 99.2171 204.184 96.0079 204.184 92.0491C204.184 88.0903 200.975 84.8811 197.016 84.8811H190.872C186.913 84.8811 183.704 81.6719 183.704 77.7131C183.704 73.7543 186.913 70.5451 190.872 70.5451H210.328C214.287 70.5451 217.496 67.3359 217.496 63.3771C217.496 59.4183 214.287 56.2091 210.328 56.2091H187.8C191.759 56.2091 194.968 52.9999 194.968 49.0411C194.968 45.0823 191.759 41.8731 187.8 41.8731H122.264C126.223 41.8731 129.432 38.6639 129.432 34.7051C129.432 30.7463 126.223 27.5371 122.264 27.5371H63.8962C59.9374 27.5371 56.7282 30.7463 56.7282 34.7051C56.7282 38.6639 59.9374 41.8731 63.8962 41.8731H22.9362C18.9774 41.8731 15.7682 45.0823 15.7682 49.0411C15.7682 52.9999 18.9774 56.2091 22.9362 56.2091H48.5362C52.495 56.2091 55.7042 59.4183 55.7042 63.3771C55.7042 67.3359 52.495 70.5451 48.5362 70.5451H7.5762C3.61743 70.5451 0.408203 73.7543 0.408203 77.7131C0.408203 81.6719 3.61743 84.8811 7.5762 84.8811H47.5122C43.5534 84.8811 40.3442 88.0903 40.3442 92.0491C40.3442 96.0079 43.5534 99.2171 47.5122 99.2171ZM214.424 99.2171C218.383 99.2171 221.592 96.0079 221.592 92.0491C221.592 88.0903 218.383 84.8811 214.424 84.8811C210.465 84.8811 207.256 88.0903 207.256 92.0491C207.256 96.0079 210.465 99.2171 214.424 99.2171Z"
        fill="#EBF1FF"
      />
      <path
        d="M154.008 113.552H176.974M48.5365 113.552H70.5055H48.5365ZM35.3555 113.552H42.3925H35.3555ZM181.787 113.552H184.728H181.787Z"
        stroke="#76A4FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.3516 6.34155L94.7176 19.0935M136.702 6.34155L125.336 19.0935L136.702 6.34155ZM109.976 1.93555V19.0935V1.93555Z"
        stroke="#FF923F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.0231 34.7021H142.132L136.391 43.3138L144.046 49.0549H76.1094L84.721 43.3138L78.0231 34.7021Z"
        fill="#EBF1FF"
      />
      <rect x="74.1348" y="46.9902" width="72.704" height="76.8" rx="1.6" fill="#FAFAFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.8301 88.9802V53.7947C78.8301 52.3451 80.0182 51.1699 81.4837 51.1699L143.924 116.79C143.924 118.723 142.377 120.29 140.468 120.29H82.286C80.3773 120.29 78.8301 118.723 78.8301 116.79V103.714V100.166V88.9802ZM78.8301 96.5769V92.6363V96.5769Z"
        fill="#EBF1FF"
      />
      <path
        d="M75.1582 89.4479V50.9682C75.1582 49.3828 76.4592 48.0977 78.064 48.0977H144.546C145.591 48.0977 146.438 48.9544 146.438 50.0114V119.862C146.438 121.975 144.744 123.689 142.654 123.689H78.9425C76.8525 123.689 75.1582 121.975 75.1582 119.862V105.561V101.681M75.1582 97.7559V93.4463"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M77.0672 48.0977V36.6154C77.0672 35.5585 77.8361 34.7017 78.7846 34.7017L142.329 34.7017C143.278 34.7017 144.047 35.5585 144.047 36.6154V48.0977"
        stroke="#5C92FF"
        strokeWidth="2"
      />
      <path
        d="M94.7689 68.1921C96.6185 68.1921 98.1179 66.6927 98.1179 64.8431C98.1179 62.9935 96.6185 61.4941 94.7689 61.4941C92.9193 61.4941 91.4199 62.9935 91.4199 64.8431C91.4199 66.6927 92.9193 68.1921 94.7689 68.1921Z"
        fill="#E8F0FE"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M126.345 68.1921C128.195 68.1921 129.694 66.6927 129.694 64.8431C129.694 62.9935 128.195 61.4941 126.345 61.4941C124.495 61.4941 122.996 62.9935 122.996 64.8431C122.996 66.6927 124.495 68.1921 126.345 68.1921Z"
        fill="#E8F0FE"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M125.865 68.1923C125.865 76.6476 119.011 83.502 110.556 83.502C102.1 83.502 95.2461 76.6476 95.2461 68.1923"
        stroke="#FF923F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M78.0817 35.6543L84.818 42.3038C85.2204 42.7011 85.2246 43.3495 84.8273 43.7519C84.7586 43.8216 84.6802 43.881 84.5946 43.9284L77.0664 48.0964"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M143.193 35.7266L136.747 42.3011C136.351 42.7049 136.357 43.3533 136.761 43.7492C136.828 43.8153 136.905 43.8717 136.987 43.917L144.654 48.0993"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmptyVouchersListIcon;
