import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

const ReservationHistoryCardSkeleton = () => {
  return (
    <Flex
      flexDirection="column"
      data-testid="reservation-history-card-skeleton-container"
      padding={{ base: '0', md: '32px 0' }}
      backgroundColor="grey50"
      width="100%"
      height="100%"
      alignItems={{ base: 'center', xl: 'flex-start' }}
    >
      {Array(3)
        .fill(undefined)
        .map((_, index) => (
          <Flex
            key={index}
            backgroundColor={{ base: 'grey50', md: 'background' }}
            maxWidth={{ base: '375px', md: '721px' }}
            width="100%"
            maxHeight={{ base: '120px', md: '159px' }}
            minHeight="120px"
            height="100%"
            padding={{ md: '24px 32px' }}
            borderRadius="8px"
            borderBottom="1px solid #E2E8F0"
            alignItems="center"
            margin={{ xl: '0px 112px' }}
          >
            <Flex flexDirection="column" margin="0 20px 0 24px" flexGrow="1">
              <Skeleton height="14px" width="30%" marginBottom="4px" />
              <Skeleton height="14px" width="30%" marginBottom="4px" />
              <Skeleton height="14px" width="30%" />
              <Flex justifyContent="space-between" marginTop={{ base: '12px', md: '8px' }}>
                <Skeleton height="14px" width="20%" />
                <Skeleton height="14px" width="15%" />
              </Flex>
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};

export default ReservationHistoryCardSkeleton;
