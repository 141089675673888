import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

import CardsSkeleton from '../../../../shared/ProductCard/CardsSkeleton';

const CarouselSkeleton = () => {
  return (
    <Flex flexDirection="column" data-testid="carousel-container-skeleton" overflow="hidden">
      <Flex alignItems="end" height="70px">
        <Skeleton
          marginLeft={{ base: '20px', xl: '129px' }}
          borderRadius="15.343px 0px"
          width="100px"
          height="25px"
          position="relative"
          top="10px"
          data-testid="carousel-title-skeleton"
        />
      </Flex>
      <Flex
        height={{ base: '330px', xl: '394px' }}
        width="100%"
        backgroundColor="grey3"
        alignItems="start"
        flexDirection="column"
        justifyContent="center"
        paddingLeft={{ base: '15px', xl: '129px' }}
        paddingRight={{ xl: '129px' }}
      >
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          {Array(6)
            .fill(undefined)
            .map((_, index) => (
              <CardsSkeleton key={index} />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CarouselSkeleton;
