import React, { cloneElement, JSXElementConstructor, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/layout';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { theme } from '../../../theme';

interface CategoryItemsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  datatestid: string;
  itemDataTestId: string;
  title: string;
  code: string;
  pathname: string;
}

const CategoryItems = ({
  datatestid,
  children,
  title,
  code,
  pathname,
  itemDataTestId,
}: CategoryItemsProps) => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  const URLs = {
    '/deals': '/deals',
    '/pendingToReceive': '/pendingToReceive',
    '/deals/pendingToReceive': '/deals/pendingToReceive',
  };

  const url = URLs[pathname as keyof typeof URLs] || '/category';

  const navigatePath = () => {
    if (title === 'Ofertas') {
      return navigate(pathname === '/pendingToReceive' ? '/deals/pendingToReceive' : '/deals');
    }
    if (title === 'En viaje') {
      return navigate(pathname === '/deals' ? '/deals/pendingToReceive' : '/pendingToReceive');
    }
    return navigate(`${url}/${code}`);
  };

  const [changeBg, setChangeBg] = useState<boolean>(false);
  const [bgColor, setBgColor] = useState<string>(theme.colors.greyOnHover);
  return (
    <Flex margin="20px 0" justifyContent="center" alignItems="center" flexDirection="column">
      <Flex onClick={() => navigatePath()} cursor="pointer">
        <Flex
          data-testid={datatestid}
          justifyContent="center"
          alignItems="center"
          width="55px"
          height="55px"
          borderRadius="12px"
          bg={bgColor}
          margin="0 auto"
          onMouseEnter={() => {
            setChangeBg(true);
            setBgColor(theme.colors.purple);
          }}
          onMouseLeave={() => {
            setChangeBg(false);
            setBgColor(theme.colors.greyOnHover);
          }}
          onTouchStart={() => {
            setChangeBg(true);
            setBgColor(theme.colors.purple);
          }}
          onTouchEnd={() => {
            setChangeBg(false);
            setBgColor(theme.colors.greyOnHover);
          }}
        >
          {cloneElement(children, { changeBg: changeBg, dataTestId: itemDataTestId })}
        </Flex>
      </Flex>
      <Flex
        paddingTop="5px"
        onClick={() => navigatePath()}
        cursor="pointer"
        onMouseEnter={() => {
          setChangeBg(true);
          setBgColor(theme.colors.purple);
        }}
        onMouseLeave={() => {
          setChangeBg(false);
          setBgColor(theme.colors.greyOnHover);
        }}
        onTouchStart={() => {
          setChangeBg(true);
          setBgColor(theme.colors.purple);
        }}
        onTouchEnd={() => {
          setChangeBg(false);
          setBgColor(theme.colors.greyOnHover);
        }}
      >
        <Text
          fontWeight="600"
          fontSize={isMobile ? '12px' : '16px'}
          textAlign="center"
          color={theme.colors.black}
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CategoryItems;
