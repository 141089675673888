import useSWR from 'swr';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { ProductType } from '../../../interfaces';
import { getProductsFromSearchBar } from '../../../services/product';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../../shared/ErrorScreen/ErrorScreen';
import GoBack from '../../shared/GoBack/GoBack';
import PaginationNavbar from '../../shared/PaginationNavbar/PaginationNavbar';
import ProductCard from '../../shared/ProductCard/ProductCard';
import ProductCardSkeleton from '../../shared/ProductCard/ProductCardSkeleton';

const SearchBarResult = () => {
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const searchValue = searchParams.get('searchValue') ?? '';

  const { data, error, isLoading } = useSWR(
    searchValue.toLowerCase().trim() && {
      currentPage,
      pageSize: 18,
      searchValue,
      full: true,
    },
    getProductsFromSearchBar,
  );

  if (isLoading) {
    return <ProductCardSkeleton array={12} />;
  }

  if (error || data?.products.length === 0 || !searchValue) {
    return <ErrorScreen url={'/'} title={textContent.product.associatedProductsNotFound} />;
  }

  return (
    <Flex justifyContent="center" direction="column" data-testid="searchbar-result-container">
      <GoBack url={'/'} title={searchValue} />
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
          data-testid="products-list"
          marginBottom="10px"
        >
          {data?.products.map((product: ProductType, index: number) => (
            <Flex justifyContent="center" key={index} margin="20px 0">
              <ProductCard
                product={product}
                data-testid={`product-card-${index}`}
                urlKey={`${URL.GET_FILTERED_PRODUCTS}?searchValue=${searchValue}`}
              />
            </Flex>
          ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </Flex>
  );
};

export default SearchBarResult;
