import './CategoriesDropdown.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Accordion, Box, Flex, Menu, MenuButton, MenuGroup, MenuList } from '@chakra-ui/react';

import { useAppSelector } from '../../../store/hooks';
import { categoryData } from '../../../store/modules/categories';
import { categoryOrder } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import SubCategories from './SubCategories';

const CategoriesDropdown = ({ dataTestId }: { dataTestId: string }) => {
  const { loading: isLoading, data: categories } = useAppSelector(categoryData);

  const navigate = useNavigate();
  const data = categories && categoryOrder(categories);

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <Box className="menu-container">
          <MenuButton
            data-testid={dataTestId}
            border="0px"
            borderRadius="0px"
            backgroundColor="transparent"
            height="16px"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            outline="0px"
            color="darkBlue"
            _hover={{ background: 'transparent' }}
            _focus={{ background: 'transparent' }}
          >
            {textContent.categories}
            {isOpen ? (
              <ChevronUpIcon data-testid={'categories-dropdown-up-icon'} />
            ) : (
              <ChevronDownIcon data-testid={'categories-dropdown-down-icon'} />
            )}
          </MenuButton>
          <MenuList width="940px" height="85%" paddingLeft="20px" overflowY="scroll">
            <Accordion allowToggle display={'flex'} flexWrap={'wrap'}>
              {categories &&
                data.map((category, index) => (
                  <Flex
                    key={index}
                    width="300px"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    margin="10px 0"
                  >
                    <MenuGroup
                      data-testid={`menuGroup${index}`}
                      as="a"
                      onClick={() => {
                        onClose();
                        navigate(`/category/${category.code}`);
                      }}
                      title={transformString(category.category)}
                      fontSize="16px"
                      fontWeight="500"
                      cursor="pointer"
                    >
                      <SubCategories
                        key={index}
                        index={index}
                        isLoading={isLoading}
                        category={category}
                        closeModal={() => onClose()}
                      />
                      <Flex
                        padding="5px 0 0 26px"
                        fontSize="13px"
                        cursor="pointer"
                        onClick={() => {
                          onClose();
                          navigate(`/category/${category.code}/all`);
                        }}
                      >
                        {textContent.allProducts}
                      </Flex>
                    </MenuGroup>
                  </Flex>
                ))}
            </Accordion>
          </MenuList>
        </Box>
      )}
    </Menu>
  );
};

export default CategoriesDropdown;
