import React from 'react';

interface Props {
  dataTestId: string;
}
const ImagesIcon = ({ dataTestId }: Props): JSX.Element => (
  <svg
    data-testid={dataTestId}
    width="257"
    height="205"
    viewBox="0 0 257 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="256" height="204.8" transform="translate(0.5)" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0122 137.215H158.196C158.723 137.215 159.237 137.158 159.732 137.05C160.227 137.158 160.741 137.215 161.268 137.215H214.516C218.475 137.215 221.684 134.006 221.684 130.047C221.684 126.088 218.475 122.879 214.516 122.879H208.372C204.413 122.879 201.204 119.67 201.204 115.711C201.204 111.752 204.413 108.543 208.372 108.543H227.828C231.787 108.543 234.996 105.334 234.996 101.375C234.996 97.4161 231.787 94.2069 227.828 94.2069H205.3C209.259 94.2069 212.468 90.9977 212.468 87.0389C212.468 83.0801 209.259 79.8709 205.3 79.8709H139.764C143.723 79.8709 146.932 76.6617 146.932 72.7029C146.932 68.7441 143.723 65.5349 139.764 65.5349H81.3962C77.4374 65.5349 74.2282 68.7441 74.2282 72.7029C74.2282 76.6617 77.4374 79.8709 81.3962 79.8709H40.4362C36.4774 79.8709 33.2682 83.0801 33.2682 87.0389C33.2682 90.9977 36.4774 94.2069 40.4362 94.2069H66.0362C69.995 94.2069 73.2042 97.4161 73.2042 101.375C73.2042 105.334 69.995 108.543 66.0362 108.543H25.0762C21.1174 108.543 17.9082 111.752 17.9082 115.711C17.9082 119.67 21.1174 122.879 25.0762 122.879H65.0122C61.0534 122.879 57.8442 126.088 57.8442 130.047C57.8442 134.006 61.0534 137.215 65.0122 137.215ZM231.924 137.215C235.883 137.215 239.092 134.006 239.092 130.047C239.092 126.088 235.883 122.879 231.924 122.879C227.965 122.879 224.756 126.088 224.756 130.047C224.756 134.006 227.965 137.215 231.924 137.215Z"
      fill="#F3F7FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.8487 142.462L84.6808 143.048C82.4407 143.362 80.3695 141.802 80.0547 139.562L68.6536 58.4387C68.3388 56.1986 69.8995 54.1274 72.1397 53.8126L152.248 42.554C154.489 42.2392 156.56 43.8 156.875 46.0401C156.875 46.0401 157.604 51.227 157.854 53.0112"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.4359 137.945L87.6513 138.483C85.6172 138.772 83.7391 137.358 83.4564 135.324L73.2214 61.6732C72.9388 59.6394 74.3586 57.7563 76.3928 57.4671L149.135 47.1281C151.169 46.839 153.047 48.2533 153.33 50.2871L154.21 56.6162C154.257 56.9587 157.399 79.6909 163.636 124.813C163.949 127.079 162.387 129.172 160.147 129.489C160.123 129.492 160.099 129.495 160.074 129.498L91.4359 137.945Z"
      fill="#EBF1FF"
    />
    <path
      d="M88.8487 142.462L84.6808 143.048C82.4407 143.362 80.3695 141.802 80.0547 139.562L68.6536 58.4387C68.3388 56.1986 69.8995 54.1274 72.1397 53.8126L152.248 42.554C154.489 42.2392 156.56 43.8 156.875 46.0401C156.875 46.0401 157.604 51.227 157.854 53.0112"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M158.707 57.7327L159.219 60.928"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M102.698 61.8414C102.857 60.331 104.21 59.2352 105.72 59.3939L186.364 67.8699C187.875 68.0287 188.971 69.3819 188.812 70.8923L180.229 152.555C180.07 154.065 178.717 155.161 177.206 155.002L96.5625 146.526C95.0521 146.367 93.9563 145.014 94.115 143.504L102.698 61.8414Z"
      fill="white"
      stroke="#5C92FF"
      strokeWidth="2.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.761 70.8451C109.934 69.1974 111.41 68.002 113.058 68.1752L177.36 74.9336C179.007 75.1067 180.203 76.5829 180.03 78.2307L174.342 132.349C174.168 133.996 172.692 135.192 171.044 135.019L106.743 128.26C105.095 128.087 103.899 126.611 104.073 124.963L109.761 70.8451Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.875 112.515L122.976 105.702C124.609 104.6 126.802 104.852 128.143 106.296L135.56 114.281C135.918 114.667 136.514 114.709 136.922 114.377L152.731 101.568C154.579 100.071 157.317 100.507 158.608 102.504L168.886 118.402L170.354 120.851L169.649 129.195C169.602 129.756 169.102 130.167 168.542 130.105L110.165 123.619C109.623 123.559 109.229 123.076 109.279 122.534L110.055 114.115L112.875 112.515Z"
      fill="#EBF1FF"
    />
    <path
      d="M111.004 70.9758C111.105 70.0146 111.966 69.3173 112.927 69.4183L177.229 76.1767C178.19 76.2777 178.887 77.1388 178.786 78.1L173.098 132.218C172.997 133.179 172.136 133.876 171.175 133.775L106.873 127.017C105.912 126.916 105.215 126.055 105.316 125.094L111.004 70.9758Z"
      stroke="#5C92FF"
      strokeWidth="2.5"
    />
    <circle
      cx="125.46"
      cy="88.0121"
      r="6.144"
      transform="rotate(6 125.46 88.0121)"
      stroke="#FF923F"
      strokeWidth="2.5"
    />
    <path
      d="M110.815 114.099C114.846 111.315 122.908 105.748 122.908 105.748C124.581 104.62 126.826 104.878 128.199 106.356L135.545 114.265C135.912 114.659 136.521 114.702 136.94 114.363L152.648 101.635C154.405 100.211 156.985 100.481 158.409 102.239C158.501 102.353 158.587 102.471 158.666 102.594C158.666 102.594 167.85 117.166 169.996 120.571"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ImagesIcon;
