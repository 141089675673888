import { AxiosError } from 'axios';

import { del, get, patch, post, URL } from '../api';
import { ItemUpdated } from '../components/shared/SortableList/sortableListTypes';
import {
  BannerActions,
  BannerData,
  GetAllBannersToHome,
  GetBannersResponse,
} from '../interfaces/banner';
import textContent from '../utils/textContent.json';

export const getBanners = async () => {
  const bannersResponse = await get<GetBannersResponse>(URL.BANNER_CLIENT);

  const formattedBanners: BannerData[] = bannersResponse.data.map((banner) => ({
    id: banner._id,
    title: banner.title,
    desktopImage: banner.desktopImage,
    mobileImage: banner.mobileImage,
    isActive: banner.isActive || false,
  }));

  return {
    banners: formattedBanners,
  };
};

export const addBanner = async (data: {
  title: string;
  mobileImage: string;
  desktopImage: string;
}) => {
  try {
    await post(URL.BANNER, data);
    return {
      message: textContent.banners.toast.addBanner.successMessage,
    };
  } catch {
    throw new Error(textContent.banners.toast.addBanner.errorMessage);
  }
};

export const deleteBanner = async (id: string) => {
  try {
    await del(`${URL.BANNER}/${id}`);
    return {
      message: textContent.banners.toast.deleteBanner.successMessage,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400)
        throw new Error(textContent.banners.toast.deleteBanner.noEmptyBanners);
    }
    throw new Error(textContent.banners.toast.deleteBanner.errorMessage);
  }
};

export const pauseBanner = async (id: string, action: BannerActions) => {
  try {
    const isActive = action === 'activateBanner';
    await patch(`${URL.BANNER}/${id}`, { isActive });
    return {
      message: textContent.banners.toast[action]?.successMessage,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400)
        throw new Error(textContent.banners.toast.pauseBanner.noEmptyBanners);
    }
    throw new Error(textContent.banners.toast[action]?.errorMessage);
  }
};

export const getAllBannersToHome = async () => {
  const response = await get<GetAllBannersToHome>(URL.BANNER_CLIENT);

  return response.data;
};

export const updateBannersPositions = async (banners: ItemUpdated[]) => {
  try {
    await patch(`${URL.BANNER}/updatePositions`, banners);
    return {
      message: textContent.banners.toast.updatePositions.successMessage,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 400)
        throw new Error(textContent.banners.toast.updatePositions.errorMessage);
    }
    throw new Error(textContent.banners.toast.updatePositions.genericErrorMessage);
  }
};
