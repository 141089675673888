/* eslint-disable @typescript-eslint/no-explicit-any */

export const calculateRemainingStock = (product: any, cartQuantity: number) => {
  if (!product) return { existence: 0, pendingToReceive: 0, totalStock: 0 };

  let remainingExistence = +product.existence - cartQuantity;
  let remainingPendingToReceive = +product.pendingToReceive;
  let totalStock = +product.totalStock - cartQuantity;

  if (remainingExistence < 0) {
    remainingPendingToReceive += remainingExistence;
    remainingExistence = 0;
  }

  remainingPendingToReceive = Math.max(0, remainingPendingToReceive);
  totalStock = Math.max(0, totalStock);

  return {
    existence: remainingExistence,
    pendingToReceive: remainingPendingToReceive,
    totalStock: totalStock,
  };
};
