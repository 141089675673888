import { useEffect, useState } from 'react';

const useDetectKeyboardOpen = (minKeyboardHeight = 300) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const listener = () => {
      const newState =
        window.screen.height - minKeyboardHeight > (window.visualViewport?.height as number);
      if (isKeyboardOpen != newState) {
        setIsKeyboardOpen(newState);
      }
    };
    if (typeof visualViewport != 'undefined') {
      window.visualViewport?.addEventListener('resize', listener);
    }
    return () => {
      if (typeof visualViewport != 'undefined') {
        window.visualViewport?.removeEventListener('resize', listener);
      }
    };
  }, [isKeyboardOpen, minKeyboardHeight]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
