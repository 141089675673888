import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { ProductType } from '../../../interfaces';
import { getProductsFromSearchBar } from '../../../services/product';
import { getProductsBySearchBarKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../../shared/ErrorScreen/ErrorScreen';
import GoBack from '../../shared/GoBack/GoBack';
import ProductCard from '../../shared/ProductCard/ProductCard';
import ProductCardSkeleton from '../../shared/ProductCard/ProductCardSkeleton';

const SearchBarResultMobile = () => {
  const [searchParams] = useSearchParams();

  const searchValue = searchParams.get('searchValue') ?? '';

  const { data, error, size, setSize } = useSWRInfinite(
    getProductsBySearchBarKey(searchValue),
    getProductsFromSearchBar,
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  if (error || !searchValue) {
    return <ErrorScreen title={textContent.product.associatedProductsNotFound} />;
  }

  return (
    <Flex
      justifyContent="flex-start"
      direction="column"
      height="100%"
      backgroundColor="grey50"
      data-testid="searchbar-result-mobile-container"
    >
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <GoBack url={'/'} title={searchValue} />
        <InfiniteScroll
          dataLength={products?.length}
          next={() => setSize(size + 1)}
          hasMore={hasMore}
          loader={<ProductCardSkeleton array={2} />}
        >
          <Grid
            gridTemplateColumns={{
              base: 'repeat(auto-fill, minmax(150px, 1fr))',
              xl: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
            data-testid="products-list"
          >
            {products.map((product: ProductType, index: number) => (
              <Flex justifyContent={'center'} key={index} margin="20px 0">
                <ProductCard
                  product={product}
                  urlKey={`${URL.GET_FILTERED_PRODUCTS}?searchValue=${searchValue}`}
                />
              </Flex>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Flex>
  );
};

export default SearchBarResultMobile;
