import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import AlertIcon from '../../../assets/Icons/AlertIcon';

interface Props {
  title: string;
  subTitle: string;
}

const ToastWarningAlert = ({ title, subTitle }: Props) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      background="orangeAlert"
      width={{ xs: '270px', md: '293px' }}
      minHeight="74px"
      borderRadius="8px"
      padding={{ base: '15px', md: '0 15px' }}
      data-testid="warning-alert-container"
      marginTop={{ base: '55px', md: '0' }}
    >
      <Flex height="100%" alignItems="center" marginRight="10px">
        <AlertIcon dataTestId="alert-icon" />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
        <Text data-testid="warning-alert-title" fontSize="16px" fontWeight="700" color="darkBlue">
          {title}
        </Text>
        <Text
          data-testid="warning-alert-subtitle"
          fontSize="14px"
          fontWeight="400"
          color="darkBlue"
        >
          {subTitle}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ToastWarningAlert;
