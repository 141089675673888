import useSWR, { mutate } from 'swr';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';

import ActivateBannerIcon from '../../../../assets/Icons/ActivateBannerIcon';
import DeleteBannerIcon from '../../../../assets/Icons/DeleteBannerIcon';
import PauseBannerIcon from '../../../../assets/Icons/PauseBannerIcon';
import SwapBannerIcon from '../../../../assets/Icons/SwapBannerIcon';
import { ModalType } from '../../../../enum/ModalType';
import { SWRKey } from '../../../../enum/SWRKey';
import { BannerActions } from '../../../../interfaces/banner';
import {
  deleteBanner,
  getBanners,
  pauseBanner,
  updateBannersPositions,
} from '../../../../services/banner';
import { useAppDispatch } from '../../../../store/hooks';
import { showModal } from '../../../../store/modules/ui';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import SortableList from '../../../shared/SortableList/SortableList';
import SortableListSkeleton from '../../../shared/SortableList/SortableListSkeleton';
import css from './bannersView.module.css';
import { columns } from './columnsDefinition';

const BannersView = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useSWR({ key: SWRKey.adminBanners }, getBanners);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const TableContent = React.useMemo(() => {
    const onConfirm = async (
      id: string,
      action: (id: string) => Promise<{
        message: string;
      }>,
      key: BannerActions,
    ) => {
      try {
        toast.closeAll();
        const res = await action(id);
        toast({
          title: textContent.banners[key],
          description: res.message,
          status: 'success',
          variant: 'subtle',
          duration: 3000,
        });
      } catch (error) {
        if (error instanceof Error)
          toast({
            title: textContent.banners[key],
            description: error.message,
            status: 'error',
            variant: 'subtle',
            duration: 3000,
          });
      } finally {
        mutate('getBanners');
      }
    };
    const onDelete = (id: string) => {
      dispatch(
        showModal({
          type: ModalType.CONFIRM_BANNER_ADP,
          data: 'deleteBanner',
          callback: () => onConfirm(id, deleteBanner, 'deleteBanner'),
        }),
      );
    };

    const onPause = (id: string, action: BannerActions) => {
      dispatch(
        showModal({
          type: ModalType.CONFIRM_BANNER_ADP,
          data: action,
          callback: () => onConfirm(id, (id) => pauseBanner(id, action), action),
        }),
      );
    };
    if (isLoading) return <SortableListSkeleton />;
    return (
      <DndProvider backend={HTML5Backend}>
        <div className={css.bannersTable}>
          <SortableList
            data={data?.banners || []}
            columns={columns}
            onChange={(positions) => updateBannersPositions(positions)}
            onItemRender={(item) => (
              <div className={item.isActive ? css.activeBanner : css.inactiveBanner} key={item.id}>
                <div className={css.row}>
                  <div className={css.actionsHeader}>
                    <SwapBannerIcon dataTestId="swap-banner-icon" />
                  </div>
                  <div className={css.titleHeader}>{item.title}</div>
                  <div className={css.desktopHeader}>
                    <div className={css.desktopImageContainer}>
                      <img
                        className={css.image}
                        src={item.desktopImage as string}
                        alt="desktop_banner"
                      />
                    </div>
                  </div>
                  <div className={css.mobileHeader}>
                    <div className={css.desktopImageContainer}>
                      <img
                        className={css.image}
                        src={item.mobileImage as string}
                        alt="mobile_banner"
                      />
                    </div>
                  </div>
                  <div className={css.actionsHeader}>
                    <DeleteBannerIcon
                      onClick={() => onDelete(item.id)}
                      dataTestId={`delete-banner-icon-${item.id}`}
                    />
                  </div>
                  <div className={css.actionsHeader}>
                    {item.isActive ? (
                      <PauseBannerIcon
                        onClick={() => onPause(item.id, 'pauseBanner')}
                        dataTestId={`pause-banner-icon-${item.id}`}
                      />
                    ) : (
                      <ActivateBannerIcon
                        onClick={() => onPause(item.id, 'activateBanner')}
                        dataTestId={`activate-banner-icon-${item.id}`}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </DndProvider>
    );
  }, [data?.banners, dispatch, isLoading, toast]);

  return (
    <Box>
      <Box padding="24px 112px" backgroundColor="background" data-testid="container">
        <Flex height="51px" flexDirection="column">
          <Text data-testid="title" fontWeight="600" color="darkBlue" fontSize="20px">
            {textContent.banners.banners}
          </Text>
        </Flex>
      </Box>
      <Flex flexDirection="column" padding="24px 112px" width="85.42%">
        {TableContent}
        <Button
          marginBottom="95px"
          _hover={{ backgroundColor: 'lightPurple' }}
          marginTop="24px"
          onClick={() => navigate('/admin/banners/add')}
          alignSelf="end"
          backgroundColor="purple"
          content={textContent.banners.addBanner}
          color="white"
        />
      </Flex>
    </Box>
  );
};

export default BannersView;
