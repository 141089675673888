import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ArrowBackIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  NumberInput,
  Text,
} from '@chakra-ui/react';

import { NumberKeys } from '../../../../constants/index';
import useDetectKeyboardOpen from '../../../../hooks/useDetectKeyboardOpen';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { cleanError, clientCodeVerification, getAuthError } from '../../../../store/modules/auth';
import { hideModal } from '../../../../store/modules/ui';
import { RootState } from '../../../../store/types';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
interface Props {
  isMobile: boolean;
  setVerificationCodePage: Dispatch<SetStateAction<boolean>>;
}

interface Values {
  validationCode: string;
}

const ClientCodeScreen = ({ isMobile, setVerificationCodePage }: Props) => {
  const {
    handleSubmit,
    register,
    setError,
    clearErrors,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      validationCode: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const dispatch = useAppDispatch();
  const userError = useAppSelector(getAuthError);
  const userLoading = useAppSelector((state: RootState) => state.auth.loading);

  useEffect(
    () => () => {
      dispatch(cleanError());
    },
    [dispatch],
  );

  useEffect(() => {
    if (userError) {
      setError('validationCode', {
        type: 'custom',
        message: userError,
      });
    }
  }, [setError, userError]);

  const isKeyboardOpen = useDetectKeyboardOpen();

  const onSubmit = async (values: Values) => {
    const userId = values.validationCode;
    await dispatch(clientCodeVerification({ userId })).then((response) => {
      if (response.type === 'LOGIN_USER/fulfilled') {
        setVerificationCodePage(true);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="close-login-modal-icon"
            onClick={() => dispatch(hideModal())}
            cursor="pointer"
          />
        </Flex>
      )}
      <Flex
        paddingLeft={{ base: '24px', xl: '36px' }}
        paddingTop={{ base: '49px', xl: '5px' }}
        paddingRight={{ base: '24px', xl: '36px' }}
        paddingBottom={{ base: '50px', xl: '24px' }}
        flexDirection="column"
        justifyContent={{ xl: 'space-between' }}
        data-testid="client-code-login-page"
        backgroundColor="background"
        height={isMobile ? '100%' : '485px'}
        borderRadius={{ xl: '6px' }}
      >
        <Flex flexDirection="column" height={{ base: '75%' }}>
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent="space-between"
            alignItems={{ xl: 'flex-start' }}
            marginBottom={{ base: '32px' }}
          >
            <Flex alignItems="center" justifyContent="flex-start" marginBottom="24px">
              {isMobile && (
                <ArrowBackIcon
                  data-testid="close-login-modal-icon-mobile"
                  boxSize="1.5em"
                  viewBox="0 0 24 24"
                  marginRight="12px"
                  marginTop="8px"
                  onClick={() => dispatch(hideModal())}
                />
              )}
              <Text
                color="black"
                fontSize="26px"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="normal"
              >
                {textContent.enterSite}
              </Text>
            </Flex>
            <Text
              color="black"
              fontSize="14px"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="24px"
            >
              {textContent.insertClientCode}
            </Text>
          </Flex>
          <FormControl isInvalid={!isValid}>
            <Flex flexDirection="column" alignItems={{ xl: 'center' }}>
              <Text
                color="black"
                fontSize="14px"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                marginBottom="16px"
                justifyContent="flex-start"
                width={{ xl: '100%' }}
              >
                {textContent.clientCode}
              </Text>
              <NumberInput width="100%">
                <Input
                  height="60px"
                  isDisabled={userLoading}
                  placeholder={textContent.clientCode}
                  backgroundColor="white"
                  type="number"
                  isInvalid={Boolean(errors?.validationCode?.message)}
                  data-testid="client-code-input"
                  {...register('validationCode', { onChange: () => clearErrors() })}
                  onKeyDown={(e) => !NumberKeys.includes(e.key) && e.preventDefault()}
                />
              </NumberInput>
              <FormErrorMessage fontSize="12px" fontWeight={500} width={{ xl: '100%' }}>
                {userError && <WarningIcon marginRight="8px" />}
                {userError && <div data-testid="client-code-error-message">{userError}</div>}
              </FormErrorMessage>
            </Flex>
          </FormControl>
        </Flex>
        {!isKeyboardOpen && (
          <Flex
            flexDirection="column"
            height={{ base: '25%' }}
            justifyContent={{ base: 'flex-end' }}
          >
            <Flex justifyContent="center">
              <Text
                textAlign="center"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                marginBottom="20px"
                color="grey800"
              >
                {textContent.getClientCode}{' '}
                <Link
                  href={`https://wa.me/5493525521001?text=${textContent.clientCodeInfoWhatsappMessage}`}
                  isExternal
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight={500}
                  lineHeight="normal"
                  color="purple"
                  textAlign="center"
                  textDecoration={'underline'}
                  data-testid="get-client-code-link"
                  marginBottom={{ base: '32px' }}
                >
                  {textContent.clickHere}
                </Link>
              </Text>
            </Flex>
            <Button
              color="grey50"
              backgroundColor="purple"
              width="100%"
              height="48px"
              content={textContent.enterSite}
              data-testid="client-code-enter-button"
              type="submit"
              isLoading={userLoading}
              isDisabled={!isDirty || Boolean(errors?.validationCode?.message)}
              _hover={{
                backgroundColor: { base: 'none', xl: 'darkPurple' },
              }}
              _active={{
                backgroundColor: { base: 'darkPurple', xl: 'none' },
              }}
              _disabled={{
                backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
                pointerEvents: 'none',
              }}
            />
          </Flex>
        )}
      </Flex>
    </form>
  );
};

export default ClientCodeScreen;
