import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Text, useOutsideClick } from '@chakra-ui/react';

import HamburgerIcon from '../../../assets/Icons/Hamburger';
import NorpanLogo from '../../../assets/Icons/NorpanIcon';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authData } from '../../../store/modules/auth';
import { isSystemActive } from '../../../store/modules/settings/selectors';
import { closeMenu, isOpenSideMenu, openMenu } from '../../../store/modules/sideMenu';
import textContent from '../../../utils/textContent.json';
import LoginPopover from '../../pages/LoginPopover/LoginPopover';
import SearchBar from '../../shared/SearchBar/SearchBar';
import SideMenu from '../SideMenu/SideMenu';
import CartIconCounter from './CartIconCounter';
import CategoriesDropdown from './CategoriesDropdown';

interface Props {
  isLoggedIn: boolean;
}

const Header = ({ isLoggedIn }: Props) => {
  const { pathname } = useLocation();
  const { isMobile, breakPoint } = useBreakpoint();
  const ref = React.useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLogged } = useAppSelector(authData);
  const isSideMenuOpen = useAppSelector(isOpenSideMenu);

  const isSystemOn = useAppSelector(isSystemActive);

  useOutsideClick({
    ref: ref,
    handler: () => dispatch(closeMenu()),
  });

  const loggedInOptions = (
    <>
      <Text
        data-testid="favorites-label"
        marginRight="25px"
        cursor="pointer"
        onClick={() => navigate('/favorites')}
      >
        {textContent.favorites}
      </Text>
      <Text
        data-testid="reservation-history-label"
        style={{ marginRight: '25px' }}
        cursor="pointer"
        onClick={() => navigate('/reservation-history')}
      >
        {textContent.reservationHistory}
      </Text>
    </>
  );

  const backgroundColor = () => {
    if (isMobile && pathname !== '/') {
      return 'blue200';
    } else if (isMobile && pathname === '/') {
      return 'linear-gradient(0deg, rgba(255,255,255,1) -133%, rgba(51,119,255,1) 100%)';
    } else if (pathname !== '/') {
      return 'linear-gradient(0deg, rgba(255,255,255,1) -133%, rgba(51,119,255,1) 100%)';
    }
    return '';
  };

  const headerContent = () => {
    if (breakPoint !== 'xl') {
      return (
        <>
          <Box cursor="pointer" onClick={() => dispatch(openMenu())}>
            <HamburgerIcon dataTestId="hamburger-icon" />
          </Box>
          {isSideMenuOpen && <SideMenu refElement={ref} />}
        </>
      );
    }
  };

  return (
    <Flex
      width={{ base: '100%' }}
      padding={{ base: '0 24px', xl: '0 115px 0 120px' }}
      flexDirection={{ base: 'column', md: 'row' }}
      data-testid="header-container"
      background={backgroundColor()}
      position={isMobile ? 'fixed' : 'initial'}
      top={isMobile ? '0' : ''}
      zIndex={isMobile ? 100 : 1}
    >
      {breakPoint === 'xl' && (
        <Flex marginRight="67px" alignItems="center" width={134}>
          <Box onClick={() => navigate('/')} cursor="pointer">
            <NorpanLogo dataTestId="norpan-logo" />
          </Box>
        </Flex>
      )}
      <Flex
        margin={{ base: '9px 0 ' }}
        width="100%"
        alignItems={{ base: 'center', xl: 'flex-start' }}
        justifyContent="space-between"
        flexDirection={{ xl: 'column' }}
        gap={{ base: '16px' }}
      >
        <>{headerContent()}</>
        {(!isMobile || (isMobile && pathname !== '/')) && (
          <Box
            width={{ base: '100%', md: '60%', xl: '55%' }}
            justifyContent={{ xl: 'flex-start' }}
            marginBottom={{ xl: '16px' }}
            zIndex="10"
          >
            {isSystemOn && <SearchBar />}
          </Box>
        )}
        <Flex
          width={{ md: '25%', xl: '100%' }}
          justifyContent={{ base: 'flex-end', xl: 'space-between' }}
        >
          {breakPoint === 'xl' && (
            <Flex
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="normal"
              width={{ xl: '55%' }}
              justifyContent="flex-start"
              color="darkBlue"
            >
              <div style={{ marginRight: '25px' }}>
                {isSystemOn && <CategoriesDropdown dataTestId={'categories-dropdown'} />}
              </div>
              <Text
                data-testid="sales-label"
                marginRight="25px"
                cursor="pointer"
                onClick={() => navigate('/deals')}
              >
                {textContent.deals}
              </Text>
              {isLogged && loggedInOptions}
              <Text
                data-testid="contact-label"
                style={{ marginRight: '25px' }}
                onClick={() => navigate('/contact')}
                cursor="pointer"
              >
                {textContent.contactUs}
              </Text>
            </Flex>
          )}
          <Flex alignItems="center">
            {(!isMobile || pathname === '/' || !isLogged) && (
              <LoginPopover isMobile={isMobile} isLoggedIn={isLoggedIn} />
            )}
            {isLogged && <CartIconCounter />}
          </Flex>
        </Flex>
      </Flex>
      {isMobile && pathname === '/' && (
        <Box width={{ base: '100%', xl: '50%' }} paddingBottom={'20px'}>
          <SearchBar />
        </Box>
      )}
    </Flex>
  );
};

export default Header;
