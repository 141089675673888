import React from 'react';
import { To } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import EmptyState from '../../../assets/categories/EmptyState';
import textContent from '../../../utils/textContent.json';
import GoBack from '../GoBack/GoBack';

interface ErrorScreenProps {
  url?: To;
  title: string;
  subTitle?: string;
  arrowTitle?: string;
}

const ErrorScreen = ({ url = -1 as To, title, subTitle, arrowTitle }: ErrorScreenProps) => {
  return (
    <Box height="100vh" backgroundColor="white">
      <GoBack url={url} title={arrowTitle ?? textContent.goBack} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        paddingTop="120px"
        paddingBottom="120px"
      >
        <EmptyState dataTestId="empty-state" />
        <Text
          fontSize="18px"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="29px"
          data-testid="error-message-title"
        >
          {title}
        </Text>
        <Text
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="29px"
          data-testid="error-message-sub-title"
        >
          {subTitle}
        </Text>
      </Flex>
    </Box>
  );
};

export default ErrorScreen;
