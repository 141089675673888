import * as React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Props } from '../Types';

export const ArcorBrand = ({ dataTestId }: Props) => {
  const { breakPoint } = useBreakpoint();

  return (
    <svg
      cursor="pointer"
      data-testid={dataTestId}
      width={breakPoint === 'xl' ? '105' : '51'}
      height={breakPoint === 'xl' ? '72' : '35'}
      viewBox="0 0 105 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M50.6155 71.8472C46.0139 71.7806 41.4605 71.3451 36.954 70.4766C32.3007 69.5798 27.7769 68.2289 23.4419 66.3044C19.2906 64.4613 15.417 62.1483 11.9331 59.2208C8.41972 56.2675 5.50707 52.8095 3.46415 48.6793C1.93813 45.5939 1.02646 42.3321 0.815502 38.873C0.446641 32.854 2.18239 27.4395 5.41825 22.442C8.29635 17.996 12.0627 14.4221 16.3817 11.4083C20.1554 8.77689 24.2154 6.69079 28.5085 5.03153C33.3604 3.15762 38.3666 1.91287 43.4998 1.12703C48.4541 0.36834 53.4393 0.190694 58.4319 0.431256C65.307 0.763107 72.0045 2.07571 78.4577 4.49613C83.5477 6.40582 88.3047 8.94343 92.5349 12.3902C96.8872 15.937 100.373 20.1635 102.471 25.4287C103.739 28.6115 104.395 31.9288 104.288 35.3608C104.116 40.8135 102.304 45.7259 99.2471 50.2065C96.9698 53.5436 94.1633 56.376 91.0212 58.9001C87.5706 61.6721 83.7722 63.8618 79.7641 65.6999C76.3963 67.2445 72.8988 68.4325 69.3163 69.3713C65.9337 70.2583 62.5029 70.9133 59.0289 71.2736C56.2323 71.5672 53.4195 71.6646 50.6155 71.8472Z"
          fill="#ECB739"
        />
        <path
          id="Vector_2"
          d="M90.4249 13.3524C86.2453 11.9473 81.9251 11.6981 77.5863 11.5501C67.7554 11.2133 58.2945 13.0699 49.0619 16.3107C41.2862 19.0396 33.9805 22.701 27.1622 27.3297C26.211 27.9749 25.2882 28.6633 24.3507 29.3294C24.2039 29.4331 24.0497 29.5243 23.8399 29.6601C23.3588 28.102 22.8937 26.6043 22.4323 25.1067C22.0117 23.741 22.2979 24.0778 20.9828 23.968C19.9947 23.8853 19.4358 24.1962 18.9115 25.0931C14.7085 32.2816 10.4388 39.4306 6.19012 46.5919C6.12103 46.7091 6.06552 46.8349 5.95326 47.0582C6.78967 47.0582 7.53973 47.0755 8.28732 47.0422C8.42672 47.036 8.57476 46.8448 8.69073 46.7128C12.1363 42.7922 16.1962 39.602 20.5251 36.7289C20.588 36.6869 20.667 36.6684 20.7978 36.6141C21.1296 37.738 21.4467 38.8359 21.7785 39.9277C22.2547 41.4944 22.6939 43.076 23.2367 44.6193C23.9053 46.5154 25.1118 47.3173 27.1116 47.3284C28.2367 47.3345 29.3605 47.3296 30.5214 47.3296C30.6188 46.7436 30.6916 46.3094 30.7681 45.8517C29.1952 45.6148 28.3847 44.6304 27.9653 43.2216C27.5026 41.6721 27.0141 40.13 26.5441 38.583C26.0753 37.0385 25.6139 35.4915 25.1291 33.8767C26.2567 33.266 27.3645 32.6467 28.4896 32.0607C36.2764 28.0057 44.4517 25.0154 53.0219 23.1069C58.7349 21.835 64.5096 21.1047 70.3658 20.9875C76.5217 20.8641 82.5986 21.4711 88.5794 22.9219C91.7647 23.6941 94.7525 24.9586 97.2729 27.1175C101.135 30.4249 102.373 34.6625 101.411 39.5564C100.364 44.8833 97.7072 49.3935 94.079 53.3683C89.7575 58.1018 84.5367 61.5672 78.7435 64.2183C72.6382 67.0112 66.2269 68.7038 59.5751 69.5242C50.24 70.6764 41.0321 70.0781 31.9882 67.443C25.9014 65.669 20.2303 63.0192 15.1995 59.1085C11.4751 56.2131 8.32433 52.8008 6.17161 48.5719C2.86296 42.073 2.42008 35.3792 5.02555 28.5313C7.01173 23.3142 10.3858 19.0988 14.5641 15.4879C19.7985 10.9641 25.8138 7.82939 32.3435 5.6372C38.9386 3.4228 45.7224 2.30511 52.6728 2.11883C59.6713 1.93132 66.5402 2.75169 73.2587 4.71443C79.406 6.51062 85.1165 9.21232 90.2029 13.1514C90.2806 13.2143 90.3509 13.2858 90.4249 13.3524Z"
          fill="#255398"
        />
        <g id="Group_2">
          <path
            id="Vector_3"
            d="M87.5325 40.2778C88.6539 40.6195 89.2078 41.3647 89.531 42.317C89.8394 43.2275 90.0898 44.1589 90.4168 45.0631C90.8868 46.3634 91.7158 47.3713 93.1777 47.4453C94.6852 47.5206 96.2026 47.4046 97.715 47.3528C97.8433 47.3479 98.0493 47.2171 98.0765 47.1098C98.1752 46.7076 98.2147 46.2918 98.2887 45.7935C97.0735 46.0229 96.3235 45.4258 95.6943 44.6536C94.563 43.262 93.9191 41.6287 93.4219 39.8904C93.6168 39.8164 93.7859 39.7498 93.9561 39.6881C95.265 39.2033 96.4431 38.5285 97.3462 37.4305C99.1991 35.1779 98.6575 32.3269 96.02 31.1525C94.943 30.6726 93.702 30.3926 92.5238 30.3445C89.6087 30.2273 86.6874 30.3038 83.7674 30.2828C83.3529 30.2803 83.1913 30.4247 83.1271 30.8231C82.984 31.7114 82.968 31.7077 83.7995 32.0136C83.9512 32.0691 84.098 32.1357 84.309 32.2233C83.4244 37.2467 82.5424 42.2615 81.6455 47.3553C83.1518 47.3553 84.605 47.3639 86.057 47.338C86.1631 47.3355 86.3297 47.1048 86.3593 46.958C86.516 46.1747 86.6356 45.3839 86.7725 44.5968C87.0193 43.1843 87.2697 41.7742 87.5325 40.2778Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M37.6007 40.3671C38.4458 40.4325 39.2748 41.2344 39.582 42.2373C39.8632 43.1576 40.1482 44.0767 40.4628 44.9846C40.9957 46.5267 42.0135 47.4791 43.7172 47.505C44.7189 47.521 45.7268 47.5482 46.7223 47.4581C48.463 47.3002 48.0781 47.6136 48.3508 46.0024C48.3557 45.9691 48.3236 45.9309 48.3076 45.8938C47.6636 45.7927 46.9617 45.7002 46.5015 45.3177C44.8089 43.9114 44.1107 41.9215 43.5 39.8736C43.7011 39.8033 43.8356 39.7589 43.9688 39.7096C45.2691 39.2223 46.483 38.5931 47.3749 37.4865C49.0552 35.4017 49.0897 32.2768 45.8649 31.0037C44.5647 30.4905 43.2188 30.2931 41.8346 30.2907C39.4302 30.2857 37.0271 30.2894 34.6227 30.2894C33.2928 30.2894 33.2928 30.2894 33.0794 31.5712C33.0732 31.6057 33.1041 31.6464 33.1263 31.7106C33.5383 31.8784 33.9689 32.0535 34.392 32.225C33.5001 37.2867 32.6168 42.2941 31.7236 47.3594C33.225 47.3594 34.6202 47.373 36.0143 47.3384C36.1598 47.3347 36.3905 47.0633 36.4238 46.8869C36.8346 44.7169 37.2146 42.5396 37.6007 40.3671Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M82.3892 37.2447C82.3682 36.8944 82.3744 36.5008 82.3176 36.1184C81.9809 33.862 80.8989 32.1127 78.7882 31.1196C76.7971 30.1821 74.6789 29.9785 72.536 30.3289C67.182 31.2035 63.354 35.9568 63.6673 41.3626C63.8265 44.1223 65.1724 46.1221 67.7988 47.1707C69.6444 47.9071 71.5676 47.9836 73.4921 47.7036C78.4835 46.9745 82.3904 42.3656 82.3892 37.2447Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M63.2832 44.4876C63.1204 44.5086 63.0377 44.5049 62.9674 44.5308C61.4093 45.0933 59.7994 45.3956 58.1451 45.3179C56.2675 45.229 54.7994 44.4185 53.9581 42.6668C53.3277 41.3517 53.3215 39.9651 53.5793 38.5723C53.9198 36.7329 54.5206 34.9935 55.9072 33.6537C57.6997 31.9204 60.3989 31.5935 62.8922 32.7926C63.5855 33.1257 64.2418 33.5341 64.9622 33.9325C65.0325 33.6315 65.109 33.3613 65.1584 33.0862C65.3792 31.8464 65.378 31.8452 64.264 31.2506C61.7769 29.9219 59.169 29.8479 56.5019 30.5351C51.8954 31.7231 48.171 36.1 48.4831 40.8854C48.6312 43.1516 49.5897 45.0156 51.6302 46.1308C55.1695 48.0664 58.8754 47.8444 62.6121 46.8044C62.7429 46.7686 62.9144 46.6243 62.939 46.5034C63.0747 45.852 63.1685 45.192 63.2832 44.4876Z"
            fill="white"
          />
        </g>
        <path
          id="Vector_7"
          d="M87.9141 38.3892C88.2792 36.3216 88.6308 34.3342 89.0132 32.1729C90.1939 32.2975 91.3782 32.3369 92.523 32.5701C93.5901 32.7872 94.1403 33.8 93.9688 34.8807C93.6394 36.9483 92.2565 38.2325 90.147 38.3818C89.4352 38.4312 88.7172 38.3892 87.9141 38.3892Z"
          fill="#255398"
        />
        <path
          id="Vector_8"
          d="M38.0049 38.3815C38.3343 36.457 38.6439 34.5818 38.9906 32.7141C39.0214 32.5475 39.2842 32.3033 39.4396 32.302C40.297 32.2971 41.1643 32.2897 42.0105 32.4106C43.6501 32.645 44.331 33.7022 43.9634 35.3023C43.5625 37.0491 42.2363 38.2026 40.3463 38.3704C39.5975 38.437 38.8364 38.3815 38.0049 38.3815Z"
          fill="#255398"
        />
        <path
          id="Vector_9"
          d="M78.1463 37.165C77.9957 39.9839 77.1914 42.5313 74.9634 44.4312C73.5188 45.6623 71.2983 45.8647 69.7772 44.9777C68.7742 44.3917 68.2734 43.4331 68.1167 42.3636C67.6158 38.9513 68.5731 35.9708 71.1145 33.6121C72.4098 32.4092 74.0345 32.1329 75.7135 32.7029C77.4283 33.2851 77.9723 34.7211 78.1413 36.3643C78.1697 36.6283 78.1463 36.8985 78.1463 37.165Z"
          fill="#255398"
        />
        <path
          id="Vector_10"
          d="M24.3093 31.0925C25.6737 35.657 26.3559 37.9392 26.4201 38.2661C26.4867 38.6079 26.9777 40.1141 27.9597 43.1255C28.1201 43.6189 28.4186 44.4936 29.2402 45.1499C29.7892 45.5878 30.3715 45.7692 30.7687 45.8531C30.686 46.3453 30.6046 46.8388 30.522 47.331C30.068 47.3322 29.4647 47.3372 28.7517 47.3495C27.2553 47.3754 26.9024 47.4075 26.2585 47.2792C25.3665 47.1015 24.8595 46.8054 24.6313 46.6586C24.482 46.5624 24.202 46.3786 23.9269 46.0838C23.431 45.5521 23.3767 45.1166 22.6883 42.9565C22.5353 42.4778 22.4946 42.3606 22.3725 41.9634C22.1146 41.1208 21.9493 40.4929 21.9025 40.3202C21.6088 39.2222 21.1635 37.785 20.2802 34.8539C19.9126 33.6338 19.3883 31.8894 18.7505 29.7428C15.3049 35.5028 11.8606 41.2614 8.41497 47.0213C7.5946 47.0337 6.77422 47.046 5.95508 47.0583C8.17688 43.255 10.4098 39.4591 12.6563 35.6693C14.9743 31.7574 17.3047 27.8541 19.6486 23.957C20.0421 23.9644 20.4394 23.9706 20.8415 23.9731C21.2474 23.9755 21.6483 23.9755 22.0443 23.9718C23.0461 26.9449 23.7998 29.3913 24.3093 31.0925Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M12.7217 39.881C14.714 36.5317 16.7051 33.1823 18.7493 29.7441C19.0676 30.8211 19.376 31.7871 19.6215 32.769C19.6634 32.9344 19.4796 33.2107 19.3266 33.3612C17.6193 35.0476 15.8909 36.713 14.186 38.4007C13.6963 38.8855 13.2608 39.4234 12.7994 39.9366C12.7735 39.918 12.7476 39.8995 12.7217 39.881Z"
          fill="#255398"
        />
      </g>
    </svg>
  );
};
