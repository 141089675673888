import React, { useEffect, useState } from 'react';
import { Input, NumberInput, NumberInputStepper } from '@chakra-ui/react';

import { NumberKeys } from '../../../constants';
import Button from '../Button/Button';
import { ProductType } from './ReservationProductCard';

interface CartButtonCounterProps<T> {
  product: T & ProductType;
  breakPoint: string;
  incrementDisabled?: boolean;
  isCancelled?: boolean;
  onClickToReplaceQuantity: (quantity: number) => void;
  onClickToIncrementQuantity: (product: T & ProductType) => void;
  onClickToDecrementQuantity: (product: T & ProductType) => void;
}

const CartButtonCounter = <T,>({
  product,
  breakPoint,
  incrementDisabled,
  isCancelled = false,
  onClickToReplaceQuantity,
  onClickToIncrementQuantity,
  onClickToDecrementQuantity,
}: CartButtonCounterProps<T>) => {
  const [quantity, setQuantity] = useState(product.quantity);

  const onSubmit = (quantity: number) => onClickToReplaceQuantity(quantity);

  useEffect(() => {
    setQuantity(product.quantity as number);
  }, [product.quantity]);

  return (
    <form>
      <NumberInput data-testid="product-counter">
        <Input
          value={quantity}
          type="number"
          textAlign="center"
          color="grey900"
          _disabled={{
            color: 'grey700',
            'pointer-events': 'none',
            cursor: 'not-allowed',
          }}
          fontSize="16px"
          padding="0"
          disabled={isCancelled}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => onSubmit(+e.target.value)}
          onChange={(e: React.FocusEvent<HTMLInputElement>) => setQuantity(+e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit(quantity);
            }
            !NumberKeys.includes(e.key) && e.preventDefault();
          }}
          data-testid="product-counter-input"
          onWheel={(event) => event.currentTarget.blur()}
        />
        {!isCancelled && (
          <NumberInputStepper height="80px" border="none" data-testid="product-counter-buttons">
            <Button
              color="darkBlue"
              backgroundColor="transparent"
              border="none"
              fontSize="14px"
              fontWeight="500"
              padding="0"
              position="relative"
              right={breakPoint === 'xs' ? '10px' : '16px'}
              content="+"
              _hover={{ backgroundColor: 'none' }}
              _active={{ backgroundColor: 'none' }}
              isDisabled={incrementDisabled}
              onClick={() => {
                setQuantity(quantity + 1);
                onClickToIncrementQuantity(product);
              }}
              data-testid="product-button-increment"
            />
            <Button
              color={quantity <= 1 ? 'grey50' : 'darkBlue'}
              backgroundColor="transparent"
              border="none"
              fontSize="14px"
              fontWeight="500"
              padding="0"
              position="relative"
              right="93px"
              bottom="40px"
              content="-"
              _hover={{ backgroundColor: 'none' }}
              _active={{ backgroundColor: 'none' }}
              onClick={() => {
                if (quantity > 1) {
                  setQuantity(quantity - 1);
                  onClickToDecrementQuantity(product);
                }
              }}
              data-testid="product-button-decrement"
            />
          </NumberInputStepper>
        )}
      </NumberInput>
    </form>
  );
};

export default CartButtonCounter;
