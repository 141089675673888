import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';

import ReservationFailIcon from '../../../assets/Icons/FailReservationIcon';
import { useAppDispatch } from '../../../store/hooks';
import { hideModal } from '../../../store/modules/ui/slice';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';

interface Props {
  isMobile: boolean;
}

const VoucherDifferenceModal = ({ isMobile }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Box width="100%">
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="fail-reservation-close-icon"
            onClick={() => dispatch(hideModal())}
            cursor="pointer"
          />
        </Flex>
      )}
      <Flex
        padding={{ base: '0 24px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="fail-reservation-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ md: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="85%"
        >
          <ReservationFailIcon dataTestId="fail-reservation-icon" />
          <Text
            marginBottom="24px"
            data-testid="fail-reservation-title"
            fontSize="20px"
            textAlign="center"
            fontWeight="500"
            color="grey900"
          >
            {textContent.cart.voucherDifferenceTitle}
          </Text>
          <Text
            marginBottom={{ base: '8px' }}
            data-testid="fail-reservation-info"
            fontSize="18px"
            textAlign="center"
            fontWeight="500"
            color="grey900"
          >
            {textContent.cart.voucherDifferenceSubtitle}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'center' }} padding="30px 0">
          <Button
            width={{ base: '90%' }}
            content={textContent.cart.voucherDifferenceButton}
            data-testid="retry-reservation"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            onClick={() => {
              dispatch(hideModal());
            }}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default VoucherDifferenceModal;
