export const ItemTypes = {
  ITEM: 'item',
};

export interface ItemProps<T> {
  item: T;
  index: number;
  setIsDragging: (value: boolean) => void;
  onRender: (item: T) => React.ReactNode;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
}

export interface DragItem {
  index: number;
  type: string;
}
