import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import { getCategories, getCategoryById } from './actions';
import { InitialState } from './types';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    data: [],
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {
    clearCategoryData: () => {
      return {
        data: [],
        loading: false,
        error: undefined,
      } as InitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string'
            ? action.payload
            : textContent.category.foundProblemCategories;
        state.loading = false;
      })
      .addCase(getCategoryById.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.data = [action.payload];
        state.loading = false;
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string'
            ? action.payload
            : textContent.category.foundProblemCategoryById;
        state.loading = false;
      });
  },
});

export const categoryReducer = categoriesSlice.reducer;
export const { clearCategoryData } = categoriesSlice.actions;
