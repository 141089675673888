import React from 'react';

import ImagesIcon from '../../../../assets/Icons/ImagesIcon';
import textContent from '../../../../utils/textContent.json';
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal';

const ConfirmSubmitBanner = () => (
  <ConfirmModal
    title={textContent.banners.addBanner}
    subtitle={textContent.banners.modal.addNewBanner}
  >
    <ImagesIcon dataTestId="images-icon" />
  </ConfirmModal>
);

export default ConfirmSubmitBanner;
