import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text, useToast } from '@chakra-ui/react';

import PencilIcon from '../../../../assets/Icons/PencilIcon';
import { updateImageToProduct } from '../../../../services/product';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { AddImageAndDescriptionProps } from '../../../../store/modules/products/types';
import { getModalState } from '../../../../store/modules/ui/selectors';
import { hideModal } from '../../../../store/modules/ui/slice';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';

const ConfirmEditProduct = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { data } = useAppSelector(getModalState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { productId, isActive, data: dataToEdit, isNew } = data as AddImageAndDescriptionProps;

  const onSave = async () => {
    try {
      setLoading(true);
      await updateImageToProduct({ productId, isActive, data: dataToEdit, isNew });
      toast({
        title: textContent.product.productEdition,
        description: textContent.product.productEditionSuccess,
        status: 'success',
        variant: 'subtle',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: textContent.product.productEdition,
        description: textContent.product.productEditionError,
        status: 'error',
        variant: 'subtle',
        duration: 3000,
      });
    } finally {
      setLoading(false);
      navigate('/admin/products');
      dispatch(hideModal());
    }
  };

  return (
    <div data-testid="edit-product-modal-container">
      <Flex justifyContent="flex-end" p="8px 12px">
        <CloseIcon
          color={loading ? 'grey' : ''}
          onClick={() => {
            if (!loading) dispatch(hideModal());
          }}
          cursor={loading ? 'default' : 'pointer'}
          width="12px"
          height="12px"
          data-testid="close-product-modal-icon"
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        height="485px"
        padding="0 20.5px 24px"
      >
        <PencilIcon dataTestId="pencil-icon" />
        <Flex flexDirection="column">
          <Text
            fontSize="20px"
            fontWeight="500"
            paddingBottom="24px"
            lineHeight="21.1px"
            align="center"
            data-testid="edit-product-modal-title"
          >
            {textContent.product.modalTitleToEditProduct}
          </Text>
          <Text
            fontSize="18px"
            fontWeight="500"
            lineHeight="21.1px"
            align="center"
            data-testid="edit-product-modal-description"
          >
            {textContent.product.modalSubTitleToEditProduct}
          </Text>
        </Flex>
        <Text
          fontSize="18px"
          fontWeight="500"
          lineHeight="21.1px"
          align="center"
          data-testid="edit-product-modal-sub-title"
        >
          {textContent.continueMark}
        </Text>
        <Flex
          justifySelf="flex-end"
          justify="space-evenly"
          marginTop="40px"
          height="40px"
          width="388px"
        >
          <Button
            variant="outline"
            borderColor="purple"
            data-testid="cancel-button"
            width="155px"
            height="40px"
            isDisabled={loading}
            onClick={() => dispatch(hideModal())}
            color="purple"
            _hover={{ bg: 'greyOnHover' }}
          >
            {textContent.cancel}
          </Button>
          <Button
            data-testid="confirm-button"
            width="155px"
            height="40px"
            color="white"
            content={textContent.confirm}
            isLoading={loading}
            onClick={onSave}
            _disabled={{
              bg: 'disabledPurple',
              cursor: 'default',
              _hover: { bg: 'disabledPurple' },
            }}
            _hover={{ bg: 'darkPurple' }}
            backgroundColor="purple"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default ConfirmEditProduct;
