export enum AdminRoutes {
  HOME = '/',
  PRODUCTS = '/products',
  EDIT_PRODUCT = '/products/:productId',
  VOUCHERS = '/reservations',
  BANNERS = '/banners',
  BANNERS_ADD = '/banners/add',
  EDIT_BRANCH_EMAIL = '/branch',
}

export enum ClientRoutes {
  HOME = '/',
  PRODUCT = '/product/:id',
  BRAND = '/brand/:name',
  FAVORITES = '/favorites',
  RESERVATION_HISTORY = '/reservation-history',
  DEALS = '/deals',
  DEALS_PENDING_TO_RECEIVE = '/deals/pendingToReceive',
  CATEGORIES = '/categories',
  CATEGORY_DEALS = '/deals/:id',
  CATEGORY_DEALS_BY_BRAND = '/deals/:id/brand/:name',
  CATEGORY_VIEW = '/category/:id',
  CATEGORY_DEALS_PENDING_TO_RECEIVE = '/deals/pendingToReceive/:id',
  CATEGORY_DEALS_PENDING_TO_RECEIVE_BY_BRANDS = '/deals/pendingToReceive/:id/brand/:name',
  SUBCATEGORY_VIEW = '/category/:id/:subCategoryId',
  SUBCATEGORY_BRAND = '/category/:id/:subCategoryId/brand/:name',
  PENDING_TO_RECEIVE = '/pendingToReceive',
  CATEGORIES_PENDING_TO_RECEIVE = '/pendingToReceive/:id',
  CATEGORIES_PENDING_TO_RECEIVE_BY_BRANDS = '/pendingToReceive/:id/brand/:name',
  REPEAT_RESERVATION = '/reservation-history/:voucherId',
  CONTACT = '/contact',
  SEARCH_RESULT = '/search-result',
  CART = '/cart',
  TERMS = '/terms',
}
