import useSWR from 'swr';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { getProductsPendingToReceiveByCategoryAndBrand } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { structuredAndOrderedCategory } from '../../../store/modules/categories';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { RootState } from '../../../store/types';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const CategoriesPendingToReceiveByBrands = () => {
  const navigate = useNavigate();
  const { id: categoryCode, name: brand } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const categoryItem = useAppSelector((state: RootState) =>
    structuredAndOrderedCategory(state, categoryCode as string),
  );

  const { data, error, isLoading } = useSWR(
    { currentPage, categoryCode, brand, key: SWRKey.productsPendingToReceive },
    getProductsPendingToReceiveByCategoryAndBrand,
  );

  if (isLoading || loadingFavorites) {
    return <ProductCardSkeleton array={12} onClick={() => navigate(-1)} />;
  }

  if (error || data?.products.length === 0) {
    return <ErrorScreen title={textContent.product.productError} />;
  }

  return (
    <Flex justifyContent="flex-start" direction="column" backgroundColor="grey50" height="100%">
      <GoBack title={transformString(categoryItem?.category)} subTitle={transformString(brand)} />
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '16px', md: '20px' }}
          fontWeight={{ base: '500', md: '600' }}
          margin={{ base: '24px', xl: '24px 0' }}
        >
          {textContent.onTrip}
        </Text>
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
        >
          {data?.products.map((product, index) => (
            <Flex justifyContent={'center'} key={index} marginBottom="20px">
              <ProductCard
                urlKey={`${URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE}/${categoryCode}/${brand}`}
                product={product}
              />
            </Flex>
          ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </Flex>
  );
};

export default CategoriesPendingToReceiveByBrands;
