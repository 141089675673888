import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  isAllowed?: boolean;
  redirectTo: string;
  children: React.ReactNode;
}

const ProtectedRoute = ({ isAllowed, redirectTo, children }: Props): JSX.Element => {
  if (!isAllowed) {
    return <Navigate to={redirectTo} />;
  }
  return <>{children ?? <Outlet />}</>;
};

export default ProtectedRoute;
