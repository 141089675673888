import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import { getSettings, updateSettings } from './actions';
import { InitialState } from './types';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    data: undefined,
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      });
    builder
      .addCase(updateSettings.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(updateSettings.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      });
  },
});

export const settingsReducer = settingsSlice.reducer;
