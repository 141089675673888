import useSWR from 'swr';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import AdminHome from '../components/pages/Admin/AdminHome/AdminHome';
import AddBanner from '../components/pages/Admin/Banners/AddBanner';
import BannersView from '../components/pages/Admin/Banners/BannersView';
import EditBranchEmail from '../components/pages/Admin/EditBrachEmail/EditBranchEmail';
import EditProduct from '../components/pages/Admin/Products/EditProduct';
import ProductsView from '../components/pages/Admin/Products/ProductsView';
import ReservationsTable from '../components/pages/Admin/Reservations/Reservations';
import Cart from '../components/pages/Cart/Cart';
import Categories from '../components/pages/Categories/MobileCategories';
import ContactScreen from '../components/pages/Contact/Contact';
import Favorites from '../components/pages/Favorite/Favorites';
import Home from '../components/pages/Home/Home';
import ProductView from '../components/pages/ProductView/ProductView';
import RepeatReservation from '../components/pages/RepeatReservation/RepeatReservation';
import ReservationHistory from '../components/pages/ReservationHistory/ReservationHistory';
import ReservationHistoryMobile from '../components/pages/ReservationHistory/ReservationHistoryMobile';
import SearchBarResult from '../components/pages/SearchBarResult/SearchBarResult';
import SearchBarResultMobile from '../components/pages/SearchBarResult/SearchBarResultMobile';
import TermsScreen from '../components/pages/Terms/Terms';
import Brand from '../components/shared/Brand/Brand';
import BrandMobile from '../components/shared/Brand/BrandMobile';
import CategoriesPendingToReceive from '../components/shared/CategoriesPendingToReceive/CategoriesPendingToReceive';
import CategoriesPendingToReceiveMobile from '../components/shared/CategoriesPendingToReceive/CategoriesPendingToReceiveMobile';
import CategoriesPendingToReceiveByBrands from '../components/shared/CategoriesPendingToReceiveByBrands/CategoriesPendingToReceiveByBrands';
import CategoriesPendingToReceiveByBrandsMobile from '../components/shared/CategoriesPendingToReceiveByBrands/CategoriesPendingToReceiveByBrandsMobile';
import CategoryDeals from '../components/shared/CategoryDeals/CategoryDeals';
import CategoryDealsMobile from '../components/shared/CategoryDeals/CategoryDealsMobile';
import CategoryDealsByBrands from '../components/shared/CategoryDealsByBrands/CategoryDealsByBrands';
import CategoryDealsByBrandsMobile from '../components/shared/CategoryDealsByBrands/CategoryDealsByBrandsMobile';
import CategoryDealsPendingToReceive from '../components/shared/CategoryDealsPendingToReceive/CategoryDealsPendingToReceive';
import CategoryDealsPendingToReceiveMobile from '../components/shared/CategoryDealsPendingToReceive/CategoryDealsPendingToReceiveMobile';
import CategoryDealsPendingToReceiveByBrands from '../components/shared/CategoryDealsPendingToReceiveByBrands/CategoryDealsPendingToReceiveByBrands';
import CategoryDealsPendingToReceiveByBrandsMobile from '../components/shared/CategoryDealsPendingToReceiveByBrands/CategoryDealsPendingToReceiveByBrandsMobile';
import CategoryView from '../components/shared/CategoryView/CategoryView';
import DealsPendingToReceiveView from '../components/shared/DealsPendingToReceive/DealsPendingToReceiveView';
import DealsPendingToReceiveViewMobile from '../components/shared/DealsPendingToReceive/DealsPendingToReceiveViewMobile';
import DealsView from '../components/shared/DealsView/DealsView';
import DealsViewMobile from '../components/shared/DealsView/DealsViewMobile';
import Layout from '../components/shared/Layout/Layout';
import PendingToReceiveView from '../components/shared/PendingToReceiveView/PendingToReceiveView';
import PendingToReceiveViewMobile from '../components/shared/PendingToReceiveView/PendingToReceiveViewMobile';
import SubCategoryBrands from '../components/shared/SubCategoryBrands/SubCategoryBrands';
import SubCategoryBrandsMobile from '../components/shared/SubCategoryBrands/SubCategoryBrandsMobile';
import SubCategoryView from '../components/shared/SubCategoryView/SubCategoryView';
import SubCategoryViewMobile from '../components/shared/SubCategoryView/SubCategoryViewMobile';
import { AdminRoutes, ClientRoutes } from '../enum/routesEnum';
import { SWRKey } from '../enum/SWRKey';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { userRole } from '../store/modules/auth/selectors';
import { getFavoriteList } from '../store/modules/favorites';
import { getSettings, isSystemActive } from '../store/modules/settings';
import ProtectedRoute from './ProtectedRoute';

const AppRoutes = (): JSX.Element => {
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoint();
  const isAdmin = useAppSelector(userRole);
  const dispatch = useAppDispatch();
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  useSWR(SWRKey.settings, () => dispatch(getSettings()), {
    refreshInterval: 7200000,
  });
  const systemActive = useAppSelector(isSystemActive);
  const navigate = useNavigate();

  const marginInMobile = pathname === '/' ? '107px' : '60px';
  const accessToken = localStorage.getItem('access_token');

  useEffect(() => {
    if (isAdmin) {
      const validRoute = Object.values(AdminRoutes).some((route) =>
        pathname.startsWith(`/admin${route}`),
      );
      if (pathname === '/' || !validRoute) navigate('/admin');
    }
  }, [isAdmin, navigate, pathname]);

  useEffect(() => {
    if (isLogged && !isAdmin) {
      dispatch(getFavoriteList());
    }
  }, [dispatch, isAdmin, isLogged]);

  const defaultRoute = isAdmin ? `/admin${AdminRoutes.HOME}` : ClientRoutes.HOME;

  return (
    <Layout>
      <Box marginTop={isMobile ? marginInMobile : ''}>
        <Routes>
          <Route path={ClientRoutes.HOME} element={<Home />} />
          {isAdmin && (
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute isAllowed={isAdmin} redirectTo="/admin">
                  <Routes>
                    <Route path={AdminRoutes.HOME} element={<AdminHome />} />
                    <Route path={AdminRoutes.PRODUCTS} element={<ProductsView />} />
                    <Route path={AdminRoutes.EDIT_PRODUCT} element={<EditProduct />} />
                    <Route path={AdminRoutes.VOUCHERS} element={<ReservationsTable />} />
                    <Route path={AdminRoutes.BANNERS} element={<BannersView />} />
                    <Route path={AdminRoutes.BANNERS_ADD} element={<AddBanner />} />
                    <Route path={AdminRoutes.EDIT_BRANCH_EMAIL} element={<EditBranchEmail />} />
                  </Routes>
                </ProtectedRoute>
              }
            />
          )}
          {systemActive && !isAdmin && (
            <>
              {typeof accessToken === 'string' && (
                <>
                  <Route path={ClientRoutes.FAVORITES} element={<Favorites />} />
                  <Route
                    path={ClientRoutes.RESERVATION_HISTORY}
                    element={isMobile ? <ReservationHistoryMobile /> : <ReservationHistory />}
                  />
                  <Route path={ClientRoutes.REPEAT_RESERVATION} element={<RepeatReservation />} />
                  <Route path={ClientRoutes.CART} element={<Cart />} />
                </>
              )}
              <Route path={ClientRoutes.PRODUCT} element={<ProductView />} />
              <Route path={ClientRoutes.BRAND} element={isMobile ? <BrandMobile /> : <Brand />} />
              <Route
                path={ClientRoutes.DEALS}
                element={isMobile ? <DealsViewMobile /> : <DealsView />}
              />
              <Route
                path={ClientRoutes.DEALS_PENDING_TO_RECEIVE}
                element={
                  isMobile ? <DealsPendingToReceiveViewMobile /> : <DealsPendingToReceiveView />
                }
              />
              <Route
                path={ClientRoutes.CATEGORY_DEALS_PENDING_TO_RECEIVE}
                element={
                  isMobile ? (
                    <CategoryDealsPendingToReceiveMobile />
                  ) : (
                    <CategoryDealsPendingToReceive />
                  )
                }
              />
              <Route
                path={ClientRoutes.CATEGORY_DEALS_PENDING_TO_RECEIVE_BY_BRANDS}
                element={
                  isMobile ? (
                    <CategoryDealsPendingToReceiveByBrandsMobile />
                  ) : (
                    <CategoryDealsPendingToReceiveByBrands />
                  )
                }
              />
              <Route path={ClientRoutes.CATEGORIES} element={<Categories />} />
              <Route
                path={ClientRoutes.CATEGORY_DEALS}
                element={isMobile ? <CategoryDealsMobile /> : <CategoryDeals />}
              />
              <Route
                path={ClientRoutes.CATEGORY_DEALS_BY_BRAND}
                element={isMobile ? <CategoryDealsByBrandsMobile /> : <CategoryDealsByBrands />}
              />
              <Route path={ClientRoutes.CATEGORY_VIEW} element={<CategoryView />} />
              <Route
                path={ClientRoutes.SUBCATEGORY_VIEW}
                element={isMobile ? <SubCategoryViewMobile /> : <SubCategoryView />}
              />
              <Route
                path={ClientRoutes.SUBCATEGORY_BRAND}
                element={isMobile ? <SubCategoryBrandsMobile /> : <SubCategoryBrands />}
              />
              <Route
                path={ClientRoutes.PENDING_TO_RECEIVE}
                element={isMobile ? <PendingToReceiveViewMobile /> : <PendingToReceiveView />}
              />
              <Route
                path={ClientRoutes.CATEGORIES_PENDING_TO_RECEIVE}
                element={
                  isMobile ? <CategoriesPendingToReceiveMobile /> : <CategoriesPendingToReceive />
                }
              />
              <Route
                path={ClientRoutes.CATEGORIES_PENDING_TO_RECEIVE_BY_BRANDS}
                element={
                  isMobile ? (
                    <CategoriesPendingToReceiveByBrandsMobile />
                  ) : (
                    <CategoriesPendingToReceiveByBrands />
                  )
                }
              />
              <Route path={ClientRoutes.CONTACT} element={<ContactScreen />} />
              <Route path={ClientRoutes.TERMS} element={<TermsScreen />} />
              <Route
                path={ClientRoutes.SEARCH_RESULT}
                element={isMobile ? <SearchBarResultMobile /> : <SearchBarResult />}
              />
            </>
          )}
          <Route path="*" element={<Navigate to={defaultRoute} replace={true} />} />
        </Routes>
      </Box>
    </Layout>
  );
};
export default AppRoutes;
