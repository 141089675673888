import { Quotation } from '../store/modules/quotations/types';

export const getCurrencyConversion = (
  quotesData: Quotation[],
  quantity: number,
  price = 0,
  currency: string,
) => {
  const quote = quotesData.find((quote) => quote.Moneda === currency);

  if (quote) {
    const convertedPrice = (quantity * price) / quote.Cotizacion;
    const formattedPrice = convertedPrice.toLocaleString('es-AR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedPrice;
  }
  return 0;
};
