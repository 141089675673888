import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import PencilIcon from '../../../../assets/Icons/PencilIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getModalState } from '../../../../store/modules/ui/selectors';
import { hideModal } from '../../../../store/modules/ui/slice';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';

const ConfirmEditGeneralSalesEmail = () => {
  const dispatch = useAppDispatch();
  const { callback: onSubmit } = useAppSelector(getModalState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      if (onSubmit) {
        await onSubmit();
      }
      dispatch(hideModal());
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end" p="8px 12px">
        <CloseIcon
          width="12px"
          height="12px"
          data-testid="close-logout-modal-icon"
          onClick={() => dispatch(hideModal())}
          cursor="pointer"
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        height="485px"
        padding="0 20.5px 24px"
      >
        <PencilIcon dataTestId="pencil-icon" />
        <Flex flexDirection="column">
          <Text
            fontSize="20px"
            fontWeight="500"
            paddingBottom="24px"
            lineHeight="21.1px"
            align="center"
            data-testid="title"
          >
            {textContent.emailEdit.editInfo}
          </Text>
          <Text
            fontSize="18px"
            fontWeight="500"
            lineHeight="21.1px"
            align="center"
            data-testid="confirmation-message"
          >
            {textContent.emailEdit.modal.areYouSure}
          </Text>
        </Flex>
        <Flex
          justifySelf="flex-end"
          justify="space-evenly"
          marginTop="40px"
          height="40px"
          width="388px"
        >
          <Button
            variant="outline"
            borderColor="purple"
            data-testid="cancel-button"
            width="155px"
            height="40px"
            onClick={() => dispatch(hideModal())}
            color="purple"
            _hover={{ bg: 'greyOnHover' }}
          >
            {textContent.cancel}
          </Button>
          <Button
            data-testid="save-button"
            width="155px"
            height="40px"
            isLoading={loading}
            color="white"
            onClick={onConfirm}
            _disabled={{
              bg: 'disabledPurple',
              cursor: 'default',
              _hover: { bg: 'disabledPurple' },
            }}
            _hover={{ bg: 'darkPurple' }}
            backgroundColor="purple"
          >
            {textContent.confirm}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ConfirmEditGeneralSalesEmail;
