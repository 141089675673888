import React from 'react';

import { theme } from '../../theme';
import { CategoriesIconPros } from './DrinksIcon';

const PetsIcon = ({ dataTestId, changeBg }: CategoriesIconPros) => (
  <svg
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.7519 2.19801C19.868 0.272101 17.5791 -0.408868 14.9422 0.245102C13.8322 0.52109 12.8693 1.09406 12.0083 1.87703C11.9603 1.84103 11.9303 1.81703 11.9003 1.79303C10.7274 0.758079 9.36843 0.152107 7.8055 0.0231124C6.94754 -0.0488844 6.10458 0.0471113 5.28561 0.314099C5.26461 0.320099 5.24361 0.335098 5.22261 0.347098C5.30961 0.554088 5.4056 0.761079 5.5046 0.96207C5.5196 0.95907 5.5376 0.95607 5.5526 0.95307C7.84149 0.344098 9.8424 0.881074 11.5793 2.462C11.9603 2.80999 12.0383 2.80999 12.4133 2.465C13.9222 1.07306 15.6891 0.455093 17.729 0.803077C19.7959 1.15406 21.2959 2.34201 22.3278 4.13293C23.2968 5.81285 23.4348 7.63077 23.1798 9.51168C22.8438 11.9866 21.8839 14.2065 20.4169 16.2134C18.257 19.1712 15.4581 21.3821 12.2663 23.1221C12.0743 23.2271 11.9303 23.2331 11.7353 23.1251C8.98744 21.6311 6.52156 19.7742 4.48165 17.3893C2.33075 14.8754 0.938806 12.0256 0.725816 8.66872C0.602821 6.73081 1.01381 4.94589 2.17476 3.36496C2.58574 2.80399 3.07771 2.32101 3.64168 1.91303C3.52769 1.70604 3.41969 1.49305 3.3117 1.28306C2.17175 2.09002 1.32879 3.14297 0.746817 4.40891C-0.0781459 6.20883 -0.132144 8.09275 0.164842 10.0067C0.560824 12.5625 1.61978 14.8364 3.17671 16.8793C5.46861 19.8912 8.38447 22.1531 11.7203 23.902C11.9333 24.013 12.1103 23.992 12.3143 23.887C15.1072 22.4351 17.5911 20.5812 19.694 18.2353C22.1688 15.4694 23.7558 12.3016 23.9808 8.53673C24.1248 6.10984 23.4678 3.94693 21.7519 2.19501V2.19801Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M13.7451 17.1645C13.5531 17.1465 13.3461 17.1435 13.1721 17.0715C12.4011 16.7475 11.6512 16.7625 10.8772 17.0715C10.1842 17.3445 9.39827 17.0835 8.94829 16.4985C8.25832 15.6076 8.56431 14.3386 9.59926 13.8496C10.0012 13.6606 10.2172 13.3697 10.2412 12.9287C10.2472 12.7877 10.2622 12.6467 10.2922 12.5117C10.4932 11.6747 11.2792 11.0988 12.1101 11.1708C13.0041 11.2488 13.6791 11.9387 13.7241 12.8537C13.7451 13.3067 13.9371 13.6186 14.354 13.8046C15.464 14.3026 15.791 15.4396 15.128 16.4655C15.083 16.5345 15.062 16.5975 15.053 16.6605C15.188 16.7925 15.326 16.9245 15.47 17.0505C15.551 17.0295 15.632 16.9695 15.707 16.8705C16.022 16.4415 16.178 15.9585 16.181 15.3046C16.13 14.4016 15.668 13.6396 14.729 13.2047C14.522 13.1087 14.444 12.9917 14.426 12.7667C14.321 11.2938 13.0491 10.2858 11.5882 10.5018C10.5052 10.6638 9.63526 11.5937 9.56026 12.7037C9.54226 12.9827 9.45226 13.1447 9.18827 13.2767C8.04232 13.8586 7.56535 15.2536 8.08733 16.4235C8.6093 17.5965 9.95924 18.1844 11.1592 17.7225C11.7562 17.4915 12.3171 17.4855 12.9081 17.7225C13.1631 17.8245 13.4541 17.8455 13.7301 17.8665C13.7781 17.8695 13.8231 17.8665 13.8651 17.8515C13.8261 17.6235 13.7931 17.3955 13.7631 17.1675C13.7571 17.1675 13.7541 17.1675 13.7511 17.1675L13.7451 17.1645Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M11.9006 8.34476C11.8376 8.56975 11.7776 8.98673 11.6067 9.35271C11.1027 10.4477 9.87572 10.7716 8.87077 10.1087C7.71282 9.34371 7.21785 7.71778 7.75182 6.43684C8.2288 5.29989 9.46174 4.89491 10.5057 5.56088C11.4477 6.16086 11.8346 7.07281 11.8976 8.34476H11.9006Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M12.1069 8.15008C12.1609 7.09413 12.5029 6.25416 13.2949 5.65419C14.4798 4.75423 15.9288 5.24321 16.3517 6.67114C16.6997 7.84109 16.2437 9.22403 15.2658 9.959C14.0538 10.874 12.5599 10.307 12.1969 8.78905C12.1399 8.55506 12.1279 8.31207 12.1069 8.15008Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M8.96072 11.8036C8.96072 12.6825 8.50173 13.3605 7.77277 13.5705C7.43678 13.6665 7.1098 13.6425 6.78282 13.5315C5.59187 13.1235 4.83591 11.7076 5.15389 10.4866C5.41788 9.47566 6.36583 8.98369 7.34379 9.35267C8.28875 9.70965 8.96371 10.7326 8.96371 11.8006L8.96072 11.8036Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M18.9169 11.012C18.8749 12.065 18.413 12.8899 17.45 13.3819C16.4 13.9189 15.3051 13.3549 15.0831 12.197C14.8641 11.051 15.5091 9.81207 16.574 9.34109C17.636 8.87011 18.7399 9.49109 18.8869 10.646C18.9019 10.763 18.9049 10.88 18.9169 11.015V11.012Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
  </svg>
);

export default PetsIcon;
