import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

interface ProductViewSkeletonProps {
  isMobile: boolean;
  isLogged: boolean;
  onClick?: () => void;
}

const ProductViewSkeleton = ({ onClick, isMobile, isLogged }: ProductViewSkeletonProps) => {
  return (
    <Flex backgroundColor="grey50" width="100%" data-testid="product-view-skeleton-container">
      <Flex justifyContent="flex-start" direction="column" width="100%" height="100vh">
        <Flex
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          backgroundColor="background"
        >
          <Flex
            alignItems="center"
            textAlign="start"
            margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
          >
            <ArrowBackIcon
              boxSize={8}
              marginRight="20px"
              onClick={onClick}
              cursor="pointer"
              data-testid="arrow-icon"
            />
            <Box>
              <Skeleton width="80px" height="20px" />
            </Box>
          </Flex>
        </Flex>
        <Box
          borderRadius={isMobile ? '0' : '8px'}
          backgroundColor={{ base: 'grey50', md: 'background' }}
          border={isMobile ? 'none' : '0.48px solid #D5DDE0'}
          marginTop={isMobile ? '0' : '48px'}
          marginLeft={isMobile ? '0' : '112px'}
          marginRight={isMobile ? '0' : '112px'}
          marginBottom={{ base: '65px', md: '85px' }}
          minWidth="280px"
        >
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={isMobile ? 'center' : 'flex-start'}
            margin={{ base: '16px 27px 0 21px', md: '32px 29px 3px 29px' }}
          >
            <Flex flexDirection="column" width={{ base: '90%', md: '325px' }} gap="15px">
              <Skeleton
                height="215px"
                marginTop="20px"
                marginRight={{ md: '32px' }}
                borderRadius="9.59px"
              />
              {!isMobile && isLogged && (
                <Skeleton width="150px" height="16px" data-testid="heart-icon-desktop-skeleton" />
              )}
            </Flex>
            <Box margin="16px 20px" width={{ base: '90%', md: '40%' }}>
              {isLogged && (
                <Flex
                  justifyContent={isMobile ? 'space-between' : 'flex-start'}
                  alignItems={isMobile ? 'center' : 'baseline'}
                >
                  <Skeleton
                    width="150px"
                    height="16px"
                    marginBottom="7px"
                    marginRight={isMobile ? '0' : '20px'}
                    data-testid="product-price-skeleton"
                  />
                </Flex>
              )}
              <Skeleton width="250px" height="16px" marginBottom={{ base: '8px', md: '16px' }} />
              {!isLogged ? (
                <Skeleton
                  width="150px"
                  height="16px"
                  marginBottom="24px"
                  data-testid="stock-available-skeleton"
                />
              ) : (
                <>
                  <Skeleton width="250px" height="16px" marginBottom="7px" />
                  <Skeleton width="200px" height="16px" marginBottom="24px" />
                </>
              )}
              {isLogged && (
                <Box>
                  <Skeleton width="150px" height="16px" marginBottom="7px" />
                  <Skeleton width="250px" height="40px" marginBottom="7px" borderRadius="6px" />
                  <Skeleton
                    width="100px"
                    height="16px"
                    marginBottom="7px"
                    data-testid="total-price-skeleton"
                  />
                  <Flex justifyContent="flex-start" marginBottom="32px">
                    <Skeleton width="50px" height="16px" marginRight={isMobile ? '7px' : '30px'} />
                    <Skeleton width="50px" height="16px" marginRight={isMobile ? '7px' : '30px'} />
                    <Skeleton width="50px" height="16px" marginRight={isMobile ? '0' : '30px'} />
                  </Flex>
                  <Flex
                    justifyContent={isMobile ? 'center' : 'flex-start'}
                    alignItems="flex-end"
                    marginBottom="24px"
                    width="100%"
                    maxWidth={{ md: '325px' }}
                  >
                    <Skeleton
                      width={{ base: '100%', md: '325px' }}
                      height="48px"
                      marginTop="10px"
                      borderRadius="6px"
                    />
                  </Flex>
                </Box>
              )}
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            marginLeft={{ base: '38px', md: '29px' }}
            marginBottom={{ md: '40px' }}
          >
            <Skeleton width="200px" height="16px" marginBottom="7px" />
            <Skeleton width="280px" height="16px" marginBottom="7px" />
          </Flex>
          {!isLogged && (
            <Flex
              flexDirection="column"
              margin={isMobile ? '30px' : '100px 30px'}
              alignItems={{ md: 'center' }}
            >
              <Skeleton width="250px" height="50px" marginBottom="7px" />
              <Flex justifyContent="center">
                <Skeleton width="150px" height="16px" paddingTop="25px" />
              </Flex>
              <Flex justifyContent="center" width="100%">
                <Skeleton
                  width="200px"
                  height={isMobile ? '34px' : '48px'}
                  marginTop="10px"
                  data-testid="login-button-skeleton"
                />
              </Flex>
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProductViewSkeleton;
