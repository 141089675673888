import './CategoriesCarousel.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import CleaningIcon from '../../../assets/Icons/CleaningIcon';
import DealsIcon from '../../../assets/Icons/DealsIcon';
import DrinksIcon from '../../../assets/Icons/DrinksIcon';
import ElectroHomeIcon from '../../../assets/Icons/ElectroHomeIcon';
import LubricantsIcon from '../../../assets/Icons/LubricantsIcon';
import PendingToReceive from '../../../assets/Icons/PendingToReceive';
import PerfumeryIcon from '../../../assets/Icons/PerfumeryIcon';
import PetsIcon from '../../../assets/Icons/PetsIcon';
import SeasonIcon from '../../../assets/Icons/SeasonIcon';
import StoreIcon from '../../../assets/Icons/StoreIcon';
import CategoryItems from './CategoryItems';

const CategoriesCarousel = () => {
  const { pathname } = useLocation();

  const data = [
    {
      icon: <SeasonIcon />,
      title: 'Temporada',
      container: 'season-container',
      dataTestId: 'season-icon',
      code: '01.18',
    },
    {
      icon: <StoreIcon />,
      title: 'Almacén',
      container: 'store-container',
      dataTestId: 'store-icon',
      code: '01.01',
    },
    {
      icon: <DrinksIcon />,
      title: 'Bebidas',
      container: 'drinks-container',
      dataTestId: 'drinks-icon',
      code: '01.03',
    },
    {
      icon: <ElectroHomeIcon />,
      title: 'Electro-Hogar',
      container: 'electroHome-container',
      dataTestId: 'electroHome-icon',
      code: '01.05',
    },
    {
      icon: <CleaningIcon />,
      title: 'Limpieza',
      container: 'cleaning-container',
      dataTestId: 'cleaning-icon',
      code: '01.09',
    },
    {
      icon: <PerfumeryIcon />,
      title: 'Perfumería',
      container: 'perfumery-container',
      dataTestId: 'perfumery-icon',
      code: '01.11',
    },
    {
      icon: <PetsIcon />,
      title: 'Mascotas',
      container: 'pets-container',
      dataTestId: 'pets-icon',
      code: '01.10',
    },
    {
      icon: <LubricantsIcon />,
      title: 'Lubricantes',
      container: 'lubricants-container',
      dataTestId: 'lubricants-icon',
      code: '01.12',
    },
  ];

  if (!pathname.includes('pendingToReceive')) {
    data.unshift({
      icon: <PendingToReceive />,
      title: 'En viaje',
      container: 'pending-to-receive-container',
      dataTestId: 'pending-to-receive-icon',
      code: 'pending-to-receive',
    });
  }

  if (!pathname.includes('deals')) {
    data.unshift({
      icon: <DealsIcon />,
      title: 'Ofertas',
      container: 'deals-container',
      dataTestId: 'deals-icon',
      code: '01.20',
    });
  }

  return (
    <Flex
      alignItems="start"
      justifyContent={{ base: 'start', md: 'space-between' }}
      overflowX="scroll"
      width="100%"
      data-testid="categories"
      gap="20px"
      padding={{ base: '0 20px', md: '0 60px' }}
      className="carousel-container"
    >
      {data.map((item, index) => (
        <CategoryItems
          datatestid={item.container}
          title={item.title}
          code={item.code}
          key={`${item.code}-${index}`}
          itemDataTestId={item.dataTestId}
          pathname={pathname}
        >
          {item.icon}
        </CategoryItems>
      ))}
    </Flex>
  );
};

export default CategoriesCarousel;
