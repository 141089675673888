import { isAxiosError } from 'axios';
import useSWRMutation from 'swr/mutation';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import ConfirmReservationIcon from '../../../assets/Icons/ConfirmReservationIcon';
import { ModalType } from '../../../enum/ModalType';
import { CreateVoucherDifferenceErrorResponse } from '../../../interfaces/voucher';
import { createVoucher } from '../../../services/voucher';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { applyDifferences } from '../../../store/modules/cart';
import { getModalState } from '../../../store/modules/ui/selectors';
import { hideModal, showModal } from '../../../store/modules/ui/slice';
import { ConfirmReservationData } from '../../../store/modules/ui/types';
import { clearCache } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import { parseVoucherProducts } from '../../../utils/vouchers';
import Button from '../../shared/Button/Button';

interface Props {
  isMobile: boolean;
}

const ConfirmReservation = ({ isMobile }: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(getModalState);
  const modalData = modalState.data as ConfirmReservationData;

  const products = parseVoucherProducts(modalData.products);

  const {
    data: swrData,
    trigger,
    isMutating,
  } = useSWRMutation({ products }, createVoucher, {
    onError: (error: unknown) => {
      if (
        isAxiosError<CreateVoucherDifferenceErrorResponse>(error) &&
        error.response?.status !== 503
      ) {
        if (error.response?.data.data.differentProducts) {
          if (!location.pathname.includes('/reservation-history')) {
            dispatch(applyDifferences({ data: error.response.data.data }));
          } else {
            clearCache({});
          }
          dispatch(showModal({ type: ModalType.VOUCHER_DIFFERENCE }));
          return;
        }
        dispatch(showModal({ type: ModalType.FAIL_RESERVATION }));
      }
    },
  });

  useEffect(() => {
    if (swrData) {
      dispatch(
        showModal({
          type: ModalType.SUCCESSFUL_RESERVATION,
          data: swrData.data.IDTablaWS,
        }),
      );
    }
  }, [swrData, dispatch]);

  return (
    <>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="close-modal-icon"
            onClick={() => {
              !isMutating && dispatch(hideModal());
            }}
            color={isMutating ? 'grey400' : 'inherit'}
            cursor={isMutating ? 'default' : 'pointer'}
          />
        </Flex>
      )}
      <Flex
        padding={{ base: '0 24px 20px', xl: '24px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="confirm-reservation-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ md: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="85%"
        >
          <ConfirmReservationIcon dataTestId="confirm-reservation-icon" />
          <Text
            data-testid="confirm-reservation-title"
            fontSize="20px"
            marginBottom="24px"
            textAlign="center"
            fontWeight="500"
            color="grey900"
          >
            {textContent.cart.confirmReservation}
          </Text>
          <Text
            marginBottom="28px"
            data-testid="confirm-reservation-info"
            fontSize="16px"
            textAlign="start"
            fontWeight="400"
            letterSpacing="0.16px"
            color="grey900"
          >
            Recuerde que la reserva debe ser abonada{' '}
            <Text display="inline" fontWeight="500">
              antes
            </Text>{' '}
            de las{' '}
            <Text display="inline" fontWeight="500">
              {' '}
              11 AM del próximo día hábil{' '}
            </Text>{' '}
            (horario de Argentina). De lo contrario,{' '}
            <Text display="inline" fontWeight="500">
              la reserva será anulada y su cuenta puede ser bloqueada
            </Text>
            .
          </Text>
          <Text
            data-testid="reservation-continue-label"
            fontSize="18px"
            textAlign="center"
            fontWeight="500"
            color="grey900"
          >
            {textContent.wishToContinue}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'space-between' }}>
          <Button
            width={{ base: '45%' }}
            isDisabled={isMutating}
            content={textContent.cart.editReservation}
            data-testid="edit-reservation-button"
            color="purple"
            backgroundColor="background"
            height={{ base: '40px' }}
            border="1px solid"
            borderColor="purple"
            onClick={() => dispatch(hideModal())}
            fontSize="14px"
          />
          <Button
            width={{ base: '45%' }}
            isDisabled={isMutating}
            content={isMutating ? 'Procesando...' : textContent.cart.finalizeReservation}
            data-testid="finalize-reservation-button"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            onClick={async () => {
              try {
                await trigger();
              } catch (error) {
                console.error(error);
              }
            }}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default ConfirmReservation;
