export enum ModalType {
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  DELETE_PRODUCT = 'DeleteProduct',
  CONFIRM_RESERVATION = 'ConfirmReservation',
  SUCCESSFUL_RESERVATION = 'SuccessfulReservation',
  FAIL_RESERVATION = 'FailReservation',
  CONFIRM_EMAIL_GENERAL_SALES_EDIT = 'ConfirmEmailGeneralSalesEdition',
  CONFIRM_EMAIL_BRANCH_EDIT = 'ConfirmEmailBranchEdition',
  CONFIRM_PRODUCT_EDIT = 'ConfirmProductEdition',
  SYSTEM_SETTINGS = 'SystemSettings',
  CONFIRM_BANNER_SUBMIT = 'ConfirmBannerSubmit',
  CONFIRM_BANNER_ADP = 'ConfirmBannerADP',
  CONFIRM_VOUCHER_DELETE = 'ConfirmVoucherDelete',
  CONFIRM_MANUAL_DELETE_VOUCHER = 'ConfirmManualDeleteModal',
  VOUCHER_DIFFERENCE = 'VoucherDifferenceModal',
}
