import { BaseResponse } from './common';

export interface VoucherProduct {
  articleCode: string;
  description: string;
  finalPrice: number;
  unitPrice: number;
  quantity: number;
  productImageUrl?: string;
  articlePriceId: string;
}

export enum Status {
  CANCELLED = 'Anulada',
  PENDING = 'Pendiente',
  PAID = 'Pagado',
}

export enum FormattedStatus {
  Pagado = 'Pagado',
  Pendiente = 'Pendiente de pago',
  Anulada = 'Anulada',
}

export type StatusType = 'Anulada' | 'Pagado' | 'Pendiente' | 'Pendiente de pago';

export interface VoucherProductWithStock extends VoucherProduct {
  productImageUrl: string;
  existence: number;
  pendingToReceive: number;
  totalStock: number;
}

export interface Voucher {
  IDTablaWS: string;
  voucherNumber: string;
  voucher: string;
  branch: string;
  clientID: string;
  client: string;
  date: string;
  status: StatusType;
  expirationDays: number;
  products: VoucherProduct[];
  manualDelete?: boolean;
}

export interface VoucherById extends Omit<Voucher, 'products'> {
  products: VoucherProductWithStock[];
}

export interface GetVoucherResponse {
  data: {
    pagination: {
      totalPages: number;
      total: number;
    };
    vouchers: Voucher[];
  };
  statusCode: number;
  message: string;
}

export interface GetVoucherFiltersResponse {
  data: {
    years: string[];
  };
  statusCode: number;
  message: string;
}

export interface GetVoucherByResponse {
  data: VoucherById;
  statusCode: number;
  message: string;
}

export interface PostVoucherResponse {
  data: {
    IDTablaWS: string;
  };
  statusCode: number;
  message: string;
}

export interface DeleteVouchersResponse {
  data: {
    IDTablaWS: string;
  };
  statusCode: number;
  message: string;
}

export interface ManualDeleteResponse {
  message: string;
}

export interface CreateVoucherProduct {
  articleCode: string;
  unitPrice: string;
  quantity: string;
  articleDesc: string;
  articlePriceId: string;
}

export interface CreateVoucherDifferenceErrorResponse extends BaseResponse {
  data: {
    error: boolean;
    differentProducts: Record<
      string,
      {
        existence: number;
        pendingToReceive: number;
        priceList: number;
        articlePriceId: string;
      }
    >;
    removedProducts: string[];
  };
}
