import * as React from 'react';

interface Props {
  dataTestId: string;
  onClick: () => void;
}

const ActivateBannerIcon = ({ dataTestId, onClick }: Props) => (
  <svg
    data-testid={dataTestId}
    onClick={onClick}
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.167 16.0514L15.3431 12.5766C15.4277 12.5062 15.4958 12.418 15.5425 12.3184C15.5892 12.2188 15.6135 12.1101 15.6135 12C15.6135 11.89 15.5892 11.7812 15.5425 11.6816C15.4958 11.582 15.4277 11.4938 15.3431 11.4234L11.167 7.9486C11.0575 7.85753 10.9243 7.79953 10.7831 7.78138C10.6418 7.76323 10.4983 7.78569 10.3693 7.84612C10.2403 7.90655 10.1312 8.00246 10.0547 8.12263C9.97828 8.2428 9.93761 8.38226 9.9375 8.52469V15.4753C9.93761 15.6178 9.97828 15.7572 10.0547 15.8774C10.1312 15.9975 10.2403 16.0935 10.3693 16.1539C10.4983 16.2143 10.6418 16.2368 10.7831 16.2186C10.9243 16.2005 11.0575 16.1425 11.167 16.0514Z"
      fill="#3377FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12C2.25 6.61704 6.61704 2.25 12 2.25C17.383 2.25 21.75 6.61704 21.75 12C21.75 17.383 17.383 21.75 12 21.75C6.61704 21.75 2.25 17.383 2.25 12ZM12 3.75C7.44546 3.75 3.75 7.44546 3.75 12C3.75 16.5545 7.44546 20.25 12 20.25C16.5545 20.25 20.25 16.5545 20.25 12C20.25 7.44546 16.5545 3.75 12 3.75Z"
      fill="#3377FF"
    />
  </svg>
);
export default ActivateBannerIcon;
