import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Props } from './ProductIcon';

const MissingIcon = ({ dataTestId }: Props) => {
  return (
    <Flex width="100%" justifyContent="center">
      <svg
        data-testid={dataTestId}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5680_36278)">
          <path
            d="M9.99935 18.3332C14.6017 18.3332 18.3327 14.6022 18.3327 9.99984C18.3327 5.39746 14.6017 1.6665 9.99935 1.6665C5.39698 1.6665 1.66602 5.39746 1.66602 9.99984C1.66602 14.6022 5.39698 18.3332 9.99935 18.3332Z"
            stroke="#E53E3E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.6665V9.99984"
            stroke="#E53E3E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3335H10.0083"
            stroke="#E53E3E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5680_36278">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Flex>
  );
};

export default MissingIcon;
