import { StatusType, Voucher } from '../interfaces/voucher';

export const voucherFormatter = (voucherData: Voucher[]) =>
  voucherData.reduce((acc: Voucher[], curr: Voucher) => {
    const parsedStatus = curr.status.replace('Pendiente', 'Pendiente de pago') as StatusType;
    return [
      ...acc,
      {
        ...curr,
        status: parsedStatus,
      },
    ];
  }, [] as Voucher[]);
