import React, { useCallback, useState } from 'react';
import { Flex, useToast } from '@chakra-ui/react';

import { ProductType } from '../../../interfaces';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addProductFavorite,
  deleteFavorite,
  favorites,
  isLoadingFavorites,
} from '../../../store/modules/favorites';
import { closeToast, openToast } from '../../../store/modules/toast';
import { RootState } from '../../../store/types';
import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';
import DeleteFavoriteAlert from './DeleteFavoriteAlert';
import EmptyFavoritesList from './EmptyFavoritesList';
import FavoriteCard from './FavoriteCard';
import FavoriteCardSkeleton from './FavoriteCardSkeleton';

const Favorites = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [favoriteId, setFavoriteId] = useState('');

  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const favoritesList = useAppSelector((state: RootState) => favorites(state, favoriteId));

  const handleRestoreFavorite = useCallback(
    async (favorite: ProductType) => {
      dispatch(addProductFavorite(favorite));
      setFavoriteId('');
      toast.closeAll();
      dispatch(closeToast());
    },
    [toast],
  );

  const handleDeleteFavorite = useCallback(
    (favorite: ProductType) => {
      dispatch(deleteFavorite({ productId: favorite.articleCode }));
      toast.close(favoriteId);
      dispatch(openToast());
      toast({
        id: favorite.articleCode,
        duration: 5000,
        render: () => (
          <DeleteFavoriteAlert favorite={favorite} onRestoreFavorite={handleRestoreFavorite} />
        ),
      });
    },
    [dispatch, favoriteId, handleRestoreFavorite, toast],
  );

  if (loadingFavorites) {
    return <FavoriteCardSkeleton />;
  }

  if (favoritesList.length === 0) {
    return <EmptyFavoritesList />;
  }

  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor="grey50"
      width="100%"
      height="100%"
    >
      <GoBack title={textContent.favorites} />
      <Flex
        flexDirection="column"
        padding={{ base: '0', md: '32px 0' }}
        backgroundColor="grey50"
        width="100%"
        alignItems={{ base: 'center', xl: 'flex-start' }}
      >
        {favoritesList.map((favorite, index) => (
          <FavoriteCard
            key={`${index}-${favorite.articleCode}`}
            product={favorite}
            onDeleteFavorite={handleDeleteFavorite}
            setFavoriteId={setFavoriteId}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default Favorites;
