import useSWR from 'swr';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { PaginationState } from '@tanstack/react-table';

import { ModalType } from '../../../../enum/ModalType';
import { SWRKey } from '../../../../enum/SWRKey';
import { getFilteredBranches } from '../../../../services/branch';
import {
  getGeneralSalesEmail,
  updateGeneralSalesEmail,
} from '../../../../services/generalSalesEmail';
import { useAppDispatch } from '../../../../store/hooks';
import { isSystemActive } from '../../../../store/modules/settings';
import { showModal } from '../../../../store/modules/ui';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import SharedTable from '../../../shared/Table/SharedTable';
import SharedTableSkeleton from '../../../shared/Table/SharedTableSkeleton';
import { emailValidation } from '../AdminHome/validation';
import { SkeletonCells, tableColumns } from './columnDefinition';
import EmptyBranches from './EmptyBranches';
import SellsInformationSkeleton from './SellsInformationSkeleton';

const AdminHome = () => {
  const dispatch = useAppDispatch();
  const [onEdit, setOnEdit] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageSize: 5,
    pageIndex: 0,
  });
  const { data: generalSalesEmailData, isLoading: emailLoading } = useSWR(
    {
      key: SWRKey.generalSalesEmail,
    },
    getGeneralSalesEmail,
  );

  const isSystemOn = useSelector(isSystemActive);

  const handleSwitchChange = () => {
    dispatch(
      showModal({
        type: ModalType.SYSTEM_SETTINGS,
      }),
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors, isValid },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(emailValidation),
    mode: 'onChange',
  });

  useEffect(() => {
    if (generalSalesEmailData?.email) {
      reset({ email: generalSalesEmailData.email.email });
    }
  }, [generalSalesEmailData, reset]);

  const {
    data: BranchesData,
    error,
    isLoading: branchesLoading,
  } = useSWR({ pagination, searchValue, key: SWRKey.branches }, getFilteredBranches);

  const Branches = React.useMemo(() => {
    if (branchesLoading) return <SharedTableSkeleton rows={5} cells={SkeletonCells} />;

    if (error || !BranchesData?.data?.length) return <EmptyBranches />;

    return (
      <SharedTable
        total={BranchesData.total}
        justifyFilters="start"
        onPaginationChange={setPagination}
        pagination={pagination}
        marginBottom="0px"
        data={BranchesData?.data}
        columns={tableColumns}
        pageCount={BranchesData?.pageCount}
        dataTestId="associated-offices-table-container"
      />
    );
  }, [
    branchesLoading,
    error,
    BranchesData?.data,
    BranchesData?.total,
    BranchesData?.pageCount,
    pagination,
  ]);

  const toast = useToast();

  const onSubmit = async ({ email: newEmail }: { email: string }) => {
    if (!generalSalesEmailData) {
      return;
    }

    try {
      await updateGeneralSalesEmail(generalSalesEmailData.email.id, newEmail);
      reset({ email: newEmail });
      setOnEdit(false);
      toast({
        title: 'Éxito',
        description: textContent.emailEdit.toast.successMessage,
        status: 'success',
        variant: 'subtle',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: textContent.emailEdit.toast.errorMessage,
        status: 'error',
        variant: 'subtle',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex justify="center" backgroundColor="background" height="100%">
      <Flex width="86.6%" direction="column" align="start" paddingTop="40px">
        <Flex alignItems="start" direction="column">
          <Text
            data-testid="shutdown-system"
            fontWeight="600"
            color="darkBlue"
            fontSize="20px"
            textAlign="start"
            lineHeight="31px"
            paddingBottom="10px"
            paddingTop="24px"
          >
            {textContent.system.systemText}
          </Text>
          <Flex alignItems="top" marginBottom="20px">
            <Text
              data-testid="shutdown-system"
              fontWeight="600"
              color="darkBlue"
              fontSize="12px"
              textAlign="start"
              marginRight="10px"
            >
              {textContent.system.systemOff}
            </Text>
            <Switch
              paddingBottom="10px"
              size="md"
              isChecked={isSystemOn}
              onChange={handleSwitchChange}
            />
            <Text
              data-testid="turnon-system"
              fontWeight="600"
              color="darkBlue"
              fontSize="12px"
              textAlign="start"
              marginLeft="10px"
            >
              {textContent.system.systemOn}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column" width="65%">
          <Text
            data-testid="title"
            fontWeight="600"
            color="darkBlue"
            fontSize="20px"
            lineHeight="31px"
            paddingBottom="24px"
          >
            {onEdit ? textContent.editSellsGeneralInfo : textContent.sellsGeneralInfo}
          </Text>
          {emailLoading ? (
            <SellsInformationSkeleton />
          ) : (
            <Flex direction="column">
              <Box
                display="flex"
                width="388px"
                height="132px"
                padding="24px"
                border="1px solid"
                borderColor="grey2"
                background="white"
                borderRadius="8px"
              >
                <Flex flexDirection="column" width="100%">
                  <Text
                    data-testid="card-title"
                    display="flex"
                    alignItems="center"
                    height="32px"
                    fontWeight="700"
                    color="darkBlue"
                    fontSize="14px"
                  >
                    {textContent.associatedEmail}
                  </Text>
                  <InputGroup>
                    <Input
                      disabled={!onEdit}
                      {...register('email')}
                      display="flex"
                      alignItems="center"
                      height="52px"
                      data-testid="email-input"
                      bg="background"
                      fontWeight="500"
                      background="background"
                      color="darkBlue"
                      fontSize="12px"
                    />
                    {!onEdit && (
                      <InputRightElement height="100%">
                        <EditIcon
                          _hover={{ cursor: 'pointer' }}
                          onClick={() => setOnEdit(true)}
                          data-testid="edit-email-button"
                          color="lightBlue"
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Flex>
              </Box>
              <Flex height="40px" alignItems="center">
                {errors.email && (
                  <Text
                    data-testid="email-error"
                    height="fit-content"
                    color="errorRed"
                    fontFamily="Roboto"
                    fontSize="12px"
                    fontWeight="500"
                  >
                    {errors.email.message}
                  </Text>
                )}
              </Flex>
              <Flex justify="space-evenly" height="40px" width="388px">
                {onEdit && (
                  <>
                    <Button
                      variant="outline"
                      borderColor="purple"
                      data-testid="cancel-button"
                      width="155px"
                      height="40px"
                      onClick={() => {
                        setOnEdit(false);
                        reset();
                      }}
                      color="purple"
                      _hover={{ bg: 'greyOnHover' }}
                    >
                      {textContent.cancel}
                    </Button>
                    <Button
                      isDisabled={!isDirty || !isValid}
                      data-testid="save-button"
                      width="155px"
                      height="40px"
                      color="white"
                      onClick={() => {
                        dispatch(
                          showModal({
                            type: ModalType.CONFIRM_EMAIL_GENERAL_SALES_EDIT,
                            callback: handleSubmit(onSubmit),
                          }),
                        );
                      }}
                      _disabled={{
                        bg: 'disabledPurple',
                        cursor: 'default',
                        _hover: { bg: 'disabledPurple' },
                      }}
                      _hover={{ bg: 'darkPurple' }}
                      backgroundColor="purple"
                    >
                      {textContent.saveChanges}
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          )}
          <Flex marginTop="24px" direction="column" width="100%">
            <Text
              data-testid="offices-title"
              fontWeight="600"
              color="darkBlue"
              fontSize="20px"
              textAlign="start"
              lineHeight="31px"
              paddingBottom="24px"
            >
              {textContent.branches.title}
            </Text>
            <Input
              data-testid="table-searchbar"
              backgroundColor="white"
              width="290px"
              marginBottom="24px"
              placeholder={textContent.branches.searchPlaceholder}
              type="text"
              onChange={(e) => {
                if (!e.target.value.length) setSearchValue('');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setPagination({
                    ...pagination,
                    pageIndex: 0,
                  });
                  setSearchValue(e.currentTarget.value);
                }
                const allowedCharacters = /[a-zA-Z0-9]/;
                if (!allowedCharacters.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {Branches}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AdminHome;
