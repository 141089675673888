import * as React from 'react';

import { Props } from '../Types';

const ErrorMessageIcon = ({ dataTestId }: Props) => (
  <svg
    data-testid={dataTestId}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CkWarning">
      <path
        id="Vector"
        d="M7.99035 1.3348C6.20922 1.36396 4.51111 2.09289 3.2631 3.36402C2.63866 3.98571 2.14597 4.72693 1.8145 5.54336C1.48302 6.35979 1.31955 7.23467 1.33387 8.11571C1.33285 8.9766 1.50172 9.82924 1.83082 10.6247C2.15991 11.4203 2.64276 12.143 3.25169 12.7516C3.86061 13.3601 4.58365 13.8426 5.37935 14.1712C6.17506 14.4998 7.0278 14.6682 7.88869 14.6666H8.00757C9.78924 14.6483 11.4909 13.9239 12.7391 12.6525C13.9873 11.381 14.6802 9.66633 14.6657 7.88462C14.6678 7.01328 14.4956 6.15033 14.1592 5.34651C13.8229 4.54269 13.3293 3.81424 12.7073 3.20398C12.0854 2.59373 11.3477 2.11399 10.5376 1.793C9.72756 1.472 8.86149 1.31621 7.99035 1.3348ZM7.16655 10.5238C7.16255 10.4149 7.1803 10.3062 7.21877 10.2043C7.25724 10.1023 7.31565 10.009 7.3906 9.92986C7.46554 9.85072 7.55552 9.78732 7.65525 9.74337C7.75499 9.69941 7.86249 9.67579 7.97146 9.67386H7.98646C8.20566 9.67429 8.41619 9.75955 8.57391 9.91178C8.73163 10.064 8.82429 10.2714 8.83247 10.4904C8.83656 10.5994 8.81886 10.708 8.78041 10.81C8.74197 10.9121 8.68356 11.0054 8.6086 11.0845C8.53364 11.1637 8.44364 11.2271 8.34387 11.271C8.2441 11.3149 8.13656 11.3385 8.02757 11.3403H8.01257C7.79345 11.3396 7.58308 11.2543 7.42542 11.1021C7.26775 10.9499 7.17501 10.7427 7.16655 10.5238ZM7.4443 8.27847V4.94551C7.4443 4.79818 7.50282 4.65689 7.607 4.55271C7.71117 4.44854 7.85246 4.39001 7.99979 4.39001C8.14712 4.39001 8.28841 4.44854 8.39258 4.55271C8.49676 4.65689 8.55528 4.79818 8.55528 4.94551V8.27847C8.55528 8.42579 8.49676 8.56708 8.39258 8.67126C8.28841 8.77543 8.14712 8.83396 7.99979 8.83396C7.85246 8.83396 7.71117 8.77543 7.607 8.67126C7.50282 8.56708 7.4443 8.42579 7.4443 8.27847Z"
        fill="#E53E3E"
      />
    </g>
  </svg>
);
export default ErrorMessageIcon;
