import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { GetCatergoriesByIdResponse, GetCatergoriesResponse } from './types';

export const getCategories = createAsyncThunk('GET_CATEGORIES', async (_, { rejectWithValue }) => {
  try {
    const response = await get<GetCatergoriesResponse>(`${URL.GET_CATEGORIES}`);

    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return rejectWithValue(`${textContent.category.categoriesNotFound}`);
    }

    if (isAxiosError(error) && error.response?.status === 400) {
      return rejectWithValue(`${textContent.category.foundProblemCategories}`);
    }

    return rejectWithValue(`${textContent.category.foundProblemCategories}`);
  }
});

export const getCategoryById = createAsyncThunk(
  'GET_CATEGORY_BY_ID',
  async (data: { categoryId: string }, { rejectWithValue }) => {
    try {
      const { categoryId } = data;
      const response = await get<GetCatergoriesByIdResponse>(`${URL.GET_CATEGORIES}/${categoryId}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return rejectWithValue(`${textContent.product.associatedProductsNotFound}`);
      }

      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.category.foundProblemCategoryById}`);
      }

      return rejectWithValue(`${textContent.category.foundProblemCategoryById}`);
    }
  },
);
