import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

import { ButtonInterface } from '../../../interfaces/index';

const Button: React.FC<ButtonInterface> = ({ content, backgroundColor, color, ...props }) => {
  return (
    <ChakraButton color={color} {...props} backgroundColor={backgroundColor}>
      {props.children ?? content}
    </ChakraButton>
  );
};

export default Button;
