import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import { CategoriesBrands } from '../../../constants/brands';
import textContent from '../../../utils/textContent.json';

const OurBrands = () => {
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      data-testid="our-brands"
    >
      <Text
        color="darkBlue"
        fontFamily="Roboto"
        fontSize={{ base: '18px', md: '24px' }}
        fontWeight="600"
        data-testid="brands-heading"
      >
        {textContent.ourBrandsTitle}
      </Text>
      <Flex
        width={{ base: '90%', md: '700px', xl: '1300px' }}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        margin="50px 0 100px"
        data-testid="brands-container"
      >
        {CategoriesBrands.map((brand, index) => (
          <Box
            key={index}
            margin={{ base: '15px', md: '20px 50px' }}
            data-testid={`brand-${index}`}
            onClick={() => navigate(`brand/${brand.brand}`)}
          >
            {brand.image}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default OurBrands;
