import React from 'react';
import { To } from 'react-router-dom';
import { Button, Flex, Link, Text } from '@chakra-ui/react';

import ContactIcon from '../../../assets/Icons/Contact';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';

interface ContactScreenProps {
  url?: To;
}

const ContactScreen = ({ url = -1 as To }: ContactScreenProps) => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      <GoBack url={url} title={textContent.contactUs} data-testid="back-button" />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight={isMobile ? '75vh' : '70vh'}
        data-testid="contact-container"
        marginBottom="50px"
      >
        <Flex flexDirection="column" alignItems="center" data-testid="contact-content-containter">
          <ContactIcon dataTestId="contact-icon" isMobile={isMobile} />
          <Text
            fontSize={{ base: '20px', xl: '25px' }}
            fontStyle="normal"
            fontWeight="500"
            lineHeight="29px"
            data-testid="contact-title"
            paddingBottom="16px"
            textAlign="center"
          >
            {textContent.contact.title}
          </Text>
          <Text
            marginBottom="28px"
            data-testid="contact-text-first-line"
            fontSize={{ base: '16px', xl: '20px' }}
            textAlign="center"
            fontWeight="400"
            lineHeight="29px"
            letterSpacing="0.16px"
            color="grey900"
            paddingBottom="30px"
          >
            {textContent.contact.officeSchedule}
            <Text display="block" fontWeight="600" data-testid="contact-text-second-line">
              {textContent.contact.weekHours}
            </Text>
            <Text display="block" fontWeight="600" data-testid="contact-text-third-line">
              {textContent.contact.saturdayHours}
            </Text>
            {textContent.contact.argSchedule}
          </Text>
        </Flex>
        <Link
          href={`https://wa.me/5493525521001?text=${textContent.contact.infoWhatsappMessage}`}
          isExternal
          data-testid="whatsapp-link"
        >
          <Button
            maxWidth="312px"
            width="90vw"
            color="white"
            backgroundColor="purple"
            fontSize="18px"
            _hover={{ backgroundColor: 'darkPurple' }}
            data-testid="whatsapp-button"
          >
            {textContent.contact.goToWhatsapp}
          </Button>
        </Link>
      </Flex>
    </>
  );
};

export default ContactScreen;
