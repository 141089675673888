import Ambientes from '../assets/categories/cleaning/Ambientes';
import Baño from '../assets/categories/cleaning/Baño';
import Cocina from '../assets/categories/cleaning/Cocina';
import Insecticidas from '../assets/categories/cleaning/Insercticidas';
import PisosSuperficies from '../assets/categories/cleaning/PisosSuperficies';
import Ropa from '../assets/categories/cleaning/Ropa';
import Default from '../assets/categories/Default';
import BebidasConAlcohol from '../assets/categories/drinks/BebidasConAlcohol';
import BebidasSinAlcohol from '../assets/categories/drinks/BebidasSinAlcohol';
import Hogar from '../assets/categories/electro/Hogar';
import Informatica from '../assets/categories/electro/Informatica';
import MueblesBazar from '../assets/categories/electro/MueblesBazar';
import PequeñosElectros from '../assets/categories/electro/PequeñosElectros';
import TVAudio from '../assets/categories/electro/TVAudio';
import Lubricante from '../assets/categories/lubricants/Lubricante';
import Girasol from '../assets/categories/oil/Girasol';
import Mezcla from '../assets/categories/oil/Mezcla';
import CuidadoCabello from '../assets/categories/perfumery/CuidadoCabello';
import CuidadoOral from '../assets/categories/perfumery/CuidadoOral';
import CuidadoPersonal from '../assets/categories/perfumery/CuidadoPersonal';
import CuidadoPiel from '../assets/categories/perfumery/CuidadoPiel';
import AccesoriosMascotas from '../assets/categories/pets/AccesoriosMascotas';
import AlimentosGatos from '../assets/categories/pets/AlimentosGatos';
import AlimentosPerros from '../assets/categories/pets/AlimentosPerros';
import Festividades from '../assets/categories/season/Festividades';
import AderezosEndulzantes from '../assets/categories/warehouse/AderezosEndulzantes';
import DesayunosMeriendas from '../assets/categories/warehouse/DesayunosMeriendas';
import EnlatadosConservas from '../assets/categories/warehouse/EnlatadosConservas';
import GolosinasChocolates from '../assets/categories/warehouse/GolosinasChocolates';
import YerbaCerealLegumbre from '../assets/categories/warehouse/YerbaCerealLegumbre';

const imagesByCategories: Record<
  string,
  Record<string, ({ dataTestId }: { dataTestId: string }) => React.JSX.Element>
> = {
  '01.01': {
    '01.01.02': AderezosEndulzantes,
    '01.01.04': YerbaCerealLegumbre,
    '01.01.05': EnlatadosConservas,
    '01.01.14': DesayunosMeriendas,
    '01.01.15': GolosinasChocolates,
  },
  '01.02': {
    '01.02.01': Girasol,
    '01.02.02': Mezcla,
  },
  '01.03': {
    '01.03.01': BebidasConAlcohol,
    '01.03.02': BebidasSinAlcohol,
  },
  '01.05': {
    '01.05.01': Hogar,
    '01.05.02': PequeñosElectros,
    '01.05.06': TVAudio,
    '01.05.11': Informatica,
    '01.05.21': MueblesBazar,
  },
  '01.09': {
    '01.09.01': Baño,
    '01.09.03': Cocina,
    '01.09.04': Ambientes,
    '01.09.05': PisosSuperficies,
    '01.09.06': Ropa,
    '01.09.07': Insecticidas,
  },
  '01.10': {
    '01.10.01': AccesoriosMascotas,
    '01.10.02': AlimentosPerros,
    '01.10.03': AlimentosGatos,
  },
  '01.11': {
    '01.11.03': CuidadoPiel,
    '01.11.04': CuidadoOral,
    '01.11.05': CuidadoCabello,
    '01.11.16': CuidadoPersonal,
  },
  '01.12': {
    '01.12': Lubricante,
  },
  '01.18': {
    '01.18.03': Festividades,
  },
};

export const getCategoryImageComponent = (categoryCode: string, subCategoryCode: string) => {
  const ImageComponent =
    imagesByCategories[categoryCode] && imagesByCategories[categoryCode][subCategoryCode];
  return ImageComponent ?? Default;
};
