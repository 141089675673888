import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: '#003399',
  secondary: '#FF6600',
  lightgrey: '#F5F8FF',
  grey: '#F4F4F4',
  grey50: '#F5F5F6',
  grey200: '#EEEEEE',
  grey400: '#AFB1B3',
  grey700: '#6E7072',
  grey800: '#555758',
  grey900: '#414243',
  greyOnHover: '#EFE9ED',
  black: '#1F1F1F',
  blackFooter: '#272727',
  purple: '#B23E8F',
  lightPurple: '#e7c3dc',
  disabledPurple: '#B23E8F4D',
  darkPurple: '#7E2C66',
  background: '#FAFAFC',
  lightBlue: '#3377FF',
  white: '#FFFFFF',
  white900: '#696969',
  blue200: '#A1C0FF',
  darkBlue: '#001133',
  lightDarkBlue: '#0011337f',
  lightViolet: '#F7ECF4',
  violet: '#F0D7E8',
  violet200: '#DCA6CB',
  grey3: '#F7F8F9',
  grey2: '#E2E8F0',
  orange: '#EB690B',
  orange400: '#FFA865',
  orangeAlert: '#FEEBCB',
  errorRed: '#E53E3E',
};

export const theme = extendTheme({
  colors,
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
});
