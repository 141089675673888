import useSWR from 'swr';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { ProductType } from '../../../interfaces';
import { getProductsByBrand } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const Brand = () => {
  const { name: brand } = useParams();
  const loadingFavorites = useAppSelector(isLoadingFavorites);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, error, isLoading } = useSWR(
    {
      currentPage,
      brand,
      key: SWRKey.products,
    },
    getProductsByBrand,
  );

  if (isLoading || loadingFavorites) {
    return <ProductCardSkeleton array={12} />;
  }

  if (error || data?.products.length === 0) {
    return (
      <ErrorScreen title={textContent.product.associatedProductsNotFound} arrowTitle={brand} />
    );
  }

  return (
    <Flex
      data-testid="products-brand-container"
      justifyContent="flex-start"
      direction="column"
      height="100%"
      backgroundColor="grey50"
    >
      <GoBack title={brand} />
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
        >
          {data?.products.map((product: ProductType, index: number) => (
            <Flex justifyContent={'center'} key={index} margin="20px 0">
              <ProductCard
                product={product}
                urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_BRAND}?productsBrand=${name}`}
              />
            </Flex>
          ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </Flex>
  );
};

export default Brand;
