import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';

import textContent from '../../../utils/textContent.json';

interface GoBackProps {
  url?: To;
  title?: string;
  subTitle?: string;
}

const GoBack = ({ url = -1 as To, title, subTitle }: GoBackProps) => {
  const navigate = useNavigate();

  return (
    <Flex
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      data-testid="back-button-container"
      backgroundColor="background"
      position={{ base: 'sticky', md: 'static' }}
      top="58px"
      zIndex={{ base: 5, md: 0 }}
    >
      <Flex
        alignItems="flex-start"
        textAlign="start"
        margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
      >
        <ArrowBackIcon
          boxSize={8}
          marginRight="20px"
          onClick={() => navigate(url)}
          cursor="pointer"
          data-testid="back-button"
        />
        <Box>
          <Text
            color="darkBlue"
            fontSize={{ base: '18px', md: '20px' }}
            fontWeight="600"
            data-testid="back-button-title"
          >
            {title ?? textContent.goBack}
          </Text>
          <Text
            color="darkBlue"
            fontSize={{ base: '14px', md: '16px' }}
            fontWeight="500"
            data-testid="back-button-sub-title"
          >
            {subTitle}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default GoBack;
