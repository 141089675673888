import { AxiosError, isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { setLocalStorage } from '../../../utils/token';
import {
  ClientCodeVerificationResponse,
  LogoutResponse,
  PhoneCodeAuthenticationResponse,
} from './types';

const incompletePhoneUserServer =
  'The user has been successfully found but does not have a phone number.';
const incompleteListUserServer =
  'The user has been successfully found but does not have a price list.';
const inactiveUser = 'The user has been successfully found but is inactive.';

export const clientCodeVerification = createAsyncThunk(
  'LOGIN_USER',
  async (data: { userId: string }, { rejectWithValue }) => {
    try {
      const { userId } = data;
      const userUrl = `${URL.LOGIN_USER}/${userId}`;
      const response = await get<ClientCodeVerificationResponse>(userUrl);

      return {
        data: response.data,
      };
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error.response?.data.success === incompletePhoneUserServer ||
          error.response?.data.success === incompleteListUserServer ||
          error.response?.data.success === inactiveUser)
      ) {
        return rejectWithValue(`${textContent.incompleteUser}`);
      }
      if (isAxiosError(error) && error.response?.status === 404) {
        return rejectWithValue(`${textContent.invalidClientCode}`);
      }
      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.foundProblem}`);
      }
      return rejectWithValue(`${textContent.foundProblem}`);
    }
  },
);

export const phoneCodeAuthentication = createAsyncThunk(
  'AUTH_USER',
  async (data: { authCode: string; userPhone: string; userId: string }, { rejectWithValue }) => {
    try {
      const response = await post<PhoneCodeAuthenticationResponse>(URL.AUTH_USER, data);

      const { access_token, id_token, refresh_token } = response.data;
      const accessToken = access_token;
      const idToken = id_token;
      const refreshToken = refresh_token;
      const userId = data.userId;

      setLocalStorage('access_token', accessToken);
      setLocalStorage('id_token', idToken);
      setLocalStorage('refresh_token', refreshToken);
      setLocalStorage('user_id', userId);

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 403) {
        return rejectWithValue(`${textContent.foundProblem}`);
      }
      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.authCodeIncorrect}`);
      }
      return rejectWithValue(`${textContent.foundProblem}`);
    }
  },
);

export const getUserRole = createAsyncThunk('GET_USER_ROLE', async (_, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = await get<any>(`${URL.GET_USER_ROLE}`);

    return response.response;
  } catch (error) {
    if (
      isAxiosError(error) &&
      (error.response?.data.success === incompletePhoneUserServer ||
        error.response?.data.success === incompleteListUserServer)
    ) {
      return rejectWithValue(`${textContent.incompleteUser}`);
    }
    if (isAxiosError(error) && error.response?.status === 404) {
      return rejectWithValue(`${textContent.invalidClientCode}`);
    }
    if (isAxiosError(error) && error.response?.status === 400) {
      return rejectWithValue(`${textContent.foundProblem}`);
    }
    return rejectWithValue(`${textContent.foundProblem}`);
  }
});

const clearSessionStorage = () => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_id');
};

export const userLogout = createAsyncThunk(
  'USER_LOGOUT',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await post<LogoutResponse>(URL.USER_LOGOUT);
      clearSessionStorage();

      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.message === 'INVALID_SESSION_ID') {
          clearSessionStorage();
          return fulfillWithValue(undefined);
        }
      }
      return rejectWithValue(`${textContent.logoutError}`);
    }
  },
);
