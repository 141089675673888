import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

import CartIcon from '../../../assets/Icons/CartIcon';
import { useAppSelector } from '../../../store/hooks';
import { getCartProducts } from '../../../store/modules/cart/selectors';

const CartIconCounter = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const cartItems = useAppSelector(getCartProducts);
  const totalItems = cartItems.length;
  const paddingBase = pathname === '/' ? '7px' : '15px';

  return (
    <Box
      marginLeft={{ base: '0', md: '16px' }}
      width="13px"
      onClick={() => navigate('/cart')}
      cursor="pointer"
      data-testid="cart-icon-container"
    >
      {totalItems > 0 && (
        <Tooltip label={totalItems} backgroundColor="grey700" fontSize="10px" borderRadius="8px">
          <Flex
            width="13px"
            height="13px"
            backgroundColor="#FFA865"
            borderRadius="50%"
            fontSize="8px"
            fontWeight="600"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={{ base: paddingBase, md: '15px', xl: '80px' }}
            right={{ base: '18px', xl: '109px' }}
            data-testid="cart-icon-number"
          >
            <Text>{totalItems > 9 ? '9+' : totalItems}</Text>
          </Flex>
        </Tooltip>
      )}
      <CartIcon dataTestId="shopping-cart" />
    </Box>
  );
};

export default CartIconCounter;
