import * as React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Props } from '../Types';

export const PlusbelleBrand = ({ dataTestId }: Props) => {
  const { breakPoint } = useBreakpoint();

  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
      width={breakPoint === 'xl' ? '100' : '48'}
      height={breakPoint === 'xl' ? '100' : '51'}
    >
      <path
        d="M0.000599877 63.3783C0.491899 60.028 0.956804 56.7502 1.4565 53.4779C2.02458 49.7557 2.66585 46.0436 3.18775 42.3148C3.38031 40.9381 3.8914 40.3052 5.3803 40.4144C7.35569 40.559 9.35088 40.43 11.3287 40.5596C13.9231 40.7293 15.9189 41.9897 16.8032 44.4732C17.6862 46.9524 16.8457 49.202 15.1361 51.0604C13.0131 53.3687 10.1565 54.2032 7.16253 54.6501C7.00356 54.6735 6.8212 54.5391 6.25432 54.3375C7.02816 53.9476 7.49006 53.6687 7.98496 53.4731C11.0539 52.2614 13.4144 49.0286 13.3485 46.0964C13.2747 42.8007 11.0677 40.9663 7.83619 41.6267C7.43547 41.7089 6.93878 42.3772 6.86439 42.8355C5.90399 48.7545 5.00297 54.6831 4.10736 60.6122C3.73543 63.0735 2.69045 63.8582 0 63.3777L0.000599877 63.3783Z"
        fill="black"
      />
      <path
        d="M52.6933 48.8904C54.1954 48.4255 55.5127 47.759 56.875 47.6504C59.6777 47.4273 61.8678 49.3991 62.1647 52.1819C62.4677 55.0229 61.9314 57.7134 60.4383 60.1723C58.0208 64.1536 53.1132 65.2634 49.3466 62.7343C48.2872 62.0229 47.8331 61.1908 48.0532 59.8621C49.0394 53.9041 49.9405 47.9318 50.9093 41.9702C50.9872 41.4915 51.3484 41.059 51.5775 40.6055C52.0346 40.9438 52.7293 41.1921 52.8984 41.6397C53.232 42.5233 53.4575 43.5281 53.3963 44.4615C53.2974 45.9768 52.9314 47.4741 52.6933 48.8904ZM59.2475 53.3403C59.2086 53.0061 59.187 52.2563 59.0244 51.5388C58.5487 49.4381 56.8906 48.6744 55.121 49.9096C54.1918 50.558 53.2368 51.5472 52.893 52.5814C52.058 55.0919 51.4989 57.705 51.0118 60.3108C50.9177 60.8159 51.5127 61.7199 52.0328 62.0205C53.7881 63.0343 55.839 62.3648 57.1768 60.4554C58.5817 58.4506 59.181 56.1789 59.2475 53.3403Z"
        fill="black"
      />
      <path
        d="M24.3376 47.3906C25.944 47.8633 26.6153 48.6564 26.4377 50.2886C26.1804 52.6521 25.7821 55.0013 25.3934 57.3486C24.9944 59.7559 25.6657 61.372 27.301 61.7643C28.9992 62.1716 30.7521 60.8723 31.2386 58.6023C31.8306 55.8411 32.2362 53.0397 32.7317 50.2574C32.827 49.7229 32.8228 49.0907 33.1204 48.6954C33.4263 48.2892 34.1078 47.8291 34.5097 47.9173C34.9992 48.0247 35.6297 48.6258 35.7292 49.1105C35.881 49.8495 35.6806 50.6809 35.5409 51.4566C35.031 54.285 34.4161 57.096 33.9842 59.9359C33.7952 61.1782 33.9524 62.4727 33.9524 64.3054C32.7994 63.4745 31.9494 62.8621 31.0586 62.2196C30.2098 62.6749 29.2446 63.3947 28.16 63.7355C25.2752 64.6407 22.1672 62.6809 22.3628 59.7631C22.6333 55.7277 23.4732 51.7295 24.0814 47.7176C24.1 47.5952 24.2548 47.4938 24.3388 47.3906H24.3376Z"
        fill="black"
      />
      <path
        d="M66.1573 56.337C65.6444 58.0497 65.8892 59.6435 67.0787 60.9105C69.1033 63.0658 71.2197 61.7599 73.2833 60.6921C73.3619 60.7905 73.4404 60.8883 73.519 60.9867C73.2797 61.3892 73.1171 61.8745 72.7884 62.181C70.894 63.9482 68.657 64.226 66.2941 63.4053C64.0884 62.6393 63.2971 60.8373 63.1387 58.6519C62.8028 54.0185 65.1561 49.5302 68.822 48.1199C69.849 47.7246 71.0343 47.5992 72.1465 47.6016C73.8855 47.6052 75.4968 48.6712 75.7944 49.9297C76.2071 51.6718 75.7836 53.3832 74.2611 54.2584C72.6798 55.1678 70.8244 55.6411 69.0439 56.1444C68.1645 56.3934 67.1825 56.2806 66.1561 56.337H66.1573ZM66.5646 54.5866C66.642 54.7413 66.7194 54.8955 66.7968 55.0503C68.2857 54.6747 69.8412 54.4564 71.2365 53.8613C71.9995 53.5362 72.7086 52.6628 73.0325 51.8697C73.2707 51.286 73.0877 50.1523 72.6504 49.7695C72.1495 49.3316 71.0121 49.1223 70.4075 49.391C68.1759 50.3826 67.2821 52.4558 66.5646 54.586V54.5866Z"
        fill="black"
      />
      <path
        d="M97.7446 60.547C97.2443 62.2369 96.2599 62.8985 95.1909 63.3076C91.3775 64.7671 87.0044 63.1997 87.1976 57.9663C87.3542 53.7258 88.6247 50.0132 92.8406 48.153C93.9576 47.6605 95.3517 47.5777 96.5971 47.6533C98.0104 47.7396 99.3241 48.3797 99.8052 49.9016C100.298 51.4601 99.8292 52.8524 98.7122 53.9886C97.2785 55.4469 95.3823 55.8626 93.4501 56.1505C93.2545 56.1799 93.0392 56.1349 92.8592 56.1991C91.8136 56.5716 89.9132 55.3875 89.9744 57.7888C90.035 60.1709 91.4879 62.0221 93.4891 61.9999C94.3373 61.9903 95.1891 61.5962 96.023 61.3263C96.4975 61.1727 96.9384 60.9171 97.7452 60.5464H97.7446V60.547ZM90.3529 54.3605C90.4939 54.5254 90.6355 54.691 90.7765 54.856C92.6853 54.7852 94.5839 54.616 96.1843 53.3629C97.3925 52.4169 97.687 50.7462 96.8874 49.8176C96.0644 48.8626 94.5743 48.7798 93.4747 49.9826C92.2749 51.2957 91.3805 52.889 90.3535 54.3599H90.3529V54.3605Z"
        fill="black"
      />
      <path
        d="M51.5312 15.0006C53.44 17.3329 53.3398 20.09 51.4634 22.2645C50.7843 23.0515 50.0027 23.7522 49.3446 24.5554C47.8923 26.3275 46.9703 28.3239 47.9337 30.5932C48.8353 32.7162 50.686 33.4606 53.0513 33.2399C50.9295 34.7965 47.3968 34.4786 45.2625 32.6454C43.2823 30.9447 42.6482 27.7504 44.2391 25.3179C45.4628 23.4469 47.1401 21.8422 48.7646 20.2729C50.2301 18.8572 51.6074 17.4841 51.5318 15H51.5312V15.0006Z"
        fill="black"
      />
      <path
        d="M36.1936 61.6048C37.2014 61.9383 38.1486 62.3492 39.139 62.5592C40.9825 62.9509 42.5085 62.2202 43.1228 60.7835C43.7923 59.2173 43.4192 58.1789 41.6993 56.8627C40.7557 56.1411 39.7305 55.5088 38.8673 54.702C36.9765 52.9348 37.1006 50.2443 39.2398 48.8202C41.5721 47.2671 44.1408 47.056 46.7383 48.2305C47.124 48.4051 47.5931 48.723 47.7083 49.0775C47.8318 49.4573 47.5823 49.9576 47.4953 50.4057C47.157 50.3115 46.8109 50.2383 46.4809 50.1195C45.4023 49.7326 44.3244 49.014 43.256 49.032C42.4132 49.0458 41.1552 49.7902 40.8715 50.5179C40.5901 51.2395 41.0892 52.4944 41.6255 53.2443C42.2038 54.0523 43.2266 54.5406 44.049 55.1771C45.6441 56.4116 46.9278 57.8052 46.4527 60.0583C45.9782 62.3072 44.334 63.2814 42.3106 63.8255C40.5391 64.3024 38.7749 64.3756 37.1084 63.4806C36.4348 63.1189 35.6831 62.6792 36.1936 61.6048Z"
        fill="black"
      />
      <path
        d="M79.2599 63.8549C77.1963 63.4944 76.0949 62.3864 76.2551 60.5856C76.4866 57.9767 76.9743 55.3895 77.3624 52.795C77.8837 49.3127 78.3864 45.8268 78.9683 42.3548C79.0697 41.7495 79.522 41.2024 79.8123 40.6289C80.3654 41.0884 81.2964 41.4777 81.3966 42.0206C81.6402 43.3445 81.675 44.7651 81.4812 46.1016C80.8129 50.714 79.9689 55.3007 79.288 59.9114C79.1039 61.1603 79.2599 62.4602 79.2599 63.8561V63.8549Z"
        fill="black"
      />
      <path
        d="M81.9403 59.6096C82.2642 57.4057 82.6643 54.558 83.104 51.7164C83.6121 48.4333 84.1394 45.1526 84.7009 41.8779C84.7759 41.4393 85.1004 41.044 85.3092 40.6289C85.7333 40.8497 86.3686 40.9666 86.5389 41.3128C86.9577 42.1628 87.5131 43.1658 87.3962 44.023C86.6715 49.3289 85.7555 54.6084 84.9577 59.9048C84.8125 60.8694 84.9295 61.8723 84.8995 62.8573C84.8899 63.1729 84.8977 63.6444 84.7171 63.759C84.506 63.8927 84.0602 63.7908 83.7795 63.6576C82.3524 62.9821 81.7927 61.7956 81.9391 59.6102H81.9403V59.6096Z"
        fill="black"
      />
      <path
        d="M22.346 43.625C21.7689 47.229 21.1396 50.6897 20.6807 54.1726C20.289 57.1438 20.0821 60.1396 19.8169 63.127C19.7449 63.941 19.3694 63.9884 18.7167 63.725C17.3076 63.1564 16.3982 61.5241 16.673 59.806C17.6106 53.9423 18.5668 48.0815 19.559 42.2273C19.6543 41.6646 20.0515 41.1529 20.3088 40.6172C20.8367 41.0377 21.4924 41.3718 21.8541 41.9051C22.1894 42.3988 22.211 43.1061 22.346 43.6244V43.625Z"
        fill="black"
      />
      <path
        d="M54.7915 20.4805C56.9691 22.4499 58.2768 27.454 55.6217 30.3316C54.2018 31.8703 52.3608 32.2153 50.8707 31.1271C49.4754 30.1085 49.1827 27.9981 50.209 26.3137C50.3632 26.0611 50.5366 25.8182 50.7207 25.586C52.0495 23.9136 53.383 22.2453 54.7915 20.4805Z"
        fill="black"
      />
    </svg>
  );
};
