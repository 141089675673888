import { persistReducer, PersistState } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateVoucherDifferenceErrorResponse } from '../../../interfaces/voucher';
import { clearAuthData } from '../auth';
import { CartItem, InitialState } from './types';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: { products: [], _persist: {} as PersistState } as InitialState,
  reducers: {
    addItem: (state: InitialState, { payload }: PayloadAction<CartItem>) => {
      const existingItem = state.products.find(
        (product) => product.articleCode === payload.articleCode,
      );
      if (existingItem) {
        existingItem.quantity += payload.quantity;
      } else {
        state.products.push(payload);
      }
    },
    replaceQuantity: (state: InitialState, { payload }: PayloadAction<CartItem>) => {
      const existingItem = state.products.find(
        (product) => product.articleCode === payload.articleCode,
      );
      if (existingItem) {
        existingItem.quantity = payload.quantity;
      }
    },
    incrementQuantity: (state: InitialState, { payload }: PayloadAction<CartItem>) => {
      const product = state.products.find((product) => product.articleCode === payload.articleCode);
      if (product) {
        product.quantity++;
      }
    },
    removeItem: (state: InitialState, { payload }: PayloadAction<CartItem>) => {
      state.products = state.products.filter(
        (product) => product.articleCode !== payload.articleCode,
      );
    },
    decrementQuantity: (state: InitialState, { payload }: PayloadAction<CartItem>) => {
      const product = state.products.find((product) => product.articleCode === payload.articleCode);
      if (product) {
        product.quantity -= 1;
      }
    },
    clearCart: (state) => {
      state.products = [];
    },
    applyDifferences: (
      state,
      { payload }: PayloadAction<Pick<CreateVoucherDifferenceErrorResponse, 'data'>>,
    ) => {
      const { differentProducts, removedProducts } = payload.data;
      const newProducts = [...state.products].reduce<CartItem[]>((acc, product) => {
        if (removedProducts.includes(product.articleCode)) {
          return acc;
        }
        const differentProduct = differentProducts[product.articleCode];
        if (differentProduct) {
          acc.push({
            ...product,
            iDArticlePrice: differentProduct.articlePriceId,
            priceList: differentProduct.priceList,
            existence: +differentProduct.existence,
            pendingToReceive: +differentProduct.pendingToReceive,
          });
          return acc;
        }
        acc.push(product);
        return acc;
      }, []);

      state.products = newProducts;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData.type, (state) => {
      state.products = [];
    });
  },
});

const persistConfig = {
  key: 'cart',
  storage,
  stateReconciler: autoMergeLevel2<InitialState>,
};

export const cartReducer = persistReducer(persistConfig, cartSlice.reducer);
export const {
  addItem,
  replaceQuantity,
  incrementQuantity,
  removeItem,
  decrementQuantity,
  clearCart,
  applyDifferences,
} = cartSlice.actions;
