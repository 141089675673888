import React from 'react';

interface Props {
  dataTestId: string;
}
const HandIcon = ({ dataTestId }: Props) => (
  <svg
    data-testid={dataTestId}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.375 1.25C9.20924 1.25 9.05027 1.31585 8.93306 1.43306C8.81585 1.55027 8.75 1.70924 8.75 1.875V9.375C8.75 9.72018 8.47018 10 8.125 10C7.77982 10 7.5 9.72018 7.5 9.375V3.125C7.5 2.95924 7.43415 2.80027 7.31694 2.68306C7.19973 2.56585 7.04076 2.5 6.875 2.5C6.70924 2.5 6.55027 2.56585 6.43306 2.68306C6.31585 2.80027 6.25 2.95924 6.25 3.125V10C6.25 10.3452 5.97018 10.625 5.625 10.625C5.27982 10.625 5 10.3452 5 10V5.625C5 5.45924 4.93415 5.30027 4.81694 5.18306C4.69973 5.06585 4.54076 5 4.375 5C4.20924 5 4.05027 5.06585 3.93306 5.18306C3.81585 5.30027 3.75 5.45924 3.75 5.625V12.5C3.75 12.8452 3.47018 13.125 3.125 13.125C2.77982 13.125 2.5 12.8452 2.5 12.5V5.625C2.5 5.12772 2.69754 4.65081 3.04917 4.29917C3.40081 3.94754 3.87772 3.75 4.375 3.75C4.58996 3.75 4.80112 3.78691 5 3.85723V3.125C5 2.62772 5.19754 2.15081 5.54917 1.79917C5.90081 1.44754 6.37772 1.25 6.875 1.25C7.11391 1.25 7.34811 1.29559 7.56598 1.38196C7.65108 1.06975 7.81635 0.781998 8.04917 0.549175C8.40081 0.197544 8.87772 0 9.375 0C9.87228 0 10.3492 0.197544 10.7008 0.549175C11.0525 0.900805 11.25 1.37772 11.25 1.875V1.98223C11.4489 1.91191 11.66 1.875 11.875 1.875C12.3723 1.875 12.8492 2.07254 13.2008 2.42417C13.5525 2.77581 13.75 3.25272 13.75 3.75V12.5C13.75 12.8452 13.4702 13.125 13.125 13.125C12.7798 13.125 12.5 12.8452 12.5 12.5V3.75C12.5 3.58424 12.4342 3.42527 12.3169 3.30806C12.1997 3.19085 12.0408 3.125 11.875 3.125C11.7092 3.125 11.5503 3.19085 11.4331 3.30806C11.3158 3.42527 11.25 3.58424 11.25 3.75V9.41406C11.25 9.75924 10.9702 10.0391 10.625 10.0391C10.2798 10.0391 10 9.75924 10 9.41406V1.875C10 1.70924 9.93415 1.55027 9.81694 1.43306C9.69973 1.31585 9.54076 1.25 9.375 1.25Z"
      fill="#3377FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5915 8.06605C15.7198 7.57074 14.5128 7.79811 13.9989 8.77465L16.5915 8.06605ZM15.116 9.33702L13.7022 12.7399C13.5697 13.0587 13.204 13.2097 12.8852 13.0773C12.5664 12.9448 12.4154 12.5791 12.5478 12.2603L13.9748 8.82594C13.982 8.80849 13.9901 8.79138 13.9989 8.77465M15.116 9.33702C15.2664 9.08043 15.6417 8.964 15.974 9.15288L15.9741 9.15294C16.1311 9.24213 16.2019 9.35105 16.2326 9.47544C16.2674 9.61655 16.2601 9.82543 16.1601 10.0963L16.1596 10.0978L14.1056 15.709C13.7365 16.5854 13.2509 17.3262 12.505 17.8557C11.7581 18.3861 10.6858 18.7501 9.0625 18.7501C7.48648 18.7501 6.18077 18.2292 5.26448 17.234C4.34366 16.2339 3.75 14.6825 3.75 12.5001C3.75 12.1549 3.47018 11.8751 3.125 11.8751C2.77982 11.8751 2.5 12.1549 2.5 12.5001C2.5 14.9037 3.15634 16.7898 4.3449 18.0807C5.53798 19.3765 7.20102 20.0001 9.0625 20.0001C10.8767 20.0001 12.2206 19.5907 13.2287 18.875C14.2341 18.1611 14.8411 17.1878 15.2639 16.1793C15.2676 16.1704 15.2711 16.1615 15.2744 16.1525L17.3328 10.529C17.3329 10.5288 17.333 10.5287 17.333 10.5285C17.4931 10.0944 17.5568 9.62396 17.4462 9.17597C17.3315 8.71115 17.0404 8.32107 16.5916 8.06611"
      fill="#3377FF"
    />
  </svg>
);

export default HandIcon;
