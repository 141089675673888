import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Flex, Input, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import AlertIcon from '../../../../assets/Icons/AlertIcon';
import { ModalType } from '../../../../enum/ModalType';
import { Banner } from '../../../../interfaces/banner';
import { addBanner } from '../../../../services/banner';
import { useAppDispatch } from '../../../../store/hooks';
import { showModal } from '../../../../store/modules/ui';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import FileInput from '../../../shared/FileInput/FileInput';
import GoBack from '../../../shared/GoBack/GoBack';
import { addBannerValidation } from './validations';

const AddBanner = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    getValues,
    setValue,
    trigger,
    formState: { isValid, isDirty, errors },
  } = useForm<Banner>({
    defaultValues: {
      desktopImage: '',
      mobileImage: '',
      title: '',
    },
    resolver: yupResolver(addBannerValidation),
  });

  const emptyFieldsAlert =
    errors.mobileImage ||
    errors.desktopImage ||
    (errors.title && errors.title.message !== textContent.banners.validations.titleMax);

  const toast = useToast();

  const onSubmit = async () => {
    try {
      const bannerRes = await addBanner(getValues());
      toast({
        title: textContent.banners.addBanner,
        description: bannerRes.message,
        status: 'success',
        variant: 'subtle',
        duration: 3000,
      });
    } catch (error) {
      if (error instanceof Error)
        toast({
          title: textContent.banners.addBanner,
          description: error.message,
          status: 'error',
          variant: 'subtle',
          duration: 3000,
        });
    }
  };

  return (
    <Flex direction="column" marginBottom="70px">
      <GoBack title={textContent.banners.addBanner} />
      <Flex marginLeft="112px" marginTop="24px" width="600px" direction="column">
        <Flex direction="column">
          <Text
            data-testid="title"
            fontSize="16px"
            fontWeight="500"
          >{`${textContent.title}*`}</Text>
          <Input
            data-testid="title-input"
            borderColor={errors.title ? '#E53E3E' : '#E2E8F0'}
            onBlur={(e) =>
              setValue('title', e.target.value, { shouldDirty: true, shouldValidate: true })
            }
            marginTop="8px"
            placeholder={textContent.title}
          />
        </Flex>
        <Flex direction="column" marginTop="24px">
          <Text data-testid="desktop-banner-title" fontSize="14px" fontWeight="700">
            {textContent.banners.desktopBanner}
          </Text>
          <Flex
            justify="center"
            align="center"
            marginTop="8px"
            border="1px solid"
            borderColor={errors.desktopImage ? '#E53E3E' : '#E2E8F0'}
            borderRadius="8px"
            width="full"
            height="200px"
          >
            <FileInput
              iconTestId="desktop-image-input-icon"
              inputTestId="desktop-image-input-element"
              havePreview
              changeHandler={(value) => setValue('desktopImage', value, { shouldValidate: true })}
              width="600px"
              height="200px"
              leftPosition="400px"
              maxSizeBytes={150 * 1024}
            />
          </Flex>
        </Flex>
        <Flex direction="column" marginTop="24px">
          <Text data-testid="mobile-banner-title" fontSize="14px" fontWeight="700">
            {textContent.banners.mobileBanner}
          </Text>
          <Flex
            justify="center"
            align="center"
            marginTop="8px"
            border="1px solid"
            borderColor={errors.mobileImage ? '#E53E3E' : '#E2E8F0'}
            borderRadius="8px"
            width="160px"
            height="160px"
          >
            <FileInput
              iconTestId="mobile-image-input-icon"
              inputTestId="mobile-image-input-element"
              havePreview
              changeHandler={(value) => setValue('mobileImage', value, { shouldValidate: true })}
              width="160px"
              height="160px"
              leftPosition="180px"
              maxSizeBytes={150 * 1024}
            />
          </Flex>
        </Flex>
        <Flex height="48px" flexDirection="column" marginTop="24px">
          {emptyFieldsAlert && (
            <Flex alignItems="center" gap="8px">
              <AlertIcon color="errorRed" dataTestId="alert-icon" />
              <Text
                data-testid="required-fields-error"
                verticalAlign="baseline"
                fontSize="14px"
                fontWeight="500"
                color="errorRed"
              >
                {textContent.banners.validations.requiredFields}
              </Text>
            </Flex>
          )}
          {errors.title?.message === textContent.banners.validations.titleMax && (
            <Flex alignItems="center" gap="8px">
              <AlertIcon color="errorRed" dataTestId="alert-icon" />
              <Text
                data-testid="long-title-error"
                verticalAlign="baseline"
                fontSize="14px"
                fontWeight="500"
                color="errorRed"
              >
                {textContent.banners.validations.titleMax}
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex justify="end" gap="24px" marginTop="24px" marginBottom="31px">
          <Button
            _hover={{ backgroundColor: 'lightPurple' }}
            marginTop="8px"
            variant="outline"
            width="155px"
            data-testid="cancel-button"
            height="40px"
            borderColor="purple"
            onClick={() => navigate('/admin/banners')}
            alignSelf="end"
            backgroundColor="white"
            content={textContent.cancel}
            color="purple"
          />
          <Button
            _hover={{ backgroundColor: 'lightPurple' }}
            marginTop="8px"
            onClick={() => {
              trigger();
              if (isValid && isDirty)
                dispatch(
                  showModal({
                    type: ModalType.CONFIRM_BANNER_SUBMIT,
                    callback: onSubmit,
                  }),
                );
            }}
            _disabled={{
              bg: 'disabledPurple',
              cursor: 'default',
              _hover: { bg: 'disabledPurple' },
            }}
            alignSelf="end"
            width="155px"
            data-testid="save-button"
            height="40px"
            backgroundColor="purple"
            content={textContent.saveChanges}
            color="white"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddBanner;
