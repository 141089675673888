import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Skeleton } from '@chakra-ui/react';

import { SkeletonProps } from '../../../interfaces/skeletons';

const ProductCardSkeleton = ({ array, onClick }: SkeletonProps) => {
  return (
    <>
      {onClick && (
        <Flex
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          backgroundColor="background"
        >
          <Flex
            alignItems="flex-start"
            textAlign="start"
            margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
          >
            <ArrowBackIcon
              boxSize={8}
              marginRight="20px"
              onClick={onClick}
              cursor="pointer"
              data-testid="arrow-icon"
            />
            <Box>
              <Skeleton marginBottom="10px" width="80px" height="20px" />
              <Skeleton width="50px" height="18px" />
            </Box>
          </Flex>
        </Flex>
      )}
      <Box
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        minHeight="100%"
        backgroundColor="grey50"
      >
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
          data-testid="product-card-skeleton-container"
        >
          {Array(array)
            .fill(undefined)
            .map((_, index) => (
              <Flex justifyContent={'center'} key={index} margin="20px 0">
                <Skeleton
                  width={{ base: '135px ', xl: '185px' }}
                  height={{ base: '250px', xl: '315px' }}
                  borderRadius="8px"
                  data-testid={`product-card-skeleton-${index}`}
                />
              </Flex>
            ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProductCardSkeleton;
