import { ProductTableType, Status } from '../interfaces/products';

export const SkeletonCells = [
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
  {
    width: '150px',
    height: '20px',
  },
];

export const productStatus = ['Completos', 'Incompletos'];

export const dropdownColor = (value: string) => {
  return value ? '#001133' : '#0011337f';
};

export const updateFilter = <T>(newFilter: Record<string, string>, filters: T) => {
  return { ...filters, [newFilter.key]: newFilter.value };
};

export const isIncompleteProducts = (products: ProductTableType[]) => {
  let isIncomplete = false;
  products.forEach((product) => {
    if (product.dataIsComplete === Status.INCOMPLETE) isIncomplete = true;
  });
  return isIncomplete;
};

// export const isIncompleteProducts = (products: ProductTableType[]) => {
//   return products.some((product) => product.status === Status.INCOMPLETE);
// };
