import React from 'react';
import { Text } from '@chakra-ui/react';

import { FormattedStatus } from '../../../../interfaces/voucher';

const StatusText = (value: FormattedStatus) => {
  const colors = {
    [FormattedStatus.Pagado]: '#C6F6D5',
    [FormattedStatus.Pendiente]: '#FFDDC3',
    [FormattedStatus.Anulada]: '#FED7D7',
  };
  return (
    <Text
      paddingX="4px"
      backgroundColor={colors[value]}
      display="inline"
      borderRadius="2px"
      fontSize="12px"
      fontWeight="700"
      lineHeight="16px"
    >
      {value}
    </Text>
  );
};

export default StatusText;
