import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

interface FeaturedCategoryProps {
  children: React.ReactNode;
  subCategory: string | undefined;
  code: string;
  bgColor: string;
}

const FeaturedCategory = ({ children, subCategory, code, bgColor }: FeaturedCategoryProps) => {
  const navigate = useNavigate();

  return (
    <Box
      width={{ base: '80%', md: '20%' }}
      height="161px"
      flexWrap="wrap"
      margin={{ md: '15px 0px' }}
      cursor="pointer"
      onClick={() => navigate(code)}
    >
      <Flex
        width="100%"
        height="129px"
        alignItems="center"
        backgroundColor={bgColor}
        borderRadius="8px 8px 0 0"
        justifyContent="center"
        boxShadow="0px 4px 8px 0px rgba(0, 17, 51, 0.15)"
      >
        {children}
      </Flex>
      <Text
        color="darkBlue"
        width="100%"
        fontWeight="500"
        textAlign="center"
        fontSize={{ base: '14px', md: '16px' }}
        backgroundColor="white"
        borderRadius="0 0 8px 8px"
        boxShadow="0px 4px 8px 0px rgba(0, 17, 51, 0.15)"
      >
        {subCategory}
      </Text>
    </Box>
  );
};

export default FeaturedCategory;
