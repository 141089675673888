import { CreateVoucherProduct, VoucherProductWithStock } from '../interfaces/voucher';
import { CartItem } from '../store/modules/cart/types';

export const replaceQuantity = (
  products: VoucherProductWithStock[],
  voucherProduct: VoucherProductWithStock,
  setVoucher: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>,
) => {
  const newArr = products.reduce<VoucherProductWithStock[]>((acc, product) => {
    if (product.articleCode === voucherProduct.articleCode) {
      acc.push({ ...product, quantity: voucherProduct.quantity });
      return acc;
    }
    acc.push(product);
    return acc;
  }, []);
  setVoucher(newArr);
};

export const incrementQuantity = (
  products: VoucherProductWithStock[],
  voucherProduct: VoucherProductWithStock,
  setVoucher: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>,
) => {
  const product = products.find((product) => product.articleCode === voucherProduct.articleCode);
  const productIndex = products.findIndex(
    (product) => product.articleCode === voucherProduct.articleCode,
  );
  const arr = products.filter((product) => product.articleCode !== voucherProduct.articleCode);

  if (product?.quantity != undefined && product?.quantity >= 0) {
    const obj = { ...product, quantity: product.quantity + 1 };
    arr.splice(productIndex, 0, obj);
    setVoucher(arr);
  }
};

export const decrementQuantity = (
  products: VoucherProductWithStock[],
  voucherProduct: VoucherProductWithStock,
  setVoucher: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>,
) => {
  const product = products.find((product) => product.articleCode === voucherProduct.articleCode);
  const productIndex = products.findIndex(
    (product) => product.articleCode === voucherProduct.articleCode,
  );
  const arr = products.filter((product) => product.articleCode !== voucherProduct.articleCode);

  if (product?.quantity) {
    const obj = { ...product, quantity: product.quantity - 1 };
    arr.splice(productIndex, 0, obj);
    setVoucher(arr);
  }
};

export const removeItem = (
  products: VoucherProductWithStock[],
  voucherProduct: VoucherProductWithStock,
  setVoucher: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>,
) => {
  setVoucher(products.filter((product) => product.articleCode !== voucherProduct.articleCode));
};

export const getTotalPriceVouchersCart = (products: VoucherProductWithStock[]) => {
  return products.reduce((acc, product) => acc + product.unitPrice * (product.quantity ?? 0), 0);
};

const isCartItem = (product: CartItem | VoucherProductWithStock): product is CartItem => {
  return 'iDArticlePrice' in product;
};

export const parseVoucherProducts = (
  products: CartItem[] | VoucherProductWithStock[],
): CreateVoucherProduct[] => {
  return products.map((product) => {
    if (isCartItem(product)) {
      return {
        articlePriceId: product.iDArticlePrice,
        articleDesc: product.description,
        articleCode: product.articleCode,
        unitPrice: product.priceList.toString(),
        quantity: product.quantity.toString(),
      };
    }
    return {
      articlePriceId: product.articlePriceId,
      articleDesc: product.description,
      articleCode: product.articleCode,
      quantity: product.quantity.toString(),
      unitPrice: product.unitPrice.toString(),
    };
  });
};
