import React from 'react';

const DeleteVoucherIcon = () => (
  <svg
    width="172"
    height="116"
    viewBox="0 0 172 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="delete-voucher-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.969 26.5596C167.928 26.5596 171.137 29.7688 171.137 33.7276C171.137 37.6863 167.928 40.8956 163.969 40.8956H123.009C126.968 40.8956 130.177 44.1048 130.177 48.0636C130.177 52.0224 126.968 55.2316 123.009 55.2316H145.537C149.496 55.2316 152.705 58.4408 152.705 62.3996C152.705 66.3584 149.496 69.5676 145.537 69.5676H135.119C130.127 69.5676 126.081 72.7768 126.081 76.7356C126.081 79.3748 128.129 81.7641 132.225 83.9036C136.184 83.9036 139.393 87.1128 139.393 91.0716C139.393 95.0304 136.184 98.2396 132.225 98.2396H47.2329C43.2741 98.2396 40.0649 95.0304 40.0649 91.0716C40.0649 87.1128 43.2741 83.9036 47.2329 83.9036H7.29691C3.33813 83.9036 0.128906 80.6944 0.128906 76.7356C0.128906 72.7768 3.33813 69.5676 7.29691 69.5676H48.2569C52.2157 69.5676 55.4249 66.3584 55.4249 62.3996C55.4249 58.4408 52.2157 55.2316 48.2569 55.2316H22.6569C18.6981 55.2316 15.4889 52.0224 15.4889 48.0636C15.4889 44.1048 18.6981 40.8956 22.6569 40.8956H63.6169C59.6581 40.8956 56.4489 37.6863 56.4489 33.7276C56.4489 29.7688 59.6581 26.5596 63.6169 26.5596H163.969ZM163.969 55.2316C167.928 55.2316 171.137 58.4408 171.137 62.3996C171.137 66.3584 167.928 69.5676 163.969 69.5676C160.01 69.5676 156.801 66.3584 156.801 62.3996C156.801 58.4408 160.01 55.2316 163.969 55.2316Z"
      fill="#EBF1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.36 25.5359L118.885 95.0069L119.74 101.977C120.016 104.222 118.419 106.266 116.174 106.541L56.2085 113.904C53.9632 114.18 51.9195 112.583 51.6438 110.338L42.4117 35.148C42.2739 34.0253 43.0722 33.0035 44.1948 32.8656C44.2019 32.8648 44.209 32.8639 44.2161 32.8631L49.1911 32.3051M53.2139 31.8538L57.911 31.3269L53.2139 31.8538Z"
      fill="white"
    />
    <path
      d="M110.598 25.3661C110.504 24.6822 109.874 24.2037 109.19 24.2975C108.506 24.3913 108.027 25.0218 108.121 25.7057L110.598 25.3661ZM118.885 95.0069L120.125 94.8546C120.125 94.8487 120.124 94.8429 120.123 94.8371L118.885 95.0069ZM119.74 101.977L120.981 101.824L119.74 101.977ZM116.174 106.541L116.326 107.782L116.174 106.541ZM56.2085 113.904L56.3608 115.145L56.2085 113.904ZM51.6438 110.338L52.8845 110.185L51.6438 110.338ZM42.4117 35.148L43.6524 34.9956L42.4117 35.148ZM44.2161 32.8631L44.3555 34.1054L44.2161 32.8631ZM49.3304 33.5473C50.0165 33.4703 50.5103 32.8518 50.4333 32.1657C50.3564 31.4797 49.7378 30.9859 49.0518 31.0629L49.3304 33.5473ZM53.0746 30.6116C52.3885 30.6886 51.8947 31.3071 51.9717 31.9932C52.0487 32.6792 52.6672 33.173 53.3533 33.096L53.0746 30.6116ZM58.0504 32.5691C58.7364 32.4922 59.2302 31.8736 59.1533 31.1876C59.0763 30.5015 58.4578 30.0078 57.7717 30.0847L58.0504 32.5691ZM108.121 25.7057L117.646 95.1767L120.123 94.8371L110.598 25.3661L108.121 25.7057ZM117.644 95.1592L118.5 102.129L120.981 101.824L120.125 94.8546L117.644 95.1592ZM118.5 102.129C118.691 103.689 117.582 105.109 116.022 105.301L116.326 107.782C119.257 107.422 121.341 104.755 120.981 101.824L118.5 102.129ZM116.022 105.301L56.0562 112.663L56.3608 115.145L116.326 107.782L116.022 105.301ZM56.0562 112.663C54.4961 112.855 53.0761 111.746 52.8845 110.185L50.4032 110.49C50.763 113.421 53.4303 115.505 56.3608 115.145L56.0562 112.663ZM52.8845 110.185L43.6524 34.9956L41.171 35.3003L50.4032 110.49L52.8845 110.185ZM43.6524 34.9956C43.5987 34.5582 43.9097 34.16 44.3472 34.1063L44.0425 31.625C42.2346 31.8469 40.949 33.4924 41.171 35.3003L43.6524 34.9956ZM44.3472 34.1063C44.3499 34.106 44.3527 34.1057 44.3555 34.1054L44.0768 31.6209C44.0654 31.6222 44.0539 31.6236 44.0425 31.625L44.3472 34.1063ZM44.3555 34.1054L49.3304 33.5473L49.0518 31.0629L44.0768 31.6209L44.3555 34.1054ZM53.3533 33.096L58.0504 32.5691L57.7717 30.0847L53.0746 30.6116L53.3533 33.096Z"
      fill="#5C92FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.768 29.908L115.39 92.8673L116.165 99.1836C116.415 101.218 114.988 103.068 112.977 103.315L59.2762 109.909C57.2655 110.155 55.4329 108.706 55.1831 106.671L46.8316 38.654C46.697 37.5577 47.4766 36.5598 48.573 36.4252L55.2639 35.6037"
      fill="#EBF1FF"
    />
    <path
      d="M62.4824 15.2002C62.4824 13.6814 63.7136 12.4502 65.2324 12.4502H112.018C112.747 12.4502 113.447 12.7397 113.962 13.255L127.784 27.0686C128.3 27.5844 128.59 28.2841 128.59 29.0138V93.2162C128.59 94.735 127.359 95.9662 125.84 95.9662H65.2324C63.7136 95.9662 62.4824 94.735 62.4824 93.2162V15.2002Z"
      fill="white"
      stroke="#5C92FF"
      strokeWidth="2.5"
    />
    <path
      d="M112.432 13.6602V25.5357C112.432 27.2323 113.807 28.6077 115.504 28.6077H123.628"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M72.3203 27.8398H100.48" stroke="#5C92FF" strokeWidth="4.5" strokeLinecap="round" />
    <path
      d="M96.6657 59.2003L102.103 53.763C103.351 52.5147 103.351 50.5048 102.103 49.2565C100.855 48.0082 98.8449 48.0082 97.5967 49.2565L92.1593 54.6939L86.7219 49.2565C85.4737 48.0082 83.4638 48.0082 82.2155 49.2565C80.9672 50.5048 80.9672 52.5147 82.2155 53.763L87.6528 59.2003L82.2155 64.6377C80.9672 65.8859 80.9672 67.8959 82.2155 69.1441C83.4638 70.3924 85.4737 70.3924 86.7219 69.1441L92.1593 63.7068L97.5967 69.1441C98.8449 70.3924 100.855 70.3924 102.103 69.1441C103.351 67.8959 103.351 65.8859 102.103 64.6377L96.6657 59.2003Z"
      fill="#FF923F"
    />
    <path
      d="M18.341 99.52L19.6204 98.2406C19.9141 97.9469 19.9141 97.474 19.6204 97.1802C19.3267 96.8865 18.8538 96.8865 18.5601 97.1802L17.2807 98.4596L16.0013 97.1802C15.7076 96.8865 15.2347 96.8865 14.941 97.1802C14.6473 97.474 14.6473 97.9469 14.941 98.2406L16.2204 99.52L14.941 100.799C14.6473 101.093 14.6473 101.566 14.941 101.86C15.2347 102.153 15.7076 102.153 16.0013 101.86L17.2807 100.58L18.5601 101.86C18.8538 102.153 19.3267 102.153 19.6204 101.86C19.9141 101.566 19.9141 101.093 19.6204 100.799L18.341 99.52Z"
      fill="#C0D5FF"
    />
    <path
      d="M26.0207 104.64L27.3001 103.361C27.5938 103.067 27.5938 102.594 27.3001 102.3C27.0064 102.007 26.5335 102.007 26.2398 102.3L24.9604 103.58L23.681 102.3C23.3873 102.007 22.9144 102.007 22.6207 102.3C22.327 102.594 22.327 103.067 22.6207 103.361L23.9 104.64L22.6207 105.919C22.327 106.213 22.327 106.686 22.6207 106.98C22.9144 107.274 23.3873 107.274 23.681 106.98L24.9604 105.7L26.2398 106.98C26.5335 107.274 27.0064 107.274 27.3001 106.98C27.5938 106.686 27.5938 106.213 27.3001 105.919L26.0207 104.64Z"
      fill="#C0D5FF"
    />
    <path
      d="M143.78 21.4399L145.06 20.1605C145.354 19.8668 145.354 19.3939 145.06 19.1002C144.766 18.8065 144.293 18.8065 144 19.1002L142.72 20.3795L141.441 19.1002C141.147 18.8065 140.674 18.8065 140.38 19.1002C140.087 19.3939 140.087 19.8668 140.38 20.1605L141.66 21.4399L140.38 22.7193C140.087 23.013 140.087 23.4859 140.38 23.7796C140.674 24.0733 141.147 24.0733 141.441 23.7796L142.72 22.5002L144 23.7796C144.293 24.0733 144.766 24.0733 145.06 23.7796C145.354 23.4859 145.354 23.013 145.06 22.7193L143.78 21.4399Z"
      fill="#C0D5FF"
    />
    <path
      d="M127.14 3.51996L128.419 2.24058C128.713 1.94687 128.713 1.47395 128.419 1.18024C128.126 0.886534 127.653 0.886534 127.359 1.18024L126.08 2.45962L124.8 1.18024C124.506 0.886534 124.034 0.886534 123.74 1.18024C123.446 1.47395 123.446 1.94687 123.74 2.24058L125.019 3.51996L123.74 4.79934C123.446 5.09305 123.446 5.56597 123.74 5.85968C124.034 6.15339 124.506 6.15339 124.8 5.85968L126.08 4.5803L127.359 5.85968C127.653 6.15339 128.126 6.15339 128.419 5.85968C128.713 5.56597 128.713 5.09305 128.419 4.79934L127.14 3.51996Z"
      fill="#C0D5FF"
    />
  </svg>
);

export default DeleteVoucherIcon;
