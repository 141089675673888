import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { categoryData, getCategories } from '../../../store/modules/categories';
import { RootState } from '../../../store/types';
import { getCategoryImageComponent } from '../../../utils/categoriesToImages';
import { categoryOrder } from '../../../utils/categoryOrder';
import { cutString, transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import CategoryViewSkeleton from './CategoryViewSkeleton';
import FeaturedCategory from './FeaturedCategory';

const CategoryView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const categoriesList = useAppSelector((state: RootState) => categoryData(state));
  const categories = useMemo(() => categoryOrder(categoriesList.data), [categoriesList.data]);
  const categoryItem = useMemo(
    () => categories.find((category) => category.code === id),
    [categories, id],
  );
  const cardColors = ['orange400', 'blue200', 'violet200'];

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const renderCategoryImage = () => {
    if (categoryItem?.item.length) {
      return categoryItem.item.map((item, index) => {
        const bgColor = cardColors[index % cardColors.length];
        const ImageComponent = getCategoryImageComponent(categoryItem.code, item.code);
        return (
          <FeaturedCategory
            key={index}
            subCategory={transformString(cutString(item.subCategory))}
            code={`/category/${categoryItem.code}/${item.code}`}
            bgColor={bgColor}
          >
            <ImageComponent dataTestId={item.subCategory} />
          </FeaturedCategory>
        );
      });
    }

    if (categoryItem) {
      const ImageComponent = getCategoryImageComponent(categoryItem.code, categoryItem.code);

      return (
        <FeaturedCategory
          subCategory={transformString(cutString(categoryItem?.category))}
          code={categoryItem?.code as string}
          bgColor="orange400"
        >
          <ImageComponent dataTestId={'item.subCategory'} />
        </FeaturedCategory>
      );
    }

    return null;
  };

  const render = () => {
    if (categoriesList.loading) {
      return <CategoryViewSkeleton onClick={() => navigate('/')} />;
    } else if (categoriesList.error || !categoryItem) {
      return <ErrorScreen url={'/'} title={textContent.product.associatedProductsNotFound} />;
    }
    return (
      <Flex justifyContent="flex-start" height="100vh" direction="column" backgroundColor="white">
        <GoBack title={transformString(categoryItem?.category)} />
        <Box margin={{ md: '20px 112px' }}>
          <Text
            color="darkBlue"
            fontWeight="500"
            fontSize={{ base: '16px', md: '18px' }}
            margin={{ base: '20px 30px', md: '15px 0px' }}
            data-testid="shop-by-category"
          >
            {textContent.category.shopByCategory}
          </Text>
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            gap="15px"
            wrap="wrap"
          >
            {renderCategoryImage()}
          </Flex>
        </Box>
      </Flex>
    );
  };

  return render();
};

export default CategoryView;
