import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';

import NorpanLogo from '../../../../assets/Icons/NorpanIcon';
import textContent from '../../../../utils/textContent.json';
import LoginPopover from '../../LoginPopover/LoginPopover';

interface Props {
  isLoggedIn: boolean;
}

const AdminHeader = ({ isLoggedIn }: Props) => {
  const navigate = useNavigate();

  return (
    <Flex
      padding="0 115px 0 120px"
      data-testid="admin-header-container"
      background="linear-gradient(180.02deg, #3377FF 3.76%, rgba(51, 119, 255, 0.56) 57.62%, rgba(255, 255, 255, 0.35) 186.7%)"
      position="initial"
      zIndex="1"
    >
      <Flex marginRight="104px" alignItems="center" width="134PX">
        <NorpanLogo dataTestId="norpan-logo" />
      </Flex>
      <Flex width="100%" height="122px" alignItems="center">
        <Flex width="100%" justifyContent="space-between">
          <Flex
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="normal"
            width="55%"
            justifyContent="flex-start"
            color="darkBlue"
          >
            <Text
              data-testid="home-label"
              marginRight="25px"
              cursor="pointer"
              onClick={() => navigate('/admin')}
            >
              {textContent.goToHome}
            </Text>
            <Text
              data-testid="reservations-label"
              marginRight="25px"
              cursor="pointer"
              onClick={() => navigate('/admin/reservations')}
            >
              {textContent.reservations.reservations}
            </Text>
            <Text
              data-testid="products-label"
              marginRight="25px"
              cursor="pointer"
              onClick={() => navigate('/admin/products')}
            >
              {textContent.product.products}
            </Text>
            <Text
              data-testid="banners-label"
              marginRight="25px"
              cursor="pointer"
              onClick={() => navigate('/admin/banners')}
            >
              {textContent.banners.banners}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <LoginPopover isLoggedIn={isLoggedIn} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AdminHeader;
