import React from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';

const FavoriteCardSkeleton = () => {
  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor="grey50"
      width="100%"
      height="100%"
      data-testid="favorite-card-skeleton-container"
    >
      <GoBack title={textContent.favorites} url={'/'} />
      <Flex
        flexDirection="column"
        padding={{ base: '0', md: '32px 0' }}
        backgroundColor="grey50"
        width="100%"
        alignItems={{ base: 'center', xl: 'flex-start' }}
      >
        {Array(3)
          .fill(undefined)
          .map((_, index) => (
            <Flex
              key={index}
              backgroundColor={{ base: 'grey50', md: 'background' }}
              maxWidth={{ base: '375px', md: '721px' }}
              width="100%"
              maxHeight={{ base: '120px', md: '159px' }}
              minHeight="120px"
              height="100%"
              padding={{ md: '24px 32px' }}
              borderRadius="8px"
              borderBottom="1px solid #E2E8F0"
              alignItems="center"
              margin={{ xl: '0px 112px' }}
            >
              <Flex
                justifyContent="center"
                width={{ base: '48px', md: '80px', xl: '166px' }}
                height={{ base: '53px', md: '80px', xl: '109px' }}
                borderRadius="4.082px"
                marginLeft={{ base: '20px', md: '0' }}
              >
                <Skeleton
                  width={{ base: '48px', md: '80px', xl: '166px' }}
                  height={{ base: '53px', md: '80px', xl: '109px' }}
                />
              </Flex>
              <Flex flexDirection="column" margin="0 20px 0 24px" flexGrow="1">
                <Skeleton height="14px" width="80%" marginBottom="4px" />
                <Skeleton height="14px" width="40%" marginBottom="4px" />
                <Skeleton height="14px" width="40%" />
                <Flex justifyContent="space-between" marginTop={{ base: '12px', md: '8px' }}>
                  <Skeleton height="14px" width="20%" />
                  <Skeleton height="14px" width="30%" />
                </Flex>
              </Flex>
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};

export default FavoriteCardSkeleton;
