import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon, CloseIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  clientCodeVerification,
  getAuthError,
  phoneCodeAuthentication,
} from '../../../../store/modules/auth';
import { getUserRole } from '../../../../store/modules/auth/actions';
import { hideModal } from '../../../../store/modules/ui';
import { RootState } from '../../../../store/types';
import { clearCache } from '../../../../utils/swr';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import Countdown from '../../../shared/Counter/Counter';

interface Props {
  isMobile: boolean;
  setVerificationCodePage: Dispatch<SetStateAction<boolean>>;
}

interface Values {
  pinInput: string;
}

const VerificationCodeScreen = ({ isMobile, setVerificationCodePage }: Props) => {
  const [resetClock, setResetClock] = useState(false);
  const [countZero, setCountZero] = useState<boolean>(false);
  const authUserData = useAppSelector((state: RootState) => state.auth.data);
  const authLoading = useAppSelector((state: RootState) => state.auth.loading);
  const authError = useAppSelector(getAuthError);
  const userId = useAppSelector((state: RootState) => state.auth.data.account);
  const userPhone = useAppSelector((state: RootState) => state.auth.data.phoneNumber);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      pinInput: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const isButtonDisabled = useMemo(() => {
    if (countZero) {
      return false;
    }
    return !isDirty || !isValid;
  }, [countZero, isDirty, isValid]);

  const onSubmit = async (values: Values) => {
    const inputsValue = values.pinInput;
    const phoneNumber = authUserData?.verificationPhone;

    if (countZero) {
      clearErrors('pinInput');
      setResetClock(!resetClock);
      reset({
        pinInput: '',
      });
      await dispatch(clientCodeVerification({ userId })).then((response) => {
        if (response.type === 'LOGIN_USER/fulfilled') {
          setVerificationCodePage(true);
        }
      });
    } else {
      const response = await dispatch(
        phoneCodeAuthentication({ authCode: inputsValue, userPhone: phoneNumber, userId }),
      );
      if (response.type === 'AUTH_USER/fulfilled') {
        await clearCache({});
        const userRoleRes = await dispatch(getUserRole());
        if (userRoleRes.payload?.isAdmin) {
          navigate('/admin');
        }
        dispatch(hideModal());
      }
    }
  };

  useEffect(() => {
    if (countZero) {
      setError('pinInput', {
        type: 'custom',
        message: textContent.expiredCode,
      });
    }
    setError('pinInput', {
      type: 'custom',
      message: authError,
    });
  }, [countZero, setError, authError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            viewBox="0 0 24 24"
            data-testid="close-login-modal-icon"
            onClick={() => {
              if (!authLoading) {
                dispatch(hideModal());
              }
            }}
            cursor={authLoading ? 'default' : 'pointer'}
            color={authLoading ? 'grey200' : 'black'}
          />
        </Flex>
      )}
      <Flex
        paddingLeft={{ base: '24px', xl: '36px' }}
        paddingTop={{ base: '49px', xl: '0' }}
        paddingRight={{ base: '24px', xl: '36px' }}
        paddingBottom={{ base: '50px', xl: '24px' }}
        flexDirection="column"
        justifyContent={{ xl: 'space-between' }}
        data-testid="verification-code-login-page"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ xl: '6px' }}
      >
        <Flex flexDirection="column" height={{ base: '75%' }}>
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent="space-between"
            alignItems={{ xl: 'flex-start' }}
            marginBottom={{ base: '32px' }}
          >
            <Flex alignItems="center" justifyContent="flex-start" marginBottom="24px">
              {isMobile && (
                <ArrowBackIcon
                  data-testid="go-back-arrow-icon"
                  boxSize="1.5em"
                  viewBox="0 0 24 24"
                  marginRight="16px"
                  onClick={() => setVerificationCodePage(false)}
                />
              )}
              <Text
                color="black"
                fontSize="26px"
                fontStyle="normal"
                fontWeight={600}
                lineHeight="normal"
              >
                {textContent.accountValidation}
              </Text>
            </Flex>
            <Text
              color="black"
              fontSize="14px"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="24px"
            >
              {`${textContent.verificationCodeWhatsappLogin} ${userPhone}`}
            </Text>
          </Flex>
          <FormControl isInvalid={!isValid}>
            <Flex flexDirection="column" alignItems={{ xl: 'center' }}>
              <Flex width="100%" marginBottom="16px">
                <Text
                  color="black"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight={500}
                  lineHeight="normal"
                  justifyContent="flex-start"
                  width="50%"
                >
                  {textContent.verificactionCodeLogin}
                </Text>
                <Box width="50%">
                  <Countdown resetClock={resetClock} setCountZero={setCountZero} />
                </Box>
              </Flex>
              <Flex width="100%" justifyContent="space-between" marginBottom="16px">
                <Controller
                  control={control}
                  name="pinInput"
                  render={({ field: { onChange, value } }) => (
                    <PinInput
                      size="lg"
                      variant="outline"
                      placeholder=""
                      isDisabled={countZero}
                      onChange={(newValue) => {
                        onChange(newValue);
                      }}
                      value={value}
                    >
                      <PinInputField backgroundColor="white" data-testid="first-input" />
                      <PinInputField backgroundColor="white" data-testid="second-input" />
                      <PinInputField backgroundColor="white" data-testid="third-input" />
                      <PinInputField backgroundColor="white" data-testid="fourth-input" />
                      <PinInputField backgroundColor="white" data-testid="fifth-input" />
                      <PinInputField backgroundColor="white" data-testid="sixth-input" />
                    </PinInput>
                  )}
                />
              </Flex>
              <FormErrorMessage
                fontSize="12px"
                width={{ xl: '100%' }}
                data-testid="verification-code-error-message"
                marginTop="0"
              >
                {errors.pinInput?.message && <WarningIcon marginRight="8px" />}
                {errors.pinInput?.message}
              </FormErrorMessage>
            </Flex>
          </FormControl>
        </Flex>
        <Flex flexDirection="column" height={{ base: '25%' }} justifyContent={{ base: 'flex-end' }}>
          <Flex justifyContent="center">
            <Text
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              color="grey800"
            >
              {textContent.getHelp}{' '}
              <Link
                href={`https://wa.me/5493525521001?text=${textContent.getHelpWhatsappMessage}`}
                isExternal
                fontSize="14px"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                color="purple"
                textAlign="center"
                textDecoration={'underline'}
                data-testid="get-client-code-link"
                marginBottom={{ base: '32px' }}
              >
                {textContent.clickHere}
              </Link>
            </Text>
          </Flex>
          <Flex width="100%" justifyContent="space-between">
            {!isMobile && (
              <Button
                color="purple"
                backgroundColor="background"
                width="45%"
                height="48px"
                content={textContent.goBack}
                border="1px solid"
                borderColor="purple"
                data-testid="go-back-button"
                onClick={() => setVerificationCodePage(false)}
                marginTop="20px"
                isDisabled={authLoading}
              />
            )}
            <Button
              color="grey50"
              backgroundColor="purple"
              width={{ base: '100%', md: '45%', xl: '45%' }}
              height="48px"
              data-testid="verification-code-enter-button"
              content={!countZero ? textContent.enterSite : textContent.sendCodeAgain}
              marginTop="20px"
              type="submit"
              isLoading={authLoading}
              isDisabled={isButtonDisabled}
              _hover={{
                backgroundColor: { base: 'none', xl: 'darkPurple' },
              }}
              _active={{
                backgroundColor: { base: 'darkPurple', xl: 'none' },
              }}
              _disabled={{
                backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
                pointerEvents: 'none',
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default VerificationCodeScreen;
