import useSWR from 'swr';
import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Select, Text } from '@chakra-ui/react';
import { PaginationState } from '@tanstack/react-table';

import { Status } from '../../../../interfaces/voucher';
import { getAdminVouchers } from '../../../../services/voucher';
import { dropdownColor, updateFilter } from '../../../../utils/tableFunctions';
import textContent from '../../../../utils/textContent.json';
import { voucherFormatter } from '../../../../utils/voucherFormatter';
import Subheader from '../../../shared/Subheader/Subheader';
import SharedTable from '../../../shared/Table/SharedTable';
import SharedTableSkeleton from '../../../shared/Table/SharedTableSkeleton';
import { columns } from './columsDefinition';
import { branches, months, SkeletonCells } from './constants';
import EmptyReservations from './EmptyReservationsTable';
import { FilterType } from './reservationTypes';

const VouchersTable = () => {
  const [filters, setFilters] = useState<FilterType>({
    branch: '',
    month: '',
    year: '',
    status: Status.PENDING,
  });

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 5,
    pageIndex: 0,
  });

  const [searchValue, setSearchValue] = useState('');
  const [selectedVouchers, setSelectedVouchers] = useState<string[]>([]);

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    });
  }, [filters, searchValue]);

  const url = `pageNumber=${pagination.pageIndex + 1}&pageSize=${
    pagination.pageSize
  }&searchValue=${searchValue}&branch=${filters.branch}&month=${filters.month}&year=${
    filters.year
  }&status=${filters.status}`;

  const { data, error, isLoading } = useSWR(url, getAdminVouchers);

  const clearFilters = () => {
    setFilters({
      branch: '',
      month: '',
      year: '',
      status: Status.PENDING,
    });
    setSearchValue('');
    setSelectedVouchers([]);
  };

  const Filters = useMemo(
    (): JSX.Element => (
      <>
        <Select
          data-testid="branch-dropdown"
          fontSize="12px"
          width="107px"
          value={filters.branch}
          color={dropdownColor(filters.branch)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => updateFilter({ key: 'branch', value: e.target.value }, filters));
          }}
        >
          <option value="">Sucursal</option>
          {branches.map((branch) => {
            return (
              <option key={branch} value={branch}>
                {branch}
              </option>
            );
          })}
        </Select>
        <Select
          data-testid="month-dropdown"
          fontSize="12px"
          width="84px"
          value={filters.month}
          color={dropdownColor(filters.month)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => {
              return updateFilter({ key: 'month', value: e.target.value.toString() }, filters);
            });
          }}
        >
          <option value="">Mes</option>
          {months.map((month, index) => {
            return (
              <option key={month} value={index + 1}>
                {month}
              </option>
            );
          })}
        </Select>
        <Select
          data-testid="year-dropdown"
          fontSize="12px"
          width="81px"
          value={filters.year}
          color={dropdownColor(filters.year)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() =>
              updateFilter({ key: 'year', value: e.target.value.toString() }, filters),
            );
          }}
        >
          <option value="">Año</option>
          {['2022', '2023', '2024'].map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </Select>
        <Select
          defaultValue={textContent.reservations.reservationStatus}
          data-testid="status-dropdown"
          fontSize="12px"
          width="157px"
          value={filters.status}
          color={dropdownColor(filters.status)}
          borderColor="#E2E8F0"
          onChange={(e) => {
            setFilters(() => updateFilter({ key: 'status', value: e.target.value }, filters));
          }}
        >
          {Object.values(Status).map((status) => (
            <option key={status} value={status.replace('Pagado', 'Finalizado')}>
              {status.replace('Pendiente', 'Pendiente de pago')}
            </option>
          ))}
        </Select>
        {Object.values(filters).some((value) => value !== '' && value !== Status.PENDING) ||
        searchValue !== '' ? (
          <Button
            data-testid="clear-filters-button"
            variant="link"
            color="#B23E8F"
            fontSize="14px"
            fontWeight="500"
            onClick={() => clearFilters()}
          >
            {textContent.reservations.emptyFilters}
          </Button>
        ) : null}
      </>
    ),
    [filters, searchValue],
  );

  const showManualDelete = useMemo(() => {
    return filters.status === Status.PENDING;
  }, [filters.status]);

  const TableContent = useMemo(() => {
    const hasSelects = {
      selectedVouchers: selectedVouchers,
      setSelectedVouchers: setSelectedVouchers,
    };
    const formattedVouchers = voucherFormatter(data?.vouchers ?? []);
    if (isLoading) return <SharedTableSkeleton rows={6} cells={SkeletonCells} />;
    if (error) return <EmptyReservations />;
    return (
      <SharedTable
        searchPlaceholder={textContent.voucher.searchPlaceholder}
        hasSelects={hasSelects}
        data={formattedVouchers}
        columns={columns({
          selectedVouchers,
          setSelectedVouchers,
          showManualDelete,
        })}
        filters={Filters}
        filtersState={filters}
        onPaginationChange={setPagination}
        pageCount={data?.totalPages ?? 0}
        total={data?.total ?? 0}
        pagination={pagination}
        dataTestId="reservations-table-container"
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        error={error}
        searchBarWidth="289px"
        url={url}
        isFilter
        emptyComponent={<EmptyReservations />}
      />
    );
  }, [
    isLoading,
    error,
    data?.vouchers,
    data?.totalPages,
    data?.total,
    selectedVouchers,
    Filters,
    filters,
    pagination,
    searchValue,
    url,
  ]);

  return (
    <Box>
      <Box padding="24px 112px" backgroundColor="#FAFAFC">
        {isLoading || !error ? (
          <Box>
            <Subheader
              title={textContent.reservations.subheader.title}
              subtitle={textContent.reservations.subheader.subtitle}
            />
          </Box>
        ) : (
          <Flex height="51px" alignItems="center">
            <ArrowBackIcon
              boxSize={8}
              marginRight="20px"
              onClick={() => {
                setSearchValue('');
                clearFilters();
              }}
              cursor="pointer"
              data-testid="back-button"
            />
            <Text
              alignSelf="center"
              color="darkBlue"
              fontSize={{ base: '18px', md: '20px' }}
              fontWeight="600"
              data-testid="back-button-title"
            >
              {textContent.reservations.subheader.title}
            </Text>
          </Flex>
        )}
      </Box>
      <Box padding="28px 112px">{TableContent}</Box>
    </Box>
  );
};

export default VouchersTable;
