import React, { useState } from 'react';

import ClientCode from './ClientCode/ClientCode';
import VerificationCode from './VerificationCode/VerificationCode';
interface Props {
  isMobile: boolean;
}

const Login = ({ isMobile }: Props) => {
  const [verificationCodePage, setVerificationCodePage] = useState<boolean>(false);

  return (
    <>
      {!verificationCodePage ? (
        <ClientCode isMobile={isMobile} setVerificationCodePage={setVerificationCodePage} />
      ) : (
        <VerificationCode isMobile={isMobile} setVerificationCodePage={setVerificationCodePage} />
      )}
    </>
  );
};

export default Login;
