import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useAppSelector } from '../../../store/hooks';
import { authData } from '../../../store/modules/auth';
import { isSystemActive, isSystemLoading } from '../../../store/modules/settings/selectors';
import { isOpenSideMenu } from '../../../store/modules/sideMenu';
import textContent from '../../../utils/textContent.json';
import CategoriesCarousel from '../../shared/Categories/CategoriesCarousel';
import CustomerButton from '../../shared/CustomerButton/CustomerButton';
import Footer from '../../shared/Footer/Footer';
import OurBrands from '../../shared/OurBrands/OurBrands';
import HomeBanner from './HomeBanner/HomeBanner';
import HomeCarousels from './HomeCarousels';

const Home = () => {
  const { isMobile } = useBreakpoint();

  const { isLogged } = useAppSelector(authData);
  const isOpen = useAppSelector(isOpenSideMenu);
  const navigate = useNavigate();
  const [showCustomerButton, setShowCustomerButton] = useState(true);

  const isSystemOn = useAppSelector(isSystemActive);
  const systemLoading = useAppSelector(isSystemLoading);

  useEffect(() => {
    const handleScroll = () => {
      const isBottom =
        window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 150;

      setShowCustomerButton(!isBottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box marginTop="33px">
      {!systemLoading && !isSystemOn ? (
        <Box
          marginLeft={{ base: '17px', md: '62px', xl: '92px' }}
          marginRight={{ base: '25px', md: '62px', xl: '92px' }}
        >
          <Flex justifyContent="center" alignItems="center" height="523px">
            <Text
              data-testid="updating-system-message"
              fontWeight="600"
              color="darkBlue"
              fontSize="20px"
            >
              {textContent.system.systemOffMessage}
            </Text>
          </Flex>
        </Box>
      ) : (
        <>
          <HomeBanner />
          <Flex justifyContent="center" alignItems="center">
            {isMobile ? (
              <Image
                src="https://norpan-images.s3.us-west-2.amazonaws.com/banners-images/oilBannerMobile.png"
                alt="Banner Aceite"
                data-testid="oil-banner-mobile"
                width="100%"
                height="auto"
                onClick={() => navigate('/category/01.02')}
                cursor="pointer"
                marginRight="25px"
                marginLeft="17px"
                marginTop="20px"
              />
            ) : (
              <Image
                src="https://norpan-images.s3.us-west-2.amazonaws.com/banners-images/oilBannerDesktop.svg"
                alt="Banner Aceite"
                data-testid="oil-banner-desktop"
                width="90%"
                onClick={() => navigate('/category/01.02')}
                cursor="pointer"
                marginTop="20px"
              />
            )}
          </Flex>
          <Flex width="100%" justifyContent={{ base: 'start', xl: 'space-between' }}>
            <CategoriesCarousel />
          </Flex>
          <HomeCarousels />
          <Flex marginTop="50px" width="100%" justifyContent="center" alignItems="center">
            <OurBrands />
          </Flex>
          {!isOpen && !isLogged && showCustomerButton && (
            <Flex marginTop="20px" width="100%" justifyContent="center" alignItems="center">
              <CustomerButton />
            </Flex>
          )}
        </>
      )}
      <Footer isMobile={isMobile} />
    </Box>
  );
};

export default Home;
