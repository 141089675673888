import React from 'react';

import { theme } from '../../theme';
import { CategoriesIconPros } from './DrinksIcon';

const DealsIcon = ({ dataTestId, changeBg }: CategoriesIconPros) => (
  <svg
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.95952 23.997C9.38237 23.9939 8.82042 23.7327 8.32833 23.2406L7.48388 22.3962C5.22088 20.1331 2.95787 17.8701 0.694867 15.6071C-0.100982 14.8083 -0.225523 13.6813 0.385033 12.8034C0.494386 12.6455 0.634115 12.4997 0.767769 12.366L5.1662 7.96456C7.63272 5.49804 10.0962 3.03455 12.5597 0.564992C12.9455 0.179218 13.3738 0 13.9084 0C15.2692 0.00607518 16.6179 0.00607517 17.9696 0.00607517H21.6877C23.0849 0.00607517 23.9901 0.914315 23.9932 2.31768V4.00962V3.99443C23.9932 4.0643 23.9932 4.32857 23.9932 5.52841C23.9932 6.33337 23.9932 7.1839 23.9932 7.3054C23.9932 7.3054 23.9932 9.67169 23.9932 10.182C23.9932 10.4372 23.9415 10.6741 23.8443 10.8837C23.7411 11.1054 23.6044 11.2482 23.5345 11.3241C23.0606 11.8223 22.5291 12.3417 22.2405 12.6212L21.712 12.0775C22.0734 11.719 22.3772 11.4122 22.605 11.1844L22.9209 10.8685C22.9695 10.8229 23.0789 10.7105 23.1578 10.5313C23.2156 10.3977 23.2429 10.2488 23.2399 10.0969V7.79446V5.9719C23.2399 5.05759 23.2399 3.2806 23.2399 2.29946C23.2399 1.33958 22.6658 0.76851 21.7028 0.765473H19.3244C17.5262 0.765473 15.7309 0.765473 13.9327 0.759398C13.5985 0.759398 13.3343 0.868751 13.0973 1.10872C10.1843 4.02784 7.26821 6.94393 4.35213 9.86002L1.28416 12.928C0.585514 13.6266 0.588551 14.4286 1.29327 15.1333C3.82358 17.6666 6.35693 20.1969 8.88725 22.7272C9.22442 23.0644 9.58589 23.2376 9.96559 23.2406C10.5245 23.2406 10.9224 22.8639 11.0743 22.7212C11.8398 21.9982 15.2783 18.5597 19.531 14.2433L19.9714 14.7566C15.5578 19.4649 12.7237 22.2625 11.6363 23.2285C11.3781 23.4593 10.7706 24 9.97167 24H9.96255L9.95952 23.997Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M19.9321 2.28125C19.0604 2.28125 18.3647 2.97078 18.3647 3.84257C18.3647 4.70828 19.0725 5.41301 19.9352 5.40693C20.7887 5.40086 21.4844 4.70525 21.4935 3.85472C21.4995 2.98901 20.8009 2.28733 19.9321 2.28429V2.28125ZM19.9321 4.76904H19.9261C19.4188 4.76904 19.0057 4.35289 19.0057 3.84257C19.0057 3.59349 19.0998 3.36263 19.273 3.18949C19.4461 3.01635 19.68 2.91914 19.9321 2.91914C20.1843 2.91914 20.4212 3.01635 20.5943 3.19556C20.7675 3.37174 20.8616 3.6026 20.8586 3.84865C20.8556 4.35289 20.4394 4.766 19.9321 4.76904Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M12.8571 13.6377C12.0858 13.6377 11.4688 14.2504 11.4688 15.0217C11.4688 15.7886 12.0946 16.4145 12.8616 16.4101C13.6197 16.4057 14.2367 15.7886 14.2411 15.0305C14.2455 14.2636 13.6285 13.6377 12.8571 13.6377Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M10.5471 7.94238C9.77575 7.94238 9.15869 8.55504 9.15869 9.32637C9.15869 10.0933 9.78457 10.7192 10.5515 10.7148C11.3096 10.7104 11.9267 10.0933 11.9311 9.33518C11.9355 8.56826 11.3184 7.94238 10.5471 7.94238Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
    <path
      d="M7.57607 13.853C7.28517 13.8398 7.05157 13.6503 7.00749 13.3902C6.96342 13.1302 7.11768 12.8745 7.36891 12.7908C7.64219 12.7026 7.92427 12.6277 8.20195 12.5528C8.33859 12.5175 8.47963 12.4779 8.61627 12.4426L15.047 10.6443C15.1483 10.6134 15.2233 10.5958 15.2938 10.5782C15.3467 10.565 15.3952 10.5605 15.448 10.5605C15.6993 10.5605 15.902 10.7104 15.9726 10.9484C16.0651 11.2481 15.9153 11.5214 15.6155 11.6184C15.1395 11.7726 14.6459 11.9049 14.1698 12.0371C14.1698 12.0371 9.83717 13.2404 7.90223 13.7737C7.80967 13.8001 7.71712 13.8178 7.64219 13.8354L7.57607 13.8486V13.853Z"
      fill={changeBg ? '#EFE9ED' : theme.colors.purple}
    />
  </svg>
);

export default DealsIcon;
