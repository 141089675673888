/* eslint-disable @typescript-eslint/no-explicit-any */

import { XYCoord } from 'dnd-core';
import React, { useEffect, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import css from './item.module.css';
import { DragItem, ItemProps, ItemTypes } from './itemTypes';

const Item = <T extends Record<string, unknown>>(props: ItemProps<T>) => {
  const { item, index, onRender, onMoveItem, setIsDragging } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: unknown, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      // Check if item is a DragItem
      if ('index' in (item as any)) {
        const dragItem = item as DragItem;
        const dragIndex = dragItem.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        onMoveItem(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        dragItem.index = hoverIndex;
      }
    },
  }) as [{ handlerId: string }, any];

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: () => ({ index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    setIsDragging(isDragging);
  }, [isDragging, setIsDragging]);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} className={css.item} style={{ opacity }} data-handler-id={handlerId}>
      {onRender(item)}
    </div>
  );
};

export default Item;
