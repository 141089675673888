import { createSlice } from '@reduxjs/toolkit';

import { ModalType } from '../../../enum/ModalType';
import { PostVoucherResponse } from '../../../interfaces/voucher';
import { CartItem } from '../cart/types';
import { AddImageAndDescriptionProps } from '../products/types';
import { BranchData, ConfirmReservationData } from './types';

export interface InitialState {
  modal: {
    show: boolean;
    data?:
      | CartItem
      | PostVoucherResponse
      | ConfirmReservationData
      | AddImageAndDescriptionProps
      | BranchData
      | string
      | boolean;
    type: ModalType;
    callback?: () => void;
  };
  popover: {
    show?: boolean;
  };
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    modal: {
      show: false,
      data: {},
      type: ModalType.LOGIN,
      callback: undefined,
    },
    popover: {
      show: false,
    },
  } as InitialState,
  reducers: {
    showModal: (state, action) => {
      state.modal.show = true;
      state.modal.type = action.payload.type;
      state.modal.data = action.payload.data;
      state.modal.callback = action.payload.callback;
    },
    hideModal: (state) => {
      state.modal.show = false;
    },
    openLoginPopover: (state) => {
      state.popover.show = true;
    },
    closeLoginPopover: (state) => {
      state.popover.show = false;
    },
  },
});

export const uiReducer = uiSlice.reducer;
export const { showModal, hideModal, openLoginPopover, closeLoginPopover } = uiSlice.actions;
