import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { EditIcon } from '@chakra-ui/icons';
import { Box, Flex, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { ModalType } from '../../../../enum/ModalType';
import { useAppDispatch } from '../../../../store/hooks';
import { showModal } from '../../../../store/modules/ui/slice';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';
import GoBack from '../../../shared/GoBack/GoBack';
import { emailValidation } from '../AdminHome/validation';

interface EditBranchEmailForm {
  email: string;
}

const EditBranchEmail = () => {
  const { state } = useLocation();
  const { email, branch, branchId } = state;
  const dispatch = useAppDispatch();

  const [onEdit, setOnEdit] = React.useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, errors, isValid },
  } = useForm<EditBranchEmailForm>({
    defaultValues: {
      email,
    },
    resolver: yupResolver(emailValidation),
    mode: 'onChange',
  });

  const onSubmit = (values: { email: string }) => {
    dispatch(
      showModal({
        type: ModalType.CONFIRM_EMAIL_BRANCH_EDIT,
        data: {
          branchId,
          branchEmail: values.email,
          branch,
        },
      }),
    );
  };

  return (
    <>
      <GoBack title={`Editar ${branch}`} />
      <Flex justify="center" backgroundColor="white" height="85vh">
        <Flex width="76.6%" direction="column" align="start" paddingTop="40px">
          <Box
            display="flex"
            width="388px"
            height="132px"
            padding="24px"
            border="1px solid"
            borderColor="grey2"
            background="white"
            borderRadius="8px"
          >
            <Flex flexDirection="column" width="100%">
              <Text
                data-testid="card-title"
                display="flex"
                alignItems="center"
                height="32px"
                fontWeight="700"
                color="darkBlue"
                fontSize="14px"
              >
                {textContent.associatedEmail}
              </Text>
              <InputGroup>
                <Input
                  disabled={!onEdit}
                  {...register('email')}
                  display="flex"
                  alignItems="center"
                  height="52px"
                  data-testid="email-input"
                  bg="background"
                  fontWeight="500"
                  background="background"
                  color="darkBlue"
                  fontSize="12px"
                />
                {!onEdit && (
                  <InputRightElement height="100%">
                    <EditIcon
                      _hover={{ cursor: 'pointer' }}
                      onClick={() => setOnEdit(true)}
                      data-testid="edit-email-button"
                      color="lightBlue"
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Box>
          <Flex height="40px" alignItems="center">
            {errors.email && (
              <Text
                data-testid="email-error"
                height="fit-content"
                color="errorRed"
                fontFamily="Roboto"
                fontSize="12px"
                fontWeight="500"
              >
                {errors.email.message}
              </Text>
            )}
          </Flex>
          <Flex justify="space-evenly" marginTop="40px" height="40px" width="388px">
            {onEdit && (
              <>
                <Button
                  variant="outline"
                  borderColor="purple"
                  data-testid="cancel-button"
                  width="155px"
                  height="40px"
                  onClick={() => {
                    setOnEdit(false);
                    reset();
                  }}
                  color="purple"
                  _hover={{ bg: 'greyOnHover' }}
                >
                  {textContent.cancel}
                </Button>
                <Button
                  isDisabled={!isDirty || !isValid}
                  data-testid="save-button"
                  width="155px"
                  height="40px"
                  color="white"
                  onClick={handleSubmit(onSubmit)}
                  _disabled={{
                    bg: 'disabledPurple',
                    cursor: 'default',
                    _hover: { bg: 'disabledPurple' },
                  }}
                  _hover={{ bg: 'darkPurple' }}
                  backgroundColor="purple"
                >
                  {textContent.saveChanges}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default EditBranchEmail;
