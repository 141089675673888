import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

import { SkeletonProps } from '../../../interfaces/skeletons';

const CategoryViewSkeleton = ({ onClick }: SkeletonProps) => {
  return (
    <Flex justifyContent="flex-start" height="100%" direction="column">
      <Flex
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
        backgroundColor="background"
      >
        <Flex
          alignItems="center"
          textAlign="start"
          margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
        >
          <ArrowBackIcon
            boxSize={8}
            marginRight="20px"
            onClick={onClick}
            cursor="pointer"
            data-testid="arrow-icon"
          />
          <Box>
            <Skeleton width="80px" height="20px" />
          </Box>
        </Flex>
      </Flex>
      <Box
        margin={{ md: '20px 112px' }}
        marginBottom="20px"
        data-testid="category-skeleton-container"
      >
        <Skeleton margin={{ base: '20px 30px', md: '15px 0px' }} width="200px" height="16px" />
        <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" gap="15px">
          {Array(3)
            .fill(undefined)
            .map((_, index) => (
              <Skeleton
                key={index}
                width={{ base: '80%', md: '327px' }}
                height="161px"
                borderRadius="8px"
                data-testid={`featured-category-skeleton-${index}`}
              />
            ))}
        </Flex>
      </Box>
    </Flex>
  );
};

export default CategoryViewSkeleton;
