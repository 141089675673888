import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@chakra-ui/react';

import { BannerData } from '../../../interfaces/banner';
import ItemComponent from './item';
import css from './SortableList.module.css';
import { Column, ItemUpdated, Props } from './sortableListTypes';

export const SortableList = ({
  data,
  onChange,
  loading,
  positioning,
  columns,
  showActions,
  onItemRender,
}: Props) => {
  const [dynamicList, setDynamicList] = useState(data);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setDynamicList(data);
  }, [data]);

  useEffect(() => {
    if (!isDragging) {
      // once the dragging is done, create an array with elements whose position was changed
      const changes = data.reduce((acc: ItemUpdated[], item, index) => {
        if (item.id.toString() !== dynamicList[index].id.toString()) {
          const updatedItemIndex = dynamicList.findIndex(
            (newItem) => newItem.id.toString() === item.id.toString(),
          );
          if (updatedItemIndex > -1) {
            acc.push({ id: item.id, position: updatedItemIndex + 1 });
          }
        }
        return acc;
      }, []);
      if (changes.length > 0) {
        onChange && onChange(changes);
      }
    }
    // eslint-disable-next-line
  }, [isDragging]);

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = dynamicList[dragIndex];
      const newDynamicList = [...dynamicList];
      newDynamicList.splice(dragIndex, 1);
      newDynamicList.splice(hoverIndex, 0, dragItem);
      setDynamicList(newDynamicList);
    },
    [dynamicList],
  );

  const renderItem = (item: BannerData, index: number) => {
    return (
      <ItemComponent<BannerData>
        key={item.id}
        index={index}
        item={item}
        onMoveItem={moveItem}
        onRender={onItemRender}
        setIsDragging={setIsDragging}
      />
    );
  };

  return (
    <div className={css.container}>
      <div className={css.headers}>
        {columns.map((column: Column) => (
          <div
            key={column.title}
            hidden={column.title === 'Acciones' && !showActions}
            className={cx(css.header, column.cellStyle)}
          >
            {column.title}
          </div>
        ))}
        {positioning && <CircularProgress className={css.smallSpinner} />}
      </div>
      {loading && (
        <div className={css.mainSpinnerContainer}>
          <CircularProgress />
        </div>
      )}
      {dynamicList.map(renderItem)}
      {!dynamicList.length && 'No hay datos para mostrar'}{' '}
    </div>
  );
};

export default SortableList;
