import React from 'react';

import EditIcon from '../../../shared/Table/CellComponents/EditIcon';
export const tableColumns = [
  {
    accessorKey: 'branchName',
    header: 'Nombre de sucursal',
  },
  {
    accessorKey: 'email',
    header: 'email',
  },
  {
    id: 'editIcon',
    size: 20,
    maxSize: 20,
    header: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cell: (data: any) => {
      return (
        <EditIcon
          email={data.row.original.email}
          branch={data.row.original.branchName}
          branchId={data.row.original._id}
        />
      );
    },
  },
];

export const SkeletonCells = [
  {
    width: '233px',
    height: '20px',
  },
  {
    width: '282px',
    height: '20px',
  },
  {
    width: '20px',
    height: '20px',
  },
];
