export const priceFormatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'ARS',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const priceFormatterNumber = new Intl.NumberFormat('es-AR', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
