import useSWRMutation from 'swr/mutation';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text, useToast } from '@chakra-ui/react';

import PencilIcon from '../../../../assets/Icons/PencilIcon';
import { editVoucher } from '../../../../services/branch';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getModalState } from '../../../../store/modules/ui/selectors';
import { hideModal } from '../../../../store/modules/ui/slice';
import { isBranchData } from '../../../../store/modules/ui/types';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';

const ConfirmEditBranchEmail = () => {
  const toast = useToast();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { data } = useAppSelector(getModalState);

  const { trigger } = useSWRMutation(
    isBranchData(data) && {
      branchEmail: data?.branchEmail,
      branchId: data?.branchId,
      branchName: data.branch,
    },
    editVoucher,
  );

  const onConfirm = async () => {
    await trigger();
    dispatch(hideModal());
    if (location.pathname === '/admin/branch') {
      navigate('/admin');
      toast({
        description: textContent.emailEdit.toast.successMessage,
        status: 'success',
        variant: 'subtle',
        containerStyle: { fontSize: '14px' },
        duration: 3000,
      });
    }
  };

  return (
    <>
      <Flex justifyContent="flex-end" p="8px 12px" data-testid="confirm-edit-email-modal">
        <CloseIcon
          width="12px"
          height="12px"
          data-testid="close-logout-modal-icon"
          onClick={() => dispatch(hideModal())}
          cursor="pointer"
        />
      </Flex>
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        height="485px"
        padding="0 20.5px 24px"
      >
        <PencilIcon dataTestId="pencil-icon" />
        <Flex flexDirection="column">
          <Text
            fontSize="20px"
            fontWeight="500"
            paddingBottom="24px"
            lineHeight="21.1px"
            align="center"
            data-testid="title"
          >
            {textContent.emailEdit.editInfo}
          </Text>
          <Text
            fontSize="18px"
            fontWeight="500"
            lineHeight="21.1px"
            align="center"
            data-testid="confirmation-message"
          >
            {textContent.emailEdit.modal.areYouSure}
          </Text>
        </Flex>
        <Flex
          justifySelf="flex-end"
          justify="space-evenly"
          marginTop="40px"
          height="40px"
          width="388px"
        >
          <Button
            variant="outline"
            borderColor="purple"
            data-testid="cancel-button"
            width="155px"
            height="40px"
            onClick={() => dispatch(hideModal())}
            color="purple"
            _hover={{ bg: 'greyOnHover' }}
          >
            {textContent.cancel}
          </Button>
          <Button
            data-testid="save-button"
            width="155px"
            height="40px"
            color="white"
            onClick={() => {
              onConfirm();
              dispatch(hideModal());
            }}
            _disabled={{
              bg: 'disabledPurple',
              cursor: 'default',
              _hover: { bg: 'disabledPurple' },
            }}
            _hover={{ bg: 'darkPurple' }}
            backgroundColor="purple"
          >
            {textContent.confirm}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ConfirmEditBranchEmail;
