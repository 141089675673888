import React from 'react';

import { CategoriesIconPros } from './DrinksIcon';

const DeleteProductIcon = ({ dataTestId }: CategoriesIconPros) => (
  <svg
    width="201"
    height="160"
    viewBox="0 0 201 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}
  >
    <rect x="0.5" width="200" height="160" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.8996 107.201H123.7C124.112 107.201 124.513 107.156 124.9 107.072C125.286 107.156 125.688 107.201 126.1 107.201H167.7C170.792 107.201 173.3 104.693 173.3 101.601C173.3 98.5079 170.792 96.0007 167.7 96.0007H162.9C159.807 96.0007 157.3 93.4935 157.3 90.4007C157.3 87.3079 159.807 84.8007 162.9 84.8007H178.1C181.192 84.8007 183.7 82.2935 183.7 79.2007C183.7 76.1079 181.192 73.6007 178.1 73.6007H160.5C163.592 73.6007 166.1 71.0935 166.1 68.0007C166.1 64.9079 163.592 62.4007 160.5 62.4007H109.3C112.392 62.4007 114.9 59.8935 114.9 56.8007C114.9 53.7079 112.392 51.2007 109.3 51.2007H63.6996C60.6068 51.2007 58.0996 53.7079 58.0996 56.8007C58.0996 59.8935 60.6068 62.4007 63.6996 62.4007H31.6996C28.6068 62.4007 26.0996 64.9079 26.0996 68.0007C26.0996 71.0935 28.6068 73.6007 31.6996 73.6007H51.6996C54.7924 73.6007 57.2996 76.1079 57.2996 79.2007C57.2996 82.2935 54.7924 84.8007 51.6996 84.8007H19.6996C16.6068 84.8007 14.0996 87.3079 14.0996 90.4007C14.0996 93.4935 16.6068 96.0007 19.6996 96.0007H50.8996C47.8068 96.0007 45.2996 98.5079 45.2996 101.601C45.2996 104.693 47.8068 107.201 50.8996 107.201ZM181.3 107.201C184.392 107.201 186.9 104.693 186.9 101.601C186.9 98.5079 184.392 96.0007 181.3 96.0007C178.207 96.0007 175.7 98.5079 175.7 101.601C175.7 104.693 178.207 107.201 181.3 107.201Z"
      fill="#EBF1FF"
    />
    <path
      d="M134.1 118.4H152.042M51.7 118.4H68.8633H51.7ZM41.4023 118.4H46.9H41.4023ZM155.802 118.4H158.1H155.802Z"
      stroke="#76A4FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.1253 61.8889C89.1665 61.8889 88.378 61.1087 88.4976 60.1573C88.652 58.9284 88.9705 57.723 89.4464 56.5739C90.1444 54.8888 91.1675 53.3577 92.4572 52.068C93.7469 50.7783 95.278 49.7553 96.9631 49.0573C98.6481 48.3593 100.454 48.0001 102.278 48.0001C104.102 48.0001 105.908 48.3593 107.593 49.0573C109.278 49.7553 110.809 50.7783 112.099 52.068C113.389 53.3577 114.412 54.8888 115.11 56.5739C115.586 57.723 115.904 58.9284 116.059 60.1573C116.178 61.1087 115.39 61.8889 114.431 61.8889C113.472 61.8889 112.709 61.1064 112.55 60.1608C112.42 59.3879 112.203 58.6302 111.902 57.9027C111.378 56.6388 110.611 55.4905 109.644 54.5232C108.677 53.556 107.528 52.7887 106.264 52.2652C105.001 51.7417 103.646 51.4723 102.278 51.4723C100.91 51.4723 99.5556 51.7417 98.2918 52.2652C97.028 52.7887 95.8797 53.556 94.9124 54.5232C93.9451 55.4905 93.1778 56.6388 92.6544 57.9027C92.353 58.6302 92.1358 59.3879 92.0058 60.1608C91.8467 61.1064 91.0842 61.8889 90.1253 61.8889Z"
      fill="#5C92FF"
    />
    <path
      d="M123.389 114.694C124.342 111.653 127.964 79.5879 129.133 69.0867C129.331 67.3112 127.939 65.7778 126.153 65.7778H78.4258C76.6312 65.7778 75.2381 67.3429 75.446 69.1254L78.3889 94.3579L81.3986 114.208C81.5919 115.483 82.5784 116.492 83.8488 116.714L90.9106 117.947C91.081 117.976 91.2536 117.991 91.4266 117.991H102.278H117.174C117.243 117.991 117.31 117.994 117.379 117.996L117.379 117.996C118.09 118.021 122.343 118.028 123.389 114.694Z"
      fill="#FAFAFC"
    />
    <path
      d="M82.9015 111.561C81.9627 108.496 79.4593 80.3435 78.6471 70.9988C78.5636 70.0386 79.7539 69.5484 80.3913 70.2714L118.035 112.967C118.604 113.613 118.146 114.628 117.285 114.628H105.466H88.0562C88.0048 114.628 87.9559 114.632 87.9048 114.638C87.3575 114.7 83.9378 114.944 82.9015 111.561Z"
      fill="#EBF1FF"
    />
    <path
      d="M92.293 79.2847L96.1518 101.169"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M108.672 101.112L112.531 79.2272"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M78.9443 105.222C78.9443 105.222 79.4998 111.889 81.1665 114.667C82.8331 117.444 85.0554 117.444 85.0554 117.444C85.0554 117.444 115.611 118 117.833 117.444C120.055 116.889 121.722 117.444 123.389 114.667C125.055 111.889 128.389 68 128.389 68"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M78.2548 101.2L77.6943 95.166"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.2779 91.3333L75.0557 68"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.5 66.8887L135.056 66.8887"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default DeleteProductIcon;
