import React from 'react';

import { Props } from '../Types';

const PencilIcon = ({ dataTestId }: Props) => (
  <svg
    width="201"
    data-testid={dataTestId}
    height="160"
    viewBox="0 0 201 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="200" height="160" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.8996 107.2H123.7C124.112 107.2 124.513 107.156 124.9 107.071C125.286 107.156 125.688 107.2 126.1 107.2H167.7C170.792 107.2 173.3 104.693 173.3 101.6C173.3 98.5074 170.792 96.0002 167.7 96.0002H162.9C159.807 96.0002 157.3 93.493 157.3 90.4002C157.3 87.3074 159.807 84.8002 162.9 84.8002H178.1C181.192 84.8002 183.7 82.293 183.7 79.2002C183.7 76.1074 181.192 73.6002 178.1 73.6002H160.5C163.592 73.6002 166.1 71.093 166.1 68.0002C166.1 64.9074 163.592 62.4002 160.5 62.4002H109.3C112.392 62.4002 114.9 59.893 114.9 56.8002C114.9 53.7074 112.392 51.2002 109.3 51.2002H63.6996C60.6068 51.2002 58.0996 53.7074 58.0996 56.8002C58.0996 59.893 60.6068 62.4002 63.6996 62.4002H31.6996C28.6068 62.4002 26.0996 64.9074 26.0996 68.0002C26.0996 71.093 28.6068 73.6002 31.6996 73.6002H51.6996C54.7924 73.6002 57.2996 76.1074 57.2996 79.2002C57.2996 82.293 54.7924 84.8002 51.6996 84.8002H19.6996C16.6068 84.8002 14.0996 87.3074 14.0996 90.4002C14.0996 93.493 16.6068 96.0002 19.6996 96.0002H50.8996C47.8068 96.0002 45.2996 98.5074 45.2996 101.6C45.2996 104.693 47.8068 107.2 50.8996 107.2ZM181.3 107.2C184.392 107.2 186.9 104.693 186.9 101.6C186.9 98.5074 184.392 96.0002 181.3 96.0002C178.207 96.0002 175.7 98.5074 175.7 101.6C175.7 104.693 178.207 107.2 181.3 107.2Z"
      fill="#EBF1FF"
    />
    <path
      d="M86.9001 47V50.84"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M86.9001 55.96V59.8"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.5 53.3997H84.34"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.4609 53.3997H93.3009"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.621 91V93.56"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M127.621 98.6797V101.24"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M122.5 96.1204H125.06"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M130.18 96.1204H132.74"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.5 95.3936V109.894L102 100.894C102 100.894 130.518 53.8967 131.5 51.8936C132.482 49.8906 132.12 49.3274 131.5 47.8936C130.725 46.1014 127.5 44.8936 127.5 44.8936C127.5 44.8936 123 42.883 121 44.8936C119 46.9043 88.5 95.3936 88.5 95.3936Z"
      fill="white"
      stroke="#5C92FF"
      strokeWidth="2"
    />
    <path
      d="M97.4683 95.8973L89.499 108L101.499 100.5C101.499 100.5 129.51 54.9537 130.532 52.9541C131.555 50.9545 131.992 49.6573 130.578 48.9398C127.542 47.3994 127.519 49.4047 127.519 49.4047C127.519 49.4047 120.783 59.8324 117.672 64.8557C114.561 69.879 97.4683 95.8973 97.4683 95.8973Z"
      fill="#E7F0FE"
    />
    <path
      d="M95 98.3906C95 97.8383 94.5523 97.3906 94 97.3906C93.4477 97.3906 93 97.8383 93 98.3906H95ZM94 98.3906C93 98.3906 93 98.3919 93 98.3931C93 98.3935 93 98.3947 93 98.3956C93 98.3972 93 98.3989 93 98.4006C93.0001 98.404 93.0001 98.4075 93.0002 98.411C93.0003 98.4181 93.0006 98.4255 93.0009 98.4332C93.0015 98.4485 93.0024 98.465 93.0038 98.4825C93.0067 98.5176 93.0113 98.5568 93.0185 98.5999C93.0329 98.6864 93.0574 98.787 93.0973 98.8988C93.1779 99.1244 93.3166 99.3826 93.5443 99.6558C93.9957 100.198 94.78 100.78 96.1286 101.319L96.8714 99.4621C95.72 99.0016 95.2543 98.5837 95.0807 98.3754C94.9959 98.2736 94.9784 98.2193 94.9808 98.2262C94.9817 98.2286 94.9866 98.2433 94.9913 98.2711C94.9936 98.285 94.9958 98.3018 94.9974 98.3217C94.9982 98.3317 94.9988 98.3424 94.9993 98.3539C94.9995 98.3596 94.9997 98.3655 94.9998 98.3717C94.9999 98.3747 94.9999 98.3778 95 98.381C95 98.3826 95 98.3842 95 98.3858C95 98.3866 95 98.3878 95 98.3882C95 98.3894 95 98.3906 94 98.3906ZM96.1286 101.319C98.8 102.388 100.947 102.391 101.5 102.391V100.391C101.053 100.391 99.2 100.394 96.8714 99.4621L96.1286 101.319Z"
      fill="#5C92FF"
    />
    <line
      x1="71.5"
      y1="109.391"
      x2="73.5"
      y2="109.391"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="77.5"
      y1="109.391"
      x2="84.5"
      y2="109.391"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="99.5"
      y1="109.391"
      x2="143.5"
      y2="109.391"
      stroke="#5C92FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M115.5 53.3906C115.5 53.3906 117.5 56.3906 119 57.8906C120.5 59.3906 126.5 60.3906 126.5 60.3906"
      stroke="#5C92FF"
      strokeWidth="2"
    />
    <circle cx="143.5" cy="71" r="2.5" stroke="#709FFF" />
    <circle cx="75.5" cy="83" r="3" fill="#709FFF" />
  </svg>
);

export default PencilIcon;
