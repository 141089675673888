import './BrandsCarousel.css';
import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Brand } from '../../../interfaces/brands';
import { capitalizeFirstLetters } from '../../../utils/search-items';
import BrandItems from '../SubCategoryView/BrandItems';

interface BrandCarousel extends Omit<Brand, 'image'> {
  image: JSX.Element | string;
}

interface BrandsProps {
  brands?: BrandCarousel[];
  isLoading: boolean;
  pathname?: string;
}

const BrandsCarousel = ({ brands, isLoading, pathname }: BrandsProps) => {
  return (
    <Flex
      alignItems="start"
      justifyContent="flex-start"
      overflowX="scroll"
      width="100%"
      data-testid="brands-carousel"
      paddingRight={{ base: '20px', xl: '10px' }}
      paddingLeft={{ base: '20px', xl: '10px' }}
      gap="20px"
      className="carousel-container"
    >
      {brands?.map((item, index) => (
        <BrandItems
          key={`${item.brand}-${index}`}
          title={capitalizeFirstLetters(item.brand) as string}
          loading={isLoading}
          pathname={pathname}
          brandIcon={item.image}
        />
      ))}
    </Flex>
  );
};

export default BrandsCarousel;
