import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { ProductType } from '../../../interfaces';
import { getProductsByFilter } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { categoryData } from '../../../store/modules/categories';
import { categoryOrder } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';
import { getProductsByFiltersKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const SubCategoryBrandsMobile = () => {
  const navigate = useNavigate();
  const { id, subCategoryId: categoryCode, name: brand } = useParams();
  const { data, isLoading, error, size, setSize } = useSWRInfinite(
    getProductsByFiltersKey({
      brand,
      key: SWRKey.products,
      categoryCode,
    }),
    getProductsByFilter,
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  const { data: categories } = useAppSelector(categoryData);

  const categoryItem = useMemo(
    () => categoryOrder(categories).find((category) => category.code === id),
    [categories, id],
  );

  const subCategoryName = useMemo(() => {
    if (products) {
      return (
        categoryItem?.item
          .reduce((name: string[], item) => {
            if (item.code === categoryCode) {
              name.push(item.subCategory);
            }
            name.push(
              ...item.subItems
                .filter((subItem) => subItem.code === categoryCode)
                .map((subItem) => subItem.description),
            );
            return name;
          }, [])
          .concat(
            products
              ?.filter((product: ProductType) => product.operatingCenterCode === categoryCode)
              .map((product: ProductType) => product?.operatingCenterDesc as string),
          )[0] || ''
      );
    }
  }, [categoryItem, categoryCode, products]);

  if (isLoading) {
    return (
      <ProductCardSkeleton
        array={12}
        onClick={() => navigate(`/category/${categoryItem?.code}/${categoryCode}`)}
      />
    );
  }

  if (error) {
    return (
      <ErrorScreen
        url={`/category/${categoryItem?.code}/${categoryCode}`}
        title={textContent.product.associatedProductsNotFound}
        arrowTitle={transformString(subCategoryName)}
      />
    );
  }

  return (
    <Flex justifyContent="flex-start" direction="column" height="100%" backgroundColor="grey50">
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <GoBack title={transformString(subCategoryName)} subTitle={transformString(brand)} />
        <InfiniteScroll
          dataLength={products.length}
          next={() => setSize(size + 1)}
          hasMore={hasMore}
          loader={<ProductCardSkeleton array={2} />}
        >
          <Grid
            gridTemplateColumns={{
              base: 'repeat(auto-fill, minmax(150px, 1fr))',
              xl: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
          >
            {products &&
              products.map((product, index) => (
                <Flex justifyContent={'center'} key={index} margin="20px 0">
                  <ProductCard
                    product={product}
                    urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_BRAND}?productsBrand=${brand}`}
                  />
                </Flex>
              ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Flex>
  );
};

export default SubCategoryBrandsMobile;
