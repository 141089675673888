import cx from 'classnames';
import React from 'react';
import { Skeleton } from '@chakra-ui/react';

import bannerModule from '../../pages/Admin/Banners/bannersView.module.css';
import { skeletonColumns } from './skeletonColumnsDefinition';
import css from './SortableList.module.css';
import { SkeletonColumn } from './sortableListTypes';

const SortableListSkeleton = () => (
  <div className={bannerModule.bannersTable}>
    <div className={css.container}>
      <div className={css.skeletonHeaders}>
        {skeletonColumns.map((SkeletonColumn: SkeletonColumn, indx) => (
          <div key={indx} className={cx(css.header, SkeletonColumn.cellStyle)}>
            {SkeletonColumn.title}
          </div>
        ))}
      </div>
      {Array(3)
        .fill(null)
        .map((row) => (
          <div className={bannerModule.activeBanner} key={row}>
            <div className={bannerModule.row}>
              <div className={bannerModule.actionsHeader}>
                <Skeleton width="90%" height="20px" />
              </div>
              <div className={bannerModule.titleHeader}>
                <Skeleton width="90%" height="20px" />
              </div>
              <div className={bannerModule.desktopHeader}>
                <div className={bannerModule.desktopImageContainer}>
                  <Skeleton borderRadius="5%" width="90%" height="83px" />
                </div>
              </div>
              <div className={bannerModule.mobileHeader}>
                <div className={bannerModule.desktopImageContainer}>
                  <Skeleton borderRadius="5%" width="90%" height="48px" />
                </div>
              </div>
              <div className={bannerModule.actionsHeader}>
                <Skeleton width="90%" height="20px" />
              </div>
              <div className={bannerModule.actionsHeader}>
                <Skeleton width="90%" height="20px" />
              </div>
            </div>
          </div>
        ))}
    </div>
  </div>
);

export default SortableListSkeleton;
