import { createSelector } from '@reduxjs/toolkit';

import { categoryOrder } from '../../../utils/categoryOrder';
import { RootState } from '../../types';

export const categoryData = (state: RootState) => state.category;

export const categoryList = (state: RootState) => state.category.data;

export const isLoadingCategory = (state: RootState) => state.category.loading;

export const categoryError = (state: RootState) => state.category.error;

export const structuredAndOrderedCategory = createSelector(
  categoryList,
  (_state: RootState, id: string) => id,
  (category, id) => categoryOrder(category).find((category) => category.code === id),
);
