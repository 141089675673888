import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import EmptyState from '../../../../assets/categories/EmptyState';

interface EmptyProductViewProps {
  title: string;
}

const EmptyProductView = ({ title }: EmptyProductViewProps) => {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" paddingBottom="120px">
      <EmptyState dataTestId="empty-state" />
      <Text
        fontSize="18px"
        fontStyle="normal"
        fontWeight="600"
        lineHeight="29px"
        data-testid="error-message-title"
      >
        {title}
      </Text>
    </Flex>
  );
};

export default EmptyProductView;
