export const fileToBase64 = async (blob: File): Promise<string> => {
  const text = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      if (typeof reader.result !== 'string') {
        return reject('Unexpected reader.result type: ' + typeof reader.result);
      }
      resolve(reader.result);
    };

    reader.onerror = reject;
  });
  const base64file = text.slice(text.indexOf(','));
  return `data:${blob.type};base64${base64file}`;
};
