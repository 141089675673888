import React, { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { Checkbox } from '@chakra-ui/react';

import { FormattedStatus, Voucher } from '../../../../interfaces/voucher';

export interface SelectCellProps {
  selectedVouchers: string[];
  setSelectedVouchers: Dispatch<SetStateAction<string[]>>;
  row: {
    original: Voucher;
    getIsSelected: () => boolean;
    getCanSelect: () => boolean;
    getToggleSelectedHandler: () => FormEventHandler<HTMLInputElement> | undefined;
  };
}

const SelectCell = ({ row, selectedVouchers, setSelectedVouchers }: SelectCellProps) => {
  const isChecked = selectedVouchers.some((id: string) => id === row.original.IDTablaWS);
  return (
    <Checkbox
      data-testid="cell-checkbox"
      isChecked={isChecked}
      isDisabled={row.original.status === FormattedStatus.Anulada}
      onChange={() => {
        isChecked
          ? setSelectedVouchers((prevState) =>
              prevState.filter((id) => id !== row.original.IDTablaWS),
            )
          : setSelectedVouchers((prevState) => [...prevState, row.original.IDTablaWS]);
      }}
      colorScheme="white"
      iconColor="#3377FF"
      borderColor="#3377FF"
    />
  );
};

export default SelectCell;
