import React, { cloneElement, Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

import ErrorMessageIcon from '../../../assets/Icons/ErrorMessageIcon';
import { VoucherProductWithStock } from '../../../interfaces/voucher';
import { useAppSelector } from '../../../store/hooks';
import { getUserPriceList } from '../../../store/modules/auth';
import { calculateRemainingStock } from '../../../utils/calculateRemainingStock';
import { priceFormatter } from '../../../utils/priceFormatter';
import { cutString, transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import { ModifyCartQuantity } from '../ReservationProductCard/validations';

type ChildComponentType<T> = React.ReactElement<{
  product: T & ProductType;
  breakPoint: string;
  vouchersProducts?: VoucherProductWithStock[];
  setVoucher?: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>;
}>;

export type ProductType = {
  quantity: number;
  existence: number;
  pendingToReceive: number;
  totalStock: number;
  articleDesc?: string;
  priceList?: number;
  unitPrice?: number;
  description?: string;
  productImageUrl: string;
  articleCode: string;
};

interface ReservationProductCardProps<T> {
  children: ChildComponentType<T>;
  breakPoint: string;
  inputName: string;
  product: T & ProductType;
  isCancelled?: boolean;
  errors: { [key: string]: string };
  vouchersProducts?: VoucherProductWithStock[];
  setVoucher?: React.Dispatch<React.SetStateAction<VoucherProductWithStock[]>>;
  onClick: () => void;
  setError: Dispatch<SetStateAction<{ [key: string]: string }>>;
}

const ReservationProductCard = <T,>({
  children,
  breakPoint,
  product,
  isCancelled = false,
  inputName,
  vouchersProducts,
  setVoucher,
  onClick,
  setError,
}: ReservationProductCardProps<T>) => {
  const navigate = useNavigate();
  const userPriceList = useAppSelector(getUserPriceList);

  const memoErrors = useMemo(
    () => ModifyCartQuantity(product.quantity, product.totalStock),
    [product.quantity, product.totalStock],
  );

  const stockError = memoErrors?.message === textContent.product.messageMaximum;

  useEffect(() => {
    if (memoErrors) {
      setError((prevErrors) => ({
        ...prevErrors,
        [inputName]: memoErrors.message,
      }));
    } else {
      setError((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[inputName];
        return newErrors;
      });
    }
  }, [inputName, memoErrors, setError]);

  const availableStock = useMemo(() => {
    if (!product) return null;

    const stock = calculateRemainingStock(product, product.quantity);

    return stock;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, product.quantity]);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      borderRadius="8px"
      borderBottom="1px solid #E2E8F0"
      backgroundColor={{ base: 'grey50', md: 'background' }}
      padding={{ base: '16px 24px', md: '24px 32px' }}
    >
      <Flex
        maxWidth={{ md: '100%', xl: '721px' }}
        width="100%"
        justifyContent="space-between"
        gap="16px"
        data-testid="reservation-product-card-container"
      >
        <Flex
          justifyContent="center"
          borderRadius="4px"
          border="1px solid #E2E8F0"
          data-testid="product-image-container"
          onClick={() => navigate(`/product/${product?.articleCode}`)}
          cursor="pointer"
        >
          <Image
            src={product.productImageUrl}
            alt={transformString(product.articleDesc)}
            maxH="100px"
            maxW="100px"
            borderRadius="4px"
            data-testid="product-image"
          />
        </Flex>
        <Flex
          minWidth={{ base: '10%', md: '120px' }}
          width="100%"
          flexDirection="column"
          data-testid="product-info"
        >
          <Text
            fontSize={{ base: '11px', md: '14px' }}
            color="darkBlue"
            fontWeight="400"
            marginBottom="8px"
            data-testid="product-title"
          >
            {cutString(product.articleDesc ?? product.description?.toUpperCase())}
          </Text>
          {!isCancelled && (
            <>
              {userPriceList === '001' && (
                <>
                  <Text
                    fontSize={{ base: '8px', md: '11px' }}
                    color={stockError && !isCancelled ? 'errorRed' : 'darkBlue'}
                    fontWeight="400"
                    data-testid="product-available-stock"
                  >
                    {textContent.branchAvailableStock}: {availableStock?.existence}
                  </Text>
                  <Text
                    fontSize={{ base: '8px', md: '11px' }}
                    color={stockError && !isCancelled ? 'errorRed' : 'darkBlue'}
                    fontWeight="400"
                    data-testid="product-stock-on-trip"
                  >
                    {textContent.onTripStock}: {availableStock?.pendingToReceive}
                  </Text>
                </>
              )}

              <Text
                fontSize={{ base: '8px', md: '11px' }}
                color={stockError && !isCancelled ? 'errorRed' : 'darkBlue'}
                fontWeight="400"
                data-testid="product-stock-total"
              >
                {textContent.totalStock}: {availableStock?.totalStock}
              </Text>
            </>
          )}
        </Flex>
        <Flex
          maxWidth="119px"
          minWidth="110px"
          width="100%"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
          data-testid="product-controls"
        >
          {cloneElement(children, {
            product: product as T & {
              quantity: number;
              existence: number;
              pendingToReceive: number;
              totalStock: number;
              articleDesc: string;
              priceList?: number;
              unitPrice?: number;
              productImageUrl: string;
              articleCode: string;
            },
            breakPoint: breakPoint,
            vouchersProducts: vouchersProducts,
            setVoucher,
          })}
          <Text fontSize="16px" color="darkBlue" fontWeight="500" data-testid="product-price">
            {(product.priceList && priceFormatter.format(product.priceList)) ??
              (product.unitPrice && priceFormatter.format(product.unitPrice)) ??
              0 * product.quantity}
          </Text>
          {!isCancelled && (
            <Text
              fontSize={breakPoint === 'xs' ? '10px' : '14px'}
              color="purple"
              fontWeight="500"
              textDecoration="underline"
              cursor="pointer"
              onClick={onClick}
              data-testid="product-delete-product-button"
            >
              {textContent.cart.deleteProduct}
            </Text>
          )}
        </Flex>
      </Flex>
      {memoErrors && !isCancelled && (
        <Flex alignItems="center" maxWidth="340px" width="100%" padding="8px 20px">
          <Box width="16px" height="16px">
            <ErrorMessageIcon dataTestId="error-message-icon" />
          </Box>
          <Text
            color="errorRed"
            fontFamily="Roboto"
            fontSize="12px"
            fontWeight="500"
            marginLeft="8px"
          >
            {memoErrors.message}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ReservationProductCard;
