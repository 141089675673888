import * as yup from 'yup';

import textContent from '../../../../utils/textContent.json';

export const AddProductImageValidation = () =>
  yup.object().shape({
    description: yup
      .string()
      .min(3, textContent.product.minDescription)
      .max(380, textContent.product.maxDescription)
      .required(textContent.product.fieldRequired),
    productImageUrl: yup
      .string()
      .required(textContent.product.fieldRequired)
      .typeError(textContent.product.fieldRequired),
  });
