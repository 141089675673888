import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Grid } from '@chakra-ui/react';

import { URL } from '../../../api';
import { ProductType } from '../../../interfaces';
import { getProductsByBrand } from '../../../services/product';
import { getProductsByBrandKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const Brand = () => {
  const navigate = useNavigate();
  const { name: brand } = useParams();

  const { data, isLoading, error, size, setSize } = useSWRInfinite(
    getProductsByBrandKey(brand as string),
    getProductsByBrand,
  );

  const { products, hasMore } = useMemo(() => {
    if (!data) {
      return {
        products: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      products: data.map((data) => data.products).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  if (isLoading) {
    return <ProductCardSkeleton onClick={() => navigate('/')} />;
  }

  if (error || products.length === 0) {
    return (
      <ErrorScreen title={textContent.product.associatedProductsNotFound} arrowTitle={brand} />
    );
  }

  return (
    <Flex
      data-testid="products-brand-container"
      justifyContent="flex-start"
      direction="column"
      height="100%"
      backgroundColor="grey50"
    >
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <GoBack title={brand} />
        <InfiniteScroll
          dataLength={products.length}
          next={() => setSize(size + 1)}
          hasMore={hasMore}
          loader={<ProductCardSkeleton array={2} />}
        >
          <Grid
            gridTemplateColumns={{
              base: 'repeat(auto-fill, minmax(150px, 1fr))',
              xl: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
          >
            {products.map((product: ProductType, index: number) => (
              <Flex justifyContent={'center'} key={index} margin="20px 0">
                <ProductCard
                  product={product}
                  urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_BRAND}?productsBrand=${name}`}
                />
              </Flex>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Flex>
  );
};

export default Brand;
