import { createSlice } from '@reduxjs/toolkit';

import textContent from '../../../utils/textContent.json';
import { getUserById } from './actions';
import { InitialState } from './types';

export const usersSlice = createSlice({
  name: 'user',
  initialState: {
    data: {},
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {
    clearUserData: () => {
      return {
        data: {},
        loading: false,
        error: undefined,
      } as InitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.data = {
          ...action.payload,
          isAdmin: false,
        };

        state.loading = false;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      });
  },
});

export const userReducer = usersSlice.reducer;
export const { clearUserData } = usersSlice.actions;
