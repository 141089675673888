import React from 'react';
import { Tooltip } from '@chakra-ui/react';

import textContent from '../../../utils/textContent.json';

interface Props {
  children: React.ReactNode;
  bgIcon: boolean;
  alertIsOpen: boolean;
}

const FavoriteTooltip = ({ children, bgIcon, alertIsOpen }: Props) => {
  return (
    <Tooltip
      hasArrow
      label={bgIcon ? textContent.favorite.savedFavorites : textContent.favorite.removedFavorite}
      background="lightViolet"
      color="darkBlue"
      fontSize="14.4px"
      fontWeight="400"
      placement="bottom-end"
      isOpen={alertIsOpen}
      data-testid="alert-tooltip-delete-favorite"
    >
      {children}
    </Tooltip>
  );
};

export default FavoriteTooltip;
