import useSWR from 'swr';
import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';

import { SWRKey } from '../../../enum/SWRKey';
import { MonthAndYear } from '../../../interfaces';
import { getVoucher } from '../../../services/voucher';
import textContent from '../../../utils/textContent.json';
import { voucherFormatter } from '../../../utils/voucherFormatter';
import GoBack from '../../shared/GoBack/GoBack';
import PaginationNavbar from '../../shared/PaginationNavbar/PaginationNavbar';
import EmptyReservationHistory from './EmptyReservationHistory';
import ReservationHistoryCard from './ReservationHistoryCard';
import ReservationHistoryCardSkeleton from './ReservationHistoryCardSkeleton';
import Filters from './ReservationHistoryFilters';

const ReservationHistory = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [monthAndYear, setMonthAndYear] = useState<MonthAndYear>({
    month: '',
    year: '',
  });

  const {
    data: vouchersData,
    error: errorVoucher,
    isLoading: loadingVouchers,
  } = useSWR({ currentPage, monthAndYear, key: SWRKey.reservationHistory }, getVoucher);

  const shouldRenderFilters =
    monthAndYear.month === '' && monthAndYear.year === '' && !vouchersData?.vouchers?.length;

  const ReservationList = React.useMemo(() => {
    if (loadingVouchers) return <ReservationHistoryCardSkeleton />;
    if (errorVoucher || !vouchersData?.vouchers.length) return <EmptyReservationHistory />;
    const formattedVouchers = voucherFormatter(vouchersData.vouchers);
    return (
      <Flex height="100%" flexDirection="column" justifyContent="space-between">
        <Flex
          flexDirection="column"
          padding={{ base: '0', md: '32px 0' }}
          backgroundColor="grey50"
          width="100%"
          alignItems={{ base: 'center', xl: 'flex-start' }}
          data-testid="reservation-history-container"
        >
          {formattedVouchers.map((voucher, index) => (
            <ReservationHistoryCard key={index} voucher={voucher} />
          ))}
        </Flex>
        <PaginationNavbar
          totalPages={vouchersData?.totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    );
  }, [currentPage, vouchersData, errorVoucher, loadingVouchers]);

  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor="grey50"
      width="100%"
      height="100%"
    >
      <GoBack title={textContent.reservationHistory} />
      {!shouldRenderFilters && (
        <Filters monthAndYear={monthAndYear} setMonthAndYear={setMonthAndYear} />
      )}
      {ReservationList}
    </Flex>
  );
};

export default ReservationHistory;
