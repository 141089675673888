import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { User } from './types';

const incompletePhoneUserServer =
  'The user has been successfully found but does not have a phone number.';
const incompleteListUserServer =
  'The user has been successfully found but does not have a price list.';

export const getUserById = createAsyncThunk(
  'GET_USER_BY_ID',
  async (data: { userId: string }, { rejectWithValue }) => {
    try {
      const { userId } = data;
      const userUrl = `${URL.GET_USER}/${userId}`;
      const response = await get<User>(userUrl);

      return response;
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error.response?.data.success === incompletePhoneUserServer ||
          error.response?.data.success === incompleteListUserServer)
      ) {
        return rejectWithValue(`${textContent.incompleteUser}`);
      }
      if (isAxiosError(error) && error.response?.status === 404) {
        return rejectWithValue(`${textContent.invalidClientCode}`);
      }
      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.foundProblem}`);
      }
      return rejectWithValue(`${textContent.foundProblem}`);
    }
  },
);
