import { useSWRConfig } from 'swr';
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Input,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';

import CrossIcon from '../../../assets/Icons/CrossIcon';
import HandIcon from '../../../assets/Icons/HandIcon';
import { ModalType } from '../../../enum/ModalType';
import { FormattedStatus, Status } from '../../../interfaces/voucher';
import {
  activateManualDelete,
  deactivateManualDelete,
  deleteAdminVouchers,
} from '../../../services/voucher';
import { useAppDispatch } from '../../../store/hooks';
import { showModal } from '../../../store/modules/ui';
import textContent from '../../../utils/textContent.json';
import { pageSizes } from '../../pages/Admin/Reservations/constants';
import { FilterType } from '../../pages/Admin/Reservations/reservationTypes';

interface hasSelectType {
  selectedVouchers: string[];
  setSelectedVouchers: Dispatch<SetStateAction<string[]>>;
}

interface TableProps<T> {
  hasSelects?: hasSelectType;
  data?: T[];
  columns: ColumnDef<T>[];
  filters?: JSX.Element;
  filtersState?: FilterType;
  dataTestId: string;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  total: number;
  pageCount?: number;
  pagination: PaginationState;
  searchPlaceholder?: string;
  justifyFilters?: string;
  hasActionIcon?: boolean;
  searchBarWidth?: string;
  padding?: string;
  marginBottom?: string;
  searchValue?: string;
  searchType?: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
  error?: boolean;
  headerAlign?: boolean;
  selectedVouchers?: string[];
  setSelectedVouchers?: Dispatch<SetStateAction<string[]>>;
  url?: string;
  isFilter?: boolean;
  emptyComponent?: JSX.Element;
}

const isVoucherType = (rowData: object) => {
  if ('IDTablaWS' in rowData && 'status' in rowData) {
    return { IDTablaWS: rowData.IDTablaWS, status: rowData.status };
  }
};

const SharedTable = <T,>({
  data = [],
  columns,
  hasSelects,
  dataTestId,
  pagination,
  justifyFilters = 'space-between',
  padding = '0',
  marginBottom = '16px',
  searchBarWidth = '100%',
  onPaginationChange,
  total,
  pageCount = 0,
  filters,
  filtersState,
  searchPlaceholder,
  searchValue,
  setSearchValue,
  searchType = 'number',
  error,
  headerAlign,
  emptyComponent,
  url,
  isFilter,
}: TableProps<T>) => {
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { mutate } = useSWRConfig();

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    state: {
      pagination,
    },
    pageCount,
    onPaginationChange,
    getCoreRowModel: getCoreRowModel(),
  });

  const pendingRows = useMemo(() => {
    return table.getRowModel().rows.reduce((acc, row) => {
      if (isVoucherType(row.original as object)?.status === FormattedStatus.Pendiente) {
        acc.push(isVoucherType(row.original as object)?.IDTablaWS as string);
      }
      return acc;
    }, [] as string[]);
  }, [table, data]);

  const showSelects =
    !!hasSelects?.selectedVouchers?.length &&
    hasSelects?.selectedVouchers?.length > 0 &&
    data.length > 0 &&
    filtersState?.status === Status.PENDING;

  const isChecked = useMemo(() => {
    return (
      !!pendingRows.length &&
      pendingRows.every((row) => hasSelects?.selectedVouchers.some((voucher) => voucher === row))
    );
  }, [hasSelects?.selectedVouchers, pendingRows]);

  return (
    <Box width="100%" padding={padding} data-testid={dataTestId} backgroundColor="white">
      <Flex direction="column" gap="16px">
        <Flex justifyContent={justifyFilters} marginBottom={marginBottom}>
          <Flex gap="16px">
            {hasSelects && (
              <Checkbox
                data-testid="select-all-dropdown"
                isChecked={isChecked}
                isDisabled={!pendingRows.length}
                onChange={() => {
                  isChecked
                    ? hasSelects?.setSelectedVouchers(
                        hasSelects?.selectedVouchers?.filter((v) => !pendingRows.includes(v)) ?? [],
                      )
                    : hasSelects?.setSelectedVouchers([
                        ...(hasSelects?.selectedVouchers ?? []),
                        ...pendingRows,
                      ]);
                }}
                colorScheme="white"
                iconColor="#3377FF"
                borderColor="#3377FF"
              >
                {textContent.reservations.selectAll}
              </Checkbox>
            )}
            {filters}
          </Flex>
          {setSearchValue && (
            <Input
              data-testid={'table-searchbar'}
              width={searchBarWidth}
              placeholder={searchPlaceholder}
              type={searchType}
              value={value || searchValue}
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value === '') setSearchValue('');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchValue(value);
                  setValue('');
                }
                if (searchType === 'number') {
                  const allowedCharacters = /[0-9\s]/;
                  if (!allowedCharacters.test(e.key) && !e.ctrlKey && e.key !== 'Backspace') {
                    e.preventDefault();
                  }
                }
                if (searchType === 'text') {
                  const allowedCharacters = /[a-zA-Z0-9\s]/;
                  if (!allowedCharacters.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => {
                if (searchType === 'number') {
                  const paste = (e.clipboardData || window.Clipboard).getData('text');
                  if (!/^\d+$/.test(paste)) {
                    e.preventDefault();
                  }
                }
              }}
            />
          )}
        </Flex>
        {isFilter && (
          <Flex gap="16px" mb="20px" height="20px">
            {showSelects && (
              <>
                <Button
                  variant="link"
                  color="blue"
                  fontSize="14px"
                  gap="4px"
                  data-testId="voucher-delete"
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: ModalType.CONFIRM_VOUCHER_DELETE,
                        callback: async () => {
                          try {
                            await deleteAdminVouchers(hasSelects?.selectedVouchers);
                            await mutate(url);
                            toast({
                              title: textContent.voucher.deleteVoucher.title,
                              description: textContent.voucher.deleteVoucher.successMessage,
                              status: 'success',
                              variant: 'subtle',
                              duration: 3000,
                            });
                            hasSelects.setSelectedVouchers([]);
                          } catch (error) {
                            if (error instanceof Error)
                              toast({
                                title: textContent.voucher.deleteVoucher.title,
                                description: textContent.voucher.deleteVoucher.errorMessage,
                                status: 'error',
                                variant: 'subtle',
                                duration: 3000,
                              });
                          }
                        },
                      }),
                    );
                  }}
                >
                  <CrossIcon dataTestId="cross-icon" />
                  {textContent.reservations.cancelReservation}
                </Button>
                <Button
                  variant="link"
                  color="blue"
                  fontSize="14px"
                  gap="4px"
                  data-testId="activate-manual-delete"
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: ModalType.CONFIRM_MANUAL_DELETE_VOUCHER,
                        data: 'activate',
                        callback: async () => {
                          try {
                            await activateManualDelete(hasSelects?.selectedVouchers);
                            await mutate(url);
                            toast({
                              title: textContent.voucher.manualDelete.activate.title,
                              description: textContent.voucher.manualDelete.activate.successMessage,
                              status: 'success',
                              variant: 'subtle',
                              duration: 3000,
                            });
                            hasSelects.setSelectedVouchers([]);
                          } catch (error) {
                            toast({
                              title: textContent.voucher.manualDelete.activate.title,
                              description: textContent.voucher.manualDelete.activate.errorMessage,
                              status: 'error',
                              variant: 'subtle',
                              duration: 3000,
                            });
                          }
                        },
                      }),
                    );
                  }}
                >
                  <HandIcon dataTestId="hand-icon" />
                  {textContent.reservations.activateManualCancellation}
                </Button>
                <Button
                  variant="link"
                  color="blue"
                  fontSize="14px"
                  gap="4px"
                  data-testId="deactivate-manual-delete"
                  onClick={() => {
                    dispatch(
                      showModal({
                        type: ModalType.CONFIRM_MANUAL_DELETE_VOUCHER,
                        callback: async () => {
                          try {
                            await deactivateManualDelete(hasSelects?.selectedVouchers);
                            await mutate(url);
                            toast({
                              title: textContent.voucher.manualDelete.deactivate.title,
                              description:
                                textContent.voucher.manualDelete.deactivate.successMessage,
                              status: 'success',
                              variant: 'subtle',
                              duration: 3000,
                            });
                            hasSelects.setSelectedVouchers([]);
                          } catch (error) {
                            toast({
                              title: textContent.voucher.manualDelete.deactivate.title,
                              description: textContent.voucher.manualDelete.deactivate.errorMessage,
                              status: 'error',
                              variant: 'subtle',
                              duration: 3000,
                            });
                          }
                        },
                      }),
                    );
                  }}
                >
                  <HandIcon dataTestId="hand-icon" />
                  {textContent.reservations.deactivateManualCancellation}
                </Button>
              </>
            )}
          </Flex>
        )}
      </Flex>
      {error || data?.length <= 0 || !data ? (
        emptyComponent
      ) : (
        <>
          <Box border="1px solid #E2E8F0" borderRadius="8px 8px 0 0">
            <Table data-testid="table-component">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          colSpan={header.colSpan}
                          textAlign={headerAlign ? 'center' : 'initial'}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <Tr key={row.id} data-testid={`row-${row.id}`}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            fontSize="12px"
                            fontWeight="500"
                            key={cell.id}
                            data-testid={`cell-${cell.column.id}`}
                            style={{ width: cell.column.getSize() }}
                            textAlign={headerAlign ? 'center' : 'initial'}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Flex padding="12px 24px" border="1px solid #E2E8F0" borderRadius="0 0 8px 8px">
            <Flex alignItems="center" gap="16px">
              <Text
                width="90px"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="normal"
              >
                {textContent.rowsPerPage}
              </Text>
              <Select
                width="65px"
                height="18px"
                fontSize="10px"
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {pageSizes.map((pagSize) => {
                  return (
                    <option
                      key={pagSize}
                      value={pagSize}
                      selected={pagination.pageSize === pagSize}
                    >
                      {pagSize}
                    </option>
                  );
                })}
              </Select>
            </Flex>
            <Spacer />
            <ButtonGroup gap="3">
              <Button
                height="18px"
                alignSelf="center"
                onClick={table.previousPage}
                isDisabled={!table.getCanPreviousPage()}
                data-testid="previous-page-button"
              >
                <ChevronLeftIcon />
              </Button>
              <Flex fontSize="10px" alignSelf="center">
                {`${
                  table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1
                } - ${
                  table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                  table.getRowModel().rows.length
                } de ${total}`}
              </Flex>
              <Button
                alignSelf="center"
                height="18px"
                onClick={table.nextPage}
                isDisabled={!table.getCanNextPage()}
                data-testid="next-page-button"
              >
                <ChevronRightIcon />
              </Button>
            </ButtonGroup>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default SharedTable;
