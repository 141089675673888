import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import React, { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Image } from '@chakra-ui/react';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { BannersToHome } from '../../../interfaces/banner';
import { useAppSelector } from '../../../store/hooks';
import { isOpenSideMenu } from '../../../store/modules/sideMenu';
import { theme } from '../../../theme';
import Button from '../Button/Button';

interface CarouselProps {
  data?: BannersToHome[];
  children?: React.ReactNode | React.ReactNode[];
  width: number | string;
  height?: number | string;
  btnPositions?: number;
  columns: number;
  md?: number;
  sm?: number;
  base?: number;
  dots: boolean;
  infinite: boolean;
  autoplay: boolean;
  datatestid: string;
  className?: string;
  visibleButtons?: boolean;
  buttonsBackground?: string;
  renderAsImage?: boolean;
}

const Carousel = ({
  data,
  children,
  width,
  height,
  btnPositions,
  columns,
  base,
  md,
  sm,
  dots,
  infinite,
  autoplay,
  datatestid,
  className,
  visibleButtons,
  buttonsBackground,
  renderAsImage = false,
}: CarouselProps) => {
  const [slider, setSlider] = useState<Slider | null>(null);
  const [hoverBtn, setHoverBtn] = useState(false);
  const { isMobile } = useBreakpoint();
  const isSideMenuOpen = useAppSelector(isOpenSideMenu);

  const SampleNextArrow = ({ backgroundColor }: { backgroundColor?: string }) => {
    if (btnPositions && !isMobile && data && data.length > 1) {
      return (
        <Button
          data-testid="right-button"
          color={`${theme.colors.black}`}
          borderRadius="100px 0 0 100px"
          position="absolute"
          backgroundColor={backgroundColor}
          width="45px"
          height="80px"
          onClick={() => slider?.slickNext()}
          bottom={`${btnPositions}px`}
          right={'0'}
          zIndex={isSideMenuOpen ? 0 : 1}
          opacity={visibleButtons || hoverBtn ? '1' : '0'}
          _hover={{ opacity: '1' }}
        >
          <ChevronRightIcon boxSize={6} />
        </Button>
      );
    }
    return null;
  };

  const SamplePrevArrow = ({ backgroundColor }: { backgroundColor?: string }) => {
    if (btnPositions && !isMobile && data && data.length > 1) {
      return (
        <Button
          data-testid="left-button"
          color={`${theme.colors.black}`}
          borderRadius="0 100px 100px 0"
          position="absolute"
          backgroundColor={backgroundColor}
          width="40px"
          height="80px"
          onClick={() => slider?.slickPrev()}
          bottom={`${btnPositions}px`}
          zIndex={isSideMenuOpen ? 0 : 1}
          opacity={visibleButtons || hoverBtn ? '1' : '0'}
          _hover={{ opacity: '1' }}
        >
          <ChevronLeftIcon boxSize={6} />
        </Button>
      );
    }
    return null;
  };

  const settings: Settings = {
    dots: dots,
    dotsClass: 'dots',
    appendDots: (dots: React.ReactNode | React.ReactNode[]) => {
      return <ul style={{ display: 'flex', flexWrap: 'wrap' }}>{dots}</ul>;
    },
    customPaging: (i: number) => {
      return <div key={i} style={{ padding: '7px' }}></div>;
    },
    infinite: infinite,
    autoplay: autoplay,
    speed: 1000,
    autoplaySpeed: 10000,
    slidesToShow: columns,
    slidesToScroll: columns > 1 ? columns / 2 : columns,
    className: className,
    pauseOnHover: true,
    arrows: true,
    adaptiveHeight: renderAsImage ? true : false,
    nextArrow: <SampleNextArrow backgroundColor={buttonsBackground || 'transparent'} />,
    prevArrow: <SamplePrevArrow backgroundColor={buttonsBackground || 'transparent'} />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: md ?? columns,
          slidesToScroll: (md && md / 2) ?? columns,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: base ?? columns,
          slidesToScroll: (base && base / 2) ?? columns,
        },
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: sm ?? columns,
          slidesToScroll: (sm && sm / 2) ?? columns,
        },
      },
    ],
  };

  return (
    <Box
      data-testid={`${datatestid}-carousel-container`}
      width={width}
      height={height}
      borderRadius="10.676px"
      overflow="hidden"
      className="carousel-container"
    >
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {children ??
          data?.map((data: BannersToHome, index: number) => {
            if (data.isActive) {
              return renderAsImage ? (
                <Image
                  width="auto"
                  key={index}
                  data-testid={`carousel-image-${index}`}
                  src={isMobile ? data.mobileImage : data.desktopImage}
                  onMouseEnter={() => setHoverBtn(true)}
                  onMouseLeave={() => setHoverBtn(false)}
                  _active={{ cursor: 'grabbing' }}
                  cursor="grab"
                  height={height}
                />
              ) : (
                <Flex
                  data-testid={`carousel-image-${index}`}
                  key={index}
                  height={height}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="10.676px"
                  backgroundSize="cover"
                  backgroundPosition="center"
                  backgroundImage={{
                    base: `url(${data.mobileImage})`,
                    md: `url(${data.desktopImage})`,
                  }}
                  _active={{ cursor: 'grabbing' }}
                  cursor="grab"
                  onMouseEnter={() => setHoverBtn(true)}
                  onMouseLeave={() => setHoverBtn(false)}
                />
              );
            }
            return null;
          })}
      </Slider>
    </Box>
  );
};

export default Carousel;
