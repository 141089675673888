import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  isOpen: boolean;
}

export const sideMenuSlice = createSlice({
  name: 'sideMenuIsOpen',
  initialState: {
    isOpen: false,
  } as InitialState,
  reducers: {
    openMenu: (state) => {
      state.isOpen = true;
    },
    closeMenu: (state) => {
      state.isOpen = false;
    },
  },
});

export const sideMenuReducer = sideMenuSlice.reducer;
export const { openMenu, closeMenu } = sideMenuSlice.actions;
