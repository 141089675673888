import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/react';

import EmptyCartIcon from '../../../assets/Icons/EmptyCartIcon';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';
import GoBack from '../../shared/GoBack/GoBack';

const EmptyCart = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      height="90vh"
      alignItems="center"
      marginBottom="20px"
      data-testid="empty-cart-container"
      backgroundColor="white"
    >
      <GoBack title={textContent.cart.reservationCart} />
      <Flex flexDirection="column" alignItems="center" data-testid="empty-cart-content">
        <EmptyCartIcon dataTestId={'empty-cart-icon'} isMobile={isMobile} />
        <Text
          color="darkBlue"
          textAlign="center"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="500"
          marginBottom="24px"
          data-testid="empty-cart-title"
        >
          {textContent.cart.reservationCart}
        </Text>
        <Text
          color="darkBlue"
          textAlign="center"
          fontSize={{ base: '18px', md: '20px' }}
          fontWeight="500"
          marginBottom="12px"
          padding="0 10px"
          data-testid="empty-cart-description"
        >
          {textContent.cart.notReservedProducts}
        </Text>
        <Text
          color="darkBlue"
          textAlign="center"
          fontSize={{ base: '18px', md: '20px' }}
          fontWeight="500"
          marginBottom="50px"
          data-testid="empty-cart-call-to-action"
        >
          {textContent.cart.startReserve}
        </Text>
      </Flex>
      <Button
        color="grey50"
        backgroundColor="purple"
        content={textContent.start}
        _hover={{
          backgroundColor: { base: 'none', xl: 'darkPurple' },
        }}
        _active={{
          backgroundColor: { base: 'darkPurple', xl: 'none' },
        }}
        _disabled={{
          backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
          pointerEvents: 'none',
        }}
        height="48px"
        width="80%"
        maxWidth="312px"
        onClick={() => navigate('/')}
        data-testid="start-reserve-button"
      />
    </Flex>
  );
};

export default EmptyCart;
