import React from 'react';

import { CategoriesIconPros } from './DrinksIcon';

const FailReservationIcon = ({ dataTestId }: CategoriesIconPros) => {
  return (
    <svg
      data-testid={dataTestId}
      width="201"
      height="160"
      viewBox="0 0 201 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="200" height="160" fill="#FAFAFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8996 107.2H123.7C124.112 107.2 124.513 107.156 124.9 107.071C125.286 107.156 125.688 107.2 126.1 107.2H167.7C170.792 107.2 173.3 104.693 173.3 101.6C173.3 98.5072 170.792 96 167.7 96H162.9C159.807 96 157.3 93.4928 157.3 90.4C157.3 87.3072 159.807 84.8 162.9 84.8H178.1C181.192 84.8 183.7 82.2928 183.7 79.2C183.7 76.1072 181.192 73.6 178.1 73.6H160.5C163.592 73.6 166.1 71.0928 166.1 68C166.1 64.9072 163.592 62.4 160.5 62.4H109.3C112.392 62.4 114.9 59.8928 114.9 56.8C114.9 53.7072 112.392 51.2 109.3 51.2H63.6996C60.6068 51.2 58.0996 53.7072 58.0996 56.8C58.0996 59.8928 60.6068 62.4 63.6996 62.4H31.6996C28.6068 62.4 26.0996 64.9072 26.0996 68C26.0996 71.0928 28.6068 73.6 31.6996 73.6H51.6996C54.7924 73.6 57.2996 76.1072 57.2996 79.2C57.2996 82.2928 54.7924 84.8 51.6996 84.8H19.6996C16.6068 84.8 14.0996 87.3072 14.0996 90.4C14.0996 93.4928 16.6068 96 19.6996 96H50.8996C47.8068 96 45.2996 98.5072 45.2996 101.6C45.2996 104.693 47.8068 107.2 50.8996 107.2ZM181.3 107.2C184.392 107.2 186.9 104.693 186.9 101.6C186.9 98.5072 184.392 96 181.3 96C178.207 96 175.7 98.5072 175.7 101.6C175.7 104.693 178.207 107.2 181.3 107.2Z"
        fill="#EBF1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1 112C64.3798 112 56.5 104.299 56.5 94.8C56.5 85.3007 64.3798 77.6 74.1 77.6C74.5133 77.6 74.9234 77.6139 75.3296 77.6413C75.0473 76.0702 74.9 74.4523 74.9 72.8C74.9 57.7778 87.0779 45.6 102.1 45.6C114.093 45.6 124.273 53.3616 127.891 64.1356C128.748 64.0459 129.619 64 130.5 64C143.976 64 154.9 74.7451 154.9 88C154.9 100.592 145.04 110.982 132.5 111.983V112H87.3062H74.1ZM83.6968 112H78.1251H83.6968Z"
        fill="#FAFAFC"
      />
      <path
        d="M83.6968 112H78.1251M74.1 112C64.3798 112 56.5 104.299 56.5 94.8C56.5 85.3007 64.3798 77.6 74.1 77.6C74.5133 77.6 74.9234 77.6139 75.3296 77.6413C75.0473 76.0702 74.9 74.4523 74.9 72.8C74.9 57.7778 87.0779 45.6 102.1 45.6C114.093 45.6 124.273 53.3616 127.891 64.1356C128.748 64.0459 129.619 64 130.5 64C143.976 64 154.9 74.7451 154.9 88C154.9 100.592 145.04 110.982 132.5 111.983V112H87.3063H74.1Z"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.7901 51.4741C93.7901 77.2526 117.006 98.4944 146.9 101.362C143.154 105.969 137.369 109.094 130.778 109.585V109.6H77.0946C70.5813 109.6 59.7002 106.856 59.7002 94.8387C59.7002 82.8216 68.1595 80.0774 77.0946 80.0774C77.4746 80.0774 77.8515 80.0893 78.2249 80.1128C77.9655 78.7645 77.9199 77.3747 77.83 75.9579C77.2232 66.3874 81.3985 54.3256 93.8045 50.4C93.7946 50.7579 93.7901 51.1156 93.7901 51.4741ZM102.193 91.8064C99.7623 91.8064 97.7916 93.7537 97.7916 96.1559C97.7916 98.5581 99.7623 100.505 102.193 100.505C104.624 100.505 106.595 98.5581 106.595 96.1559C106.595 93.7537 104.624 91.8064 102.193 91.8064Z"
        fill="#EBF1FF"
      />
      <path
        d="M102.5 100.8C104.93 100.8 106.9 98.8301 106.9 96.4C106.9 93.9699 104.93 92 102.5 92C100.07 92 98.0996 93.9699 98.0996 96.4C98.0996 98.8301 100.07 100.8 102.5 100.8Z"
        stroke="#5C92FF"
        strokeWidth="2.5"
      />
      <path
        d="M90.0996 87.2L95.6996 82.8055L90.0996 78.6221"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.9 87.2L109.3 82.8055L114.9 78.6221"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.1 53.6C115.307 54.978 119.419 59.0503 120.853 64.2345"
        stroke="#75A4FE"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M126.9 40C128.225 40 129.3 38.9255 129.3 37.6C129.3 36.2745 128.225 35.2 126.9 35.2C125.575 35.2 124.5 36.2745 124.5 37.6C124.5 38.9255 125.575 40 126.9 40Z"
        stroke="#75A4FE"
        strokeWidth="2"
      />
      <path
        d="M151.7 52.8C153.025 52.8 154.1 51.7255 154.1 50.4C154.1 49.0745 153.025 48 151.7 48C150.374 48 149.3 49.0745 149.3 50.4C149.3 51.7255 150.374 52.8 151.7 52.8Z"
        fill="#C0D5FF"
      />
      <path
        d="M133.105 46.2059L139.792 52.8925M139.894 46.2059L133.207 52.8925L139.894 46.2059Z"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.623 60.4769L62.423 65.2769M62.423 60.4769L57.623 65.2769L62.423 60.4769Z"
        stroke="#FF923F"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.4996 55.2C69.8251 55.2 70.8996 54.1255 70.8996 52.8C70.8996 51.4745 69.8251 50.4 68.4996 50.4C67.1741 50.4 66.0996 51.4745 66.0996 52.8C66.0996 54.1255 67.1741 55.2 68.4996 55.2Z"
        fill="#C0D5FF"
      />
    </svg>
  );
};

export default FailReservationIcon;
