import { Column } from '../../../shared/SortableList/sortableListTypes';
import css from './bannersView.module.css';

export const columns: Column[] = [
  {
    title: 'Posición',
    cellStyle: css.actionsHeader,
  },
  {
    title: 'Título',
    cellStyle: css.titleHeader,
  },
  {
    title: 'Desktop Preview',
    cellStyle: css.desktopHeader,
  },
  {
    title: 'Mobile Preview',
    cellStyle: css.mobileHeader,
  },
  {
    title: 'Eliminar',
    cellStyle: css.actionsHeader,
  },
  {
    title: 'Pausar',
    cellStyle: css.actionsHeader,
  },
];
