import { createSlice } from '@reduxjs/toolkit';

import { ProductType } from '../../../interfaces';
import textContent from '../../../utils/textContent.json';
import { addProductFavorite, deleteFavorite, getFavoriteList } from './actions';
import { InitialState } from './types';

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: {
    favorites: [],
    newFavorite: {},
    loading: false,
    error: undefined,
  } as InitialState,
  reducers: {
    clearFavorite: () => {
      return {
        favorites: [],
        newFavorite: {},
        loading: false,
        error: undefined,
      } as InitialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFavoriteList.pending, (state) => {
        state.favorites = [];
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getFavoriteList.fulfilled, (state, action) => {
        state.loading = false;
        state.favorites = action.payload.data;
      })
      .addCase(getFavoriteList.rejected, (state, action) => {
        state.loading = false;
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
      })
      .addCase(addProductFavorite.pending, (state) => {
        state.loading = false;
        state.error = undefined;
      })
      .addCase(addProductFavorite.fulfilled, (state, { payload }) => {
        const newFavorites = [...state.favorites, payload];
        state.favorites = newFavorites as ProductType[];
        state.loading = false;
      })
      .addCase(addProductFavorite.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      })
      .addCase(deleteFavorite.pending, (state) => {
        state.loading = false;
        state.error = undefined;
      })
      .addCase(deleteFavorite.fulfilled, (state, action) => {
        const newFavoriteList = state.favorites.filter(
          (favorite) => favorite.articleCode !== action.payload.data.data.productId,
        );
        state.favorites = newFavoriteList;
        state.loading = false;
      })
      .addCase(deleteFavorite.rejected, (state, action) => {
        state.error =
          typeof action.payload === 'string' ? action.payload : textContent.foundProblem;
        state.loading = false;
      });
  },
});

export const favoriteReducer = favoriteSlice.reducer;
export const { clearFavorite } = favoriteSlice.actions;
