import useSWR from 'swr';
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { URL } from '../../../../api';
import { SWRKey } from '../../../../enum/SWRKey';
import { ProductType } from '../../../../interfaces';
import {
  getHomeDealsProducts,
  getHomeOilsProducts,
  getHomeOnTripProducts,
} from '../../../../services/product';
import { useAppSelector } from '../../../../store/hooks';
import { isLoadingFavorites } from '../../../../store/modules/favorites';
import textContent from '../../../../utils/textContent.json';
import ProductsCarousel from '../ProductsCarousel';
import CarouselSkeleton from './CarouselSkeleton';

const HomeCarousels = () => {
  const loadingFavorites = useAppSelector(isLoadingFavorites);

  const { data: dealsData, isLoading: dealsLoading } = useSWR(
    { key: SWRKey.homeDeals },
    getHomeDealsProducts,
  );
  const { data: onTripData, isLoading: onTripLoading } = useSWR(
    { key: SWRKey.homeOnTrip },
    getHomeOnTripProducts,
  );
  const { data: oilsData, isLoading: oilsLoading } = useSWR(
    { key: SWRKey.homeOils },
    getHomeOilsProducts,
  );

  const renderProductList = ({ title, list }: { list?: ProductType[]; title: string }) => {
    if (list && list.length) {
      return (
        <Flex key={title} flexDirection="column" data-testid={`${title}-carousel-container`}>
          <Flex alignItems="end" height="60px">
            <Text
              marginLeft={{ base: '20px', xl: '129px' }}
              backgroundColor="orange"
              color="white"
              borderRadius="15.343px 0px"
              width="100px"
              height="25px"
              textAlign="center"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="700"
              position="relative"
              top="10px"
              data-testid={`${title}-carousel-title`}
            >
              {title}
            </Text>
          </Flex>
          <ProductsCarousel
            backgroundColor="grey3"
            dataTestId={`${title}-products-carousel`}
            productsList={list}
            isLoading={false}
            carouselTitle={title}
            urlKey={`${URL.GET_HOME_PAGINATED_PRODUCTS}`}
          />
        </Flex>
      );
    }
    return null;
  };

  return (
    <Box>
      {loadingFavorites || dealsLoading ? (
        <CarouselSkeleton />
      ) : (
        renderProductList({
          title: textContent.deals,
          list: dealsData,
        })
      )}
      {loadingFavorites || onTripLoading ? (
        <CarouselSkeleton />
      ) : (
        renderProductList({
          title: textContent.onTrip,
          list: onTripData,
        })
      )}
      {loadingFavorites || oilsLoading ? (
        <CarouselSkeleton />
      ) : (
        renderProductList({
          title: textContent.oils,
          list: oilsData,
        })
      )}
    </Box>
  );
};

export default HomeCarousels;
