import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import ManualDeleteVoucherIcon from '../../../../assets/Icons/ManualDeleteVoucherIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getModalState } from '../../../../store/modules/ui/selectors';
import { hideModal } from '../../../../store/modules/ui/slice';
import textContent from '../../../../utils/textContent.json';
import Button from '../../../shared/Button/Button';

interface Props {
  isMobile: boolean;
}

const ConfirmManualDeleteModal = ({ isMobile }: Props) => {
  const dispatch = useAppDispatch();

  const { data, callback } = useAppSelector(getModalState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isActivate = data === 'activate';

  const title = isActivate
    ? textContent.voucher.manualDelete.activate.title
    : textContent.voucher.manualDelete.deactivate.title;

  const modalMessage = isActivate
    ? textContent.voucher.manualDelete.activate.modalMessage
    : textContent.voucher.manualDelete.deactivate.modalMessage;

  const onConfirm = async () => {
    setLoading(true);
    if (callback) {
      await callback();
    }
    setLoading(false);
    dispatch(hideModal());
  };

  return (
    <>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            color={loading ? 'grey' : ''}
            data-testid="close-delete-voucher-modal-icon"
            onClick={() => {
              if (!loading) dispatch(hideModal());
            }}
            cursor={loading ? 'default' : 'pointer'}
          />
        </Flex>
      )}
      <Flex
        paddingLeft={{ base: '36px', xl: '36px' }}
        paddingRight={{ base: '36px', xl: '36px' }}
        paddingBottom={{ base: '50px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="delete-voucher-modal-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ xl: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="85%"
        >
          <ManualDeleteVoucherIcon />
          <Text
            marginTop={{ base: '48px', md: '24px' }}
            data-testid="delete-voucher-label"
            fontSize={{ base: '25px' }}
            marginBottom={{ base: '48px', md: '30px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {title}
          </Text>
          <Text
            marginBottom={{ base: '30px' }}
            data-testid="delete-voucher-session-label"
            fontSize={{ base: '18px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {modalMessage}
          </Text>
          <Text
            data-testid="delete-voucher-with-continue-label"
            fontSize={{ base: '20px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="grey900"
          >
            {textContent.wishToContinue}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'space-between' }} height="5%">
          <Button
            width={{ base: '45%' }}
            content={textContent.cancel}
            data-testid="delete-voucher-cancel-button"
            color="purple"
            backgroundColor="background"
            height={{ base: '40px' }}
            border="1px solid"
            borderColor="purple"
            isDisabled={loading}
            onClick={() => {
              dispatch(hideModal());
            }}
            fontSize="14px"
          />
          <Button
            width={{ base: '45%' }}
            data-testid="delete-voucher-confirm-button"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            isLoading={loading}
            content={textContent.continue}
            onClick={onConfirm}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          ></Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ConfirmManualDeleteModal;
