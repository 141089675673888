import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import ConfirmReservationIcon from '../../../../assets/Icons/ConfirmReservationIcon';
import textContent from '../../../../utils/textContent.json';

const EmptyBranches = () => {
  return (
    <>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <ConfirmReservationIcon dataTestId="empty-offices-icon" />
        <Text
          width="256px"
          textAlign="center"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="500"
          data-testid="empty-offices-message"
        >
          {textContent.emptyBranches}
        </Text>
      </Flex>
    </>
  );
};

export default EmptyBranches;
