import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Image, Text } from '@chakra-ui/react';

import { ProductType } from '../../../interfaces';
import { useAppSelector } from '../../../store/hooks';
import { getUserPriceList } from '../../../store/modules/auth';
import { priceFormatter } from '../../../utils/priceFormatter';
import { cutString, transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';

interface FavoriteCardProps {
  product: ProductType;
  onDeleteFavorite: (favorite: ProductType) => void;
  setFavoriteId: React.Dispatch<React.SetStateAction<string>>;
}

const FavoriteCard = ({ product, onDeleteFavorite, setFavoriteId }: FavoriteCardProps) => {
  const navigate = useNavigate();
  const userPriceList = useAppSelector(getUserPriceList);

  return (
    <Flex
      backgroundColor={{ base: 'grey50', md: 'background' }}
      maxWidth={{ base: '375px', md: '721px' }}
      width="100%"
      maxHeight={{ base: '120px', md: '159px' }}
      minHeight="120px"
      height="100%"
      padding={{ md: '24px 32px' }}
      borderRadius="8px"
      borderBottom="1px solid #E2E8F0"
      alignItems="center"
      margin={{ xl: '0px 112px' }}
      data-testid="favorite-card-container"
    >
      <Flex
        justifyContent="center"
        width={{ base: '48px', md: '80px', xl: '166px' }}
        height={{ base: '53px', md: '80px', xl: '109px' }}
        borderRadius="4.082px"
        border="1.021px solid #E2E8F0"
        cursor="pointer"
        data-testid="product-image-container"
        marginLeft={{ base: '20px', md: '0' }}
        onClick={() => navigate(`/product/${product.articleCode}`)}
      >
        <Image
          src={product.productImageUrl}
          alt={transformString(product.articleDesc)}
          width={{ base: '48px', md: '80px', xl: '166px' }}
          height={{ base: '53px', md: '80px', xl: '109px' }}
          borderRadius="4.082px"
          objectFit="contain"
          backgroundColor="white"
          data-testid="product-image"
        />
      </Flex>
      <Flex flexDirection="column" margin="0 20px 0 15px" flexGrow="1">
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '11px', md: '14px' }}
          fontWeight="400"
          marginBottom={{ base: '0', md: '8px' }}
          data-testid="favorite-card-title"
        >
          {cutString(product.articleDesc)}
        </Text>
        {userPriceList === '001' && (
          <>
            <Text
              color="darkBlue"
              textAlign="start"
              fontSize={{ base: '8px', md: '12px' }}
              fontWeight="400"
              data-testid="favorite-card-available-stock"
            >
              {textContent.branchAvailableStock}: {product.existence}
            </Text>
            <Text
              color="darkBlue"
              textAlign="start"
              fontSize={{ base: '8px', md: '12px' }}
              fontWeight="400"
              data-testid="favorite-card-stock-on-trip"
            >
              {textContent.onTripStock}: {product.pendingToReceive}
            </Text>
          </>
        )}
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '8px', md: '12px' }}
          fontWeight="400"
          data-testid="favorite-card-total-stock"
        >
          {textContent.totalStock}: {product.totalStock}
        </Text>
        <Flex justifyContent="space-between" marginTop={{ base: '12px', md: '8px' }}>
          <Text
            color="darkBlue"
            textAlign="start"
            fontSize="16px"
            fontWeight="500"
            data-testid="favorite-card-price"
          >
            {priceFormatter.format(product.priceList)}
          </Text>
          <Text
            color="purple"
            textAlign="start"
            fontSize={{ base: '12px', md: '14px' }}
            fontWeight="500"
            textDecoration="underline"
            cursor="pointer"
            data-testid="favorite-card-delete-favorite"
            onClick={() => {
              setFavoriteId(product.articleCode);
              onDeleteFavorite(product);
            }}
          >
            {textContent.deleteFavorite}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FavoriteCard;
