import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';

export const favoriteData = (state: RootState) => state.favorite.favorites;

export const isLoadingFavorites = (state: RootState) => state.favorite.loading;

export const isFavoriteProduct = createSelector(
  favoriteData,
  (_state: RootState, productId: string) => productId,
  (favorites, productId) => favorites.find((favorite) => favorite.articleCode === productId),
);

export const favorites = createSelector(
  favoriteData,
  (_state: RootState, productId?: string) => productId,
  (favorites, productId) => favorites.filter((favorite) => favorite.articleCode !== productId),
);
