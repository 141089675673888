import { DEFAULT_PRICE_LIST_CODE } from '../../../constants';
import { RootState } from '../../types';

export const authData = (state: RootState) => state.auth;

export const getAuthError = (state: RootState) => state.auth.error;

export const userRole = (state: RootState) => state.auth.data.isAdmin;

export const getUserPriceList = (state: RootState) => {
  return state.auth.data.priceListCode ?? DEFAULT_PRICE_LIST_CODE;
};

export const isAuthLoading = (state: RootState) => state.auth.loading;
