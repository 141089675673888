import * as yup from 'yup';

import textContent from '../../../../utils/textContent.json';

export const emailValidation = yup.object({
  email: yup
    .string()
    .required(textContent.product.fieldRequired)
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, textContent.invalidEmailFormat),
});
