import useSWR from 'swr';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { ProductType } from '../../../interfaces';
import { getBrandsByCategory } from '../../../services/brands';
import { getProductsByCategory } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { categoryData } from '../../../store/modules/categories';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { RootState } from '../../../store/types';
import { categoryOrder } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import BrandsCarousel from '../BrandsCasousel/BrandsCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import SubCategoryViewSkeleton from './SubCategoryViewSkeleton';

const SubCategoryView = (): JSX.Element => {
  const { id, subCategoryId: categoryCode } = useParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, error, isLoading } = useSWR(
    {
      currentPage,
      categoryCode: categoryCode === 'all' ? id : categoryCode,
      key: SWRKey.products,
      onlyStock: categoryCode === 'all',
    },
    getProductsByCategory,
  );

  const { data: brands, isLoading: isLoadingBrands } = useSWR(
    categoryCode &&
      id && {
        categoryCode: categoryCode === 'all' ? id : categoryCode,
        key: SWRKey.brands,
        onlyStock: categoryCode === 'all',
      },
    getBrandsByCategory,
  );

  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const categories = useAppSelector((state: RootState) => categoryData(state));

  const categoryItem = useMemo(
    () => categoryOrder(categories.data).find((category) => category.code === id),
    [categories.data, id],
  );

  const subCategoryName = useMemo(() => {
    const name: string[] = [];
    categoryItem?.item.forEach((item) => {
      if (item.code === categoryCode) name.push(item.subCategory);
      item.subItems.forEach((subItem) => {
        if (subItem.code === categoryCode) name.push(subItem.description);
      });
    });

    data?.products.forEach((product: ProductType) => {
      if (product.operatingCenterCode === categoryCode)
        name.push(product?.operatingCenterDesc as string);
    });
    return name[0];
  }, [categoryItem?.item, data?.products, categoryCode]);

  if (categories.loading || isLoading || loadingFavorites || isLoadingBrands) {
    return <SubCategoryViewSkeleton />;
  }

  if (categories.error || !categoryItem || error || data?.products.length === 0) {
    return <ErrorScreen title={textContent.product.associatedProductsNotFound} />;
  }

  return (
    <Flex justifyContent="center" direction="column">
      <GoBack
        title={transformString(categoryItem?.category)}
        subTitle={transformString(subCategoryName)}
      />
      <Flex
        flexDirection="column"
        alignItems={{ md: 'center' }}
        backgroundColor={{ base: 'white', md: 'grey50' }}
        padding={{ md: '30px 0' }}
      >
        <Flex
          flexDirection="column"
          minHeight={{ base: '150px', md: '220px' }}
          width={{ md: '85%' }}
          backgroundColor="white"
          borderRadius="8px"
          justifyContent="flex-start"
        >
          <Text
            margin={{ base: '20px 30px 0' }}
            color="darkBlue"
            fontWeight="500"
            fontSize={{ base: '16px', md: '18px' }}
          >
            {textContent.category.SelectFavoriteBrand}
          </Text>
          <BrandsCarousel brands={brands} isLoading={categories.loading} />
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        backgroundColor="grey50"
        height="100%"
      >
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
          data-testid="product-list"
        >
          {data?.products.map((product: ProductType, index: number) => (
            <Flex justifyContent={'center'} key={index} margin="20px 0">
              <ProductCard
                product={product}
                data-testid={`product-card-${index}`}
                urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_CATEGORY}?categoryCode=${categoryCode}`}
              />
            </Flex>
          ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    </Flex>
  );
};

export default SubCategoryView;
