import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { GetProductByIdResponse, GetProductsResponse } from './types';

export const getProducts = createAsyncThunk('GET_PRODUCTS', async (_, { rejectWithValue }) => {
  try {
    const response = await get<GetProductsResponse>(URL.GET_PRODUCTS);

    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return rejectWithValue(`${textContent.product.productsNotFound}`);
    }

    if (isAxiosError(error) && error.response?.status === 400) {
      return rejectWithValue(`${textContent.product.foundProblemProducts}`);
    }

    return rejectWithValue(`${textContent.product.foundProblemProducts}`);
  }
});

export const getProductById = createAsyncThunk(
  'GET_PRODUCT_BY_ID',
  async (data: { productId: string }, { rejectWithValue }) => {
    try {
      const { productId } = data;
      const response = await get<GetProductByIdResponse>(`${URL.GET_PRODUCTS}/${productId}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        return rejectWithValue(`${textContent.product.productNotFound}`);
      }

      if (isAxiosError(error) && error.response?.status === 400) {
        return rejectWithValue(`${textContent.product.foundProblemProductById}`);
      }

      return rejectWithValue(`${textContent.product.foundProblemProductById}`);
    }
  },
);
