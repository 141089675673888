import * as React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { Props } from '../Types';

export const VinoToroBrand = ({ dataTestId }: Props) => {
  const { breakPoint } = useBreakpoint();

  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
      width={breakPoint === 'xl' ? '100' : '48'}
      height={breakPoint === 'xl' ? '100' : '51'}
    >
      <g clipPath="url(#clip0_7933_30722)">
        <path
          d="M51.3862 41.2594C52.0298 41.2594 52.6733 41.2581 53.3176 41.2594C57.5149 41.272 61.7176 41.1791 65.9077 41.3563C67.3213 41.4162 68.8483 41.9182 70.0768 42.6381C73.319 44.5386 73.2624 48.3763 70.0906 50.392C69.5748 50.72 69.0294 51.0019 68.3588 51.3847C68.7139 51.6976 68.9168 52.021 69.1855 52.0915C71.7789 52.7713 72.7604 54.6829 73.1082 57.105C73.2051 57.7795 73.3434 58.4495 73.4152 59.1266C73.5575 60.4717 73.9843 61.4914 75.6278 61.1529C75.641 61.3683 75.6931 61.5343 75.6476 61.5679C73.7676 62.9499 69.3298 63.2245 67.546 61.8696C66.8108 61.311 66.2404 60.3064 65.9881 59.396C65.6284 58.0997 65.8326 56.636 65.413 55.3699C65.0712 54.3384 64.3657 53.1579 63.4738 52.6323C61.4146 51.4189 60.4772 52.0869 60.4693 54.4359C60.4654 55.6861 60.4324 56.937 60.4772 58.186C60.5286 59.6259 59.8686 61.338 61.8467 62.2701H51.3796C53.3874 61.226 52.6812 59.4132 52.6977 57.8849C52.7451 53.3964 52.6891 48.9066 52.7306 44.418C52.7418 43.1974 52.8183 42.0453 51.124 41.7067C51.211 41.5578 51.2986 41.4083 51.3855 41.2594H51.3862ZM60.5741 51.1212C64.45 51.691 65.3386 50.9361 65.3432 47.209C65.3432 46.6412 65.3393 46.0727 65.307 45.5062C65.1324 42.4451 63.6193 41.4294 60.5741 42.3865V51.1218V51.1212Z"
          fill="black"
        />
        <path
          d="M88.3115 62.5858C81.8152 62.518 76.9097 57.8661 77.0184 51.8763C77.1304 45.6995 82.215 40.9988 88.6916 41.0831C95.0344 41.1661 100.038 46.0341 99.9999 52.0851C99.9617 58.1256 94.9198 62.655 88.3115 62.5858ZM85.1898 51.879C85.3947 54.1832 85.5198 56.5671 85.8499 58.9227C86.0468 60.3271 86.6937 61.5431 88.4485 61.5648C90.2007 61.5859 90.8707 60.4088 91.1098 58.9918C91.9081 54.255 91.9015 49.495 91.1565 44.7522C90.9326 43.3268 90.2567 42.143 88.5276 42.1318C86.7168 42.1193 86.0001 43.334 85.8051 44.8398C85.5067 47.1434 85.3914 49.4707 85.1905 51.8796L85.1898 51.879Z"
          fill="black"
        />
        <path
          d="M36.6889 62.5917C30.1721 62.5495 25.3147 58.0136 25.3213 51.9764C25.3279 45.8233 30.4047 41.0297 36.8674 41.0745C43.2794 41.1187 48.2336 45.8766 48.2257 51.9823C48.2178 58.1269 43.3163 62.6345 36.6895 62.5917H36.6889ZM40.157 51.894C39.9048 49.4647 39.6979 46.9767 39.3699 44.5052C39.1769 43.0527 38.3456 42.1061 36.7482 42.1305C35.1514 42.1548 34.4196 43.1409 34.1693 44.5796C33.3221 49.441 33.307 54.3069 34.2061 59.1604C34.4657 60.5615 35.185 61.5852 36.82 61.5615C38.4556 61.5384 39.1663 60.4917 39.3626 59.086C39.692 56.7284 39.8922 54.3524 40.157 51.8934V51.894Z"
          fill="black"
        />
        <path
          d="M0 46.3803V38H23.8313C23.8313 40.6211 23.8451 43.2072 23.8089 45.7927C23.8056 46.0311 23.4861 46.265 23.3142 46.5015C23.0691 46.2591 22.7075 46.0601 22.5988 45.7677C22.0731 44.3468 21.7589 42.8298 21.0949 41.482C20.3446 39.9604 18.8737 39.2845 17.2157 39.0158C16.2329 38.8563 15.7198 39.0902 15.7237 40.2159C15.7487 47.147 15.7362 54.0774 15.7362 61.0955C16.5741 61.301 17.3435 61.49 18.1136 61.6791C18.0984 61.8155 18.0833 61.9518 18.0681 62.0882H5.72232C5.69202 61.9525 5.66172 61.8168 5.63142 61.6817C6.32439 61.5243 7.01737 61.3662 7.89742 61.166C7.93892 60.6192 8.02258 60.0191 8.02324 59.4184C8.03576 53.2224 8.04498 47.0271 8.0318 40.8312C8.02785 38.8827 7.69849 38.6442 5.78754 39.191C2.87203 40.0262 1.76406 42.2712 1.28912 45.0187C1.17516 45.6794 1.41955 46.7992 0 46.3803Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7933_30722">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
