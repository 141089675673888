import { PaginationState } from '@tanstack/react-table';

import { get, patch, URL } from '../api';
import { BranchesResponse, EditBranchesResponse } from '../interfaces/branches';

export const getFilteredBranches = async ({
  pagination,
  searchValue,
}: {
  pagination: PaginationState;
  searchValue: string;
}) => {
  const searchQuery = searchValue ? `${searchValue}` : '';
  const url = `${URL.GET_FILTERED_BRANCHES}?limit=${pagination.pageSize}&page=${pagination.pageIndex}&searchValue=${searchQuery}`;
  try {
    const res = await get<BranchesResponse>(url);
    return {
      data: res.data[0].branches,
      pageCount: res.data[0].pagination[0].totalPages,
      total: res.data[0].pagination[0].totalDocuments,
    };
  } catch (error) {
    throw new Error('error');
  }
};

export const editVoucher = async ({
  branchEmail,
  branchId,
  branchName,
}: {
  branchEmail: string;
  branchId: string;
  branchName?: string;
}) => {
  const response = await patch<EditBranchesResponse>(`branch/${branchId}`, {
    email: branchEmail,
    branchName: branchName,
  });

  return response;
};
