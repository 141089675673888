import React from 'react';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Skeleton } from '@chakra-ui/skeleton';

import { SkeletonCell } from './constants';

const SharedTableSkeleton = ({
  padding = '0',
  rows,
  cells,
}: {
  padding?: string;
  rows: number;
  cells: SkeletonCell[];
}) => (
  <Box width="100%" padding={padding} data-testid="skeleton-table-container">
    <Box marginTop="16px" border="1px solid #E2E8F0" borderRadius="8px 8px 0 0">
      <Table data-testid="skeleton-table-component">
        <Thead>
          <Tr data-testid="header-row">
            {cells.map((cell, idx) => {
              return (
                <Th key={idx}>
                  <Skeleton data-testid="header-cell" width={cell.width} height={cell.height} />
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {Array(rows)
            .fill('row')
            .map((row, rIdx) => {
              return (
                <Tr key={rIdx} data-testid="body-row">
                  {cells.map((cell, cIdx) => {
                    return (
                      <Td key={cIdx}>
                        <Skeleton
                          data-testid={`body-cell-${rIdx}`}
                          width={cell.width}
                          height={cell.height}
                        />
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Box>
  </Box>
);

export default SharedTableSkeleton;
