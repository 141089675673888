import React from 'react';

import {
  ArcorBrand,
  BrancaBrand,
  CbseBrand,
  CoronaBrand,
  CostaDelSolBrand,
  DelValleBrand,
  GranoDeOroBrand,
  IndigoBrand,
  PlusbelleBrand,
  QuilmesBrand,
  VinoToroBrand,
  YPFBrand,
} from '../assets/brands';

export const CategoriesBrands = [
  {
    image: <GranoDeOroBrand dataTestId="granoDeOro-brand" />,
    brand: 'Grano de oro',
  },
  {
    image: <IndigoBrand dataTestId="indigo-brand" />,
    brand: 'Indigo',
  },
  {
    image: <CostaDelSolBrand dataTestId="costa-del-sol-brand" />,
    brand: 'Costa del sol',
  },
  {
    image: <ArcorBrand dataTestId="arcor-brand" />,
    brand: 'Arcor',
  },
  {
    image: <QuilmesBrand dataTestId="quilmes-brand" />,
    brand: 'Quilmes',
  },
  {
    image: <BrancaBrand dataTestId="branca-brand" />,
    brand: 'Branca',
  },
  {
    image: <DelValleBrand dataTestId="del-valle-brand" />,
    brand: 'Del valle',
  },
  {
    image: <CoronaBrand dataTestId="corona-brand" />,
    brand: 'Corona',
  },
  {
    image: <VinoToroBrand dataTestId="vino-toro-brand" />,
    brand: 'Toro',
  },
  {
    image: <CbseBrand dataTestId="cbse-brand" />,
    brand: 'Cbse',
  },
  {
    image: <PlusbelleBrand dataTestId="plusbelle-brand" />,
    brand: 'Plusbelle',
  },
  {
    image: <YPFBrand dataTestId="ypf-brand" />,
    brand: 'YPF',
  },
].sort((a, b) => a.brand.localeCompare(b.brand));
