import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  isOpen: boolean;
}

export const toastSlice = createSlice({
  name: 'toastIsOpen',
  initialState: {
    isOpen: false,
  } as InitialState,
  reducers: {
    openToast: (state) => {
      state.isOpen = true;
    },
    closeToast: (state) => {
      state.isOpen = false;
    },
  },
});

export const toastReducer = toastSlice.reducer;
export const { openToast, closeToast } = toastSlice.actions;
