import React from 'react';
import { ColumnDef } from '@tanstack/react-table';

import DoneIcon from '../../../../assets/Icons/DoneIcon';
import EditIcon from '../../../../assets/Icons/EditIcon';
import MissingIcon from '../../../../assets/Icons/MissingIcon';
import { ProductTableType } from '../../../../interfaces/products';

export const columns: ColumnDef<ProductTableType>[] = [
  {
    accessorKey: 'articleDesc',
    header: 'Nombre',
  },
  {
    accessorKey: 'articleCode',
    header: 'Código',
  },
  {
    accessorKey: 'brand',
    header: 'Marca',
  },
  {
    accessorKey: 'productImageUrl',
    header: 'Imagen',
    cell: ({ row: { original } }) =>
      original.productImageUrl ? (
        <DoneIcon dataTestId="hasImage" />
      ) : (
        <MissingIcon dataTestId="hasNotImage" />
      ),
  },
  {
    accessorKey: 'description',
    header: 'Descripción',
    cell: ({ row: { original } }) =>
      original.description ? (
        <DoneIcon dataTestId="hasDescription" />
      ) : (
        <MissingIcon dataTestId="hasNotDescription" />
      ),
  },
  {
    header: 'Editar',
    cell: ({ row: { original } }) => <EditIcon dataTestId="edit" id={original.articleCode} />,
  },
];
