import { del, get, post, URL } from '../api';
import { MonthAndYear } from '../interfaces';
import {
  CreateVoucherProduct,
  DeleteVouchersResponse,
  GetVoucherByResponse,
  GetVoucherFiltersResponse,
  GetVoucherResponse,
  ManualDeleteResponse,
  PostVoucherResponse,
} from '../interfaces/voucher';

export const getVoucher = async ({
  currentPage,
  monthAndYear,
}: {
  currentPage: number;
  monthAndYear: MonthAndYear;
}) => {
  const query = `${URL.VOUCHER}?pageNumber=${currentPage}&pageSize=${18}&month=${
    monthAndYear.month
  }&year=${monthAndYear.year}`;
  const vouchersData = await get<GetVoucherResponse>(query);

  return {
    vouchers: vouchersData.data.vouchers,
    totalPages: vouchersData.data.pagination.totalPages,
  };
};

export const getVoucherFilters = async () => {
  const yearsData = await get<GetVoucherFiltersResponse>(`${URL.VOUCHER}/voucherFilters`);
  return yearsData.data.years;
};

export const getVoucherById = async ({
  voucherId,
  userPriceList,
}: {
  voucherId?: string;
  userPriceList?: string;
  status?: string;
}) => {
  const response = await get<GetVoucherByResponse>(
    `${URL.VOUCHER}/${voucherId}?userPriceList=${userPriceList}`,
  );

  return {
    voucher: response.data,
  };
};

export const createVoucher = async ({ products }: { products: CreateVoucherProduct[] }) => {
  try {
    const response = await post<PostVoucherResponse>(URL.VOUCHER, {
      products,
    });

    if (response.statusCode === 200) {
      const totalAmount = products
        .reduce((total, product) => {
          const quantity = +product.quantity;
          const unitPrice = +product.unitPrice;

          return total + quantity * unitPrice;
        }, 0)
        .toFixed(2);

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: response.data.IDTablaWS,
          value: totalAmount,
          currency: 'ARS',
          items: products.map((product) => ({
            item_name: product.articleDesc,
            item_id: product.articleCode,
            price: +product.unitPrice,
            quantity: +product.quantity,
          })),
        },
      });
    }

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAdminVouchers = async (idsToDelete: string[]) => {
  idsToDelete.join(',');
  const response = await del<DeleteVouchersResponse>(
    `${URL.DELETE_ADMIN_VOUCHERS}?idsToDelete=${idsToDelete}`,
  );
  return response;
};

export const getAdminVouchers = async (url: string) => {
  const response = await get<GetVoucherResponse>(`${URL.GET_ADMIN_VOUCHERS}?${url}`);
  return {
    vouchers: response.data.vouchers,
    totalPages: response.data.pagination.totalPages,
    total: response.data.pagination.total,
  };
};

export const activateManualDelete = async (voucherIds: string[]) => {
  voucherIds.join(',');
  const response = await post<ManualDeleteResponse>(
    `${URL.MANUAL_DELETE}/?vouchersToCreate=${voucherIds}`,
  );
  return response;
};

export const deactivateManualDelete = async (voucherIds: string[]) => {
  voucherIds.join(',');
  const response = await del<ManualDeleteResponse>(
    `${URL.MANUAL_DELETE}/?vouchersToDelete=${voucherIds}`,
  );
  return response;
};
