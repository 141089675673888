import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { priceFormatter } from '../../../utils/priceFormatter';
import textContent from '../../../utils/textContent.json';
import Button from '../Button/Button';

interface TotalPriceProps {
  total: number;
  rejectText: string;
  resolveText: string;
  breakPoint: string;
  onClickRejectButton: () => void;
  onClickResolveButton: () => void;
  dataTestId?: string;
  hideButtons?: boolean;
  disableConfirm?: boolean;
}

const TotalPrice = ({
  total,
  rejectText,
  resolveText,
  breakPoint,
  onClickRejectButton,
  onClickResolveButton,
  dataTestId = '',
  hideButtons = false,
  disableConfirm,
}: TotalPriceProps) => {
  return (
    <Flex
      data-testid={`${dataTestId}total-container`}
      height={{ base: '170px' }}
      width={{ base: '100%', md: '35%' }}
      backgroundColor="background"
      flexDirection="column"
      justifyContent="space-around"
      marginLeft={{ md: '20px' }}
      position={{ base: 'fixed', md: 'static' }}
      bottom="0"
    >
      <Flex justifyContent="space-around" padding={{ base: '10px' }}>
        <Text
          width="155px"
          fontSize="20px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="normal"
          color="black"
          data-testid={`${dataTestId}total-label`}
        >
          {`${textContent.total}: `}
        </Text>
        <Text
          width="155px"
          textAlign="end"
          fontSize="20px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="normal"
          color="grey900"
          data-testid={`${dataTestId}amount-label`}
        >
          {priceFormatter.format(total)}
        </Text>
      </Flex>
      {!hideButtons && (
        <Flex
          flexDirection={{ md: 'column', xl: 'row' }}
          alignItems="center"
          justifyContent="space-around"
        >
          <Button
            content={rejectText}
            data-testid={dataTestId ? `${dataTestId}cancel-button` : 'continue-shopping-button'}
            border="1px solid"
            borderColor="purple"
            color="purple"
            backgroundColor="background"
            maxWidth="155px"
            width={breakPoint === 'xs' ? '40%' : '100%'}
            whiteSpace={breakPoint === 'xs' ? 'pre-wrap' : 'nowrap'}
            onClick={onClickRejectButton}
            marginBottom={{ base: '0', md: '20px', xl: '0' }}
          />
          <Button
            isDisabled={disableConfirm}
            color="grey50"
            backgroundColor="purple"
            content={resolveText}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            maxWidth="155px"
            width={breakPoint === 'xs' ? '40%' : '100%'}
            data-testid={dataTestId ? `${dataTestId}confirm-button` : 'reserve-button'}
            onClick={onClickResolveButton}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default TotalPrice;
