import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import ReservationSuccessfulIcon from '../../../assets/Icons/SuccessfulReservationIcon';
import { PostVoucherResponse } from '../../../interfaces/voucher';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearCart } from '../../../store/modules/cart';
import { getModalState } from '../../../store/modules/ui/selectors';
import { hideModal } from '../../../store/modules/ui/slice';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';

interface Props {
  isMobile: boolean;
}

const SuccessfulReservation = ({ isMobile }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(getModalState);

  const IDTablaWS = modalState.data as PostVoucherResponse;

  return (
    <>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="successful-reservation-close-icon"
            onClick={() => {
              navigate('/');
              dispatch(clearCart());
              dispatch(hideModal());
            }}
            cursor="pointer"
          />
        </Flex>
      )}
      <Flex
        padding={{ base: '0 24px', xl: '0 24px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="successful-reservation-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ md: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="85%"
        >
          <ReservationSuccessfulIcon dataTestId="successful-reservation-icon" />
          <Text
            marginBottom="24px"
            data-testid="successful-reservation-title"
            fontSize="20px"
            textAlign="center"
            fontWeight="500"
            color="grey900"
          >
            {textContent.cart.reservationMade}
          </Text>
          <Text
            marginBottom={{ base: '8px' }}
            data-testid="successful-reservation-info"
            fontSize="18px"
            textAlign="start"
            fontWeight="500"
            color="grey900"
          >
            {textContent.cart.reservationNumber + IDTablaWS}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'space-between' }} padding="20px 0">
          <Button
            width={{ base: '45%' }}
            content={textContent.goToHome}
            data-testid="go-to-home-button"
            color="purple"
            backgroundColor="background"
            height={{ base: '40px' }}
            border="1px solid"
            borderColor="purple"
            onClick={() => {
              dispatch(hideModal());
              dispatch(clearCart());
              navigate('/');
            }}
            fontSize="14px"
          />
          <Button
            width={{ base: '45%' }}
            content={textContent.reservationHistory}
            data-testid="go-to-reservation-history-button"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            onClick={() => {
              dispatch(hideModal());
              dispatch(clearCart());
              navigate('/reservation-history');
            }}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SuccessfulReservation;
