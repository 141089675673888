import useSWRInfinite from 'swr/infinite';
import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Flex } from '@chakra-ui/react';

import { MonthAndYear } from '../../../interfaces';
import { getVoucher } from '../../../services/voucher';
import { getVoucherKey } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import { voucherFormatter } from '../../../utils/voucherFormatter';
import GoBack from '../../shared/GoBack/GoBack';
import EmptyReservationHistory from './EmptyReservationHistory';
import ReservationHistoryCard from './ReservationHistoryCard';
import ReservationHistoryCardSkeleton from './ReservationHistoryCardSkeleton';
import Filters from './ReservationHistoryFilters';

const ReservationHistoryMobile = () => {
  const [monthAndYear, setMonthAndYear] = React.useState<MonthAndYear>({
    month: '',
    year: '',
  });

  const {
    data,
    error: errorVoucher,
    isLoading: loadingVouchers,
    size,
    setSize,
  } = useSWRInfinite(getVoucherKey(monthAndYear), getVoucher);

  const { vouchers, hasMore } = useMemo(() => {
    if (!data) {
      return {
        vouchers: [],
        hasMore: false,
      };
    }
    const totalPages = data[0].totalPages;
    return {
      vouchers: data.map((data) => data.vouchers).flat(),
      hasMore: totalPages !== size,
    };
  }, [data, size]);

  const shouldRenderFilters =
    monthAndYear.month === '' && monthAndYear.year === '' && !vouchers?.length;

  const ReservationList = React.useMemo(() => {
    if (loadingVouchers) return <ReservationHistoryCardSkeleton />;
    if (errorVoucher || !vouchers?.length) return <EmptyReservationHistory />;
    const formattedVouchers = voucherFormatter(vouchers);
    return (
      <InfiniteScroll
        dataLength={vouchers.length}
        next={() => {
          setSize((currentSize) => currentSize + 1);
        }}
        hasMore={hasMore}
        loader={<ReservationHistoryCardSkeleton />}
      >
        <Flex
          flexDirection="column"
          padding={{ base: '0', md: '32px 0' }}
          backgroundColor={vouchers?.length ? 'grey50' : 'white'}
          width="100%"
          alignItems={{ base: 'center', xl: 'flex-start' }}
          data-testid="reservation-history-container"
        >
          {formattedVouchers.map((voucher, index) => (
            <ReservationHistoryCard key={index} voucher={voucher} />
          ))}
        </Flex>
      </InfiniteScroll>
    );
  }, [errorVoucher, hasMore, loadingVouchers, setSize, vouchers]);

  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor={vouchers?.length ? 'grey50' : 'background'}
      width="100%"
      height="100%"
    >
      <GoBack title={textContent.reservationHistory} />
      {!shouldRenderFilters && (
        <Filters monthAndYear={monthAndYear} setMonthAndYear={setMonthAndYear} />
      )}
      {ReservationList}
    </Flex>
  );
};

export default ReservationHistoryMobile;
