import React, { ReactNode } from 'react';
import { Box, Container, Flex, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';

import textContent from '../../../utils/textContent.json';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={{ base: '12px' }} mb={2}>
      {children}
    </Text>
  );
};

interface Props {
  isMobile: boolean;
}

const Footer = ({ isMobile }: Props) => {
  const year = new Date().getFullYear().toString();

  return (
    <Box
      backgroundColor="blackFooter"
      color="white"
      data-testid="footer-container"
      position="sticky"
    >
      <Flex
        width="100%"
        justifyContent="center"
        backgroundColor="white900"
        height={{ base: '40px' }}
        alignItems="center"
        data-testid="go-up"
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
        cursor="pointer"
      >
        {textContent.goUp}
      </Flex>
      <Container
        as={Stack}
        py={10}
        minWidth="100%"
        display="flex"
        justifyContent="space-between"
        data-testid="sections-container"
      >
        <SimpleGrid columns={{ base: 2, sm: 2, md: 4 }} spacing={8} fontSize="11px">
          <Stack align={'flex-start'} alignItems={{ md: 'center' }}>
            <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'10px'}>
              <ListHeader>{textContent.SinsacateBranch}</ListHeader>
              <Text cursor="default">{textContent.addressSinsacate}</Text>
              <Text cursor="default">
                {textContent.whatsapp}: {textContent.whatsappSinsacate}
              </Text>
              <Text cursor="default">
                {textContent.phoneNumber}: {textContent.phoneNumberSinsacate}
              </Text>
            </Flex>
          </Stack>
          <Stack align={'flex-start'} alignItems={{ md: 'center' }}>
            <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'10px'}>
              <ListHeader>{textContent.salvadorMazzaBranch}</ListHeader>
              <Text cursor="default">{textContent.addressSalvadorMazza}</Text>
              <Text cursor="default">
                {textContent.whatsapp}: {textContent.whatsappSalvadorMazza}
              </Text>
              <Text cursor="default">
                {textContent.phoneNumber}: {textContent.phoneNumberSalvadorMazza}
              </Text>
            </Flex>
          </Stack>
          <Stack align={'flex-start'} alignItems={{ md: 'center' }}>
            <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'10px'}>
              <ListHeader>{textContent.extractionPoint}</ListHeader>
              <Text cursor="default">{textContent.addressExtractionPoint}</Text>
              <Text cursor="default">
                {textContent.whatsapp}: {textContent.whatsappExtractionPoint}
              </Text>
              <Text cursor="default">
                {textContent.phoneNumber}: {textContent.phoneNumberExtractionPoint}
              </Text>
            </Flex>
          </Stack>
          <Stack align={'flex-start'} alignItems={{ md: 'center' }}>
            <Flex alignItems={'flex-start'} flexDirection={'column'} gap={'10px'}>
              <ListHeader>{textContent.industrialFactory}</ListHeader>
              <Text cursor="default">{textContent.addressIndustrialFactory}</Text>
              <Text cursor="default">
                {textContent.whatsapp}: {textContent.whatsappIndustrialFactory}
              </Text>
              <Text cursor="default">
                {textContent.phoneNumber}: {textContent.phoneNumberIndustrialFactory}
              </Text>
            </Flex>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box borderTopWidth={1} borderStyle={'solid'} borderColor="grey700" data-testid="legal-text">
        <Container
          as={Stack}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-around' }}
          align={{ base: 'center' }}
          minWidth="100%"
          fontSize="10px"
        >
          <Flex flexWrap={{ base: 'wrap' }} justifyContent="center">
            <Text textAlign={'center'}>{textContent.legalText}</Text>
          </Flex>
        </Container>
      </Box>
      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor="grey700"
        data-testid="legal-content"
      >
        <Container
          as={Stack}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ md: 'space-around' }}
          align={{ base: 'center' }}
          minWidth="100%"
          fontSize="10px"
        >
          <Flex
            flexWrap={{ base: 'wrap' }}
            justifyContent="center"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Text>
              {textContent.copyright} {year} {textContent.norpan} | {textContent.rightsReserved}
            </Text>
            {!isMobile && (
              <Text
                marginRight={{ xl: '3px', md: '3px' }}
                marginLeft={{ xl: '3px', md: '3px' }}
                data-testid="middle-bar"
              >
                {'|'}
              </Text>
            )}
            <Flex textAlign="center" display={'block'}>
              <Text
                as={Link}
                href={
                  'https://norpan-images.s3.us-west-2.amazonaws.com/documents/terminos-y-condiciones.pdf'
                }
                target="_blank"
                _hover={{ textDecoration: 'none' }}
                cursor="pointer"
              >
                Ver {textContent.termsAndConditions.name}
              </Text>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
