import React from 'react';
import { Flex } from '@chakra-ui/react';

import { generatePagination } from '../../../utils/pagination';
import Textcontent from '../../../utils/textContent.json';
import Button from '../Button/Button';

interface PaginationNavbarProps {
  setCurrentPage: (newPage: number) => void;
  currentPage: number;
  totalPages?: number;
}

const PaginationNavbar = ({
  setCurrentPage,
  currentPage,
  totalPages = 0,
}: PaginationNavbarProps) => {
  const allPages = generatePagination(currentPage, totalPages);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <Flex justifyContent="center" marginBottom="20px" data-testid="pagination-navbar-container">
      <Button
        data-testid="previous-button"
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        mr={2}
        variant="outline"
        color="black"
        backgroundColor={'white'}
        _hover={{
          backgroundColor: { base: 'none', xl: 'purple' },
        }}
        _active={{
          backgroundColor: { base: 'purple', xl: 'none' },
        }}
      >
        {Textcontent.previous}
      </Button>
      <div className="flex -space-x-px">
        {allPages.map((page, index) => {
          return (
            <Button
              key={`${page} + ${index}`}
              color="black"
              variant={currentPage === page ? 'solid' : 'outline'}
              backgroundColor={currentPage === page ? 'purple' : 'white'}
              onClick={() => {
                if (page === '...') return;
                return handlePageChange(+page);
              }}
              _hover={{
                backgroundColor: { base: 'none', xl: 'purple' },
              }}
              _active={{
                backgroundColor: { base: 'purple', xl: 'none' },
              }}
              marginRight="5px"
            >
              {page}
            </Button>
          );
        })}
      </div>
      <Button
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={currentPage >= totalPages}
        ml={2}
        variant="outline"
        color="black"
        backgroundColor={'white'}
        _hover={{
          backgroundColor: { base: 'none', xl: 'purple' },
        }}
        _active={{
          backgroundColor: { base: 'purple', xl: 'none' },
        }}
        data-testid="next-button"
      >
        {Textcontent.next}
      </Button>
    </Flex>
  );
};

export default PaginationNavbar;
