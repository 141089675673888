import textContent from '../../../utils/textContent.json';

export const ModifyCartQuantity = (quantity: number, totalStock: number) => {
  if (quantity <= 0) {
    return { message: textContent.product.messagePositive };
  }

  if (!Number.isInteger(quantity)) {
    return { message: textContent.product.messageInteger };
  }

  if (quantity > totalStock) {
    return { message: textContent.product.messageMaximum };
  }
};
