import React, { useEffect } from 'react';
import { UseFormClearErrors } from 'react-hook-form';
import { Flex, Image, Text, useToast } from '@chakra-ui/react';

import AddIcon from '../../../assets/Icons/AddIcon';
import { convertBytes } from '../../../utils/convertBytes';
import { fileToBase64 } from '../../../utils/fileToBase64';
import { AddProductImageFormValues } from '../../pages/Admin/Products/EditProduct';

export interface FileInputProps {
  iconTestId: string;
  inputTestId: string;
  haveImage?: string;
  havePreview?: boolean;
  changeHandler: (value: string) => void;
  clearErrors?: UseFormClearErrors<AddProductImageFormValues>;
  width: string;
  height: string;
  leftPosition: string;
  maxSizeBytes: number;
}

const FileInput = ({
  iconTestId,
  inputTestId,
  changeHandler,
  haveImage,
  clearErrors,
  havePreview,
  width,
  height,
  leftPosition = '0',
  maxSizeBytes,
}: FileInputProps) => {
  const [imagePreview, setImagePreview] = React.useState<string | undefined>(undefined);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [imageName, setImageName] = React.useState<string | undefined>();

  useEffect(() => {
    if (haveImage) setImagePreview(haveImage);
  }, [haveImage]);

  const toast = useToast();

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        position={havePreview && imagePreview ? 'absolute' : 'static'}
        left={leftPosition}
      >
        <AddIcon
          onClick={() => {
            if (inputRef?.current?.click) inputRef.current.click();
          }}
          dataTestId={iconTestId}
        />
        {!havePreview && imageName && <Text data-testid="image-name">{imageName}</Text>}
      </Flex>
      <input
        onChange={async (e) => {
          if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].size > maxSizeBytes) {
              toast({
                title: 'Error',
                description: `La imagen no puede superar los ${convertBytes(maxSizeBytes)}`,
                status: 'error',
                variant: 'subtle',
                duration: 3000,
              });
            } else {
              const base64file = await fileToBase64(e.target.files[0]);
              setImageName(e.target.files[0].name);
              setImagePreview(base64file);
              changeHandler(base64file);
              clearErrors && clearErrors('productImageUrl');
            }
          }
          e.target.value = '';
        }}
        data-testid={inputTestId}
        accept="image/png, image/jpg, image/jpeg"
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
        id="file-input"
        name="ImageStyle"
      />
      {havePreview && imagePreview && (
        <Image
          data-testid={inputTestId}
          src={imagePreview}
          alt="Preview"
          style={{
            borderRadius: '8px',
            width,
            height,
          }}
        />
      )}
    </>
  );
};

export default FileInput;
