import React from 'react';

import { CategoriesIconPros } from './DrinksIcon';

const ConfirmReservationIcon = ({ dataTestId }: CategoriesIconPros) => {
  return (
    <svg
      data-testid={dataTestId}
      width="201"
      height="160"
      viewBox="0 0 201 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="200" height="160" transform="translate(0.5)" fill="#FAFAFC" />
      <rect x="0.5" width="200" height="160" fill="#FAFAFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.1 52C169.192 52 171.7 54.5072 171.7 57.6C171.7 60.6928 169.192 63.2 166.1 63.2H134.1C137.192 63.2 139.7 65.7072 139.7 68.8C139.7 71.8928 137.192 74.4 134.1 74.4H151.7C154.792 74.4 157.3 76.9072 157.3 80C157.3 83.0928 154.792 85.6 151.7 85.6H143.56C139.661 85.6 136.5 88.1072 136.5 91.2C136.5 93.2619 138.1 95.1285 141.3 96.8C144.392 96.8 146.9 99.3072 146.9 102.4C146.9 105.493 144.392 108 141.3 108H74.8996C71.8068 108 69.2996 105.493 69.2996 102.4C69.2996 99.3072 71.8068 96.8 74.8996 96.8H43.6996C40.6068 96.8 38.0996 94.2928 38.0996 91.2C38.0996 88.1072 40.6068 85.6 43.6996 85.6H75.6996C78.7924 85.6 81.2996 83.0928 81.2996 80C81.2996 76.9072 78.7924 74.4 75.6996 74.4H55.6996C52.6068 74.4 50.0996 71.8928 50.0996 68.8C50.0996 65.7072 52.6068 63.2 55.6996 63.2H87.6996C84.6068 63.2 82.0996 60.6928 82.0996 57.6C82.0996 54.5072 84.6068 52 87.6996 52H166.1ZM166.1 74.4C169.192 74.4 171.7 76.9072 171.7 80C171.7 83.0928 169.192 85.6 166.1 85.6C163.007 85.6 160.5 83.0928 160.5 80C160.5 76.9072 163.007 74.4 166.1 74.4Z"
        fill="#EBF1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.437 51.2L130.879 105.474L131.548 110.919C131.763 112.673 130.516 114.27 128.761 114.485L81.9132 120.238C80.159 120.453 78.5624 119.206 78.3471 117.451L71.1344 58.7094C71.0268 57.8323 71.6505 57.034 72.5275 56.9263C72.5331 56.9257 72.5386 56.925 72.5442 56.9244L76.4309 56.4884M79.5737 56.1359L83.2433 55.7242L79.5737 56.1359Z"
        fill="white"
      />
      <path
        d="M124.676 51.0302C124.582 50.3463 123.952 49.8678 123.268 49.9616C122.584 50.0554 122.105 50.6858 122.199 51.3698L124.676 51.0302ZM130.879 105.474L132.12 105.322C132.119 105.316 132.118 105.31 132.117 105.304L130.879 105.474ZM131.548 110.919L132.788 110.767L131.548 110.919ZM128.761 114.485L128.914 115.726L128.761 114.485ZM81.9132 120.238L82.0655 121.478L81.9132 120.238ZM78.3471 117.451L79.5877 117.299L78.3471 117.451ZM71.1344 58.7094L72.3751 58.5571L71.1344 58.7094ZM72.5442 56.9244L72.6835 58.1666L72.5442 56.9244ZM76.5702 57.7306C77.2562 57.6537 77.75 57.0351 77.6731 56.3491C77.5961 55.663 76.9776 55.1692 76.2915 55.2462L76.5702 57.7306ZM79.4343 54.8937C78.7483 54.9706 78.2545 55.5892 78.3315 56.2752C78.4084 56.9613 79.027 57.455 79.713 57.3781L79.4343 54.8937ZM83.3827 56.9664C84.0687 56.8895 84.5625 56.2709 84.4855 55.5849C84.4086 54.8988 83.79 54.4051 83.104 54.482L83.3827 56.9664ZM122.199 51.3698L129.641 105.644L132.117 105.304L124.676 51.0302L122.199 51.3698ZM129.638 105.627L130.307 111.072L132.788 110.767L132.12 105.322L129.638 105.627ZM130.307 111.072C130.438 112.141 129.678 113.113 128.609 113.245L128.914 115.726C131.353 115.427 133.088 113.206 132.788 110.767L130.307 111.072ZM128.609 113.245L81.7609 118.997L82.0655 121.478L128.914 115.726L128.609 113.245ZM81.7609 118.997C80.6919 119.128 79.719 118.368 79.5877 117.299L77.1064 117.604C77.4059 120.043 79.6262 121.778 82.0655 121.478L81.7609 118.997ZM79.5877 117.299L72.3751 58.5571L69.8938 58.8617L77.1064 117.604L79.5877 117.299ZM72.3751 58.5571C72.3516 58.3652 72.488 58.1906 72.6799 58.167L72.3752 55.6857C70.8129 55.8775 69.7019 57.2995 69.8938 58.8617L72.3751 58.5571ZM72.6799 58.167C72.6811 58.1669 72.6823 58.1667 72.6835 58.1666L72.4048 55.6822C72.3949 55.6833 72.3851 55.6845 72.3752 55.6857L72.6799 58.167ZM72.6835 58.1666L76.5702 57.7306L76.2915 55.2462L72.4048 55.6822L72.6835 58.1666ZM79.713 57.3781L83.3827 56.9664L83.104 54.482L79.4343 54.8937L79.713 57.3781Z"
        fill="#5C92FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.412 54.6155L128.148 103.802L128.754 108.737C128.949 110.327 127.834 111.772 126.263 111.965L84.3092 117.116C82.7383 117.309 81.3067 116.176 81.1115 114.587L74.6402 61.8826C74.5056 60.7862 75.2852 59.7883 76.3816 59.6537L81.1746 59.0652"
        fill="#EBF1FF"
      />
      <path
        d="M87.0879 44C87.0879 42.4812 88.3191 41.25 89.8379 41.25H125.152C125.881 41.25 126.58 41.5395 127.096 42.0549L137.382 52.3343C137.898 52.8501 138.188 53.5498 138.188 54.2795V103.2C138.188 104.719 136.957 105.95 135.438 105.95H89.8379C88.3191 105.95 87.0879 104.719 87.0879 103.2V44Z"
        fill="white"
        stroke="#5C92FF"
        strokeWidth="2.5"
      />
      <path
        d="M125.838 41.9224V51.2001C125.838 52.5256 126.912 53.6001 128.238 53.6001H134.585"
        stroke="#5C92FF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.9004 94.4001H115.7M94.9004 53.6001H115.7H94.9004ZM94.9004 63.2001H129.3H94.9004ZM94.9004 73.6001H129.3H94.9004ZM94.9004 84.0001H129.3H94.9004Z"
        stroke="#75A4FE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M94.5 53H116.5" stroke="#FF923F" strokeWidth="4.5" strokeLinecap="round" />
      <path
        d="M63.4711 104.209L65.5812 102.105C66.0656 101.623 66.0656 100.845 65.5812 100.362C65.0967 99.8793 64.3168 99.8793 63.8323 100.362L61.7222 102.466L59.6122 100.362C59.1277 99.8793 58.3477 99.8793 57.8633 100.362C57.3789 100.845 57.3789 101.623 57.8633 102.105L59.9734 104.209L57.8633 106.312C57.3789 106.795 57.3789 107.573 57.8633 108.056C58.3477 108.539 59.1277 108.539 59.6122 108.056L61.7222 105.952L63.8323 108.056C64.3168 108.539 65.0967 108.539 65.5812 108.056C66.0656 107.573 66.0656 106.795 65.5812 106.312L63.4711 104.209Z"
        fill="#FF923F"
      />
      <path
        d="M145.471 40.2089L147.581 38.1055C148.066 37.6226 148.066 36.8451 147.581 36.3622C147.097 35.8793 146.317 35.8793 145.832 36.3622L143.722 38.4656L141.612 36.3622C141.128 35.8793 140.348 35.8793 139.863 36.3622C139.379 36.8451 139.379 37.6226 139.863 38.1055L141.973 40.2089L139.863 42.3124C139.379 42.7953 139.379 43.5728 139.863 44.0557C140.348 44.5386 141.128 44.5386 141.612 44.0557L143.722 41.9522L145.832 44.0557C146.317 44.5386 147.097 44.5386 147.581 44.0557C148.066 43.5728 148.066 42.7953 147.581 42.3124L145.471 40.2089Z"
        fill="#FF923F"
      />
      <path
        d="M52.3284 109L53.3279 108C53.5574 107.771 53.5574 107.402 53.3279 107.172C53.0984 106.943 52.729 106.943 52.4995 107.172L51.5 108.172L50.5005 107.172C50.271 106.943 49.9016 106.943 49.6721 107.172C49.4426 107.402 49.4426 107.771 49.6721 108L50.6716 109L49.6721 110C49.4426 110.229 49.4426 110.598 49.6721 110.828C49.9016 111.057 50.271 111.057 50.5005 110.828L51.5 109.828L52.4995 110.828C52.729 111.057 53.0984 111.057 53.3279 110.828C53.5574 110.598 53.5574 110.229 53.3279 110L52.3284 109Z"
        fill="#C0D5FF"
      />
      <path
        d="M58.3284 113L59.3279 112C59.5574 111.771 59.5574 111.402 59.3279 111.172C59.0984 110.943 58.729 110.943 58.4995 111.172L57.5 112.172L56.5005 111.172C56.271 110.943 55.9016 110.943 55.6721 111.172C55.4426 111.402 55.4426 111.771 55.6721 112L56.6716 113L55.6721 114C55.4426 114.229 55.4426 114.598 55.6721 114.828C55.9016 115.057 56.271 115.057 56.5005 114.828L57.5 113.828L58.4995 114.828C58.729 115.057 59.0984 115.057 59.3279 114.828C59.5574 114.598 59.5574 114.229 59.3279 114L58.3284 113Z"
        fill="#C0D5FF"
      />
      <path
        d="M150.328 48L151.328 47.0005C151.557 46.771 151.557 46.4016 151.328 46.1721C151.098 45.9426 150.729 45.9426 150.5 46.1721L149.5 47.1716L148.5 46.1721C148.271 45.9426 147.902 45.9426 147.672 46.1721C147.443 46.4016 147.443 46.771 147.672 47.0005L148.672 48L147.672 48.9995C147.443 49.229 147.443 49.5984 147.672 49.8279C147.902 50.0574 148.271 50.0574 148.5 49.8279L149.5 48.8284L150.5 49.8279C150.729 50.0574 151.098 50.0574 151.328 49.8279C151.557 49.5984 151.557 49.229 151.328 48.9995L150.328 48Z"
        fill="#C0D5FF"
      />
      <path
        d="M137.328 34L138.328 33.0005C138.557 32.771 138.557 32.4016 138.328 32.1721C138.098 31.9426 137.729 31.9426 137.5 32.1721L136.5 33.1716L135.5 32.1721C135.271 31.9426 134.902 31.9426 134.672 32.1721C134.443 32.4016 134.443 32.771 134.672 33.0005L135.672 34L134.672 34.9995C134.443 35.229 134.443 35.5984 134.672 35.8279C134.902 36.0574 135.271 36.0574 135.5 35.8279L136.5 34.8284L137.5 35.8279C137.729 36.0574 138.098 36.0574 138.328 35.8279C138.557 35.5984 138.557 35.229 138.328 34.9995L137.328 34Z"
        fill="#C0D5FF"
      />
    </svg>
  );
};

export default ConfirmReservationIcon;
