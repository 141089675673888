import useSWR from 'swr';
import React from 'react';
import { Box } from '@chakra-ui/react';

import { SWRKey } from '../../../../enum/SWRKey';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { BannersToHome } from '../../../../interfaces/banner';
import { getAllBannersToHome } from '../../../../services/banner';
import Carousel from '../../../shared/Carousel/Carousel';

const HomeBanner = () => {
  const { isMobile } = useBreakpoint();
  const { data: banners } = useSWR(SWRKey.homeBanners, getAllBannersToHome, {
    revalidateOnFocus: false,
    dedupingInterval: 10000,
  });

  return (
    <Box
      marginLeft={{ base: '5px', md: '62px', xl: '92px' }}
      marginRight={{ base: '10px', md: '62px', xl: '92px' }}
      data-testid="banner-home-container"
      marginTop="25px"
    >
      <Carousel
        data={banners as BannersToHome[]}
        width={'full'}
        height={isMobile ? 156 : 260}
        btnPositions={135}
        columns={1}
        dots={true}
        datatestid="banner"
        infinite={true}
        autoplay={true}
        buttonsBackground="white"
        visibleButtons
        renderAsImage
      />
    </Box>
  );
};

export default HomeBanner;
