import React from 'react';
import { Skeleton } from '@chakra-ui/react';

import css from '../../pages/Admin/Banners/bannersView.module.css';
import { SkeletonColumn } from './sortableListTypes';

export const skeletonColumns: SkeletonColumn[] = [
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.actionsHeader,
  },
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.titleHeader,
  },
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.desktopHeader,
  },
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.mobileHeader,
  },
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.actionsHeader,
  },
  {
    title: <Skeleton width="50%" height="16px" />,
    cellStyle: css.actionsHeader,
  },
];
