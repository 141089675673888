/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import { ModalType } from '../../../enum/ModalType';
import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { decrementQuantity, incrementQuantity, replaceQuantity } from '../../../store/modules/cart';
import {
  getAmountOfProductInCart,
  getCartProducts,
  getTotalPriceCart,
} from '../../../store/modules/cart/selectors';
import { CartItem } from '../../../store/modules/cart/types';
import { showModal } from '../../../store/modules/ui';
import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';
import CartButtonCounter from '../../shared/ReservationProductCard/CartButtonCounter';
import ReservationProductCard from '../../shared/ReservationProductCard/ReservationProductCard';
import TotalPrice from '../../shared/TotalPrice/TotalPrice';
import EmptyCart from './EmptyCart';

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { breakPoint } = useBreakpoint();
  const cartItems = useAppSelector(getCartProducts);
  const productCounter = useAppSelector(getAmountOfProductInCart);
  const total = useAppSelector(getTotalPriceCart);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [disableConfirm, setDisableConfirm] = useState(false);

  useEffect(() => {
    if (cartItems) {
      const stockValidation = cartItems.some((product) => {
        const totalStock = product.totalStock ? +product.totalStock : 0;
        return totalStock < product.quantity || product.quantity === 0;
      });
      setDisableConfirm(stockValidation);
    }
  }, [cartItems]);

  const printRef = useRef<any>();

  if (cartItems.length === 0) {
    return <EmptyCart />;
  }

  return (
    <Flex
      justifyContent={{ base: 'flex-start' }}
      flexDirection="column"
      backgroundColor="grey50"
      data-testid="cart-container"
      width={{ md: '100%' }}
    >
      <GoBack
        title={textContent.cart.reservationCart}
        subTitle={`${productCounter} ${
          productCounter > 1 ? textContent.cart.productsAdded : textContent.cart.productAdded
        }`}
      />
      <Box padding={{ md: '30px', xl: '32px 112px' }} backgroundColor="grey50">
        <Flex
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          marginBottom={{ base: '200px', md: '0' }}
        >
          <Flex flexDirection="column" width={{ md: '65%', xl: '721px' }} ref={printRef}>
            {cartItems.map((item, index) => (
              <ReservationProductCard<CartItem>
                key={index}
                inputName={`card-${index}`}
                breakPoint={breakPoint}
                product={item}
                errors={error}
                setError={setError}
                onClick={() =>
                  dispatch(
                    showModal({
                      type: ModalType.DELETE_PRODUCT,
                      data: item,
                    }),
                  )
                }
              >
                <CartButtonCounter<CartItem>
                  product={item}
                  breakPoint={breakPoint}
                  onClickToReplaceQuantity={(quantity) =>
                    dispatch(replaceQuantity({ ...item, quantity: quantity }))
                  }
                  onClickToIncrementQuantity={() => dispatch(incrementQuantity(item))}
                  onClickToDecrementQuantity={() => dispatch(decrementQuantity(item))}
                  incrementDisabled={
                    (item.totalStock !== undefined && +item.totalStock <= item.quantity) || false
                  }
                />
              </ReservationProductCard>
            ))}
          </Flex>
          <TotalPrice
            disableConfirm={disableConfirm}
            total={total}
            rejectText={textContent.product.continueShopping}
            resolveText={textContent.cart.reserve}
            breakPoint={breakPoint}
            onClickRejectButton={() => navigate('/')}
            onClickResolveButton={async () => {
              dispatch(
                showModal({
                  type: ModalType.CONFIRM_RESERVATION,
                  data: {
                    products: cartItems,
                  },
                }),
              );
            }}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Cart;
