import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, put, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { GetSettingsResponse, Settings } from './types';

export const getSettings = createAsyncThunk('GET_SETTINGS', async (_, { rejectWithValue }) => {
  try {
    const version = localStorage.getItem('version');
    const response = await get<GetSettingsResponse>(`${URL.SETTINGS}`);

    if (response.data.version) {
      localStorage.setItem('version', response.data.version);
    }

    if (version && version !== response.data.version) {
      location.reload();
    }

    return response.data;
  } catch (error) {
    return rejectWithValue(`${textContent.system.foundProblemSettings}`);
  }
});

export const updateSettings = createAsyncThunk(
  'UPDATE_SETTINGS',
  async (data: Settings, { rejectWithValue }) => {
    try {
      const response = await put<GetSettingsResponse>(`${URL.SETTINGS}`, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(`${textContent.system.foundProblemSettings}`);
    }
  },
);
