import * as React from 'react';
interface Props {
  dataTestId: string;
}
const UserIcon = ({ dataTestId }: Props) => (
  <svg
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      d="M8.83333 4.76667C9.01718 4.76667 9.19923 4.80288 9.36909 4.87324C9.53895 4.94359 9.69328 5.04672 9.82328 5.17672C9.95328 5.30672 10.0564 5.46105 10.1268 5.63091C10.1971 5.80077 10.2333 5.98282 10.2333 6.16667C10.2333 6.35052 10.1971 6.53257 10.1268 6.70242C10.0564 6.87228 9.95328 7.02661 9.82328 7.15662C9.69328 7.28662 9.53895 7.38974 9.36909 7.4601C9.19923 7.53045 9.01718 7.56667 8.83333 7.56667C8.46203 7.56667 8.10593 7.41917 7.84338 7.15662C7.58083 6.89407 7.43333 6.53797 7.43333 6.16667C7.43333 5.79536 7.58083 5.43927 7.84338 5.17672C8.10593 4.91417 8.46203 4.76667 8.83333 4.76667ZM8.83333 10.7667C10.8133 10.7667 12.9 11.74 12.9 12.1667V12.9H4.76667V12.1667C4.76667 11.74 6.85333 10.7667 8.83333 10.7667ZM8.83333 3.5C7.36 3.5 6.16667 4.69333 6.16667 6.16667C6.16667 7.64 7.36 8.83333 8.83333 8.83333C10.3067 8.83333 11.5 7.64 11.5 6.16667C11.5 4.69333 10.3067 3.5 8.83333 3.5ZM8.83333 9.5C7.05333 9.5 3.5 10.3933 3.5 12.1667V14.1667H14.1667V12.1667C14.1667 10.3933 10.6133 9.5 8.83333 9.5Z"
      fill="#001133"
    />
  </svg>
);
export default UserIcon;
