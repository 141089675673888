import React, { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';

import UserIcon from '../../../assets/Icons/UserIcon';
import { ModalType } from '../../../enum/ModalType';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAuthData } from '../../../store/modules/auth';
import { closeLoginPopover, openLoginPopover, showModal } from '../../../store/modules/ui';
import { getPopoverState } from '../../../store/modules/ui/selectors';
import { RootState } from '../../../store/types';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';

interface Props {
  isMobile?: boolean;
  isLoggedIn: boolean;
}

const LoginPopover = ({ isMobile = false, isLoggedIn }: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const containerRef = useRef(null);
  useOutsideClick({
    ref: containerRef,
    handler: () => dispatch(closeLoginPopover()),
  });

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      dispatch(openLoginPopover());
    }
  }, [dispatch, isLoggedIn]);

  const userName = useSelector((state: RootState) => state.auth.data.name);
  const popoverIsOpen = useAppSelector(getPopoverState);

  return (
    <div ref={containerRef}>
      <Popover
        placement="bottom-end"
        arrowSize={12}
        onClose={() => dispatch(closeLoginPopover())}
        defaultIsOpen={!isLoggedIn}
        closeOnBlur={false}
        isOpen={popoverIsOpen}
      >
        <PopoverTrigger>
          <Accordion allowToggle border="none">
            <AccordionItem border="none">
              <AccordionButton
                _hover={{ background: 'none' }}
                paddingRight={0}
                padding="0"
                onClick={() => {
                  !popoverIsOpen ? dispatch(openLoginPopover()) : dispatch(closeLoginPopover());
                }}
                data-testid="accordion-button"
              >
                <>
                  <UserIcon dataTestId="user-icon" />
                  {((!isMobile && pathname !== '/') || pathname === '/') && (
                    <>
                      <Text
                        data-testid="login-label"
                        color="darkBlue"
                        fontSize={{ base: '12px', xl: '14px' }}
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="normal"
                        cursor="pointer"
                        marginLeft="4px"
                      >
                        {isLoggedIn ? transformString(userName) : textContent.login}
                      </Text>
                      {popoverIsOpen ? (
                        <ChevronUpIcon data-testid="chevron-up-icon" />
                      ) : (
                        <ChevronDownIcon data-testid="chevron-down-icon" />
                      )}
                    </>
                  )}
                </>
              </AccordionButton>
            </AccordionItem>
          </Accordion>
        </PopoverTrigger>
        <PopoverContent
          data-testid="popover-login"
          width={!isLoggedIn ? { base: '280px', xl: '328px' } : { base: '134px', xl: '160px' }}
          height={!isLoggedIn ? { base: '155px', xl: '180px' } : { base: '49px', xl: '60px' }}
          justifyContent={!isLoggedIn ? 'space-between' : 'center'}
          border="1px solid"
          borderRadius={4}
          borderColor="grey400"
          boxShadow="2xl"
          _focusVisible={{ outline: 'none' }}
        >
          {isLoggedIn ? (
            <Flex
              height={{ base: '34px', xl: '40px' }}
              _hover={{
                backgroundColor: 'lightViolet',
              }}
              _active={{
                backgroundColor: 'violet',
              }}
              width="100%"
              justifyContent="center"
              alignItems="center"
              onClick={() => {
                dispatch(
                  showModal({
                    type: ModalType.LOGOUT,
                  }),
                );
                dispatch(closeLoginPopover());
              }}
              cursor="pointer"
              data-testid="popover-logout"
            >
              <Text
                data-testid="popover-logout-label"
                textAlign="center"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="20px"
                letterSpacing="-0.1px"
                justifyContent="center"
              >
                {textContent.logout}
              </Text>
            </Flex>
          ) : (
            <>
              <PopoverArrow bg="white" data-testid="popover-arrow" />
              <Flex
                flexDir="column"
                height="100%"
                justifyContent="space-between"
                paddingTop={{ base: '12px', xl: '14px' }}
                paddingBottom={{ base: '12px', xl: '14px' }}
                paddingLeft={{ base: '15px', xl: '18px' }}
                paddingRight={{ base: '16px', xl: '17px' }}
                data-testid="login-popover-container"
              >
                <Flex flexDir="column" height="100%">
                  <Flex flexDir="column" height={{ base: '80px', xl: '92px' }}>
                    <Text
                      fontSize={{ base: '13px', xl: '15px', md: '13px' }}
                      fontWeight={400}
                      lineHeight={{ base: '14px', xl: '18px', md: '14px' }}
                      fontStyle="normal"
                      data-testid="login-popover-text"
                    >
                      {textContent.loginPopoverText}
                    </Text>
                    <Flex justifyContent="center">
                      <Text
                        fontSize={{ base: '12px', md: '12px', xl: '14px' }}
                        fontWeight={400}
                        lineHeight={{ base: '14px', md: '14px', xl: '14px' }}
                        fontStyle="normal"
                        paddingTop="25px"
                        data-testid="login-popover-to-be-client"
                      >
                        {textContent.toBeClient}
                        <Link
                          href={`https://wa.me/5493525521001?text=${textContent.newClientCodeInfoWhatsappMessage}`}
                          isExternal
                          textDecoration={'underline'}
                          data-testid="manage-code-link"
                          color="purple"
                          fontWeight={500}
                        >
                          {textContent.clickHere}
                        </Link>
                        .
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex justifyContent="center">
                    <Button
                      color="grey50"
                      backgroundColor="purple"
                      data-testid="login-popover-enter-button"
                      height={{ base: '34px', xl: '39px' }}
                      marginTop={{ base: '10px', xl: '15px' }}
                      _hover={{
                        backgroundColor: { xl: 'darkPurple' },
                      }}
                      _active={{
                        backgroundColor: { base: 'darkPurple', md: 'darkPurple' },
                      }}
                      content="Ingresar como cliente"
                      onClick={() => {
                        dispatch(closeLoginPopover());
                        dispatch(clearAuthData());
                        dispatch(
                          showModal({
                            type: ModalType.LOGIN,
                          }),
                        );
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default LoginPopover;
