import { CategoryType } from '../store/modules/categories/types';

export interface ItemsProps {
  code: string;
  subCategory: string;
  subItems: CategoryType[];
}

export interface NewCategories {
  code: string;
  category: string;
  item: ItemsProps[];
}

const getHighestIndex = (items: CategoryType[], level: number) => {
  let max = 0;

  items.forEach((item) => {
    const index = item.code.split('.')[level];
    if (index && +index > max) max = +index;
  });

  return max;
};

export const categoryOrder = (categories: CategoryType[]) => {
  const newCategories: NewCategories[] = [];

  const maxCategoryIndex = getHighestIndex(categories, 1);

  for (let index = 1; index <= maxCategoryIndex; index++) {
    const categoryCode = `01.${index < 10 ? '0' : ''}${index}`;

    const category = categories.find((category) => category.code === categoryCode);

    if (category) {
      const items = categories.filter((category) => category.code.startsWith(`${categoryCode}.`));

      const subItems: ItemsProps[] = [];

      const maxSubCategoryIndex = getHighestIndex(items, 2);

      for (let subIndex = 1; subIndex <= maxSubCategoryIndex; subIndex++) {
        const subCategoryCode = `${categoryCode}.${subIndex < 10 ? '0' : ''}${subIndex}`;

        const subItem = items.find((item) => item.code === subCategoryCode);

        if (subItem) {
          const subCategory = items.filter((item) => item.code.startsWith(`${subCategoryCode}.`));

          const subSubItems: ItemsProps[] = [];

          const maxSubItemsIndex = getHighestIndex(subCategory, 3);

          for (let subSubIndex = 1; subSubIndex <= maxSubItemsIndex; subSubIndex++) {
            const subSubCategoryCode = `${subCategoryCode}.${
              subSubIndex < 10 ? '0' : ''
            }${subSubIndex}`;

            const subSubItem = subCategory.find(
              (subCat) => subCat.code === `${subSubCategoryCode}`,
            );

            if (subSubItem) {
              const subSubCategory = subCategory.filter((subCat) =>
                subCat.code.startsWith(`${subSubCategoryCode}.`),
              );

              subSubItems.push({
                code: subSubItem.code,
                subCategory: subSubItem.description,
                subItems: subSubCategory,
              });
            }
          }

          subItems.push({
            code: subItem.code,
            subCategory: subItem.description,
            subItems: subCategory,
          });
        }
      }
      newCategories.push({ code: category.code, category: category.description, item: subItems });
    }
  }

  return newCategories;
};
