import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import styles from './Counter.module.css';
interface props {
  resetClock: boolean;
  setCountZero: Dispatch<SetStateAction<boolean>>;
}

const Countdown = ({ resetClock, setCountZero }: props) => {
  const [seconds, setSeconds] = useState(120);

  const minutes =
    Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60);
  const countdownText = `${minutes}:${('0' + Math.floor(seconds % 60)).slice(-2)} min`;

  useEffect(() => {
    const secondsInterval = setInterval(() => {
      setSeconds((sec: number) => (sec > 0 ? sec - 1 : sec));
    }, 1000);
    return () => {
      clearInterval(secondsInterval);
    };
  }, [setSeconds]);

  useEffect(() => {
    setSeconds(120);
    setCountZero(false);
  }, [resetClock, setCountZero]);

  useEffect(() => {
    if (seconds === 0) setCountZero(true);
  }, [seconds, setCountZero]);

  return (
    <div>
      <div data-testid="counter" className={styles.counterContainer}>
        {seconds > 0 ? countdownText : '00:00 min'}
      </div>
    </div>
  );
};

export default Countdown;
