import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import AlertIcon from '../../../assets/Icons/AlertIcon';
import { ProductType } from '../../../interfaces';
import textContent from '../../../utils/textContent.json';

interface Props {
  onRestoreFavorite: (favorite: ProductType) => void;
  favorite: ProductType;
}

const DeleteFavoriteAlert = ({ favorite, onRestoreFavorite }: Props) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      background="orangeAlert"
      width={{ xs: '270px', md: '293px' }}
      height="74px"
      borderRadius="8px"
      margin="0 15px"
      data-testid="delete-favorite-alert-container"
    >
      <Flex height="100%" alignItems="flex-start" marginTop="28px">
        <AlertIcon dataTestId="alert-icon" />
      </Flex>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text
          data-testid="delete-favorite-alert-title"
          marginLeft="12px"
          fontSize="14px"
          fontWeight="400"
          color="darkBlue"
        >
          {textContent.favorite.productRemovedFavorites}
        </Text>
        <Text
          data-testid="delete-favorite-alert-subtitle"
          fontSize="14px"
          fontWeight="500"
          color="purple"
          textDecoration="underline"
          cursor="pointer"
          onClick={() => onRestoreFavorite(favorite)}
        >
          {textContent.favorite.returnProductList}
        </Text>
      </Flex>
    </Flex>
  );
};

export default DeleteFavoriteAlert;
