import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import EmptyFavoriteIcon from '../../../assets/Icons/EmptyFavoriteIcon';
import textContent from '../../../utils/textContent.json';
import GoBack from '../../shared/GoBack/GoBack';

const EmptyFavoritesList = () => {
  return (
    <Box height="93vh" backgroundColor="white">
      <GoBack url={'/'} title={textContent.favorites} />
      <Flex
        overflow="hidden"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        paddingTop="120px"
        paddingBottom="120px"
        backgroundColor="white"
      >
        <EmptyFavoriteIcon dataTestId="empty-favorite-list" />
        <Text
          width="256px"
          textAlign="center"
          fontSize={{ base: '20px', md: '25px' }}
          fontWeight="500"
          data-testid="empty-favorite-list-message"
        >
          {textContent.EmptyFavoritesList}
        </Text>
      </Flex>
    </Box>
  );
};

export default EmptyFavoritesList;
