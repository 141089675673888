/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import NorpanLogo from '../../../assets/Icons/NorpanIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authData } from '../../../store/modules/auth';
import { closeMenu } from '../../../store/modules/sideMenu';
import textContent from '../../../utils/textContent.json';
import CustomerButton from '../CustomerButton/CustomerButton';

interface Props {
  refElement: any;
}

const SideMenu = ({ refElement }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector(authData);
  const loggedInOptions = (
    <>
      <Text
        data-testid="favorites-label"
        margin="0 0 25px 30px"
        cursor="pointer"
        onClick={() => {
          dispatch(closeMenu());
          navigate('/favorites');
        }}
      >
        {textContent.favorites}
      </Text>
      <Text
        data-testid="reservation-history-label"
        margin="0 0 25px 30px"
        cursor="pointer"
        onClick={() => {
          dispatch(closeMenu());
          navigate('/reservation-history');
        }}
      >
        {textContent.reservationHistory}
      </Text>
    </>
  );

  return (
    <>
      <Flex
        width="71%"
        backgroundColor="background"
        zIndex={30}
        height="100%"
        position="fixed"
        top="0"
        left="0"
        overflowY="auto"
        flexDirection="column"
        ref={refElement}
        data-testid="side-menu-container"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          height="131px"
          background="linear-gradient(180deg, #37F 3.76%, rgba(51, 119, 255, 0.56) 57.62%, rgba(255, 255, 255, 0.35) 186.7%);"
        >
          <Box
            onClick={() => {
              navigate('/');
              dispatch(closeMenu());
            }}
            cursor="pointer"
          >
            <NorpanLogo dataTestId="norpan-logo" />
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          height="100%"
          paddingTop="32px"
          justifyContent="space-between"
          textAlign="start"
          fontSize="16px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
        >
          <Box>
            <Text
              data-testid="categories-label"
              margin="0 0 25px 30px"
              cursor="pointer"
              onClick={() => {
                dispatch(closeMenu());
                navigate('/categories');
              }}
            >
              {textContent.categories}
            </Text>
            <Text
              data-testid="sales-label"
              margin="0 0 25px 30px"
              cursor="pointer"
              onClick={() => {
                dispatch(closeMenu());
                navigate('/deals');
              }}
            >
              {textContent.deals}
            </Text>
            {isLogged && loggedInOptions}
            <Text
              data-testid="contact-label"
              margin="0 0 25px 30px"
              onClick={() => {
                navigate('/contact'), dispatch(closeMenu());
              }}
              cursor="pointer"
            >
              {textContent.contactUs}
            </Text>
            {!isLogged && (
              <Flex marginTop="20px" width="100%" justifyContent="center" alignItems="center">
                <CustomerButton />
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SideMenu;
