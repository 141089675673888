import React from 'react';
import { Box, Text } from '@chakra-ui/react';

interface SubheaderProps {
  title: string;
  subtitle: string;
}
const Subheader = ({ title, subtitle }: SubheaderProps) => {
  return (
    <Box>
      <Text fontSize="20px" fontWeight="600">
        {title}
      </Text>
      <Text fontSize="14px" fontWeight="500">
        {subtitle}
      </Text>
    </Box>
  );
};

export default Subheader;
