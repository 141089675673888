import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../types';
import { CartItem } from './types';

export const getCartProducts = (state: RootState): CartItem[] => {
  return state.cart.products;
};

export const getProductId = (_state: RootState, productId: string) => productId;

export const getAmountOfProductInCart = createSelector(
  getCartProducts,
  (cartProducts: CartItem[]) => {
    return cartProducts.length;
  },
);

export const getTotalPriceCart = createSelector(getCartProducts, (cartProducts: CartItem[]) => {
  return cartProducts.reduce((acc, product) => acc + product.priceList * product.quantity, 0);
});

export const getCartProductQuantity = createSelector(
  getCartProducts,
  getProductId,
  (products, productId) => {
    const foundProduct = products.find((product) => product.articleCode === productId);
    return foundProduct ? foundProduct.quantity : 0;
  },
);
