import React from 'react';

const ManualDeleteVoucherIcon = () => (
  <svg
    width="256"
    height="205"
    viewBox="0 0 256 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="256" height="204.8" fill="#FAFAFC" />
    <rect width="256" height="204.8" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M211.969 66.5601C215.928 66.5601 219.137 69.7693 219.137 73.7281C219.137 77.6868 215.928 80.8961 211.969 80.8961H171.009C174.968 80.8961 178.177 84.1053 178.177 88.0641C178.177 92.0228 174.968 95.2321 171.009 95.2321H193.537C197.496 95.2321 200.705 98.4413 200.705 102.4C200.705 106.359 197.496 109.568 193.537 109.568H183.119C178.127 109.568 174.081 112.777 174.081 116.736C174.081 119.375 176.129 121.765 180.225 123.904C184.184 123.904 187.393 127.113 187.393 131.072C187.393 135.031 184.184 138.24 180.225 138.24H95.2329C91.2741 138.24 88.0649 135.031 88.0649 131.072C88.0649 127.113 91.2741 123.904 95.2329 123.904H55.2969C51.3381 123.904 48.1289 120.695 48.1289 116.736C48.1289 112.777 51.3381 109.568 55.2969 109.568H96.2569C100.216 109.568 103.425 106.359 103.425 102.4C103.425 98.4413 100.216 95.2321 96.2569 95.2321H70.6569C66.6981 95.2321 63.4889 92.0228 63.4889 88.0641C63.4889 84.1053 66.6981 80.8961 70.6569 80.8961H111.617C107.658 80.8961 104.449 77.6868 104.449 73.7281C104.449 69.7693 107.658 66.5601 111.617 66.5601H211.969ZM211.969 95.2321C215.928 95.2321 219.137 98.4413 219.137 102.4C219.137 106.359 215.928 109.568 211.969 109.568C208.01 109.568 204.801 106.359 204.801 102.4C204.801 98.4413 208.01 95.2321 211.969 95.2321Z"
      fill="#EBF1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.36 65.5359L166.885 135.007L167.74 141.977C168.016 144.222 166.419 146.266 164.174 146.541L104.208 153.904C101.963 154.18 99.9195 152.583 99.6438 150.338L90.4117 75.148C90.2739 74.0253 91.0722 73.0035 92.1948 72.8656C92.2019 72.8648 92.209 72.8639 92.2161 72.8631L97.1911 72.3051M101.214 71.8538L105.911 71.3269L101.214 71.8538Z"
      fill="white"
    />
    <path
      d="M158.598 65.3661C158.504 64.6822 157.874 64.2037 157.19 64.2975C156.506 64.3913 156.027 65.0218 156.121 65.7057L158.598 65.3661ZM166.885 135.007L168.125 134.855C168.125 134.849 168.124 134.843 168.123 134.837L166.885 135.007ZM167.74 141.977L168.981 141.824L167.74 141.977ZM164.174 146.541L164.326 147.782L164.174 146.541ZM104.208 153.904L104.361 155.145L104.208 153.904ZM99.6438 150.338L100.885 150.185L99.6438 150.338ZM92.2161 72.8631L92.3555 74.1054L92.2161 72.8631ZM97.3304 73.5473C98.0165 73.4703 98.5103 72.8518 98.4333 72.1657C98.3564 71.4797 97.7378 70.9859 97.0518 71.0629L97.3304 73.5473ZM101.075 70.6116C100.389 70.6886 99.8947 71.3071 99.9717 71.9932C100.049 72.6792 100.667 73.173 101.353 73.096L101.075 70.6116ZM106.05 72.5691C106.736 72.4922 107.23 71.8736 107.153 71.1876C107.076 70.5015 106.458 70.0078 105.772 70.0847L106.05 72.5691ZM156.121 65.7057L165.646 135.177L168.123 134.837L158.598 65.3661L156.121 65.7057ZM165.644 135.159L166.5 142.129L168.981 141.824L168.125 134.855L165.644 135.159ZM166.5 142.129C166.691 143.689 165.582 145.109 164.022 145.301L164.326 147.782C167.257 147.422 169.341 144.755 168.981 141.824L166.5 142.129ZM164.022 145.301L104.056 152.663L104.361 155.145L164.326 147.782L164.022 145.301ZM104.056 152.663C102.496 152.855 101.076 151.746 100.885 150.185L98.4032 150.49C98.763 153.421 101.43 155.505 104.361 155.145L104.056 152.663ZM100.885 150.185L91.6524 74.9956L89.171 75.3003L98.4032 150.49L100.885 150.185ZM91.6524 74.9956C91.5987 74.5582 91.9097 74.16 92.3472 74.1063L92.0425 71.625C90.2346 71.8469 88.949 73.4924 89.171 75.3003L91.6524 74.9956ZM92.3472 74.1063C92.3499 74.106 92.3527 74.1057 92.3555 74.1054L92.0768 71.6209C92.0654 71.6222 92.0539 71.6236 92.0425 71.625L92.3472 74.1063ZM92.3555 74.1054L97.3304 73.5473L97.0518 71.0629L92.0768 71.6209L92.3555 74.1054ZM101.353 73.096L106.05 72.5691L105.772 70.0847L101.075 70.6116L101.353 73.096Z"
      fill="#5C92FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154.768 69.9075L163.39 132.867L164.165 139.183C164.415 141.218 162.988 143.068 160.977 143.314L107.276 149.908C105.265 150.155 103.433 148.706 103.183 146.671L94.8316 78.6535C94.697 77.5572 95.4766 76.5593 96.573 76.4247L103.264 75.6032"
      fill="#EBF1FF"
    />
    <path
      d="M110.482 55.2002C110.482 53.6814 111.714 52.4502 113.232 52.4502H160.018C160.747 52.4502 161.447 52.7397 161.962 53.255L175.784 67.0686C176.3 67.5844 176.59 68.2841 176.59 69.0138V133.216C176.59 134.735 175.359 135.966 173.84 135.966H113.232C111.714 135.966 110.482 134.735 110.482 133.216V55.2002Z"
      fill="white"
      stroke="#5C92FF"
      strokeWidth="2.5"
    />
    <path
      d="M160.432 53.6606V65.5361C160.432 67.2328 161.807 68.6081 163.504 68.6081H171.628"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M120.32 67.8398H148.48" stroke="#5C92FF" strokeWidth="4.5" strokeLinecap="round" />
    <path
      d="M66.341 139.52L67.6204 138.241C67.9141 137.947 67.9141 137.474 67.6204 137.18C67.3267 136.887 66.8538 136.887 66.5601 137.18L65.2807 138.46L64.0013 137.18C63.7076 136.887 63.2347 136.887 62.941 137.18C62.6473 137.474 62.6473 137.947 62.941 138.241L64.2204 139.52L62.941 140.799C62.6473 141.093 62.6473 141.566 62.941 141.86C63.2347 142.153 63.7076 142.153 64.0013 141.86L65.2807 140.58L66.5601 141.86C66.8538 142.153 67.3267 142.153 67.6204 141.86C67.9141 141.566 67.9141 141.093 67.6204 140.799L66.341 139.52Z"
      fill="#C0D5FF"
    />
    <path
      d="M74.0207 144.64L75.3001 143.361C75.5938 143.067 75.5938 142.594 75.3001 142.3C75.0064 142.007 74.5335 142.007 74.2398 142.3L72.9604 143.58L71.681 142.3C71.3873 142.007 70.9144 142.007 70.6207 142.3C70.327 142.594 70.327 143.067 70.6207 143.361L71.9 144.64L70.6207 145.919C70.327 146.213 70.327 146.686 70.6207 146.98C70.9144 147.274 71.3873 147.274 71.681 146.98L72.9604 145.7L74.2398 146.98C74.5335 147.274 75.0064 147.274 75.3001 146.98C75.5938 146.686 75.5938 146.213 75.3001 145.919L74.0207 144.64Z"
      fill="#C0D5FF"
    />
    <path
      d="M191.78 61.4399L193.06 60.1605C193.354 59.8668 193.354 59.3939 193.06 59.1002C192.766 58.8065 192.293 58.8065 192 59.1002L190.72 60.3795L189.441 59.1002C189.147 58.8065 188.674 58.8065 188.38 59.1002C188.087 59.3939 188.087 59.8668 188.38 60.1605L189.66 61.4399L188.38 62.7193C188.087 63.013 188.087 63.4859 188.38 63.7796C188.674 64.0733 189.147 64.0733 189.441 63.7796L190.72 62.5002L192 63.7796C192.293 64.0733 192.766 64.0733 193.06 63.7796C193.354 63.4859 193.354 63.013 193.06 62.7193L191.78 61.4399Z"
      fill="#C0D5FF"
    />
    <path
      d="M175.14 43.52L176.419 42.2406C176.713 41.9469 176.713 41.474 176.419 41.1802C176.126 40.8865 175.653 40.8865 175.359 41.1802L174.08 42.4596L172.8 41.1802C172.506 40.8865 172.034 40.8865 171.74 41.1802C171.446 41.474 171.446 41.9469 171.74 42.2406L173.019 43.52L171.74 44.7993C171.446 45.0931 171.446 45.566 171.74 45.8597C172.034 46.1534 172.506 46.1534 172.8 45.8597L174.08 44.5803L175.359 45.8597C175.653 46.1534 176.126 46.1534 176.419 45.8597C176.713 45.566 176.713 45.0931 176.419 44.7993L175.14 43.52Z"
      fill="#C0D5FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.454 81.6946C140.129 81.0194 141.045 80.6401 142 80.6401C142.954 80.6401 143.87 81.0194 144.545 81.6946C144.992 82.1416 145.31 82.694 145.473 83.2935C145.891 83.1277 146.341 83.0401 146.8 83.0401C147.754 83.0401 148.67 83.4194 149.345 84.0946C150.02 84.7697 150.4 85.6854 150.4 86.6401V88.046C150.781 87.911 151.187 87.8401 151.6 87.8401C152.554 87.8401 153.47 88.2194 154.145 88.8946C154.82 89.5697 155.2 90.4854 155.2 91.4401V104.64C155.2 105.303 154.662 105.84 154 105.84C153.337 105.84 152.8 105.303 152.8 104.64V91.4401C152.8 91.1219 152.673 90.8167 152.448 90.5916C152.223 90.3666 151.918 90.2401 151.6 90.2401C151.281 90.2401 150.976 90.3666 150.751 90.5916C150.526 90.8167 150.4 91.1219 150.4 91.4401V99.8401C150.4 100.503 149.862 101.04 149.2 101.04C148.537 101.04 148 100.503 148 99.8401V86.6401C148 86.3219 147.873 86.0167 147.648 85.7916C147.423 85.5666 147.118 85.4401 146.8 85.4401C146.481 85.4401 146.176 85.5666 145.951 85.7916C145.726 86.0167 145.6 86.3219 145.6 86.6401V98.6401C145.6 99.3029 145.062 99.8401 144.4 99.8401C143.737 99.8401 143.2 99.3029 143.2 98.6401V84.2401C143.2 83.9219 143.073 83.6167 142.848 83.3916C142.623 83.1666 142.318 83.0401 142 83.0401C141.681 83.0401 141.376 83.1666 141.151 83.3916C140.926 83.6167 140.8 83.9219 140.8 84.2401V98.7151C140.8 99.3779 140.262 99.9151 139.6 99.9151C138.937 99.9151 138.4 99.3779 138.4 98.7151V87.8401C138.4 87.5219 138.273 87.2167 138.048 86.9916C137.823 86.7666 137.518 86.6401 137.2 86.6401C136.881 86.6401 136.576 86.7666 136.351 86.9916C136.126 87.2167 136 87.5219 136 87.8401V104.64C136 105.303 135.462 105.84 134.8 105.84C134.137 105.84 133.6 105.303 133.6 104.64V87.8401C133.6 86.8854 133.979 85.9697 134.654 85.2946C135.329 84.6194 136.245 84.2401 137.2 84.2401C137.612 84.2401 138.018 84.311 138.4 84.446V84.2401C138.4 83.2854 138.779 82.3697 139.454 81.6946Z"
      fill="#FF923F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.981 98.567C130.692 98.0745 129.971 97.8511 129.333 98.2137L129.333 98.2138C129.032 98.385 128.896 98.5941 128.837 98.833C128.77 99.1039 128.784 99.505 128.976 100.025L128.977 100.028L128.977 100.028L132.918 110.802C133.626 112.484 134.559 113.906 135.991 114.923C137.425 115.941 139.484 116.64 142.6 116.64C145.626 116.64 148.133 115.64 149.893 113.729C151.661 111.809 152.8 108.83 152.8 104.64C152.8 103.978 153.338 103.44 154 103.44C154.663 103.44 155.2 103.978 155.2 104.64C155.2 109.255 153.94 112.877 151.658 115.355C149.368 117.843 146.175 119.04 142.6 119.04C139.117 119.04 136.537 118.254 134.601 116.88C132.671 115.509 131.506 113.641 130.694 111.704C130.687 111.687 130.68 111.67 130.674 111.653L126.724 100.856C126.724 100.855 126.724 100.855 126.724 100.854C126.417 100.021 126.295 99.1179 126.507 98.258C126.727 97.3655 127.286 96.6166 128.148 96.1271M130.981 98.567L133.692 105.1C133.946 105.712 134.648 106.003 135.26 105.749C135.873 105.495 136.163 104.792 135.909 104.18L133.172 97.5864C133.158 97.5527 133.143 97.5197 133.126 97.4875C132.139 95.6125 129.821 95.176 128.148 96.1269"
      fill="#FF923F"
    />
  </svg>
);

export default ManualDeleteVoucherIcon;
