import * as React from 'react';

interface Props {
  dataTestId: string;
  onClick: () => void;
}

const DeleteBannerIcon = ({ dataTestId, onClick }: Props) => (
  <svg
    data-testid={dataTestId}
    width="20"
    onClick={onClick}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66602 5.00008C1.66602 4.53984 2.03911 4.16675 2.49935 4.16675H17.4993C17.9596 4.16675 18.3327 4.53984 18.3327 5.00008C18.3327 5.46032 17.9596 5.83342 17.4993 5.83342H2.49935C2.03911 5.83342 1.66602 5.46032 1.66602 5.00008Z"
      fill="#3377FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33398 2.49992C8.11297 2.49992 7.90101 2.58772 7.74473 2.744C7.58845 2.90028 7.50065 3.11224 7.50065 3.33325V4.99992C7.50065 5.46016 7.12755 5.83325 6.66732 5.83325C6.20708 5.83325 5.83398 5.46016 5.83398 4.99992V3.33325C5.83398 2.67021 6.09738 2.03433 6.56622 1.56548C7.03506 1.09664 7.67094 0.833252 8.33398 0.833252H11.6673C12.3304 0.833252 12.9662 1.09664 13.4351 1.56548C13.9039 2.03433 14.1673 2.67021 14.1673 3.33325V4.99992C14.1673 5.46016 13.7942 5.83325 13.334 5.83325C12.8737 5.83325 12.5007 5.46016 12.5007 4.99992V3.33325C12.5007 3.11224 12.4129 2.90028 12.2566 2.744C12.1003 2.58772 11.8883 2.49992 11.6673 2.49992H8.33398ZM4.16732 4.16658C4.62756 4.16658 5.00065 4.53968 5.00065 4.99992V16.6666C5.00065 16.8876 5.08845 17.0996 5.24473 17.2558C5.40101 17.4121 5.61297 17.4999 5.83398 17.4999H14.1673C14.3883 17.4999 14.6003 17.4121 14.7566 17.2558C14.9129 17.0996 15.0007 16.8876 15.0007 16.6666V4.99992C15.0007 4.53968 15.3737 4.16658 15.834 4.16658C16.2942 4.16658 16.6673 4.53968 16.6673 4.99992V16.6666C16.6673 17.3296 16.4039 17.9655 15.9351 18.4344C15.4662 18.9032 14.8304 19.1666 14.1673 19.1666H5.83398C5.17094 19.1666 4.53506 18.9032 4.06622 18.4344C3.59738 17.9655 3.33398 17.3296 3.33398 16.6666V4.99992C3.33398 4.53968 3.70708 4.16658 4.16732 4.16658Z"
      fill="#3377FF"
    />
  </svg>
);
export default DeleteBannerIcon;
