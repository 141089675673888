import * as React from 'react';

interface Props {
  dataTestId: string;
  onClick: () => void;
}

const PauseBannerIcon = ({ dataTestId, onClick }: Props) => (
  <svg
    data-testid={dataTestId}
    width="20"
    height="20"
    onClick={onClick}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0007 2.49992C5.85851 2.49992 2.50065 5.85778 2.50065 9.99992C2.50065 14.1421 5.85851 17.4999 10.0007 17.4999C14.1428 17.4999 17.5006 14.1421 17.5006 9.99992C17.5006 5.85778 14.1428 2.49992 10.0007 2.49992ZM0.833984 9.99992C0.833984 4.93731 4.93804 0.833252 10.0007 0.833252C15.0633 0.833252 19.1673 4.93731 19.1673 9.99992C19.1673 15.0625 15.0633 19.1666 10.0007 19.1666C4.93804 19.1666 0.833984 15.0625 0.833984 9.99992Z"
      fill="#3377FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 6.66675C8.79357 6.66675 9.16667 7.03984 9.16667 7.50008V12.5001C9.16667 12.9603 8.79357 13.3334 8.33333 13.3334C7.8731 13.3334 7.5 12.9603 7.5 12.5001V7.50008C7.5 7.03984 7.8731 6.66675 8.33333 6.66675Z"
      fill="#3377FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6673 6.66675C12.1276 6.66675 12.5007 7.03984 12.5007 7.50008V12.5001C12.5007 12.9603 12.1276 13.3334 11.6673 13.3334C11.2071 13.3334 10.834 12.9603 10.834 12.5001V7.50008C10.834 7.03984 11.2071 6.66675 11.6673 6.66675Z"
      fill="#3377FF"
    />
  </svg>
);
export default PauseBannerIcon;
