import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { userAuthReducer } from './modules/auth';
import { cartReducer } from './modules/cart';
import { categoryReducer } from './modules/categories';
import { favoriteReducer } from './modules/favorites';
import { productReducer } from './modules/products';
import { quotationsReducer } from './modules/quotations';
import { settingsReducer } from './modules/settings';
import { sideMenuReducer } from './modules/sideMenu';
import { toastReducer } from './modules/toast';
import { uiReducer } from './modules/ui';
import { userReducer } from './modules/users';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['ui', 'toast', 'sideMenuIsOpen'],
};

const rootReducer = combineReducers({
  auth: userAuthReducer,
  cart: cartReducer,
  user: userReducer,
  category: categoryReducer,
  product: productReducer,
  sideMenuIsOpen: sideMenuReducer,
  products: productReducer,
  ui: uiReducer,
  favorite: favoriteReducer,
  quotation: quotationsReducer,
  toast: toastReducer,
  settings: settingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
