import useSWR from 'swr';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { ProductDeals } from '../../../enum/Product';
import { SWRKey } from '../../../enum/SWRKey';
import { getBrandsByCategory } from '../../../services/brands';
import { getProductsOnDealsByCategory } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { categoryData, structuredAndOrderedCategory } from '../../../store/modules/categories';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import { RootState } from '../../../store/types';
import { transformString } from '../../../utils/search-items';
import textContent from '../../../utils/textContent.json';
import BrandsCarousel from '../BrandsCasousel/BrandsCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import SubCategoryViewSkeleton from '../SubCategoryView/SubCategoryViewSkeleton';

const CategoryDeals = () => {
  const { id: categoryCode } = useParams();
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadingFavorites = useAppSelector(isLoadingFavorites);
  const categories = useAppSelector(categoryData);
  const categoryItem = useAppSelector((state: RootState) =>
    structuredAndOrderedCategory(state, categoryCode as string),
  );

  const { data, error, isLoading } = useSWR(
    categoryCode && { currentPage, categoryCode, key: SWRKey.productsOnDeals },
    getProductsOnDealsByCategory,
  );

  const { data: brands, isLoading: isLoadingBrands } = useSWR(
    categoryCode && {
      categoryCode,
      key: SWRKey.brands,
      deals: ProductDeals.EN_OFERTA,
    },
    getBrandsByCategory,
  );

  if (isLoading || loadingFavorites || isLoadingBrands) {
    return <SubCategoryViewSkeleton />;
  }

  if (error || data?.products.length === 0) {
    return (
      <ErrorScreen
        title={textContent.product.productError}
        arrowTitle={transformString(categoryItem?.category)}
      />
    );
  }

  return (
    <Flex justifyContent="center" direction="column" height="100%">
      <GoBack title={textContent.deals} subTitle={transformString(categoryItem?.category)} />
      <Flex
        flexDirection="column"
        alignItems={{ md: 'center' }}
        backgroundColor={{ base: 'white', md: 'grey50' }}
        padding={{ md: '30px 0' }}
      >
        {brands && brands.length > 0 && (
          <Flex
            flexDirection="column"
            minHeight={{ base: '150px', md: '220px' }}
            width={{ md: '85%' }}
            backgroundColor="white"
            borderRadius="8px"
            justifyContent="flex-start"
          >
            <Text
              margin={{ base: '20px 30px 0' }}
              color="darkBlue"
              fontWeight="500"
              fontSize={{ base: '16px', md: '18px' }}
            >
              {textContent.category.SelectFavoriteBrand}
            </Text>
            <BrandsCarousel pathname={pathname} brands={brands} isLoading={categories.loading} />
          </Flex>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        backgroundColor="grey50"
        height="100%"
      >
        <Box>
          <Text
            color="darkBlue"
            textAlign="start"
            fontSize={{ base: '16px', md: '20px' }}
            fontWeight={{ base: '500', md: '600' }}
            margin={{ base: '24px', xl: '24px 0' }}
          >
            {textContent.highlightedDeals}
          </Text>
          <Grid
            gridTemplateColumns={{
              base: 'repeat(auto-fill, minmax(150px, 1fr))',
              xl: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
            data-testid="product-list"
          >
            {data?.products.map((product, index) => (
              <Flex justifyContent={'center'} key={index} marginBottom="20px">
                <ProductCard
                  isDeals
                  product={product}
                  urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_DEALS}/${categoryCode}`}
                />
              </Flex>
            ))}
          </Grid>
        </Box>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    </Flex>
  );
};

export default CategoryDeals;
