import useSWR from 'swr';
import React, { useState } from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { getProductsByDeals } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import textContent from '../../../utils/textContent.json';
import CategoriesCarousel from '../Categories/CategoriesCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import DealsViewSkeleton from './DealsViewSkeleton';

const DealsView = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const loadingFavorites = useAppSelector(isLoadingFavorites);

  const { data, error, isLoading } = useSWR(
    { currentPage, key: SWRKey.productsOnDeals },
    getProductsByDeals,
  );

  if (isLoading || loadingFavorites) {
    return <DealsViewSkeleton />;
  }

  if (error || data?.products.length === 0) {
    return (
      <ErrorScreen
        url={'/'}
        title={textContent.product.productError}
        arrowTitle={textContent.deals}
      />
    );
  }

  return (
    <Flex justifyContent="flex-start" direction="column">
      <GoBack title={textContent.deals} />
      <Flex
        backgroundColor="grey50"
        height={{ base: '130px', md: '198px' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        <CategoriesCarousel />
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        padding={{ base: '0', md: '0 80px', xl: '0 112px' }}
        backgroundColor="grey50"
        height="100%"
      >
        <Box>
          <Text
            color="darkBlue"
            textAlign="start"
            fontSize={{ base: '16px', md: '20px' }}
            fontWeight={{ base: '500', md: '600' }}
            margin={{ base: '24px', xl: '24px 0' }}
          >
            {textContent.highlightedDeals}
          </Text>
          <Grid
            gridTemplateColumns={{
              base: 'repeat(auto-fill, minmax(150px, 1fr))',
              xl: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
          >
            {data &&
              data?.products.map((product, index) => (
                <Flex justifyContent="center" key={index} marginBottom="20px">
                  <ProductCard
                    isDeals
                    product={product}
                    urlKey={`${URL.GET_PAGINATED_PRODUCTS_BY_DEALS}`}
                  />
                </Flex>
              ))}
          </Grid>
        </Box>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Flex>
    </Flex>
  );
};

export default DealsView;
