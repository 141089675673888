import useSWR from 'swr';
import { useDebouncedCallback } from 'use-debounce';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Input, List, ListItem, Spinner, Text } from '@chakra-ui/react';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { ProductType } from '../../../interfaces';
import { getProductsFromSearchBar } from '../../../services/product';
import { formatResultItems } from '../../../utils/search-items';
import TextContent from '../../../utils/textContent.json';

const SearchBar = () => {
  const { isMobile } = useBreakpoint();
  const [searchValue, setSearchValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const { data, isLoading } = useSWR(
    searchValue.toLowerCase().trim() && {
      currentPage: 1,
      pageSize: 10,
      searchValue,
      full: false,
    },
    getProductsFromSearchBar,
  );
  const navigate = useNavigate();

  const searchBarRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;

      if (searchBarRef.current && !searchBarRef.current.contains(clickedElement)) {
        setInputValue('');
        setSearchValue('');
      }
    };

    document.body.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const debounced = useDebouncedCallback(async (text: string) => {
    setSearchValue(text);
  }, 300);

  const heightSearchBar = !isMobile ? '40px' : '33px';
  const fontStyle = !isMobile ? '16px' : '14px';

  return (
    <Box position="relative" data-testid="search-bar-container" ref={searchBarRef}>
      <Input
        data-testid="search-bar-input"
        type="text"
        value={inputValue}
        placeholder={TextContent.searchFor}
        onChange={(e) => {
          setInputValue(e.target.value);
          debounced(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && inputValue.length >= 2) {
            setSearchValue('');
            navigate(`/search-result?searchValue=${inputValue}`);
          }
        }}
        style={{
          backgroundColor: 'white',
        }}
      />
      {isLoading && (
        <Flex
          alignItems="center"
          paddingLeft="8px"
          justifyContent="center"
          style={{
            borderRadius: '8px',
            backgroundColor: 'white',
            color: 'black',
            height: heightSearchBar,
          }}
          width="100%"
          position="absolute"
          top="100%"
          left="0"
          zIndex="1"
          boxShadow="md"
        >
          <Spinner data-testid="loading-spinner" size="md" color="blue.500" />
        </Flex>
      )}
      {data && data.products.length === 0 && !isLoading && (
        <Flex
          alignItems="center"
          paddingLeft="16px"
          style={{
            borderRadius: '8px',
            backgroundColor: 'white',
            color: 'black',
            height: heightSearchBar,
          }}
          width="100%"
          position="absolute"
          top="100%"
          left="0"
          zIndex="1"
          boxShadow="md"
        >
          <Text
            color="black"
            data-testid="search-error-message"
            fontSize={fontStyle}
            lineHeight={fontStyle}
          >
            {TextContent.searchBarError}
          </Text>
        </Flex>
      )}
      {data && data.products.length > 0 && (
        <List
          width="100%"
          position="absolute"
          top="100%"
          left="0"
          zIndex="1"
          bg="white"
          boxShadow="md"
          padding="10px"
        >
          {data.products.slice(0, 9).map((product: ProductType, index: number) => {
            const parsedProduct = formatResultItems(product);
            return (
              <ListItem
                key={`${product.iDArticlePrice}-${index}`}
                marginBottom="5px"
                cursor="pointer"
              >
                <Text
                  data-testid="product-result"
                  style={{ width: '90%', whiteSpace: 'pre-wrap' }}
                  onClick={() => {
                    setSearchValue('');
                    navigate(`/product/${product?.articleCode}`);
                  }}
                >
                  {`${parsedProduct?.title}: ${parsedProduct?.content}`}
                </Text>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default SearchBar;
