import React from 'react';

const PauseBannerImg = () => (
  <svg
    width="257"
    height="205"
    viewBox="0 0 257 205"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="256" height="204.8" transform="translate(0.5)" fill="#FAFAFC" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0122 137.215H158.196C158.723 137.215 159.237 137.158 159.732 137.05C160.227 137.158 160.741 137.215 161.268 137.215H214.516C218.475 137.215 221.684 134.006 221.684 130.047C221.684 126.088 218.475 122.879 214.516 122.879H208.372C204.413 122.879 201.204 119.67 201.204 115.711C201.204 111.752 204.413 108.543 208.372 108.543H227.828C231.787 108.543 234.996 105.334 234.996 101.375C234.996 97.4164 231.787 94.2072 227.828 94.2072H205.3C209.259 94.2072 212.468 90.9979 212.468 87.0392C212.468 83.0804 209.259 79.8712 205.3 79.8712H139.764C143.723 79.8712 146.932 76.6619 146.932 72.7032C146.932 68.7444 143.723 65.5352 139.764 65.5352H81.3962C77.4374 65.5352 74.2282 68.7444 74.2282 72.7032C74.2282 76.6619 77.4374 79.8712 81.3962 79.8712H40.4362C36.4774 79.8712 33.2682 83.0804 33.2682 87.0392C33.2682 90.9979 36.4774 94.2072 40.4362 94.2072H66.0362C69.995 94.2072 73.2042 97.4164 73.2042 101.375C73.2042 105.334 69.995 108.543 66.0362 108.543H25.0762C21.1174 108.543 17.9082 111.752 17.9082 115.711C17.9082 119.67 21.1174 122.879 25.0762 122.879H65.0122C61.0534 122.879 57.8442 126.088 57.8442 130.047C57.8442 134.006 61.0534 137.215 65.0122 137.215ZM231.924 137.215C235.883 137.215 239.092 134.006 239.092 130.047C239.092 126.088 235.883 122.879 231.924 122.879C227.965 122.879 224.756 126.088 224.756 130.047C224.756 134.006 227.965 137.215 231.924 137.215Z"
      fill="#F3F7FF"
    />
    <rect
      x="69.9621"
      y="88.5158"
      width="111.298"
      height="79.1077"
      rx="2.5"
      transform="rotate(-4.88347 69.9621 88.5158)"
      fill="#FAFAFC"
      stroke="#5C92FF"
      strokeWidth="3"
    />
    <path
      d="M79.25 77.8682L85.121 72.7443"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M87.041 71.0728L114.315 46.2493"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M133.018 46.25L169.055 69.6706"
      stroke="#5C92FF"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <circle
      cx="123.668"
      cy="41.5733"
      r="5.76327"
      fill="#FAFAFC"
      stroke="#5C92FF"
      strokeWidth="2.5"
    />
    <path
      d="M82.5891 161.777L77.8574 102.137C77.8574 102.137 96.8093 127.179 112.723 138.178C128.636 149.177 155.483 155.95 155.483 155.95L82.5891 161.777Z"
      fill="#E4EDFF"
      stroke="#E4EDFF"
    />
    <path
      d="M144.201 100.618C144.201 100.617 144.2 100.616 144.199 100.615C142.064 99.3402 139.739 98.3575 137.279 97.7238C136.779 97.5951 136.282 97.93 136.23 98.443C136.185 98.8762 136.483 99.2623 136.905 99.3718C138.786 99.8608 140.577 100.565 142.256 101.454C142.913 101.801 143.745 101.542 144.073 100.876C144.116 100.79 144.158 100.705 144.201 100.619C144.201 100.618 144.201 100.618 144.201 100.618Z"
      fill="#5C92FF"
    />
    <path
      d="M145.369 101.361C145.369 101.361 145.368 101.362 145.368 101.362C145.298 101.478 145.228 101.595 145.16 101.713C144.794 102.345 145.012 103.138 145.596 103.577C151.649 108.134 155.543 115.379 155.503 123.538C155.434 137.276 144.265 148.288 130.471 148.224C116.772 148.159 105.742 136.921 105.841 123.131C105.938 109.582 117.171 98.4988 130.732 98.568C131.984 98.5743 133.212 98.6735 134.411 98.8571C134.609 98.8874 134.788 98.7417 134.807 98.5426C134.864 97.8121 134.374 97.1395 133.645 97.0589C132.747 96.9594 131.835 96.9051 130.912 96.8989C116.153 96.8112 104.197 108.744 104.186 123.256C104.172 138.038 115.971 149.911 130.665 149.911C145.288 149.911 157.165 138.054 157.175 123.443C157.182 114.246 152.473 106.125 145.37 101.36C145.369 101.36 145.369 101.36 145.369 101.361Z"
      fill="#5C92FF"
    />
    <path
      d="M130.732 98.5667C144.498 98.6383 155.57 109.8 155.501 123.534C155.432 137.273 144.263 148.285 130.469 148.22C116.77 148.155 105.739 136.917 105.839 123.128C105.938 109.581 117.171 98.4975 130.732 98.5667ZM127.585 105.684C127.071 105.74 126.106 105.781 125.166 105.959C115.992 107.697 110.186 116.214 111.959 125.381C112.727 129.359 114.794 132.584 117.968 135.105C118.522 135.543 119.111 135.724 119.789 135.5C120.417 135.292 120.752 134.782 121.064 134.239C123.331 130.301 125.605 126.367 127.879 122.433C130.314 118.217 132.752 114.002 135.185 109.784C135.901 108.544 135.522 107.434 134.202 106.896C132.223 106.088 130.16 105.716 127.583 105.684H127.585ZM132.48 141.703C133.094 141.668 133.712 141.677 134.317 141.592C140.502 140.735 144.976 137.51 147.578 131.824C150.646 125.118 148.49 116.731 142.633 112.301C141.52 111.46 140.38 111.682 139.676 112.887C139.124 113.831 138.584 114.78 138.036 115.726C133.828 123.015 129.619 130.301 125.413 137.591C124.679 138.863 125.062 139.962 126.417 140.509C128.361 141.294 130.384 141.682 132.48 141.703Z"
      fill="#E6F0FE"
    />
    <path
      d="M127.585 105.684C130.162 105.714 132.226 106.088 134.204 106.896C135.525 107.436 135.903 108.544 135.187 109.784C132.754 114.001 130.316 118.217 127.881 122.432C125.609 126.366 123.333 130.3 121.066 134.239C120.752 134.783 120.419 135.291 119.791 135.499C119.113 135.723 118.522 135.543 117.97 135.104C114.796 132.583 112.732 129.358 111.961 125.381C110.188 116.213 115.994 107.697 125.168 105.958C126.108 105.781 127.073 105.741 127.588 105.684H127.585ZM133.918 108.641C128.243 105.951 119.558 107.371 115.285 114.74C111.118 121.927 113.942 130.254 119.304 133.948C124.171 125.521 129.033 117.1 133.918 108.641Z"
      fill="#5C92FF"
    />
    <path
      d="M132.481 141.704C130.382 141.683 128.362 141.296 126.418 140.511C125.063 139.964 124.68 138.865 125.414 137.593C129.62 130.304 133.829 123.016 138.037 115.728C138.584 114.781 139.125 113.833 139.676 112.888C140.381 111.683 141.521 111.459 142.634 112.302C148.491 116.732 150.647 125.119 147.579 131.826C144.977 137.512 140.503 140.737 134.318 141.593C133.711 141.677 133.092 141.67 132.481 141.704ZM126.67 138.756C126.848 138.855 126.956 138.934 127.076 138.98C131.248 140.575 135.371 140.425 139.319 138.357C145.148 135.303 148.126 129.275 147.071 122.769C146.478 119.103 144.647 116.107 141.775 113.743C141.401 113.433 141.191 113.466 141.015 113.904C140.974 114.01 140.9 114.103 140.842 114.202C137.43 120.11 134.016 126.017 130.606 131.925C129.304 134.181 128.004 136.438 126.67 138.756Z"
      fill="#5C92FF"
    />
    <path
      d="M133.917 108.644C129.032 117.102 124.17 125.524 119.303 133.951C113.94 130.257 111.119 121.927 115.284 114.743C119.555 107.374 128.242 105.954 133.917 108.644Z"
      fill="#FAFAFC"
    />
    <path
      d="M126.668 138.755C128.002 136.437 129.302 134.18 130.604 131.924C134.016 126.016 137.428 120.109 140.84 114.201C140.898 114.102 140.97 114.007 141.014 113.903C141.189 113.465 141.399 113.435 141.773 113.742C144.643 116.106 146.476 119.102 147.069 122.768C148.124 129.274 145.146 135.301 139.317 138.356C135.369 140.427 131.246 140.577 127.074 138.979C126.954 138.933 126.846 138.854 126.668 138.755Z"
      fill="#FAFAFC"
    />
    <path
      d="M195.061 44.7998V50.9438"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M195.061 59.1357V65.2797"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.82 55.0398H190.964"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M199.156 55.0398H205.3"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.8909 142.08V146.176"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.8909 154.368V158.464"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6992 150.272H55.7952"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9863 150.272H68.0823"
      stroke="#FF923F"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PauseBannerImg;
