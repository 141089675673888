import React from 'react';

import { useBreakpoint } from '../../hooks/useBreakpoint';
import { theme } from '../../theme';

const EmptyReservationHistoryIcon = ({ dataTestId }: { dataTestId: string }) => {
  const { isMobile } = useBreakpoint();

  return (
    <svg
      data-testid={dataTestId}
      width={isMobile ? '200' : '256'}
      height={isMobile ? '160' : '205'}
      viewBox="0 0 256 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="256"
        height="204.8"
        fill={isMobile ? theme.colors.background : theme.colors.grey50}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.5122 137.217H157.696C158.223 137.217 158.737 137.16 159.232 137.052C159.727 137.16 160.241 137.217 160.768 137.217H214.016C217.975 137.217 221.184 134.008 221.184 130.049C221.184 126.09 217.975 122.881 214.016 122.881H207.872C203.913 122.881 200.704 119.672 200.704 115.713C200.704 111.754 203.913 108.545 207.872 108.545H227.328C231.287 108.545 234.496 105.336 234.496 101.377C234.496 97.4183 231.287 94.2091 227.328 94.2091H204.8C208.759 94.2091 211.968 90.9999 211.968 87.0411C211.968 83.0823 208.759 79.8731 204.8 79.8731H139.264C143.223 79.8731 146.432 76.6639 146.432 72.7051C146.432 68.7463 143.223 65.5371 139.264 65.5371H80.8962C76.9374 65.5371 73.7282 68.7463 73.7282 72.7051C73.7282 76.6639 76.9374 79.8731 80.8962 79.8731H39.9362C35.9774 79.8731 32.7682 83.0823 32.7682 87.0411C32.7682 90.9999 35.9774 94.2091 39.9362 94.2091H65.5362C69.495 94.2091 72.7042 97.4183 72.7042 101.377C72.7042 105.336 69.495 108.545 65.5362 108.545H24.5762C20.6174 108.545 17.4082 111.754 17.4082 115.713C17.4082 119.672 20.6174 122.881 24.5762 122.881H64.5122C60.5534 122.881 57.3442 126.09 57.3442 130.049C57.3442 134.008 60.5534 137.217 64.5122 137.217ZM231.424 137.217C235.383 137.217 238.592 134.008 238.592 130.049C238.592 126.09 235.383 122.881 231.424 122.881C227.465 122.881 224.256 126.09 224.256 130.049C224.256 134.008 227.465 137.217 231.424 137.217Z"
        fill="#EBF1FF"
      />
      <path
        d="M171.008 151.552H193.974M65.5365 151.552H87.5055H65.5365ZM52.3555 151.552H59.3925H52.3555ZM198.787 151.552H201.728H198.787Z"
        stroke="#76A4FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.352 44.3415L111.718 57.0935M153.702 44.3415L142.336 57.0935L153.702 44.3415ZM126.976 39.9355V57.0935V39.9355Z"
        stroke="#FF923F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0231 72.7021H159.132L153.391 81.3138L161.046 87.0549H93.1094L101.721 81.3138L95.0231 72.7021Z"
        fill="#EBF1FF"
      />
      <rect x="91.1348" y="84.9902" width="72.704" height="76.8" rx="1.6" fill="#FAFAFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8301 126.98V91.7947C95.8301 90.3451 97.0182 89.1699 98.4837 89.1699L160.924 154.79C160.924 156.723 159.377 158.29 157.468 158.29H99.286C97.3773 158.29 95.8301 156.723 95.8301 154.79V141.714V138.166V126.98ZM95.8301 134.577V130.636V134.577Z"
        fill="#EBF1FF"
      />
      <path
        d="M92.1582 127.448V88.9682C92.1582 87.3828 93.4592 86.0977 95.064 86.0977H161.546C162.591 86.0977 163.438 86.9544 163.438 88.0114V157.862C163.438 159.975 161.744 161.689 159.654 161.689H95.9425C93.8525 161.689 92.1582 159.975 92.1582 157.862V143.561V139.681M92.1582 135.756V131.446"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M94.0672 86.0977V74.6154C94.0672 73.5585 94.8361 72.7017 95.7846 72.7017H159.329C160.278 72.7017 161.047 73.5585 161.047 74.6154V86.0977"
        stroke="#5C92FF"
        strokeWidth="2"
      />
      <path
        d="M111.769 106.192C113.619 106.192 115.118 104.693 115.118 102.843C115.118 100.994 113.619 99.4941 111.769 99.4941C109.919 99.4941 108.42 100.994 108.42 102.843C108.42 104.693 109.919 106.192 111.769 106.192Z"
        fill="#E8F0FE"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M143.345 106.192C145.195 106.192 146.694 104.693 146.694 102.843C146.694 100.994 145.195 99.4941 143.345 99.4941C141.495 99.4941 139.996 100.994 139.996 102.843C139.996 104.693 141.495 106.192 143.345 106.192Z"
        fill="#E8F0FE"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M142.865 106.192C142.865 114.648 136.011 121.502 127.556 121.502C119.1 121.502 112.246 114.648 112.246 106.192"
        stroke="#FF923F"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M95.0817 73.6543L101.818 80.3038C102.22 80.7011 102.225 81.3495 101.827 81.7519C101.759 81.8216 101.68 81.881 101.595 81.9284L94.0664 86.0964"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M160.193 73.7266L153.747 80.3011C153.351 80.7049 153.357 81.3533 153.761 81.7492C153.828 81.8153 153.905 81.8717 153.987 81.917L161.654 86.0993"
        stroke="#5C92FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmptyReservationHistoryIcon;
