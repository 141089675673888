import { ProductType, SearchResultProductType } from '../interfaces';

export const formatResultItems = (item: ProductType | SearchResultProductType) => {
  interface ResultContent {
    title: string;
    content: string;
  }

  const { articleDesc, brand, operatingCenterDesc } = item as {
    articleDesc: string;
    brand: string;
    operatingCenterDesc: string;
  };

  if (articleDesc) return { title: 'Artículo', content: articleDesc } as ResultContent;
  if (brand) return { title: 'Marca', content: brand } as ResultContent;
  if (operatingCenterDesc)
    return { title: 'Categoría', content: operatingCenterDesc } as ResultContent;
};

export const transformString = (word: string | undefined) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
};

export const capitalizeFirstLetters = (word: string | undefined) => {
  if (word) {
    return word
      .toLowerCase()
      .split(' ')
      .map((subWord) => subWord.charAt(0).toUpperCase() + subWord.slice(1))
      .join(' ');
  }
  return '';
};

export const cutString = (txt: string | undefined) => {
  if (txt) {
    const parts = txt.split(' - (');
    if (parts.length > 0) return parts[0];
    return txt;
  }
};

export const searchProducts = (products: ProductType[]): SearchResultProductType[] => {
  const uniqueNames: { [key: string]: Map<string, boolean> } = {
    articleDesc: new Map<string, boolean>(),
    brand: new Map<string, boolean>(),
    operatingCenterDesc: new Map<string, boolean>(),
  };
  const result: SearchResultProductType[] = [];

  const fixNameProducts: ProductType[] = products.map(({ articleDesc, ...rest }) => ({
    ...rest,
    articleDesc: cutString(articleDesc),
  }));

  fixNameProducts?.forEach((product, i) => {
    ['articleDesc', 'brand', 'operatingCenterDesc'].forEach((prop) => {
      const name = product[prop as keyof ProductType];

      if (!uniqueNames[prop].has(name as string)) {
        uniqueNames[prop].set(name as string, true);

        const resultItem: SearchResultProductType = {
          name: name,
          id:
            prop === 'articleDesc'
              ? i + 1
              : prop === 'brand'
              ? i + products.length + 1
              : i + products.length * 2 + 1,
        };
        resultItem[prop] = name;
        result.push(resultItem);
      }
    });
  });
  return result;
};
