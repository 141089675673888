import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

import LogoutIcon from '../../../assets/Icons/LogoutIcon';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAuthData, isAuthLoading, userLogout } from '../../../store/modules/auth';
import { hideModal } from '../../../store/modules/ui';
import { clearCache } from '../../../utils/swr';
import textContent from '../../../utils/textContent.json';
import Button from '../../shared/Button/Button';
interface Props {
  isMobile: boolean;
}

const Logout = ({ isMobile }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authLoading = useAppSelector(isAuthLoading);

  const onSubmit = async () => {
    try {
      const response = await dispatch(userLogout());

      if (response.type === 'USER_LOGOUT/fulfilled') {
        await dispatch(clearAuthData());
        await clearCache({});
        dispatch(hideModal());
        navigate('/');
      }
    } catch (error) {
      throw new Error(textContent.logoutError);
    }
  };

  return (
    <>
      {!isMobile && (
        <Flex justifyContent="flex-end" position="relative" top="14px" right="18px">
          <CloseIcon
            data-testid="close-logout-modal-icon"
            onClick={() => dispatch(hideModal())}
            cursor="pointer"
          />
        </Flex>
      )}
      <Flex
        paddingLeft={{ base: '36px', xl: '36px' }}
        paddingRight={{ base: '36px', xl: '36px' }}
        paddingBottom={{ base: '50px' }}
        flexDirection="column"
        justifyContent="space-between"
        data-testid="logout-modal-container"
        backgroundColor="background"
        height={isMobile ? '100dvh' : '485px'}
        borderRadius={{ xl: '6px' }}
      >
        <Flex
          justifyContent="center"
          flexDirection="column"
          alignItems={{ base: 'center' }}
          height="75%"
        >
          <LogoutIcon dataTestId="logout-icon" />
          <Text
            marginTop={{ base: '48px', md: 0 }}
            data-testid="logout-greetings"
            fontSize={{ base: '25px' }}
            marginBottom={{ base: '48px', md: '24px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="darkBlue"
          >
            {textContent.logoutGreetings}
          </Text>
          <Text
            marginBottom={{ base: '8px' }}
            data-testid="logout-session-label"
            fontSize={{ base: '20px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="darkBlue"
          >
            {textContent.logoutSession}
          </Text>
          <Text
            data-testid="logout-with-continue-label"
            fontSize={{ base: '20px' }}
            textAlign="center"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            color="darkBlue"
          >
            {textContent.wishToContinue}
          </Text>
        </Flex>
        <Flex width={{ base: '100%' }} justifyContent={{ base: 'space-between' }} height="5%">
          <Button
            width={{ base: '45%' }}
            content={textContent.cancel}
            data-testid="logout-cancel-button"
            color="purple"
            backgroundColor="background"
            height={{ base: '40px' }}
            border="1px solid"
            borderColor="purple"
            onClick={() => {
              dispatch(hideModal());
            }}
            fontSize="14px"
          />
          <Button
            width={{ base: '45%' }}
            content={textContent.logout}
            data-testid="logout-confirm-button"
            backgroundColor="purple"
            color="lightgrey"
            height={{ base: '40px' }}
            isLoading={authLoading}
            onClick={() => {
              onSubmit();
            }}
            _hover={{
              backgroundColor: { base: 'none', xl: 'darkPurple' },
            }}
            _active={{
              backgroundColor: { base: 'darkPurple', xl: 'none' },
            }}
            _disabled={{
              backgroundColor: { base: 'lightPurple', xl: 'lightPurple' },
              pointerEvents: 'none',
            }}
            fontSize="14px"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default Logout;
