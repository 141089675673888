import React from 'react';

import PauseBannerImg from '../../../../assets/Icons/PauseBannerImg';
import TrashcanIcon from '../../../../assets/Icons/TrashIcon';
import { BannerActions } from '../../../../interfaces/banner';
import { useAppSelector } from '../../../../store/hooks';
import { getModalState } from '../../../../store/modules/ui/selectors';
import textContent from '../../../../utils/textContent.json';
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal';

const ConfirmBannerADP = () => {
  const { data } = useAppSelector(getModalState);
  const action = data as BannerActions;

  return (
    <ConfirmModal title={textContent.banners[action]} subtitle={textContent.banners.modal[action]}>
      {action === 'deleteBanner' ? <TrashcanIcon /> : <PauseBannerImg />}
    </ConfirmModal>
  );
};

export default ConfirmBannerADP;
