import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, useToast } from '@chakra-ui/react';

import { useBreakpoint } from '../../../hooks/useBreakpoint';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { authData, userRole } from '../../../store/modules/auth';
import { getCategories } from '../../../store/modules/categories';
import { closeToast, toastIsOpen } from '../../../store/modules/toast';
import { getModalState } from '../../../store/modules/ui/selectors';
import AdminHeader from '../../pages/Admin/AdminHeader/AdminHeader';
import Modal from '../../shared/Modal/Modal';
import Header from '../Header/Header';
interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoint();
  const { isLogged } = useAppSelector(authData);
  const { show } = useAppSelector(getModalState);
  const toast = useToast();
  const isOpen = useAppSelector(toastIsOpen);
  const isAdmin = useAppSelector(userRole);

  const routesWithBgWhite = [
    '/contact',
    '/admin',
    '/admin/reservations',
    '/admin/products',
    '/admin/banners',
    '/admin/banners/add',
  ];

  if (isOpen) {
    if (pathname !== '/favorites' && pathname !== '/cart') {
      dispatch(closeToast());
      toast.closeAll();
    }
  }

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <>
      {show && <Box position={'fixed'} top={0} left={0} right={0} bottom={0} z-index={9999} />}
      <Box
        overflow={show && isMobile ? 'hidden' : ''}
        data-testid="layout-container"
        bgGradient={
          pathname === '/'
            ? 'linear-gradient(180deg, #37F 0%, rgba(51, 119, 255, 0.54) 115px, rgba(255,255,255) 180px)'
            : ''
        }
        backgroundColor={routesWithBgWhite.includes(pathname) ? 'white' : 'grey50'}
        style={{
          height: ' 100%',
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {isAdmin ? <AdminHeader isLoggedIn={isLogged} /> : <Header isLoggedIn={isLogged} />}
        {children}
      </Box>
      <Modal />
    </>
  );
};

export default Layout;
