import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, URL } from '../../../api';
import textContent from '../../../utils/textContent.json';
import { QuotationResponse } from './types';

export const getQuotations = createAsyncThunk('GET_QUOTATIONS', async (_, { rejectWithValue }) => {
  try {
    const response = await get<QuotationResponse>(URL.QUOTATION);

    return response.data;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return rejectWithValue(`${textContent.quotation.quotationsNotFound}`);
    }

    if (isAxiosError(error) && error.response?.status === 400) {
      return rejectWithValue(`${textContent.foundProblem}`);
    }

    return rejectWithValue(`${textContent.foundProblem}`);
  }
});
