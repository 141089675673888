import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

import Carousel from '../Carousel/Carousel';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';

const SubCategoryViewSkeleton = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
        backgroundColor="background"
      >
        <Flex
          alignItems="flex-start"
          textAlign="start"
          margin={{ base: '10px 24px', md: '20px 0 20px 112px' }}
        >
          <ArrowBackIcon
            boxSize={8}
            marginRight="20px"
            onClick={() => navigate(-1)}
            cursor="pointer"
            data-testid="arrow-icon"
          />
          <Box>
            <Skeleton marginBottom="10px" width="80px" height="20px" />
            <Skeleton width="50px" height="18px" />
          </Box>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems={{ md: 'center' }}
        backgroundColor={{ base: 'white', md: 'grey50' }}
        padding={{ md: '30px 0' }}
        data-testid="sub-category-skeleton-container"
      >
        <Flex
          flexDirection="column"
          height={{ base: '180px', md: '250px' }}
          width={{ md: '85%' }}
          backgroundColor="white"
          borderRadius="8px"
          justifyContent="space-evenly"
          gap="20px"
        >
          <Skeleton margin={{ base: '20px 30px 0' }} width="200px" height="18px" />
          <Box margin={{ base: '0 30px 50px', md: '15px 30px' }}>
            <Carousel
              datatestid="brands-carousel"
              height="140px"
              width={'full'}
              columns={7.5}
              md={4.5}
              base={3.5}
              sm={2.5}
              dots={false}
              infinite={false}
              autoplay={false}
            >
              {Array(8)
                .fill(undefined)
                .map((_, index) => (
                  <Box
                    key={index}
                    width="80px"
                    height="80px"
                    data-testid={`sub-category-brand-skeleton-${index}`}
                  >
                    <Skeleton width="80px" height="80px" borderRadius="8px" />
                  </Box>
                ))}
            </Carousel>
          </Box>
        </Flex>
      </Flex>
      <ProductCardSkeleton array={12} />
    </>
  );
};

export default SubCategoryViewSkeleton;
