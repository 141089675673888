import useSWR from 'swr';
import React, { useState } from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';

import { URL } from '../../../api';
import { SWRKey } from '../../../enum/SWRKey';
import { getProductsPendingToReceive } from '../../../services/product';
import { useAppSelector } from '../../../store/hooks';
import { isLoadingFavorites } from '../../../store/modules/favorites';
import textContent from '../../../utils/textContent.json';
import CategoriesCarousel from '../Categories/CategoriesCarousel';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import GoBack from '../GoBack/GoBack';
import PaginationNavbar from '../PaginationNavbar/PaginationNavbar';
import ProductCard from '../ProductCard/ProductCard';
import PendingToReceiveViewSkeleton from './PendingToReceiveViewSkeleton';

const PendingToReceiveView = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const loadingFavorites = useAppSelector(isLoadingFavorites);

  const { data, error, isLoading } = useSWR(
    { currentPage, key: SWRKey.productsPendingToReceive },
    getProductsPendingToReceive,
  );

  if (isLoading || loadingFavorites) {
    return <PendingToReceiveViewSkeleton />;
  }

  if (error || data?.products.length === 0) {
    return <ErrorScreen title={textContent.product.productError} />;
  }

  return (
    <Flex justifyContent="flex-start" direction="column">
      <GoBack title={textContent.onTrip} />
      <Flex
        backgroundColor="grey50"
        height={{ base: '130px', md: '198px' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
      >
        <CategoriesCarousel />
      </Flex>
      <Box padding={{ base: '0', md: '0 80px', xl: '0 112px' }} backgroundColor="grey50">
        <Text
          color="darkBlue"
          textAlign="start"
          fontSize={{ base: '16px', md: '20px' }}
          fontWeight={{ base: '500', md: '600' }}
          margin={{ base: '24px', xl: '24px 0' }}
        >
          {textContent.product.pendingToReceive}
        </Text>
        <Grid
          gridTemplateColumns={{
            base: 'repeat(auto-fill, minmax(150px, 1fr))',
            xl: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
        >
          {data &&
            data?.products.map((product, index) => (
              <Flex justifyContent={'center'} key={index} marginBottom="20px">
                <ProductCard
                  urlKey={`${URL.GET_PAGINATED_PRODUCTS_PENDING_TO_RECEIVE}`}
                  product={product}
                />
              </Flex>
            ))}
        </Grid>
        <PaginationNavbar
          totalPages={data?.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </Flex>
  );
};

export default PendingToReceiveView;
