import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  MenuItem,
  Skeleton,
} from '@chakra-ui/react';

import { NewCategories } from '../../../utils/categoryOrder';
import { transformString } from '../../../utils/search-items';

interface SubCategoriesProps {
  index: number;
  isLoading: boolean;
  category: NewCategories;
  closeModal: () => void;
}

const SubCategories = ({ index, isLoading, category, closeModal }: SubCategoriesProps) => {
  const navigate = useNavigate();
  return (
    <>
      {category.item.length ? (
        category.item.map((product, productIndex) => (
          <AccordionItem key={productIndex} border={'none'}>
            {product.subItems.length ? (
              ({ isExpanded }) => (
                <>
                  <AccordionButton padding="5px">
                    {isExpanded ? <ChevronUpIcon boxSize={5} /> : <ChevronRightIcon boxSize={5} />}
                    <Skeleton isLoaded={!isLoading} height="20px">
                      <Box as="span" flex="1" textAlign="left" fontSize="12px">
                        {transformString(product.subCategory)}
                      </Box>
                    </Skeleton>
                  </AccordionButton>
                  <AccordionPanel>
                    {product.subItems.map((subItem, index) => (
                      <MenuItem
                        key={index}
                        as="a"
                        onClick={() => navigate(`/category/${category.code}/${subItem.code}`)}
                        fontSize="12px"
                        _hover={{ backgroundColor: '#f0f0f0' }}
                      >
                        {transformString(subItem.description)}
                      </MenuItem>
                    ))}
                  </AccordionPanel>
                </>
              )
            ) : (
              <AccordionButton padding="5px">
                <Skeleton isLoaded={!isLoading} height="20px">
                  <Box
                    as="a"
                    onClick={() => {
                      navigate(`/category/${category.code}/${product.code}`);
                      closeModal();
                    }}
                    data-testid={`menuItem${index}Product${productIndex}`}
                    marginLeft="20px"
                    fontSize="12px"
                  >
                    {transformString(product.subCategory)}
                  </Box>
                </Skeleton>
              </AccordionButton>
            )}
          </AccordionItem>
        ))
      ) : (
        <AccordionItem border={'none'}>
          <AccordionButton padding="5px">
            <Skeleton isLoaded={!isLoading} height="20px">
              <Box as="a" href={`/category/${category.code}`} marginLeft="20px" fontSize="12px">
                {transformString(category.category)}
              </Box>
            </Skeleton>
          </AccordionButton>
        </AccordionItem>
      )}
    </>
  );
};

export default SubCategories;
