import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import EmptyHistoryReservationIcon from '../../../assets/Icons/EmptyReservationHistoryIcon';
import textContent from '../../../utils/textContent.json';

const EmptyReservationHistory = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      paddingTop="120px"
      paddingBottom="120px"
    >
      <EmptyHistoryReservationIcon dataTestId="empty-reservation-history" />
      <Text
        width="350px"
        textAlign="center"
        fontSize={{ base: '20px', md: '25px' }}
        fontWeight="500"
        data-testid="empty-reservation-history-message"
      >
        {textContent.emptyReservationHistory}
      </Text>
    </Flex>
  );
};

export default EmptyReservationHistory;
