//ROUTES

export enum UiRoutes {
  LOGIN = '/login',
}

export const DEFAULT_PRICE_LIST_CODE = '007';

export const NumberKeys: string[] = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Backspace',
  'Delete',
  'Supr',
  'ArrowLeft',
  'ArrowRight',
  'Tab',
  'Enter',
  'Control',
  'c',
  'v',
];
